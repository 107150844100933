import { BACKEND_URL } from '../../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../../utils/config';
import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CenteredBackground from '../../utils/CenteredBackground';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    Icon
} from 'semantic-ui-react'

// Initialize Stripe with your publishable key
const stripePromise = loadStripe("pk_test_51KLjxrBS42P1i4PWMHrFWUkvxsts0IRevUXwgdhVTdpGtNEBauZ6xVoNBAzQslIpeYhloEZoxVNBOiAT2HAiphwc002qeo3nEl");

// Create a wrapper component that includes the Elements provider
const PaymentCompleteWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentCompleteContent />
    </Elements>
  );
};

// Rename the main component and move it here
const PaymentCompleteContent = () => {
  const stripe = useStripe();
  const [status, setStatus] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const clientSecret = searchParams.get('payment_intent_client_secret');
    const orderId = searchParams.get('order_id'); // Get order ID from URL if available
    const token = localStorage.getItem('token');

    if (clientSecret) {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }) => {
          console.log('Retrieved PaymentIntent:', paymentIntent);
          setPaymentIntent(paymentIntent);
          
          if (paymentIntent.status === 'succeeded') {
            // Update order in backend
            const orderIdFromTransferGroup = paymentIntent.transfer_group?.split('_')[1];
            const finalOrderId = orderId || orderIdFromTransferGroup;

            if (finalOrderId) {
              axios.put(`${BACKEND_URL}/orders/${finalOrderId}`, {
                paymentDetails: paymentIntent,
                paid: true
              }, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`
                }
              })
              .then(response => {
                console.log('Order updated successfully:', response);
                setStatus('success');
              })
              .catch(error => {
                console.error('Error updating order:', error);
                // Still show success to user since payment succeeded
                setStatus('success');
              });
            } else {
              console.error('No order ID found');
              setStatus('success');
            }
          } else if (paymentIntent.status === 'processing') {
            setStatus('processing');
          } else {
            setStatus('failed');
          }
        })
        .catch(error => {
          console.error('Error retrieving PaymentIntent:', error);
          setStatus('failed');
        });
    }
  }, [stripe]);

  if (status === 'processing') {
    return (
      <CenteredBackground
        headerText="Payment Processing"
        subText="We'll update you when payment is received."
        buttonText="Check My Orders"
        buttonLink="/mypage"
        buttonColor="orange"
      />
    );
  }

  if (status === 'failed') {
    return (
      <CenteredBackground
        headerText="Payment Failed"
        subText="Please try another payment method."
        buttonText="Try Again"
        buttonLink="/checkout"
        buttonColor="red"
      />
    );
  }

  if (status === 'success') {
    return (
      <CenteredBackground
        headerText="Payment Successful"
        subText="Thank you for your purchase!"
        buttonText="Check My Orders"
        buttonLink="/mypage"
        buttonColor="green"
      />
    );
  }

  return (
    <CenteredBackground
      headerText={"Processing... "}
      subText="Please wait while we confirm your payment."
      buttonColor="orange"
    />
  );
};

// Export the wrapper component instead
export default PaymentCompleteWrapper; 