import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Thunk for fetching address details from Google Places API
export const fetchAddressDetails = createAsyncThunk(
  'address/fetchDetails',
  async (placeId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
      );

      const addressComponents = response.data.results[0].address_components;
      return {
        street: [
          addressComponents.find(c => c.types.includes('street_number'))?.long_name,
          addressComponents.find(c => c.types.includes('route'))?.long_name
        ].filter(Boolean).join(' '),
        city: addressComponents.find(c => c.types.includes('locality'))?.long_name || '',
        state: addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.long_name || '',
        postalCode: addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '',
        country: addressComponents.find(c => c.types.includes('country'))?.long_name || '',
        formatted: response.data.results[0].formatted_address,
        coordinates: response.data.results[0].geometry.location
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch address details');
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  selectedAddress: null,
  addresses: [], // For storing multiple addresses if needed
  fieldErrors: {}
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    clearSelectedAddress: (state) => {
      state.selectedAddress = null;
    },
    setAddressError: (state, action) => {
      state.fieldErrors = action.payload;
    },
    clearAddressError: (state) => {
      state.fieldErrors = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddressDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAddressDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedAddress = action.payload;
      })
      .addCase(fetchAddressDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { 
  setSelectedAddress, 
  clearSelectedAddress, 
  setAddressError, 
  clearAddressError 
} = addressSlice.actions;

export default addressSlice.reducer; 