import React from 'react';

const FormInput = ({ label, error, as = 'input', required, children, ...props }) => {
  const getInputClasses = (error) => 
    `form-${as} w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${error ? 'border-red-300' : 'border-gray-300'}`;

  return (
    <div className="max-w-form-input">
      <label className="block text-label font-medium text-gray-700 mb-2">
        {label} {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      {as === 'select' ? (
        <select className={getInputClasses(error)} {...props}>
          {children}
        </select>
      ) : as === 'textarea' ? (
        <textarea className={getInputClasses(error)} {...props} />
      ) : (
        <input className={getInputClasses(error)} {...props} />
      )}
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
};

export default FormInput; 