import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

// Initial state
const initialState = {
  buyProducts: [],
  rentProducts: [],
  cart: [],
  rentCart: [],
  buyTotal: 0,
  rentTotal: 0,
  loading: false,
  error: null,
  addCartSuccess: true,
  addRentSuccess: true,
  groupedBuyProducts: {},
  groupedRentProducts: {}
};

// Transaction types
export const TRANSACTION_TYPES = {
  BUY: 'buy',
  RENT: 'rent'
};

// Product types
export const PRODUCT_TYPES = {
  CLOTHING: 'clothing',
  ACCESSORY: 'accessory',
  SECOND_HAND: 'second-hand'
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setCartError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.addCartSuccess = false;
      state.addRentSuccess = false;
    },
    setBuyProducts: (state, action) => {
      state.loading = false;
      state.error = null;
      state.buyProducts = action.payload;
      
      // Group products by merchant
      state.groupedBuyProducts = action.payload.reduce((groups, product) => {
        const merchantId = product.users_permissions_user?.id;
        if (!merchantId) return groups;
        
        if (!groups[merchantId]) {
          groups[merchantId] = {
            merchantId: merchantId,
            products: []
          };
        }
        groups[merchantId].products.push(product);
        return groups;
      }, {});

      state.buyTotal = action.payload.reduce((sum, item) => 
        item.stock !== '0' ? sum + (item.price * item.quantity) : sum, 0);
    },
    setRentProducts: (state, action) => {
      state.loading = false;
      state.error = null;
      state.rentProducts = action.payload;

      // Group rent products by merchant
      state.groupedRentProducts = action.payload.reduce((groups, product) => {
        const merchantId = product.users_permissions_user?.id;
        if (!merchantId) return groups;
        
        if (!groups[merchantId]) {
          groups[merchantId] = {
            merchantId: merchantId,
            products: []
          };
        }
        groups[merchantId].products.push(product);
        return groups;
      }, {});

      state.rentTotal = action.payload.reduce((sum, item) => 
        item.stock !== '0' && item.rentTimeSelect ? sum + (item.rentTimeSelect.price * item.quantity) : sum, 0);
    },
    setCartItems: (state, action) => {
      state.cart = action.payload.cart || [];
      state.rentCart = action.payload.rentCart || [];
    },
    setAddCartSuccess: (state, action) => {
      state.addCartSuccess = action.payload;
    },
    setAddRentSuccess: (state, action) => {
      state.addRentSuccess = action.payload;
    },
    clearCart: (state) => {
      return initialState;
    }
  }
});

export const {
  setCartLoading,
  setCartError,
  setBuyProducts,
  setRentProducts,
  setCartItems,
  setAddCartSuccess,
  setAddRentSuccess,
  clearCart
} = cartSlice.actions;

// Selectors
export const selectBuyProducts = state => state.cart?.buyProducts || [];
export const selectRentProducts = state => state.cart?.rentProducts || [];
export const selectBuyTotal = state => state.cart?.buyTotal || 0;
export const selectRentTotal = state => state.cart?.rentTotal || 0;
export const selectCartLoading = state => state.cart?.loading || false;
export const selectCartError = state => state.cart?.error || null;
export const selectAddCartSuccess = state => state.cart?.addCartSuccess || true;
export const selectAddRentSuccess = state => state.cart?.addRentSuccess || true;
export const selectCartItems = state => ({
  cart: state.cart?.cart || [],
  rentCart: state.cart?.rentCart || []
});
export const selectGroupedBuyProducts = state => state.cart?.groupedBuyProducts || {};
export const selectGroupedRentProducts = state => state.cart?.groupedRentProducts || {};

// Helper function to create cart item
export const createCartItem = ({
  id,
  number,
  color,
  size,
  productType,
  merchant,
  rentTimeSelect = null,
  startDate = null,
  endDate = null
}) => ({
  id,
  number,
  color,
  size,
  productType,
  merchant,
  ...(rentTimeSelect && {
    rentTimeSelect,
    startDate,
    endDate
  })
});

// Thunk to fetch cart items
export const fetchCartItems = () => async (dispatch) => {
  try {
    dispatch(setCartLoading());
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.log('No token found');
      dispatch(setCartError('Please sign in to view cart'));
      return;
    }

    const userResponse = await axiosInstance.get('/users/me', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });

    if (!userResponse.data) {
      console.log('Failed to fetch user data:', userResponse);
      dispatch(setCartError('Unable to load user data'));
      return;
    }

    const userData = userResponse.data;
    
    // Set cart items in state
    dispatch(setCartItems({
      cart: userData.cart || [],
      rentCart: userData.rentCart || []
    }));

    // Fetch products
    await dispatch(getProductInBuyCart());
    await dispatch(getProductInRentCart());

  } catch (error) {
    console.error('Cart fetch error:', error);
    dispatch(setCartError(error.message));
  }
};

// Thunk to get buy products
export const getProductInBuyCart = () => async (dispatch, getState) => {
  try {
    const { cart = [] } = selectCartItems(getState());
    
    if (!cart || cart.length === 0) {
      dispatch(setBuyProducts([]));
      return;
    }

    const promises = cart.map(async (cartItem) => {
      try {
        // Now using single products endpoint
        const res = await axiosInstance.get('/products?id=' + cartItem.id);
        if (res.status === 200) {
          const product = res.data[0];
          return {
            ...product,
            quantity: cartItem.number,
            color: cartItem.color,
            size: cartItem.size,
            merchant: cartItem.merchant
          };
        }
        return null;
      } catch (err) {
        console.error('Error fetching product:', err);
        return null;
      }
    });

    const products = (await Promise.all(promises)).filter(Boolean);
    dispatch(setBuyProducts(products));
  } catch (error) {
    console.error('Error in getProductInBuyCart:', error);
    dispatch(setCartError(error.message));
  }
};

// Thunk to get rent products
export const getProductInRentCart = () => async (dispatch, getState) => {
  try {
    const { rentCart = [] } = selectCartItems(getState());
    var price = 0;

    if (!rentCart || rentCart.length === 0) {
      dispatch(setRentProducts([]));
      return;
    }

    const promises = rentCart.map(async (cartItem) => {
      try {
        const res = await axiosInstance.get('/products?id=' + cartItem.id);
        if (res.status === 200) {
          const product = res.data[0];
          product.quantity = cartItem.number;
          product.color = cartItem.color;
          product.size = cartItem.size;
          product.merchant = cartItem.merchant;
          product.endDate = cartItem.endDate;
          product.startDate = cartItem.startDate;
          product.rentTimeSelect = cartItem.rentTimeSelect;
          const itemPrice = cartItem.rentTimeSelect ? cartItem.rentTimeSelect.price * product.quantity : 0;
          price += itemPrice;
          return product;
        }
        console.error('Rent product fetch failed:', { id: cartItem.id, status: res.status });
        return null;
      } catch (err) {
        console.error('Error fetching rent product:', { id: cartItem.id, error: err.message });
        return null;
      }
    });

    const products = (await Promise.all(promises)).filter(Boolean);
    dispatch(setRentProducts(products));
  } catch (error) {
    console.error('Error in getProductInRentCart:', error);
    dispatch(setCartError(error.message));
  }
};

// Thunk to update cart items
export const updateCartItems = ({ id, action, productType, color, size, merchant, rentTimeSelect, startDate, endDate }) => async (dispatch, getState) => {
  try {
    dispatch(setCartLoading());
    const token = localStorage.getItem('token');
    const userInfo = getState().user.userInfo;
    
    console.log('Update cart params:', { id, action, productType, color, size, merchant });
    console.log('Auth check:', { hasToken: !!token, hasUserInfo: !!userInfo });

    if (!token || !userInfo) {
      console.log('Missing auth:', { token, userInfo });
      dispatch(setCartError('Please sign in to update cart'));
      return;
    }

    const { cart = [], rentCart = [] } = selectCartItems(getState());
    let newCart = [...cart];
    let newRentCart = [...rentCart];
    const isRent = !!rentTimeSelect;

    if (action === 'delete') {
      if (isRent) {
        newRentCart = rentCart.filter(item => item.id !== id);
      } else {
        newCart = cart.filter(item => item.id !== id);
      }
    } else if (action === 'add') {
      const cartItem = createCartItem({
        id,
        number: 1,
        color,
        size,
        productType,
        merchant,
        rentTimeSelect,
        startDate,
        endDate
      });

      if (isRent) {
        newRentCart = [...rentCart, cartItem];
      } else {
        newCart = [...cart, cartItem];
      }
    } else {
      if (isRent) {
        newRentCart = rentCart.map(item => 
          item.id === id 
            ? { ...item, number: action === 'increase' ? item.number + 1 : item.number - 1 }
            : item
        );
      } else {
        newCart = cart.map(item => 
          item.id === id 
            ? { ...item, number: action === 'increase' ? item.number + 1 : item.number - 1 }
            : item
        );
      }
    }

    const response = await axiosInstance.put('/users/' + userInfo.id, {
      cart: newCart,
      rentCart: newRentCart
    }, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    
    if (response.status === 200) {
      if (isRent) {
        dispatch(setAddRentSuccess(true));
      } else {
        dispatch(setAddCartSuccess(true));
      }
    } else {
      console.error('Update failed:', response);
      dispatch(setCartError(`Failed to update ${isRent ? 'rent' : 'buy'} cart items`));
      return;
    }

    // Refresh cart data
    dispatch(fetchCartItems());
    
  } catch (error) {
    console.error('Cart update error:', error);
    dispatch(setCartError(error.message));
  }
};

export default cartSlice.reducer; 