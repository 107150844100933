import React from 'react'
import {
  Segment,
  Button,
  Divider,
  Form,
  Grid,
  Checkbox,
  Message,
  Portal
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import newOTP from 'otp-generators'
import emailjs from 'emailjs-com'
import Foot from '../foot/Foot'
import { connect } from 'react-redux'
import { setUserInfo, setToken, setMerchantLogin, handleMerchantRedirect } from '../../redux/slices/userSlice'
import { BACKEND_URL } from '../../utils/config'

const userLang = navigator.language || navigator.userLanguage
var jwt = require('jsonwebtoken')

//live
const baseUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_susu_baseUrl_live
  : process.env.REACT_APP_susu_baseUrl_localTest;

class LoginPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      products: [],
      rememberMe: false,
      loading: true,
      buttonLoading: false,
      loggedIn: false,
      somethingWrong: false,
      windowWidth: window.innerWidth
    }
    this.onHandleUsername = this.onHandleUsername.bind(this)
    this.onHandlePassword = this.onHandlePassword.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.onHandleRememberMe = this.onHandleRememberMe.bind(this)
    this.getQueryParam = this.getQueryParam.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    var loginFailure = self.props.loginFailure
    
    // Add window resize listener
    window.addEventListener('resize', this.handleResize);
    
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    const urlParams = new URLSearchParams(window.location.search)
    const isClosetUser = urlParams.get('isClosetUser')

    // Check if user is already logged in and redirect if needed
    if (this.props.isLoggedIn) {
      this.props.handleMerchantRedirect();
      return;
    }

    if (loginFailure === 'Wrong identifier combination') {
      self.setState({
        somethingWrong: 'Wrong identifier & password combination'
      })
    } else if (loginFailure === 'An error occured') {
      self.setState({ somethingWrong: 'Something wrong occured!' })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
    
    const remember = localStorage.getItem('rememberMe')
    var identifier = localStorage.getItem('identifier')
    var password = localStorage.getItem('password')
    if (remember === 'true')
      this.setState({
        rememberMe: remember,
        username: identifier,
        password: password
      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getQueryParam (param) {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param)
  }

  onHandleUsername (event) {
    this.setState({
      username: event.target.value
    })
  }

  onHandlePassword (event) {
    this.setState({
      password: event.target.value
    })
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const remember = this.state.rememberMe
    const self = this
    self.setState({
      buttonLoading: true
    })

    var identifier = this.state.username
    var password = this.state.password
    var rememberMe = this.state.rememberMe

    const urlParams = new URLSearchParams(window.location.search)
    var isClosetUser = self.props.location.search
    isClosetUser = isClosetUser ? isClosetUser.split('=')[1] : null
    
    if (remember === 'true') {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const identifierRetype = this.state.username
      const passwordRetype = this.state.password
      if (identifierRetype !== '' && passwordRetype !== '') {
        identifier = identifierRetype
        password = passwordRetype
      }
    } else {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const rememberMe = localStorage.getItem('rememberMe')

      if (rememberMe !== null) {
        localStorage.removeItem('rememberMe')
      }

      if (
        (identifier === null && password === null) ||
        (identifier === '' && password === '')
      ) {
        identifier = this.state.username
        password = this.state.password
      }
    }

    axios
      .post(`${BACKEND_URL}/auth/local`, {
        identifier: identifier,
        password: password
      })
      .then(function (res) {
        if (res.status === 200) {
          const emailConfirmed = res.data.user.confirmed
          const merchantConfirmed = res.data.user.merchantConfirmed
          const isMerchant = res.data.user.merchantInfo?.isMerchant
          self.setState({
            emailConfirmed: emailConfirmed
          })

          const token = res.data.jwt
          const email = res.data.user.email
          const username = res.data.user.username
          const id = res.data.user.id

          // Set user info in Redux
          self.props.setUserInfo(res.data.user)
          self.props.setToken(token)

          // Handle merchant login state
          if (isMerchant && merchantConfirmed) {
            self.props.setMerchantLogin(true)
          }

          if (rememberMe) {
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            localStorage.setItem('rememberMe', rememberMe)
          }

          if (!emailConfirmed && !merchantConfirmed) {
            const verificationCode = newOTP.generate(6, {
              alphabets: false,
              upperCase: false,
              specialChar: false
            })

            const token = jwt.sign(
              {
                data: verificationCode
              },
              'sheyoufashion',
              { expiresIn: '5min' }
            )

            emailjs
              .send(
                'service_orld5qb',
                'template_c1h9qsr',
                {
                  email: email,
                  reply_to: 'susanna.xinshu@gmail.com',
                  name: username,
                  verificationCode: verificationCode
                },
                'user_4rMSnDYBigUutDlR5RSib'
              )
              .then(
                function (response) {
                  self.setState({
                    loading: false,
                    sentEmail: true
                  })

                  localStorage.setItem('identifier', identifier)
                  localStorage.setItem('verificationCode', token)
                  self.props.history.push('/emailConfirmation')
                },
                function (error) {
                  self.setState({
                    loading: false,
                    sentEmail: false,
                    somethingWrong:
                      'Email confirmation FAILED, please contact us...' +
                      error.text
                  })
                }
              )
          } else if (emailConfirmed && !merchantConfirmed) {
            if (isClosetUser) {
              localStorage.setItem('closetLogin', 'true')
            }

            self.setState({
              loggedIn: true,
              buttonLoading: false
            })

            if (isClosetUser) {
              window.location.href = `${baseUrl}#/?user=${id}&success=true&token=${token}`
            } else {
              self.props.history.push('/mypage')
            }
          } else if (merchantConfirmed) {
            self.setState({
              loggedIn: true,
              buttonLoading: false
            })
            // Use Redux for merchant redirection
            self.props.handleMerchantRedirect()
          }
        }
      })
      .catch(error => {
        self.setState({
          buttonLoading: false,
          rememberMe: false,
          username: '',
          password: ''
        })
        if (error.response?.status === 400) {
          self.setState({
            buttonLoading: false,
            somethingWrong: 'Wrong identifier & password combination'
          })
          localStorage.removeItem('identifier')
          localStorage.removeItem('password')
          localStorage.removeItem('rememberMe')
        } else {
          self.setState({
            buttonLoading: false,
            somethingWrong: 'An error occurred:' + (error.response?.statusText || error.message)
          })
        }
      })
  }

  onHandleResetPassword (event) {
    this.props.history.push('/forgetpassword')
  }

  onHandleRememberMe (event) {
    const self = this
    const remember = self.state.rememberMe
    if (remember !== 'true') {
      self.setState({ rememberMe: true })
    } else {
      self.setState({ rememberMe: false })
    }
  }

  render () {
    // Use Redux for checking login status and redirection
    if (this.props.isLoggedIn) {
      return null; // Let componentDidMount handle the redirection
    }

    const remember = this.state.rememberMe
    const identifier = this.state.username
    const password = this.state.password
    const windowWidth = this.state.windowWidth
    var isClosetUser = this.props.location.search
    isClosetUser = isClosetUser ? isClosetUser.split('=')[1] : null

    const backgroundStyle = isClosetUser
      ? {
          height: '100vh',
          backgroundImage:
            "url('https://res.cloudinary.com/sheyou/image/upload/v1727875894/virtual_closet_5d66ccc54e.svg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
      : {
          height: '100vh'
        }

    return (
      <div>
        <div className='gradient_limeade' style={backgroundStyle}>
          <Segment placeholder>
            <Grid columns={windowWidth < 655 ? 1 : 2} centered>
              <Grid.Column>
                {this.state.loggedIn === true && (
                  <Message positive>
                    <Message.Header>
                      {userLang === 'zh-CN' ? '成功' : 'Success'}
                    </Message.Header>
                    <p>
                      {userLang === 'zh-CN'
                        ? '您已成功登陆'
                        : 'Now You Are Logged In'}
                    </p>
                  </Message>
                )}
                {this.state.somethingWrong !== false && (
                  <Message negative>
                    <Message.Header>
                      {userLang === 'zh-CN' ? '登陆失败' : 'Log In Failure'}
                    </Message.Header>
                    <p>
                      {this.state.somethingWrong ===
                      'Wrong identifier & password combination'
                        ? userLang === 'zh-CN'
                          ? '用户名和密码错误或者不匹配'
                          : 'Wrong identifier & password combination'
                        : userLang === 'zh-CN'
                        ? '出现未知问题:('
                        : this.state.somethingWrong}
                    </p>
                  </Message>
                )}
                {this.state.emailConfirmed === false && userLang !== 'zh-CN' && (
                  <Message warning>
                    <Message.Header>Email not confirmed</Message.Header>
                    <p>You will get an email for confirmation</p>
                  </Message>
                )}

                <Form>
                  <Form.Input
                    onChange={this.onHandleUsername}
                    icon='user'
                    iconPosition='left'
                    label={
                      userLang === 'zh-CN'
                        ? '用户名 / 邮箱'
                        : 'Username / email'
                    }
                    placeholder='Username / email'
                    value={remember === 'true' ? identifier : undefined}
                  />

                  <Form.Input
                    onChange={this.onHandlePassword}
                    icon='lock'
                    iconPosition='left'
                    label={userLang === 'zh-CN' ? '密码' : 'Password'}
                    type='password'
                    value={remember === 'true' ? password : undefined}
                  />

                  <Form.Field>
                    <Checkbox
                      defaultChecked={remember === 'true'}
                      color='orange'
                      label={userLang === 'zh-CN' ? '记住我' : 'Remember Me'}
                      onClick={this.onHandleRememberMe}
                    />
                  </Form.Field>
                </Form>
                <br />
                <br />
                <Grid stackable>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Button
                        loading={this.state.buttonLoading}
                        onClick={this.onHandleSubmit}
                        content={userLang === 'zh-CN' ? '登录' : 'Log In'}
                        color='orange'
                        fluid
                      />
                    </Grid.Column>

                    <Grid.Column>
                      <Link to='/forgetpassword'>
                        <Button
                          basic
                          content={
                            userLang === 'zh-CN'
                              ? '忘记密码'
                              : 'Forget Password'
                          }
                          color='orange'
                          fluid
                        />
                      </Link>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>

              {windowWidth < 655 && (
                <Divider horizontal style={{ width: '60vw' }}>
                  Or
                </Divider>
              )}

              <Grid.Column verticalAlign='middle'>
                <Link to={`/signup?isClosetUser=${isClosetUser}`}>
                  <Button
                    className='ml-auto'
                    content={userLang === 'zh-CN' ? '注册' : 'Sign up'}
                    icon='signup'
                    size='big'
                    color='orange'
                    fluid
                  />
                </Link>
              </Grid.Column>
            </Grid>

            {windowWidth >= 655 && <Divider vertical>Or</Divider>}
          </Segment>
        </div>

        <Foot />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  userInfo: state.user.userInfo,
  merchantLogin: state.user.merchantLogin
})

const mapDispatchToProps = {
  setUserInfo,
  setToken,
  setMerchantLogin,
  handleMerchantRedirect
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage))
