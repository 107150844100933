/**
 * Formats an address object to match the backend schema
 * @param {Object} address - The address object to format
 * @returns {Object} Formatted address object
 */
export const formatAddress = (address) => {
  return {
    street: address.street || '',
    city: address.city || '',
    state: address.state || '',
    postalCode: address.postalCode || '',
    country: address.country || '',
    default: address.default || false,
    id: address.id || String(Date.now()) // Generate an ID if none exists
  };
};

/**
 * Validates if an address object has all required fields
 * @param {Object} address - The address object to validate
 * @returns {boolean} Whether the address is valid
 */
export const isValidAddress = (address) => {
  const requiredFields = ['street', 'city', 'state', 'postalCode', 'country'];
  return requiredFields.every(field => address[field] && address[field].trim() !== '');
};

/**
 * Formats an address object into a display string
 * @param {Object} address - The address object to format
 * @returns {string} Formatted address string
 */
export const formatAddressDisplay = (address) => {
  if (!address) return '';
  
  const { street, city, state, postalCode, country } = address;
  return `${street}, ${city}, ${state} ${postalCode}, ${country}`;
};

/**
 * Parses a raw address object into the correct format
 * @param {Object} rawAddress - The raw address object to parse
 * @returns {Object} Parsed and formatted address object
 */
export const parseAddress = (rawAddress) => {
  // Handle different possible formats and normalize them
  return formatAddress({
    street: rawAddress.street || rawAddress.streetAddress || rawAddress.address,
    city: rawAddress.city,
    state: rawAddress.state || rawAddress.province || rawAddress.region,
    postalCode: rawAddress.postalCode || rawAddress.zip || rawAddress.zipCode,
    country: rawAddress.country,
    id: rawAddress.id
  });
}; 