import { BACKEND_URL } from '../../utils/config';
import React from 'react'
import axios from 'axios'
import { Grid, Menu, Header, Button, Segment, Icon } from 'semantic-ui-react'

import Setting from '../profilePage/Setting'
import { Parallax } from 'react-parallax'
import { Link } from 'react-router-dom'

import Foot from '../foot/Foot'
// import newOTP from 'otp-generators'
// import emailjs from 'emailjs-com'
import PastOrders from '../pastOrders/PastOrdersList'
import PastPastOrders from '../pastOrders/PastPastOrderList'
import OngoingPastOrders from '../pastOrders/OngoingtPastOrderList'
import Profile from '../profilePage/ProfilePage'
import Membership from '../membership/Membership'

const userLang = navigator.language || navigator.userLanguage
// var jwt = require('jsonwebtoken')


class MyPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: [],
      order: [],
      someData: [],
      activeItem: 'All',
      activeItemZh: '全部',
      clickValue: '',
      login: false,
      buyOrRent: '',
      justSomeProductInfo: [],
      orders: [],
      loading: true
    }
    this.getProducts = this.getProducts.bind(this)
    this.handleItemClickZh = this.handleItemClickZh.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this

    this.getProducts()
    this.ifLog()

    console.log(this.props)
    if (this.props.activeItem === 'Membership') {
      self.setState({
        activeItem: 'Membership'
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        self.setState({
          loading: false
        })
      }
    }, 7000)
  }

  getProducts () {
    const self = this
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    axios(
      `${BACKEND_URL}/orders?users_permissions_user.username=${identifier}`,
      {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    ).then(function (res) {
      if (res.status === 200) {
        console.log(res.data)
        const d = res.data
        console.log(res)
        const len = d.length
        const orders = []
        for (var i = len - 1; i >= 0; i--) {
          orders.push(d[i])
        }
        self.setState({
          orders: orders
        })

        window.setTimeout(() => {
          self.setState({
            loading: false
          })
        }, 1000)        
      } else {
        alert('something went wrong :(')
        localStorage.setItem('login', 'false')
      }
    }).catch(function(err) {
      console.error('Error fetching orders:', err);
      self.setState({ loading: false });
      localStorage.setItem('login', 'false');
    });
  }

  ifLog () {
    const log = localStorage.getItem('login')
    console.log(log)
    const self = this
    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })
    }
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleItemClickZh (e, { name }) {
    console.log(name)
    this.setState({ activeItemZh: name })
  }
  render () {
    const s = this.state
    console.log(s)
    const orders = s.orders
    const loading = s.loading
    var activeItem = this.state.activeItem
    const login = s.login
    const activeItemZh = s.activeItemZh
    console.log(orders, activeItemZh)

    // var ordersWithRealProductInfo = localStorage.getItem('ordersWithRealProductInfo')
    // ordersWithRealProductInfo = JSON.parse(ordersWithRealProductInfo)

    return (
      <div>
        {/* <Nav /> */}

        {loading === true && (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        )}

        {loading === false && login === false && (
          <Parallax
            bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
            bgImageAlt=''
            strength={200}
          >
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Segment>
                <Grid>
                  <Grid.Row centered>
                    <Header>
                      {userLang === 'zh-CN'
                        ? '请登录您的账号'
                        : 'You Should login first'}
                    </Header>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Link to='/signin'>
                      <Button size='huge' color='orange'>
                        {userLang === 'zh-CN' ? '登录' : 'Login'}
                      </Button>
                    </Link>
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>

            <div style={{ height: '200px' }} />
          </Parallax>
        )}

        {loading === false && login === true && (
          <Grid columns={2} style={{ paddingTop: 10 }}>
            {userLang === 'zh-CN' && (
              <>
                <Grid.Column width={3} style={{ paddingLeft: 50 }}>
                  <Menu secondary vertical>
                    <Menu.Item header color='teal'>
                      我的订单
                    </Menu.Item>
                    <Menu.Item
                      name='全部'
                      active={activeItemZh === '全部'}
                      onClick={this.handleItemClickZh}
                    />
                    <Menu.Item
                      name='进行中'
                      active={activeItemZh === '进行中'}
                      onClick={this.handleItemClickZh}
                    />
                    <Menu.Item
                      name='已完成'
                      active={activeItemZh === '已完成'}
                      onClick={this.handleItemClickZh}
                    />
                  </Menu>

                  <Menu secondary vertical>
                    <Menu.Item header color='teal'>
                      账号信息
                    </Menu.Item>

                    <Menu.Item
                      name='简介'
                      active={activeItemZh === '简介'}
                      onClick={this.handleItemClickZh}
                    />
                    <Menu.Item
                      name='设置'
                      active={activeItemZh === '设置'}
                      onClick={this.handleItemClickZh}
                    />
                  </Menu>

                  <Menu secondary vertical>
                    <Menu.Item header color='teal'>
                      计划
                    </Menu.Item>

                    <Menu.Item
                      name='会员计划'
                      active={activeItemZh === '会员计划'}
                      onClick={this.handleItemClickZh}
                    />
                    <Menu.Item
                      name='折扣'
                      active={activeItemZh === '折扣'}
                      onClick={this.handleItemClickZh}
                    />
                  </Menu>
                </Grid.Column>
                {orders.length === 0 &&
                  activeItemZh !== '简介' &&
                  activeItemZh !== '折扣' &&
                  activeItemZh !== '设置' &&
                  activeItemZh !== '会员计划' && (
                    <Grid.Column width={13} style={{ paddingRight: 40 }}>
                      <Grid>
                        <Grid.Row centered>
                          <Header centered color='orange' as='h1'>
                            {' '}
                            您没有任何订单
                          </Header>
                          <Header centered color='orange' as='h1'>
                            {' '}
                            可以先浏览我们的衣橱
                          </Header>
                        </Grid.Row>
                        <Grid.Row centered>
                          <Link to='/clothing'>
                            <Button color='teal'>前往</Button>
                          </Link>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  )}

                {orders.length !== 0 && activeItemZh === '全部' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Grid divided='vertically'>
                      <PastOrders orders={orders} activeItem={'All'} />
                    </Grid>
                  </Grid.Column>
                )}
                {orders.length !== 0 && activeItemZh === '已完成' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Grid divided='vertically'>
                      <PastPastOrders orders={orders} activeItem={'Past'} />
                    </Grid>
                  </Grid.Column>
                )}
                {orders.length !== 0 && activeItemZh === '进行中' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Grid divided='vertically'>
                      <OngoingPastOrders
                        orders={orders}
                        activeItem={'Ongoing'}
                      />
                    </Grid>
                  </Grid.Column>
                )}
                {activeItemZh === '简介' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Segment>
                      <Profile />
                    </Segment>
                  </Grid.Column>
                )}

                {activeItemZh === '折扣' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Segment></Segment>
                  </Grid.Column>
                )}
                {activeItemZh === '设置' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Segment>
                      <Setting />
                    </Segment>
                  </Grid.Column>
                )}
                {activeItemZh === '会员计划' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Segment>
                      <Membership />
                    </Segment>
                  </Grid.Column>
                )}
              </>
            )}

            {userLang !== 'zh-CN' && (
              <>
                <Grid.Column width={3} style={{ paddingLeft: 50 }}>
                  <Menu secondary vertical>
                    <Menu.Item header color='teal'>
                      My orders
                    </Menu.Item>
                    <Menu.Item
                      name='All'
                      active={activeItem === 'All'}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      name='Ongoing'
                      active={activeItem === 'Ongoing'}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      name='Past'
                      active={activeItem === 'Past'}
                      onClick={this.handleItemClick}
                    />
                  </Menu>

                  <Menu secondary vertical>
                    <Menu.Item header color='teal'>
                      Account Info
                    </Menu.Item>

                    <Menu.Item
                      name='Profile'
                      active={activeItem === 'Profile'}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      name='Setting'
                      active={activeItem === 'Setting'}
                      onClick={this.handleItemClick}
                    />
                  </Menu>

                  <Menu secondary vertical>
                    <Menu.Item header color='teal'>
                      Plans
                    </Menu.Item>

                    <Menu.Item
                      name='Membership'
                      active={activeItem === 'Membership'}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      name='Get Discount'
                      active={activeItem === 'Get Discount'}
                      onClick={this.handleItemClick}
                    />
                  </Menu>
                </Grid.Column>

                {orders.length === 0 &&
                  activeItem !== 'Profile' &&
                  activeItem !== 'Get Discount' &&
                  activeItem !== 'Setting' &&
                  activeItem !== 'Membership' && (
                    <Grid.Column width={13} style={{ paddingRight: 40 }}>
                      <Grid>
                        <Grid.Row centered>
                          <Header centered color='orange' as='h1'>
                            {' '}
                            You haven no order yet
                          </Header>
                          <Header centered color='orange' as='h1'>
                            {' '}
                            Check out the clothing
                          </Header>
                        </Grid.Row>
                        <Grid.Row centered>
                          <Link to='/clothing'>
                            <Button color='teal'>Let's go!</Button>
                          </Link>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  )}

                {orders.length !== 0 && activeItem === 'All' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <br />
                    <Grid divided='vertically'>
                      <PastOrders orders={orders} activeItem={activeItem} />
                    </Grid>
                  </Grid.Column>
                )}
                {orders.length !== 0 && activeItem === 'Past' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <br />
                    <Grid divided='vertically'>
                      <PastPastOrders orders={orders} activeItem={activeItem} />
                    </Grid>
                  </Grid.Column>
                )}
                {orders.length !== 0 && activeItem === 'Ongoing' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <br />
                    <Grid divided='vertically'>
                      <OngoingPastOrders
                        orders={orders}
                        activeItem={activeItem}
                      />
                    </Grid>
                  </Grid.Column>
                )}
                {activeItem === 'Profile' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Segment>
                      <Profile />
                    </Segment>
                  </Grid.Column>
                )}

                {activeItem === 'Get Discount' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Segment></Segment>
                  </Grid.Column>
                )}
                {activeItem === 'Setting' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Segment>
                      <Setting />
                    </Segment>
                  </Grid.Column>
                )}
                {activeItem === 'Membership' && (
                  <Grid.Column width={13} style={{ paddingRight: 40 }}>
                    <Segment>
                      <Membership />
                    </Segment>
                  </Grid.Column>
                )}
              </>
            )}
          </Grid>
        )}
        <Foot />
      </div>
    )
  }
}

export default MyPage
