import { BACKEND_URL } from '../../../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../../../utils/config';
import React from 'react'
import axios from 'axios'
import {
  Grid,
  Icon,
  Divider,
  Form,
  Segment,
  Container,
  Button,
  Header,
  Message
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class OrderPickUp extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      toConfirm: false,
      firstName: '',
      lastName: '',
      country: 'United States',
      state: '',
      city: '',
      zipcode: '',
      phonenumber: '',
      address1: '',
      address2: '',
      identifier: '',
      token: '',
      rentOrBuy: 'buy',
      userInfo: [],
      shippingaddress: '',
      loading: true,
      useAnother: false,
      orderid: '',
      missingInput: false,
      addressValid: false
    }
    this.getUserInfo = this.getUserInfo.bind(this)
    this.onFirstName = this.onFirstName.bind(this)
    this.onLastName = this.onLastName.bind(this)
    this.onCountry = this.onCountry.bind(this)
    this.onstate = this.onstate.bind(this)
    this.onCity = this.onCity.bind(this)
    this.onZipCode = this.onZipCode.bind(this)
    this.onPhonenumber = this.onPhonenumber.bind(this)
    this.onAddress1 = this.onAddress1.bind(this)
    this.onAddress2 = this.onAddress2.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.useAnother = this.useAnother.bind(this)
    this.useList = this.useList.bind(this)
  }
  componentDidMount () {
    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(
      orderidPosition + 8
    )

    const identifier = localStorage.getItem('identifier')
    const self = this

    self.setState({
      rentOrBuy: 'buy',
      identifier: identifier,
      orderid: orderid
    })
    this.getUserInfo()
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 2000)
    // console.log(allCountries.GetCode('China'))
  }
  getUserInfo () {
    const self = this
    // const token=this.state.token
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(
      orderidPosition + 8
    )
    self.setState({
      orderid: orderid
    })

    self.setState({
      identifier: identifier,
      token: token
    })
    axios(`${BACKEND_URL}/users/me`, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        const userInfo = [res.data]
        console.log(userInfo)
        if (userInfo === null) {
          self.setState({
            userInfo: []
          })
        } else if (userInfo.length > 0) {
          if (userInfo[0].merchantInfo.address === null) {
            userInfo[0].merchantInfo.address = []
          }

          self.setState({
            userInfo: userInfo
          })
        }

        self.setState({
          firstName: userInfo[0].merchantInfo.identity.firstname,
          lastName: userInfo[0].merchantInfo.identity.lastname,
          country: userInfo[0].merchantInfo.address.country,
          state: userInfo[0].merchantInfo.address.state,
          city: userInfo[0].merchantInfo.address.city,
          zipcode: userInfo[0].merchantInfo.address.zipcode,
          phonenumber: userInfo[0].merchantInfo.mobilephone.phonenum,
          address1: userInfo[0].merchantInfo.address.address1,
          address2: userInfo[0].merchantInfo.address.address2
        })
        //   }
        // }

        console.log(userInfo)
        self.setState({
          loading: false
        })
      })
      .catch(function (err) {
        self.props.history.push('merchantsLog')
      })
  }

  onFirstName (event) {
    this.setState({
      firstName: event.target.value
    })
  }

  onLastName (event) {
    this.setState({
      lastName: event.target.value
    })
  }

  onCountry (event) {
    this.setState({
      country: event.target.value
    })
  }

  onstate (event) {
    this.setState({
      state: event.target.value
    })
  }

  onCity (event) {
    this.setState({
      city: event.target.value
    })
  }

  onZipCode (event) {
    this.setState({
      zipcode: event.target.value
    })
  }

  onPhonenumber (event) {
    this.setState({
      phonenumber: event.target.value
    })
  }

  onAddress1 (event) {
    this.setState({
      address1: event.target.value
    })
  }

  onAddress2 (event) {
    this.setState({
      address2: event.target.value
    })
  }

  onHandleNext (event) {
    event.preventDefault()
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const self = this
    const token = localStorage.getItem('token')
    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(
      orderidPosition + 8
    )
    console.log(orderid)
    var userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    const id = userInfo[0].id

    const toConfirm = true
    self.setState({
      toConfirm: toConfirm
    })

    var city = self.state.city
    var country = self.state.country
    var address1 = self.state.address1
    var address2 = self.state.address2
    var firstName = self.state.firstName
    var zipcode = self.state.zipcode
    var lastName = self.state.lastName
    var phonenumber = self.state.phonenumber
    var state = self.state.state

    var inputs = {
      city: city,
      country: country,
      address1: address1,
      // address2,
      firstName: firstName,
      zipcode: zipcode,
      lastName: lastName,
      phonenumber: phonenumber,
      state: state
    }

    var inputsType = Object.keys(inputs)
    console.log(inputsType)
    var inputNum = inputsType.length
    var missingInputValue = ''
    var missingInput = false
    for (var i = 0; i < inputNum; i++) {
      const typeName = inputsType[i]
      missingInputValue = missingInputValue + typeName + '\n'
      if (inputs[typeName] === '') {
        missingInput = true
        self.setState({
          useAnother: true,
          missingInput: missingInput,
          missingInputValue: missingInputValue
        })
      }
    }

    if (i === inputNum && missingInput === false) {
      const order = {
        city: city,
        country: country,
        address1: address1,
        address2: address2,
        firstName: firstName,
        zipcode: zipcode,
        identifier: self.state.identifier,
        lastName: lastName,
        phonenumber: phonenumber,
        state: state,
        rentOrBuy: self.state.rentOrBuy,
        id: id
      }

      console.log(order)
      if (
        self.state.city !== '' &&
        self.state.country !== '' &&
        self.state.address1 !== '' &&
        self.state.firstName !== '' &&
        self.state.zipcode !== '' &&
        self.state.identifier !== '' &&
        self.state.lastName !== '' &&
        self.state.phonenumber !== '' &&
        self.state.state !== ''
      ) {
        axios(
          `${BACKEND_URL}/expresses/validateAddress`,
          {
            method: 'post',
            data: {
              address_line1: self.state.address1,
              address_line2: self.state.address2,
              city_locality: self.state.city,
              state_state: self.state.pronvince,
              postal_code: self.state.zipcode,
              country_code: 'US'
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token
            }
          }
        )
          .then(function (res) {
            console.log(res)
            if (res.status === 200) {
            } else {
            }
          })
          .catch(function (err) {
            console.log(err)
          })
        if (self.state.addressValid === true) {
          // axios(`${BACKEND_URL}/orders/` + orderid, {
          //   method: 'get',
          //   headers: {
          //     Authorization: 'Bearer ' + token
          //   }
          // })
          //   .then(function (res) {
          //     if (res.status === 200) {
          //       console.log(res)
          //       orderOrigin = res.data.orders
          //       Object.assign(orderOrigin, { senderInfo: order })
          //       axios(
          //         `${BACKEND_URL}/orders/` + orderid,
          //         {
          //           method: 'put',
          //           data: {
          //             orders: orderOrigin
          //           },
          //           headers: {
          //             Authorization: 'Bearer ' + token
          //           }
          //         }
          //       )
          //         .then(function (res) {
          //           console.log(res)
          //           if (res.status === 200) {
          //             console.log(res)
          //             self.props.history.push(
          //               '/orderPickUp/confirm?orderid=' + orderid
          //             )
          //           } else {
          //             alert('something went wrong :' + res)
          //           }
          //         })
          //         .catch(function (err) {
          //           alert('something went wrong: ' + err)
          //         })
          //     } else {
          //       alert('something went wrong :' + res)
          //     }
          //   })
          //   .catch(function (err) {
          //     alert('something went wrong: ' + err)
          //   })
        } else {
        }
      }
    }
  }

  useAnother () {
    this.setState({
      useAnother: true,
      firstName: '',
      lastName: '',
      state: '',
      city: '',
      zipcode: '',
      phonenumber: '',
      address1: '',
      address2: ''
    })
  }

  useList () {
    this.setState({
      useAnother: false
    })
  }

  render () {
    const userinfo = this.state.userInfo
    console.log(userinfo)
    const self = this
    const s = self.state
    const loading = this.state.loading
    console.log(s.toConfirm)

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <div className='bg_color_image'>
              <br />
              <br />
              <Header inverted size='huge' textAlign='center'>
                Start Shipping!
              </Header>
              <br />
              <br />
              <Container>
                <Segment>
                  <Grid container>
                    <Grid.Row columns={5} style={{ marginTop: 20 }}>
                      <Grid.Column width={2}></Grid.Column>
                      <Grid.Column width={2}>
                        <Icon size='big' name='dot circle' color='orange' />
                        <Header
                          as='h6'
                          textAlign='left'
                          style={{ padding: 0, marginTop: 7 }}
                        >
                          Start
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <Divider />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Icon
                          size='big'
                          name='dot circle outline'
                          color='grey'
                        />
                        <Header
                          as='h6'
                          textAlign='left'
                          style={{ padding: 0, marginTop: 7 }}
                        >
                          Confirm
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>

                    <Divider></Divider>

                    <Grid.Row columns={1}>
                      <Grid.Column width={16}>
                        {userinfo.length!==0 &&
                          userinfo[0] !== undefined &&
                          self.state.useAnother === false && (
                            <Segment>
                              <Grid>
                                <Grid.Row columns={2}>
                                  <Grid.Column
                                    width={12}
                                    style={{
                                      paddingTop: 5,
                                      paddingBotton: 0,
                                      marginTop: 0,
                                      marginBottom: 0
                                    }}
                                  >
                                    <Header as='h5'>Pick Up Address</Header>
                                  </Grid.Column>

                                  <Grid.Column width={3}>
                                    <Header
                                      as='h5'
                                      color='teal'
                                      textAlign='center'
                                      onClick={self.useAnother}
                                    >
                                      <Icon name='edit outline' />
                                      Edit
                                    </Header>
                                  </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns={3}>
                                  <Grid.Column width={15}>
                                    <Header
                                      as='h5'
                                      textAlign='left'
                                      color='grey'
                                    >
                                      {s.address1 + ', ' + s.zipcode}
                                    </Header>
                                  </Grid.Column>
                                  <Grid.Column width={15}>
                                    <Header
                                      as='h5'
                                      textAlign='left'
                                      color='grey'
                                    >
                                      {s.firstName + ', ' + s.lastName}
                                    </Header>
                                  </Grid.Column>
                                  <Grid.Column width={15}>
                                    <Header as='h5' color='grey'>
                                      {' '}
                                      {s.city + ', ' + s.country}
                                    </Header>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          )}

                        {self.state.useAnother === true &&
                          userinfo.length!==0 &&
                          (userinfo[0].merchantInfo.address.address1 === '' ||
                            userinfo[0].merchantInfo.address.address1 ===
                              null) && (
                            <>
                              <Form>
                                <Form.Group widths='equal'>
                                  <Form.Input
                                    fluid
                                    label='First name'
                                    placeholder='First name'
                                    error={s.toConfirm && s.firstName === ''}
                                    onChange={this.onFirstName}
                                  />
                                  <Form.Input
                                    fluid
                                    label='Last name'
                                    placeholder='Last name'
                                    error={s.toConfirm && s.lastName === ''}
                                    onChange={this.onLastName}
                                  />
                                </Form.Group>
                              </Form>

                              <br />

                              <Form>
                                <Form.Group widths='equal'>
                                  <Form.Input
                                    label='Country'
                                    fluid
                                    // disabled
                                    value='United States'
                                  />

                                  <Form.Input
                                    fluid
                                    label='State'
                                    placeholder='State'
                                    onChange={this.onstate}
                                    error={s.toConfirm && s.state === ''}
                                  />
                                  <Form.Input
                                    fluid
                                    label='City'
                                    placeholder='City'
                                    onChange={this.onCity}
                                    error={s.toConfirm && s.city === ''}
                                  />
                                </Form.Group>
                                <Message info>
                                  <p>
                                    We only support orders in United States
                                    right now :)
                                  </p>
                                </Message>
                              </Form>
                              <br />

                              <Form>
                                <Form.Group widths='equal'>
                                  <Form.Input
                                    fluid
                                    label='Zip Code'
                                    placeholder='Zip Code'
                                    onChange={this.onZipCode}
                                    error={s.toConfirm && s.zipcode === ''}
                                  />

                                  <Form.Input
                                    fluid
                                    label='Phone Number'
                                    placeholder='Phone Number'
                                    onChange={this.onPhonenumber}
                                    error={s.toConfirm && s.phonenumber === ''}
                                  />
                                </Form.Group>
                              </Form>
                              <Form>
                                <Form.Group widths='equal'>
                                  <Form.Input
                                    fluid
                                    label='Address Line 1'
                                    placeholder='Address Line 1'
                                    onChange={this.onAddress1}
                                    error={s.toConfirm && s.address1 === ''}
                                  />
                                  <Form.Input
                                    fluid
                                    label='Address Line 2 (optional)'
                                    placeholder='Address Line 2'
                                    onChange={this.onAddress2}
                                  />
                                </Form.Group>
                              </Form>

                              {s.missingInput && (
                                <Message
                                  error
                                  header='Missing Field'
                                  content={
                                    'Please fill the ' +
                                    s.missingInputValue +
                                    'field'
                                  }
                                />
                              )}
                              {s.addressValid !== true && (
                                <Message
                                  error
                                  header='Invalid Address'
                                  content={'please change put a valid address'}
                                />
                              )}
                            </>
                          )}

                        {self.state.useAnother && (
                          <>
                            <Form>
                              <Form.Group widths='equal'>
                                <Form.Input
                                  fluid
                                  label='First name'
                                  placeholder='First name'
                                  onChange={this.onFirstName}
                                  error={s.toConfirm && s.firstName === ''}
                                />
                                <Form.Input
                                  fluid
                                  label='Last name'
                                  placeholder='Last name'
                                  onChange={this.onLastName}
                                  error={s.toConfirm && s.lastName === ''}
                                />
                              </Form.Group>
                            </Form>

                            <br />

                            <Form>
                              <Form.Group widths='equal'>
                                <Form.Input
                                  label='Country'
                                  fluid
                                  value='United States'
                                />

                                <Form.Input
                                  fluid
                                  label='State'
                                  placeholder='State'
                                  onChange={this.onstate}
                                  error={s.toConfirm && s.state === ''}
                                />
                                <Form.Input
                                  fluid
                                  label='City'
                                  placeholder='City'
                                  onChange={this.onCity}
                                  error={s.toConfirm && s.city === ''}
                                />
                              </Form.Group>
                              <Message info>
                                <p>
                                  We only support orders in United States right
                                  now :)
                                </p>
                              </Message>
                            </Form>
                            <br />

                            <Form>
                              <Form.Group widths='equal'>
                                <Form.Input
                                  fluid
                                  label='Zip Code'
                                  placeholder='Zip Code'
                                  onChange={this.onZipCode}
                                  error={s.toConfirm && s.zipcode === ''}
                                />

                                <Form.Input
                                  fluid
                                  label='Phone Number'
                                  placeholder='Phone Number'
                                  onChange={this.onPhonenumber}
                                  error={s.toConfirm && s.phonenumber === ''}
                                />
                              </Form.Group>
                            </Form>
                            <Form>
                              <Form.Group widths='equal'>
                                <Form.Input
                                  fluid
                                  label='Address Line 1'
                                  placeholder='Address Line 1'
                                  onChange={this.onAddress1}
                                  error={s.toConfirm && s.address1 === ''}
                                />
                                <Form.Input
                                  fluid
                                  label='Address Line 2 (optional)'
                                  placeholder='Address Line 2'
                                  onChange={this.onAddress2}
                                />
                              </Form.Group>
                            </Form>

                            {s.missingInput && (
                              <Message
                                error
                                header='Missing Field'
                                content={
                                  'Please fill the ' +
                                  s.missingInputValue +
                                  'field'
                                }
                              />
                            )}
                          </>
                        )}
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row></Grid.Row>

                    <Grid.Row centered>
                      {s.useAnother && (
                        <Button
                          style={{ paddingLeft: 40, paddingRight: 40 }}
                          onClick={self.useList}
                        >
                          Cancel
                        </Button>
                      )}
                      <Button
                        color='orange'
                        style={{ paddingLeft: 40, paddingRight: 40 }}
                        onClick={this.onHandleSubmit}
                      >
                        Next
                      </Button>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(OrderPickUp)
