import { BACKEND_URL } from '../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../utils/config';
import React from 'react'
import axios from 'axios'
import {
    Grid, Icon, Container, Header, Form,
    Segment, Divider, Button, Select
} from 'semantic-ui-react'
// import {
//     Link
//   } from "react-router-dom"; 
import DistPicker from 'react-distpicker'
import DatePicker from 'react-date-picker';
// import Nav from '../nav/Nav'
import { withRouter } from 'react-router-dom'

import Foot from '../foot/Foot';

class DeliveryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            orderInfo: [],
            deliveryData: [],
            loading: true,
            returnInfo: null,
            date: '',
            time: '',
            buyProducts: [],
            userInfo: [],
            cart: [],
            firstNameSA: '',
            lastNameSA: '',
            accessoryBag: [],
            select: 'square outline',
            selectDefault: 'check square outline',
            selectID: '',


        }


        this.setTime = this.setTime.bind(this)
        this.setDate = this.setDate.bind(this)
        this.changeIt = this.changeIt.bind(this)
        this.useAnother = this.useAnother.bind(this)
        this.getProductInBuyCart = this.getProductInBuyCart.bind(this)
        this.getAccessoriesBag = this.getAccessoriesBag.bind(this)
        this.getUserInfo = this.getUserInfo.bind(this)
        this.getInfo = this.getInfo.bind(this)

        this.onFirstName = this.onFirstName.bind(this)
        this.onLastName = this.onLastName.bind(this)
        this.Districts = this.Districts.bind(this)
        this.onPhonenumber = this.onPhonenumber.bind(this)
        this.onDetailAddress = this.onDetailAddress.bind(this)
        this.useList = this.useList.bind(this)
        this.onSelect = this.onSelect.bind(this)
        this.saveAddress = this.saveAddress.bind(this)
        this.ConfirmChange = this.ConfirmChange.bind(this)

    }

    getUserInfo() {
        const self = this
        const identifier = localStorage.getItem('identifier')
        const token = localStorage.getItem('token')

        self.setState({
            identifier: identifier,
            token: token
        })


        axios(`${BACKEND_URL}/users/me`, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(function (res) {
                const userInfo = [res.data]
                if (userInfo === null) {
                    self.setState({
                        userInfo: [],
                        userID: userInfo[0].id,

                    })
                } else if (userInfo.length > 0) {
                    if (userInfo[0].billinginformation === null) {
                        userInfo[0].billinginformation = []
                    }
                    if (userInfo[0].shippingaddress === null) {
                        userInfo[0].shippingaddress = []
                    }
                }

                const len = userInfo[0].shippingaddress.length

                for (var i = 0; i < len; i++) {
                    if (userInfo[0].shippingaddress[i].checkedSA === true) {
                        self.setState({
                            selectID: userInfo[0].shippingaddress[i].id,
                            firstNameSA: userInfo[0].shippingaddress[i].firstNameSA,
                            lastNameSA: userInfo[0].shippingaddress[i].lastNameSA,
                            country: userInfo[0].shippingaddress[i].country,
                            state: userInfo[0].shippingaddress[i].state,
                            city: userInfo[0].shippingaddress[i].city,
                            district: userInfo[0].shippingaddress[i].district,
                            phonenumber: userInfo[0].shippingaddress[i].phonenumber,
                            detailAddress: userInfo[0].shippingaddress[i].detailAddress,

                        })
                    }
                }

                self.setState({
                    userInfo: userInfo,
                    userID: userInfo[0].id,
                    loading: false,

                    membership: userInfo[0].membership
                })


                self.getProductInBuyCart()
                self.getAccessoriesBag()
            })
    }

    useAnother() {
        this.setState({
            useAnother: true
        })
    }

    onFirstName(event) {
        this.setState({
            firstNameSA: event.target.value
        })

    }

    onLastName(event) {

        this.setState({
            lastNameSA: event.target.value
        })
    }

    Districts(event) {
        console.log(event)
        this.setState({
            country: 'China',
            state: event.state,
            city: event.city,
            district: event.district
        })

    }

    onPhonenumber(event) {

        this.setState({
            phonenumber: event.target.value
        })

    }

    onDetailAddress(event) {

        this.setState({
            detailAddress: event.target.value
        })

    }

    useList() {
        this.setState({
            useAnother: false
        })
    }

    onSelect(event, data) {
        var id = ''
        const self = this
        console.log(event, data)
        const address = this.state.userInfo[0].shippingaddress
        if (data.id !== null || data.id !== undefined) {
            id = data.id
        }
        else {
            id = event.id
        }

        this.setState({
            selectID: id
        })

        address.forEach(function (each) {
            if (each.id === id) {
                self.setState({
                    firstName: each.firstName,
                    lastName: each.lastName,
                    country: each.country,
                    state: each.state,
                    city: each.city,
                    district: each.district,
                    phonenumber: each.phonenumber,
                    detailAddress: each.detailAddress,
                })
            }
        })

    }

    saveAddress(event, data) {
        var checked = ''
        const self = this
        if (data.checked !== null || data.checked !== undefined) {
            checked = data.checked
        }
        else {
            checked = event.checked
        }
        console.log(checked)
        if (checked === true) {
            self.setState({
                save: true
            })
        } else {
            self.setState({
                save: false
            })
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const self = this
        window.setTimeout(() => {
            if (self.state.loading === true) {
                this.setState({
                    loading: false
                })
            }

        }, 1000);
        this.getInfo()
        this.getUserInfo()

    }

    getProductInBuyCart() {

        const self = this
        const userID = self.state.userID
        const token = self.state.token

        const cartItems = self.state.cart

        self.setState({ buyProducts: [] })
        console.log(cartItems)

        if (cartItems.length === undefined) {
            alert('something went wrong :(')
            axios(`${BACKEND_URL}/users/` + userID, {
                method: 'put',
                data: {
                    cart: [],

                },
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                    alert("your cart has been cleared sorry")
                }
                else {
                    alert("something went wrong again")

                }
                self.setState({
                    loading: false
                })
            })
        }

        else {
            cartItems.forEach(function (cart) {
                if (cart.type !== 'sale') {
                    axios.get(`${BACKEND_URL}/products?id=` + cart.id)
                        .then(function (res) {

                            if (res.status === 200) {
                                const productSelected = res.data[0]

                                if (self.state.buyProducts === '') {

                                    var product = productSelected

                                    self.setState({ buyCartInfo: cart })

                                    product.quantity = self.state.buyCartInfo.number
                                    product.colors[0] = self.state.buyCartInfo.colors
                                    product.sizes[0] = self.state.buyCartInfo.sizes


                                    const stringProduct = [product]
                                    self.setState({ buyProducts: stringProduct })
                                }
                                else if (self.state.buyProducts !== '') {
                                    product = productSelected

                                    // parsedCart.filter(function(cartP){
                                    // if(cartP.id===cart.id){
                                    self.setState({ buyCartInfo: cart })
                                    // }})
                                    product.quantity = self.state.buyCartInfo.number
                                    product.colors[0] = self.state.buyCartInfo.colors
                                    product.sizes[0] = self.state.buyCartInfo.sizes

                                    const stringProduct = product
                                    product = self.state.buyProducts

                                    product.push(stringProduct)
                                    self.setState({ buyProducts: product })
                                }


                            }
                            else {
                                alert("something went wrong")
                                localStorage.setItem('login', 'false')
                            }

                            self.setState({
                                loading: false
                            })

                        })
                }
                else if (cart.type === 'sale') {
                    axios.get(`${BACKEND_URL}/second-hand-products?id=` + cart.id)
                        .then(function (res) {

                            if (res.status === 200) {
                                const productSelected = res.data[0]

                                if (self.state.buyProducts === '') {

                                    var product = productSelected

                                    self.setState({ buyCartInfo: cart })

                                    product.quantity = self.state.buyCartInfo.number
                                    product.colors[0] = self.state.buyCartInfo.colors
                                    product.sizes[0] = self.state.buyCartInfo.sizes
                                    product.type = 'sale'


                                    const stringProduct = [product]
                                    self.setState({ buyProducts: stringProduct })
                                }
                                else if (self.state.buyProducts !== '') {
                                    product = productSelected

                                    // parsedCart.filter(function(cartP){
                                    // if(cartP.id===cart.id){
                                    self.setState({ buyCartInfo: cart })
                                    // }})
                                    product.quantity = self.state.buyCartInfo.number
                                    product.colors[0] = self.state.buyCartInfo.colors
                                    product.sizes[0] = self.state.buyCartInfo.sizes
                                    product.type = 'sale'

                                    const stringProduct = product
                                    product = self.state.buyProducts


                                    product.push(stringProduct)
                                    self.setState({ buyProducts: product })
                                }
                            }
                            else {
                                alert("something went wrong")
                                localStorage.setItem('login', 'false')
                            }



                        })
                }

            })
        }

    }

    getAccessoriesBag() {

        const self = this
        const userID = self.state.userID
        const token = self.state.token

        const cartItems = self.state.accessoryBag

        self.setState({ buyProducts: [] })

        if (cartItems.length === undefined) {
            alert('something went wrong :(')
            axios(`${BACKEND_URL}/users/` + userID, {
                method: 'put',
                data: {
                    accessoryCart: [],

                },
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                    alert("your accessories has been cleared sorry")
                }
                else {
                    alert("something went wrong again")

                }
                self.setState({
                    loading: false
                })

            })
        }

        else {
            cartItems.forEach(function (cart) {
                if (cart.type !== 'sale') {
                    axios.get(`${BACKEND_URL}/accessaries?id=` + cart.id)
                        .then(function (res) {

                            if (res.status === 200) {
                                const productSelected = res.data[0]

                                if (self.state.buyProducts === '') {

                                    var product = productSelected

                                    self.setState({ buyCartInfo: cart })

                                    product.quantity = self.state.buyCartInfo.number
                                    product.colors[0] = self.state.buyCartInfo.colors
                                    product.sizes[0] = self.state.buyCartInfo.sizes



                                    const stringProduct = [product]
                                    self.setState({ buyProducts: stringProduct })
                                }
                                else if (self.state.buyProducts !== '') {
                                    product = productSelected

                                    // parsedCart.filter(function(cartP){
                                    // if(cartP.id===cart.id){
                                    self.setState({ buyCartInfo: cart })
                                    // }})
                                    product.quantity = self.state.buyCartInfo.number
                                    product.colors[0] = self.state.buyCartInfo.colors
                                    product.sizes[0] = self.state.buyCartInfo.sizes

                                    const stringProduct = product
                                    product = self.state.buyProducts

                                    product.push(stringProduct)
                                    self.setState({ buyProducts: product })
                                }
                            }
                            else {
                                alert("something went wrong")
                                localStorage.setItem('login', 'false')
                            }

                            self.setState({
                                loading: false
                            })

                        })
                }
                else if (cart.type === 'sale') {
                    axios.get(`${BACKEND_URL}/second-hand-products?id=` + cart.id)
                        .then(function (res) {

                            if (res.status === 200) {
                                const productSelected = res.data[0]

                                if (self.state.buyProducts === '') {

                                    var product = productSelected

                                    self.setState({ buyCartInfo: cart })

                                    product.quantity = self.state.buyCartInfo.number
                                    product.colors[0] = self.state.buyCartInfo.colors
                                    product.sizes[0] = self.state.buyCartInfo.sizes


                                    const stringProduct = [product]
                                    self.setState({ buyProducts: stringProduct })
                                }
                                else if (self.state.buyProducts !== '') {
                                    product = productSelected

                                    // parsedCart.filter(function(cartP){
                                    // if(cartP.id===cart.id){
                                    self.setState({ buyCartInfo: cart })
                                    // }})
                                    product.quantity = self.state.buyCartInfo.number
                                    product.colors[0] = self.state.buyCartInfo.colors
                                    product.sizes[0] = self.state.buyCartInfo.sizes

                                    const stringProduct = product
                                    product = self.state.buyProducts

                                    product.push(stringProduct)
                                    self.setState({ buyProducts: product })
                                }
                            }
                            else {
                                alert("something went wrong")
                                localStorage.setItem('login', 'false')
                            }



                        })
                }

            })
        }


    }

    getInfo() {
        const orderToDeliveryId = localStorage.getItem("orderToDeliveryId")
        const self = this

        axios(`${BACKEND_URL}/orders?id=` + orderToDeliveryId, {
            method: 'get'
        })
            .then(function (res) {
                if (res.status === 200) {
                    const theData = res.data[0]
                    console.log(theData)
                    if (theData.orders.returnInfo !== undefined && theData.orders.returnInfo !== null) {
                        var date = theData.orders.returnInfo.date
                        const time = theData.orders.returnInfo.time


                        const year = parseInt(date.substring(0, 4))
                        const month = parseInt(date.substring(5, 7))
                        const day = parseInt(date.substring(8, 10))
                        date = new Date(year, month - 1, day + 1)
                        const today = new Date()

                        var times = [
                            { value: "09:00:00", text: "09:00:00", key: "09:00:00" },
                            { value: "10:00:00", text: "10:00:00", key: "10:00:00" },
                            { value: "11:00:00", text: "11:00:00", key: "11:00:00" },
                            { value: "12:00:00", text: "12:00:00", key: "12:00:00" },
                            { value: "13:00:00", text: "13:00:00", key: "13:00:00" },
                            { value: "14:00:00", text: "14:00:00", key: "14:00:00" },
                            { value: "15:00:00", text: "15:00:00", key: "15:00:00" },
                            { value: "16:00:00", text: "16:00:00", key: "16:00:00" },
                            { value: "17:00:00", text: "17:00:00", key: "17:00:00" },
                            { value: "18:00:00", text: "18:00:00", key: "18:00:00" },
                            { value: "19:00:00", text: "19:00:00", key: "19:00:00" },
                            { value: "20:00:00", text: "20:00:00", key: "20:00:00" }
                        ]



                        if (date.toString().substring(0, 15) === today.toString().substring(0, 15)) {
                            var todayTime = today.toString().substring(16, 18)
                            todayTime = parseInt(todayTime)
                            var ii = 0
                            var timesChange = []
                            times.forEach(function (each) {
                                var value = each.value.substring(0, 2)
                                value = parseInt(value)
                                if (value > todayTime) {
                                    timesChange.push(times[ii])
                                }
                                ii++
                            })
                            times = timesChange
                        }


                        self.setState({
                            date: date,
                            time: time,
                            times: times,
                            returnInfo: theData.orders.returnInfo,
                            orderInfo: theData
                        })
                    } else {
                       
                        self.setState({ orderInfo: theData })

                    }


                }
                else {
                    alert('Something went wrong, maybe log in again :(')
                    localStorage.setItem('login', 'false')
                }

            })
    }


    setDate(event) {
        console.log(event)
        const today = new Date()
        const self = this
        var times = [
            { value: "09:00:00", text: "09:00:00", key: "09:00:00" },
            { value: "10:00:00", text: "10:00:00", key: "10:00:00" },
            { value: "11:00:00", text: "11:00:00", key: "11:00:00" },
            { value: "12:00:00", text: "12:00:00", key: "12:00:00" },
            { value: "13:00:00", text: "13:00:00", key: "13:00:00" },
            { value: "14:00:00", text: "14:00:00", key: "14:00:00" },
            { value: "15:00:00", text: "15:00:00", key: "15:00:00" },
            { value: "16:00:00", text: "16:00:00", key: "16:00:00" },
            { value: "17:00:00", text: "17:00:00", key: "17:00:00" },
            { value: "18:00:00", text: "18:00:00", key: "18:00:00" },
            { value: "19:00:00", text: "19:00:00", key: "19:00:00" },
            { value: "20:00:00", text: "20:00:00", key: "20:00:00" }
        ]



        if (event.toString().substring(0, 15) === today.toString().substring(0, 15)) {
            var todayTime = today.toString().substring(16, 18)
            todayTime = parseInt(todayTime)
            var ii = 0
            var timesChange = []
            times.forEach(function (each) {
                var value = each.value.substring(0, 2)
                value = parseInt(value)
                if (value > todayTime) {
                    timesChange.push(times[ii])
                }
                ii++
            })
            times = timesChange
        }


        self.setState({
            times: times,
            date: event

        })

    }

    setTime(event, data) {
        console.log(event, data)
        this.setState({
            time: data.value
        })

    }

    changeIt() {
        if (this.state.changeIt !== true) {
            this.setState({
                changeIt: true
            })
        } else {
            this.setState({
                changeIt: false
            })
        }

    }

    ConfirmChange(event) {
        event.preventDefault()
        const self = this
        const token = localStorage.getItem('token')
        const orderId = localStorage.getItem('orderToDeliveryId')
        var orderOrigin = []
        const membership = this.state.membership
        var endDate = this.state.normalEndDate

        if (membership.isAMember === true) {
            endDate = membership.endDate
            const year = parseInt(endDate.substring(0, 4))
            const month = parseInt(endDate.substring(5, 7))
            const day = parseInt(endDate.substring(8, 10))
            endDate = new Date(year, month, day)

        }

        console.log(this.state.date)
        const order = {
            city: self.state.city,
            country: self.state.country,
            detailAddress: self.state.detailAddress,
            district: self.state.district,
            firstNameSA: self.state.firstNameSA,
            identifier: self.state.identifier,
            lastNameSA: self.state.lastNameSA,
            password: self.state.password,
            phonenumber: self.state.phonenumber,
            state: self.state.state,
            rentOrBuy: self.state.rentOrBuy,
            endDate: endDate,
            date: self.state.date,
            time: self.state.time
        }

        // order = JSON.stringify(order)
        // localStorage.setItem('order', order)

        if (self.state.save === true) {
            var shippingaddress = this.state.userInfo[0].shippingaddress
            var len = shippingaddress.length
            len = JSON.stringify(len)
            var userInfo = localStorage.getItem('userInfo')
            userInfo = JSON.parse(userInfo)
            const id = userInfo[0].id
            const token = localStorage.getItem('token')


            const saveData = {
                city: self.state.city,
                country: self.state.country,
                detailAddress: self.state.detailAddress,
                district: self.state.district,
                firstNameSA: self.state.firstName,
                lastNameSA: self.state.lastName,
                phonenumber: self.state.phonenumber,
                state: self.state.state,
                id: len,
                checkedSA: true

            }
            shippingaddress.forEach(function (each) {
                each.checkedSA = false
            })
            shippingaddress.push(saveData)

            axios(`${BACKEND_URL}/users/` + id, {
                method: 'put',
                data: {
                    shippingaddress: shippingaddress
                },
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(function (res) {
                if (res.status === 200) {
                    console.log(res)
                } else {
                    alert('something went wrong' + res)
                }

            }).catch(function (e) {
                alert('something went wrong' + e)
            })
        }

        axios(`${BACKEND_URL}/orders/` + orderId, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(function (res) {
            if (res.status === 200) {
                orderOrigin = res.data.orders
                Object.assign(orderOrigin, { returnInfo: order })

                axios(`${BACKEND_URL}/orders/` + orderId, {
                    method: 'put',
                    data: {
                        orders: orderOrigin

                    },
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                }).then(function (res) {
                    if (res.status === 200) {
                        console.log(res)
                        alert('changed successfully')
                        self.props.history.push("/mypage")
                    } else {
                        alert('something went wrong :' + res)
                    }
                })
                    .catch(function (err) {
                        alert('something went wrong: ' + err)
                    })

            } else {
                alert('something went wrong :' + res)
            }
        })
            .catch(function (err) {
                alert('something went wrong: ' + err)
            })


    }


    render() {

        console.log(this.state)
        // const orderInfo = this.state.orderInfo
        const self = this
        
        const returnInfo = this.state.returnInfo
        console.log(returnInfo)

        if ((returnInfo !== undefined) && (returnInfo !== null)) {
            var endDate = this.state.returnInfo.endDate
            if (endDate !== undefined) {
                const year1 = parseInt(endDate.substring(0, 4))
                const month1 = parseInt(endDate.substring(5, 7))
                const day1 = parseInt(endDate.substring(8, 10))
                endDate = new Date(year1, month1 - 1, day1)
            }
            var style = null
            
        }
        const today = new Date()

        const userinfo = this.state.userInfo

        return <div>

            {/* <Nav /> */}


            {this.state.loading||returnInfo === undefined || returnInfo === null ? (
                <Grid >
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>


                    <Grid.Row colums={2}>
                        <Grid.Column width={7}></Grid.Column>
                        <Grid.Column width={6}>
                            <Header size='huge'>
                                <Icon loading name='asterisk' size='massive' color='yellow' />
                                Loading...
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                </Grid>
            ) : (<>


                <div className='bg_image'>
                    <br />
                    <br />
                    <Container>

                        <Segment style={style}>
                            <Grid container>
                               
                                   <>
                                        <Grid.Row>
                                        </Grid.Row>

                                        <Grid.Row centered>
                                            <Header>
                                                the delivery person will soon come into contact with you:
                                            </Header>
                                        </Grid.Row>

                                        <Divider />


                                        {this.state.changeIt === true && <>
                                            <Grid.Row centered columns={1}>
                                                <Header color='orange' as='h5'>
                                                    Change Pick Up Time
                                                </Header>
                                            </Grid.Row>
                                            <Grid.Row centered>
                                                <DatePicker

                                                    onChange={this.setDate}
                                                    value={this.state.date}
                                                    maxDate={endDate}
                                                    minDate={today}
                                                />

                                                <Select style={{ marginLeft: 20 }} placeholder='Select The Pick Up Time'
                                                    options={this.state.times} value={this.state.time} onChange={this.setTime} />

                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Divider />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row centered>
                                                <Header color='orange' as='h5'>
                                                    Change Address
                                                </Header>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    {self.state.useAnother !== true &&
                                                        userinfo[0].shippingaddress.length >= 0 &&
                                                        userinfo[0].shippingaddress.map(function (each) {
                                                            return <Segment>
                                                                <Grid>
                                                                    <Grid.Row columns={2}  >
                                                                        <Grid.Column width={15} style={{ paddingTop: 5, paddingBotton: 0, marginTop: 0, marginBottom: 0 }}>
                                                                            {each.checkedSA === true && <Header as='h5'>Default Shipping Address</Header>}
                                                                            {each.checkedSA !== true && <Header as='h5'>Shipping Address</Header>}

                                                                        </Grid.Column>

                                                                        <Grid.Column width={1}>
                                                                            {each.checkedSA && <>

                                                                                <Icon id={each.id} onClick={self.onSelect}
                                                                                    name={(each.id !== self.state.selectID && self.state.select) ||
                                                                                        (each.id === self.state.selectID && self.state.selectDefault)
                                                                                    }
                                                                                    size='large' color='orange' /></>}
                                                                            {each.checkedSA !== true && <>

                                                                                <Icon id={each.id} onClick={self.onSelect}
                                                                                    name={(each.id !== self.state.selectID && self.state.select) ||
                                                                                        (each.id === self.state.selectID && self.state.selectDefault)
                                                                                    }
                                                                                    size='large' color='orange' /></>}

                                                                        </Grid.Column>
                                                                    </Grid.Row>

                                                                    <Grid.Row columns={3}  >
                                                                        <Grid.Column width={15}>
                                                                            <Header as='h5' textAlign='left' color='grey'>
                                                                                {each.firstNameSA + ", " + each.lastNameSA}
                                                                            </Header>
                                                                        </Grid.Column>
                                                                        <Grid.Column width={15}>
                                                                            <Header as='h5' color='grey'> {each.city}</Header>
                                                                        </Grid.Column>
                                                                        <Grid.Column width={15}>
                                                                            <Header as='h5' textAlign='left' color='grey'>{each.detailAddress}</Header>
                                                                        </Grid.Column>
                                                                    </Grid.Row>


                                                                </Grid>

                                                            </Segment>
                                                        })

                                                    }
                                                </Grid.Column>


                                            </Grid.Row>

                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    {self.state.useAnother !== true &&userinfo[0].shippingaddress !== undefined &&
                                                        <Segment onClick={self.useAnother}>
                                                            <Header as='h3' textAlign='center'>
                                                                + Use Another Address
                                                            </Header>
                                                        </Segment>
                                                    }
                                                    {((userinfo[0].shippingaddress === '') ||
                                                        (userinfo[0].shippingaddress === null) ||
                                                        (userinfo[0].shippingaddress.length === 0) )&&
                                                        <>
                                                            <Form>
                                                                <Form.Group widths='equal'>
                                                                    <Form.Input fluid label='First name' placeholder='First name' onChange={this.onFirstName} />
                                                                    <Form.Input fluid label='Last name' placeholder='Last name' onChange={this.onLastName} />

                                                                </Form.Group>

                                                            </Form>

                                                            <br />

                                                            <DistPicker onSelect={this.Districts} />

                                                            <br />

                                                            <Form>
                                                                <Form.Input label='Phonenumber' type='phonenumber' placeholder='Phonenumber' onChange={this.onPhonenumber} />

                                                                <Form.Input label='Detail Address' type='Detail Address' placeholder='Detail Address' onChange={this.onDetailAddress} />

                                                                <Form.Checkbox label='save as default address' onChange={this.saveAddress} />

                                                            </Form>
                                                        </>
                                                    }

                                                    {self.state.useAnother === true &&
                                                        userinfo[0].shippingaddress !== undefined &&
                                                        <Segment onClick={self.useList}>
                                                            <Header as='h3' textAlign='center'>
                                                                - Back To Address List
                                                            </Header>
                                                        </Segment>


                                                    }
                                                    {self.state.useAnother && <>
                                                        <Form>
                                                            <Form.Group widths='equal'>
                                                                <Form.Input fluid label='First name' placeholder='First name' onChange={this.onFirstName} />
                                                                <Form.Input fluid label='Last name' placeholder='Last name' onChange={this.onLastName} />

                                                            </Form.Group>

                                                        </Form>

                                                        <br />

                                                        <DistPicker onSelect={this.Districts} />

                                                        <br />

                                                        <Form>
                                                            <Form.Input label='Phonenumber' type='phonenumber' placeholder='Phonenumber' onChange={this.onPhonenumber} />

                                                            <Form.Input label='Detail Address' type='Detail Address' placeholder='Detail Address' onChange={this.onDetailAddress} />

                                                            <Form.Checkbox label='save as default address' onChange={this.saveAddress} />

                                                        </Form>
                                                    </>}
                                                </Grid.Column>
                                            </Grid.Row>




                                        </>}



                                        {this.state.changeIt !== true &&
                                            <>

                                                <Grid.Row columns={5} divided>
                                                    <Grid.Column width={2}>
                                                    </Grid.Column>
                                                    <Grid.Column width={7}>
                                                        <Header as='h4'>
                                                            Time:
                                                        </Header>
                                                        <Header color='orange' as='h4'>
                                                            {this.state.date.toString().substring(0, 15) + " " + this.state.time}
                                                        </Header>

                                                    </Grid.Column>
                                                    <Grid.Column width={5}>
                                                        <Header as='h4' >Address: </Header>
                                                        <Grid.Row columns={3} >
                                                            <Grid.Column width={15}>
                                                                <Header as='h5' textAlign='left' color='orange'>
                                                                    {returnInfo.firstNameSA + ", " + returnInfo.lastNameSA}
                                                                </Header>
                                                            </Grid.Column>
                                                            <Grid.Column width={15}>
                                                                <Header as='h5' color='orange'> {returnInfo.city}</Header>
                                                            </Grid.Column>
                                                            <Grid.Column width={15}>
                                                                <Header as='h5' textAlign='left' color='orange'>{returnInfo.detailAddress}</Header>
                                                            </Grid.Column>
                                                        </Grid.Row>

                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row></Grid.Row>


                                                <Grid.Row centered>
                                                    <Button color='teal' size='big' onClick={this.changeIt}>
                                                        Change Pick up
                                                    </Button>
                                                </Grid.Row>
                                            </>
                                        }
                                        {this.state.changeIt === true && <>


                                            <Grid.Row centered>
                                                <Button basic color='teal' size='big' onClick={this.changeIt}>
                                                    Cancel
                                                </Button>
                                                <Button color='teal' size='big' onClick={this.ConfirmChange}>
                                                    Confirm
                                                </Button>
                                            </Grid.Row>
                                        </>}

                                        <Grid.Row>
                                        </Grid.Row>
                                    </>
                                
                            </Grid>
                            <br />

                        </Segment>

                    </Container>
                    <br />
                    <br />
                </div>

            </>
            )}
            <Foot />

        </div>
    }

}

export default withRouter(DeliveryPage)


