import React, { useEffect, useState } from 'react';
import {
  Menu,
  Button,
  Icon,
  Confirm,
  Popup,
  Label,
  Header,
  Search
} from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useGetCurrentUserQuery } from '../../redux/api/apiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/userSlice';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';

const userLang = navigator.language || navigator.userLanguage;
const searchClient = algoliasearch(
  'TA5GYUAPCR',
  '005f9c0c87592f1e4680023cd06179c0'
);

const Nav = ({ windowWidth }) => {
  const [activeItem, setActiveItem] = useState('');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  
  const { isLoggedIn, userInfo } = useSelector((state) => state.user);
  
  // Use RTK Query to fetch current user data without polling
  const { data: user, refetch } = useGetCurrentUserQuery(undefined, {
    skip: !isLoggedIn,
  });

  const identifier = userInfo?.username || userInfo?.email;

  // Update active menu item when route changes
  useEffect(() => {
    const menus = [
      { include: 'browse', activeItem: 'Browse' },
      { include: 'about', activeItem: 'About' },
      { include: 'merchant', activeItem: 'Merchants' },
      { include: 'contactUs', activeItem: 'Contact' },
      { include: 'mypage', activeItem: 'MyPage' },
      { include: 'cart', activeItem: 'Cart' },
      { include: 'heart', activeItem: 'Heart' },
      { include: 'message', activeItem: 'Chat' }
    ];

    const pathname = location.pathname.toLowerCase();
    menus.forEach((each) => {
      if (pathname.includes(each.include.toLowerCase())) {
        setActiveItem(each.activeItem);
      }
    });
  }, [location.pathname]);

  // Only refetch user data on login state change
  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn, refetch]);

  // Calculate cart numbers from user data
  const cartNum = React.useMemo(() => {
    if (!user) return 0;
    return (
      (user.cart?.length || 0) +
      (user.accessoryCart?.length || 0) +
      (user.bag?.length || 0) +
      (user.accessoryBag?.length || 0)
    );
  }, [user]);

  // Determine cart link based on user data
  const cartLink = React.useMemo(() => {
    if (!user) return '/cart';
    const buyItemsCount = (user.cart?.length || 0) + (user.accessoryCart?.length || 0);
    const rentItemsCount = (user.bag?.length || 0) + (user.accessoryBag?.length || 0);
    return buyItemsCount === 0 && rentItemsCount > 0 ? '/cart?rent=true' : '/cart';
  }, [user]);

  const handleItemClick = (e, data) => {
    const link = data.linkto;
    setActiveItem(data.name);

    if (link === '/clothing') {
      localStorage.setItem('filter', 'Clothing');
    }
    if (link === '/heart') {
      localStorage.setItem('filter', 'All');
    }

    if (link === '/merchantLog') {
      const merchantLogin = localStorage.getItem('merchantLogin');
      if (merchantLogin !== 'true') {
        history.push('/merchantsLog');
      } else {
        history.push('/merchantPage');
      }
    } else {
      history.push(link);
    }
  };

  const handleSignOut = (event) => {
    event.preventDefault();
    setOpen(false);
    dispatch(logout());
    history.push('/');
  };

  const labels = {
    browse: userLang === 'zh-CN' ? '浏览' : 'Browse',
    about: userLang === 'zh-CN' ? '关于我们' : 'About',
    merchants: userLang === 'zh-CN' ? '商户' : 'Merchants',
    contact: userLang === 'zh-CN' ? '联系' : 'Contact'
  };

  return (
    <Menu
      inverted
      color='teal'
      size='huge'
      borderless
      style={{
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: 10,
        paddingTop: 10
      }}
    >
      <Menu.Item>
        <Link to='/'>
          <Header
            as='h1'
            inverted
            linkto='/'
            name='Home'
            active={activeItem === 'Home'}
            style={{ paddingRight: 10 }}
          >
            SHEYOU
            <Label style={{ backgroundColor: '#F5F5DD' }}>BETA</Label>
          </Header>
        </Link>
      </Menu.Item>

      <Menu.Item
        name={labels.browse}
        linkto='/allProducts'
        active={activeItem === 'Browse'}
        onClick={handleItemClick}
      />

      <Menu.Item
        name={labels.about}
        linkto='/about'
        active={activeItem === 'About'}
        onClick={handleItemClick}
      />

      <Menu.Item
        linkto='/merchantsLog'
        name={labels.merchants}
        active={activeItem === 'Merchants'}
        onClick={handleItemClick}
      />

      <Menu.Item
        name={labels.contact}
        linkto='/contactUs'
        active={activeItem === 'Contact'}
        onClick={handleItemClick}
      />

      <Menu.Menu position='right'>
        <Menu.Item>
          <InstantSearch indexName='bestbuy' searchClient={searchClient}>
            <Search placeholder='Search...' />
          </InstantSearch>
        </Menu.Item>
      </Menu.Menu>

      {isLoggedIn ? (
        <Menu.Menu position='right' icon='labeled'>
          <Popup
            trigger={
              <Menu.Item
                name='MyPage'
                linkto='/mypage'
                active={activeItem === 'MyPage'}
                onClick={handleItemClick}
              >
                <Icon name='user' inverted />
              </Menu.Item>
            }
            content={'Hi, ' + identifier}
            style={{
              borderRadius: 12,
              opacity: 0.9,
              padding: '1.5em',
              color: 'orange'
            }}
          />

          <Menu.Item
            name='Cart'
            linkto={cartLink}
            active={activeItem === 'Cart'}
            onClick={handleItemClick}
          >
            <Icon name='shopping cart' inverted>
              <Label floating circular>
                {cartNum}
              </Label>
            </Icon>
          </Menu.Item>

          <Menu.Item
            name='Heart'
            linkto='/heart'
            active={activeItem === 'Heart'}
            onClick={handleItemClick}
          >
            <Icon name='heart' inverted />
          </Menu.Item>

          <Menu.Item
            name='Chat'
            linkto='/message'
            active={activeItem === 'Chat'}
            onClick={handleItemClick}
          >
            <Icon name='chat' inverted />
          </Menu.Item>

          <Menu.Item>
            <Button size='huge' inverted onClick={() => setOpen(true)}>
              {userLang === 'zh-CN' ? '退出' : 'Log Out'}
            </Button>
            {userLang === 'zh-CN' ? (
              <Confirm
                open={open}
                cancelButton='算了'
                confirmButton='确认'
                content='您确定要退出？'
                onCancel={() => setOpen(false)}
                onConfirm={handleSignOut}
              />
            ) : (
              <Confirm
                open={open}
                cancelButton='Never mind'
                confirmButton='Yes'
                content='Are you sure you want to log out?'
                onCancel={() => setOpen(false)}
                onConfirm={handleSignOut}
              />
            )}
          </Menu.Item>
        </Menu.Menu>
      ) : (
        <Menu.Menu position='right'>
          <Menu.Item>
            <Link to='/signin'>
              <Button size='huge' inverted>
                {userLang === 'zh-CN' ? '登录 / 注册' : 'Sign In/ Sign Up'}
              </Button>
            </Link>
          </Menu.Item>
        </Menu.Menu>
      )}
    </Menu>
  );
};

export default Nav;
