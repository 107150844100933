import React from 'react'
import AboutPage from '../component/aboutPage/AboutPagePhone'
import { HashRouter as Switch, Route } from 'react-router-dom'
import SignupPage from '../component/RedirectPages/SignupPagePhone'
// import MemberBox from '../component/shoppingpages/MemberBox'
import CheckOut from '../component/shoppingpages/CheckOutPagePhone'
import WhatIsClothesSharing from '../component/aboutPage/WhatIsClothesSharing'
// import Payment from '../component/shoppingpages/Payment'
import Confirm from '../component/shoppingpages/ConfirmPhone'
import RentingConfirm from '../component/shoppingpages/RentingConfirm'
import CheckOutRentingPage from '../component/shoppingpages/CheckOutRentingPage'
import Comment from '../component/pastOrders/Comment'
import Delivery from '../component/delivery/DeliveryPage'
import ForgetPassword from '../component/RedirectPages/ForgetPasswordPhone'
import ResetPassword from '../component/RedirectPages/ResetPasswordPhone'
import CartPage from '../component/shoppingpages/CartPagePhone'
import SignUpSuccess from '../component/RedirectPages/SignUpSuccessPhone'
import ChangeEmail from '../component/changeEmail/ChangeEmail'
import VerifyEmail from '../component/changeEmail/VerifyEmail'
import ConfirmRent from '../component/shoppingpages/ConfirmRent'
import KeepPurchase from '../component/RedirectPages/KeepPurchase'
import Return from '../component/returnPage/ReturnRenting'
import Plans from '../component/plans/Plans'
import Pay from '../component/plans/Pay'
import DeliveryPageReturn from '../component/delivery/DeliveryPageReturn'
import EmailConfirmation from '../component/RedirectPages/EmailConfirmationPhone'
import Alipay from '../component/payment/Alipay'
import WechatPay from '../component/payment/WechatPayPhone'
import Paypal from '../component/payment/Paypal'
import PhoneEmulator from '../component/PhoneEmulator'
import HomePagePhone from '../component/homePage/HomePagePhone/HomePagePhone.js'
import LogInPagePhone from '../component/RedirectPages/LogInPagePhone'
import HeartPagePhone from '../component/heartPage/HeartPagePhone'
import MyPagePhone from '../component/mypage/MyPagePhone'
import LoggingPagePhone from '../component/nav/LoggingPagePhone'
import MerchantOpenShop from '../component/merchants/MerchantOpenShopPhone'
import MerchantPage from '../component/merchants/merchantPage/MerchantPagePhone'
import MerchantsLog from '../component/merchants/MerchantsLogPhone'
import MyOrders from '../component/mypage/MyOrders'
import ProfileDetail from '../component/profilePage/ProfilePagePhone'
import FitPreferencePhone from '../component/profilePage/FitPreferencePhone'
import ChangePasswordPhone from '../component/profilePage/ChangePasswordPhone'
import SettingPhone from '../component/profilePage/SettingPhone'
import ContactPage from '../component/Contact/ContactPagePhone'
import LoggingOutPagePhone from '../component/nav/LoggingOutPagePhone'
import MessagePhone from '../component/message/MessagePhone'
import MessageListPhone from '../component/message/MessageListPhone'
import LoginFirst from '../component/RedirectPages/LoginFirst'
import MerchantStorePhone from '../component/merchants/merchantPage/MerchantStorePhone'
import MerchantOrderPhone from '../component/merchants/merchantPage/MerchantOrderPhone'
import MerchantVerify from '../component/merchants/merchantPage/merchantVerify/MerchantVerify'
import MerchantProduct from '../component/merchants/merchantPage/MerchantProduct'
import LoggingPageMerchant from '../component/nav/LoggingPageMerchant'
import ProductPage from '../component/allProduct/ProductPage'

import OrderPickUpPhone from '../component/merchants/merchantPage/merchant logistics/OrderPickUpPhone'

class PcPadVersion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      signinVisible:false
    }
    this.signinVisible=this.signinVisible.bind(this)
  }
  componentDidMount () {
  }
  signinVisible () {
    const signinVisible = this.state.signinVisible
    console.log(signinVisible)

    if (signinVisible !== true) {
      this.setState({
        signinVisible: true
      })
    } else if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  render () {
    return (
      <Switch>
        {/* {login!=='true'&&<Sidebar
            as = {Menu}
            animation = 'overlay'
            vertical
            visible = {true}
            direction = 'bottom'
          >
            <Segment
              style = {{width: '100%', paddingBottom: 7, paddingTop: 7}}
            >
              <Grid>
                <Grid.Row columns = {4}>
                  <Grid.Column width = {1}>
                    <Icon
                      name = 'close'
                      size = 'small'
                      onClick = {self.signinVisible}
                    />
                  </Grid.Column>
                  <Grid.Column width = {5}></Grid.Column>
                  <Grid.Column width = {4}>
                    <Header
                      as = 'h4'
                      color = 'teal'
                      textAlign = 'centered'
                      style = {{
                        paddingBottom: 0,
                        marginBottom: 0,
                        fontWeight: 500
                      }}
                    >
                      Sign In
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <LogInPagePhonePop />

          </Sidebar>} */}
          <Route exact path='/orderPickUp'>
          <OrderPickUpPhone/>
        </Route>
        <Route exact path='/merchantOrder'>
          <MerchantOrderPhone activeItem='All' />
        </Route>
        <Route exact path='/merchantpage/store'>
          <MerchantStorePhone activeItem='All' />
        </Route>

        <Route exact path='/merchantOrder/ongoing'>
          <MerchantOrderPhone activeItem='Ongoing' />
        </Route>

        <Route exact path='/merchantOrder/past'>
          <MerchantOrderPhone activeItem='Past' />
        </Route>
        <Route exact path='/merchantpage'>
          <MerchantPage activeItem='Home' />
        </Route>
        <Route exact path='/merchants'>
          <MerchantPage activeItem='Home' />
        </Route>

       

        <Route exact path='/merchantpage/products'>
          <MerchantProduct isPhone={true} />
        </Route>

        <Route exact path='/merchantVerify/address'>
          <MerchantVerify reload={true} activeItem='address' />
        </Route>

        <Route exact path='/merchantVerify/phone'>
          <MerchantVerify reload={true} activeItem='phone' />
        </Route>

        <Route exact path='/merchantVerify/card'>
          <MerchantVerify reload={true} activeItem='card' />
        </Route>

        <Route exact path='/merchantVerify/id'>
          <MerchantVerify reload={true} activeItem='id' />
        </Route>

        <Route exact path='/messagelist'>
          <MessageListPhone />
        </Route>
        <Route exact path='/message'>
          <MessagePhone />
        </Route>
        <Route exact path='/profileDetail'>
          <ProfileDetail />
        </Route>

        <Route exact path='/loginfirst'>
          <LoginFirst mobile={true} />
        </Route>

        <Route exact path='/fitPreference'>
          <FitPreferencePhone />
        </Route>

        <Route exact path='/changePassword'>
          <ChangePasswordPhone />
        </Route>

        <Route exact path='/merchantsLog'>
          <MerchantsLog />
        </Route>

        <Route exact path='/merchantOpenShop'>
          <MerchantOpenShop />
        </Route>

        <Route exact path='/phoneemulator'>
          <PhoneEmulator />
        </Route>

        <Route exact path='/logging'>
          <LoggingPagePhone />
        </Route>

        <Route exact path='/loggingMerchant'>
          <LoggingPageMerchant mobile={true}/>
        </Route>

        <Route exact path='/loggingOut'>
          <LoggingOutPagePhone />
        </Route>

        <Route exact path='/alipay'>
          <Alipay />
        </Route>
        <Route exact path='/paypal'>
          <Paypal />
        </Route>
        <Route exact path='/wechatpay'>
          <WechatPay />
        </Route>

        <Route exact path='/pay'>
          <Pay />
        </Route>
        <Route exact path='/deliveryreturn'>
          <DeliveryPageReturn />
        </Route>
        <Route exact path='/mypage/membership'>
          <MyPagePhone activeItem='Membership' />
        </Route>
        <Route exact path='/return'>
          <Return />
        </Route>
        <Route exact path='/plans'>
          <Plans />
        </Route>
        <Route exact path='/myAddress'>
          <SettingPhone />
        </Route>
        <Route exact path='/keeppurchase'>
          <KeepPurchase />
        </Route>
        <Route exact path='/confirmrent'>
          <ConfirmRent />
        </Route>

        <Route exact path='/contactUs'>
          <ContactPage />
        </Route>
        <Route exact path='/changeEmail'>
          <ChangeEmail />
        </Route>
        <Route exact path='/verifyEmail'>
          <VerifyEmail />
        </Route>


      

        <Route exact path='/auth/email-confirmation'>
          <SignUpSuccess />
        </Route>

        <Route exact path='/heart'>
          <HeartPagePhone activeItem='All' is='categories' />
        </Route>

        <Route exact path='/heart/feminine'>
          <HeartPagePhone reload={true} activeItem='Feminine' is='categories' />
        </Route>
        <Route exact path='/heart/masculine'>
          <HeartPagePhone
            reload={true}
            activeItem='Masculine'
            is='categories'
          />
        </Route>
        <Route exact path='/heart/accessaries'>
          <HeartPagePhone
            reload={true}
            activeItem='Accessaries'
            is='categories'
          />
        </Route>
        <Route exact path='/heart/average'>
          <HeartPagePhone reload={true} activeItem='Average' is='sizes' />
        </Route>
        <Route exact path='/heart/m'>
          <HeartPagePhone reload={true} activeItem='M' is='sizes' />
        </Route>
        <Route exact path='/heart/s'>
          <HeartPagePhone reload={true} activeItem='S' is='sizes' />
        </Route>
        <Route exact path='/heart/l'>
          <HeartPagePhone reload={true} activeItem='L' is='sizes' />
        </Route>
        <Route exact path='/heart/xxsxs'>
          <HeartPagePhone reload={true} activeItem='XXS/XS' is='sizes' />
        </Route>
        <Route exact path='/heart/xxlxl'>
          <HeartPagePhone reload={true} activeItem='XXL/XL' is='sizes' />
        </Route>

       
       

        <Route exact path='/user'>
          <MyPagePhone />
        </Route>

        <Route exact path='/myOrders'>
          <MyOrders />
        </Route>

        <Route exact path='/'>
          <HomePagePhone />
        </Route>
        <Route exact path='/comment'>
          <Comment />
        </Route>
        <Route exact path='/delivery'>
          <Delivery />
        </Route>

        <Route exact path='/cart'>
          <CartPage />
        </Route>
        <Route exact path='/resetpassword'>
          <ResetPassword />
        </Route>
        <Route exact path='/forgetpassword'>
          <ForgetPassword />
        </Route>

        <Route exact path='/rentingconfirm'>
          <RentingConfirm />
        </Route>
        <Route exact path='/rentingcheckout'>
          <CheckOutRentingPage />
        </Route>
        <Route exact path='/checkout'>
          <CheckOut />
        </Route>
        {/* <Route exact path='/heart'>
      <Heart activeItem='All' is='categories' />
    </Route> */}
        {/* <Route exact path='/payment'>
      <Payment />
    </Route> */}
        <Route exact path='/confirm'>
          <Confirm />
        </Route>
        <Route exact path='/emailConfirmation'>
          <EmailConfirmation />
        </Route>

        {/* <Route exact path='/memberbox'>
      <MemberBox />
    </Route> */}

        <Route exact path='/product'>
          <ProductPage />
        </Route>
        <Route exact path='/signin'>
          <LogInPagePhone />
        </Route>
        <Route exact path='/signup'>
          <SignupPage />
        </Route>
      
        <Route exact path='/about'>
          <AboutPage />
        </Route>
        <Route exact path='/whatisclothessharing'>
          <WhatIsClothesSharing />
        </Route>

   
  
      </Switch>
    )
  }
}

export default PcPadVersion
