import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
  severity: 'error', // 'error' | 'warning' | 'info' | 'success'
  isVisible: false,
  duration: 5000, // default duration in ms
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showError: (state, action) => {
      state.error = action.payload.message;
      state.severity = action.payload.severity || 'error';
      state.isVisible = true;
      state.duration = action.payload.duration || 5000;
    },
    hideError: (state) => {
      state.error = null;
      state.isVisible = false;
    },
  },
});

export const { showError, hideError } = errorSlice.actions;

// Thunk action creator for showing error with auto-hide
export const showErrorWithTimeout = (message, severity = 'error', duration = 5000) => (dispatch) => {
  dispatch(showError({ message, severity, duration }));
  setTimeout(() => {
    dispatch(hideError());
  }, duration);
};

export default errorSlice.reducer; 