import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BACKEND_URL } from '../../utils/config';

// Async thunks
export const toggleLike = createAsyncThunk(
  'likes/toggle',
  async (productId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const response = await axios.post(
        `${BACKEND_URL}/products/${productId}/like`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLikeStatus = createAsyncThunk(
  'likes/getStatus',
  async (productId, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      
      const response = await axios.get(
        `${BACKEND_URL}/products/${productId}/like-status`,
        { headers }
      );
      return {
        productId,
        ...response.data
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const likeSlice = createSlice({
  name: 'likes',
  initialState: {
    likeStatuses: {}, // { productId: { isLiked: boolean, likeCount: number } }
    loading: false,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Toggle Like
      .addCase(toggleLike.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(toggleLike.fulfilled, (state, action) => {
        state.loading = false;
        const productId = action.payload.id;
        const likeCount = action.payload.being_liked_by?.length || 0;
        state.likeStatuses[productId] = {
          ...state.likeStatuses[productId],
          likeCount,
          isLiked: !state.likeStatuses[productId]?.isLiked
        };
      })
      .addCase(toggleLike.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to toggle like';
      })
      // Get Like Status
      .addCase(getLikeStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLikeStatus.fulfilled, (state, action) => {
        state.loading = false;
        const { productId, isLiked, likeCount } = action.payload;
        state.likeStatuses[productId] = { isLiked, likeCount };
      })
      .addCase(getLikeStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to get like status';
      });
  },
});

export const { clearError } = likeSlice.actions;

export const selectLikeStatus = (state, productId) => state.likes.likeStatuses[productId];
export const selectLikesLoading = (state) => state.likes.loading;
export const selectLikesError = (state) => state.likes.error;

export default likeSlice.reducer; 