import { BACKEND_URL } from '../../utils/config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunks
export const createPaymentIntent = createAsyncThunk(
  'payment/createIntent',
  async ({ amount, orderId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const response = await axios.post(
        `${BACKEND_URL}/pay/stripeCheckout`,
        {
          amount,
          currency: 'usd',
          transfer_group: `ORDER_${orderId}`,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const confirmPayment = createAsyncThunk(
  'payment/confirm',
  async ({ orderId, paymentIntentId }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const response = await axios.put(
        `${BACKEND_URL}/orders/${orderId}`,
        {
          paymentStatus: 'paid',
          stripePaymentIntentId: paymentIntentId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPaymentMethods = createAsyncThunk(
  'payment/fetchMethods',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { token, id } = getState().user;
      const response = await axios.get(
        `${BACKEND_URL}/users/${id}/payment-methods`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    clientSecret: null,
    paymentMethods: [],
    loading: false,
    error: null,
    currentOrder: null,
    stripe: null, // Will hold the Stripe instance
    elements: null, // Will hold Stripe Elements instance
  },
  reducers: {
    setStripe: (state, action) => {
      state.stripe = action.payload;
    },
    setElements: (state, action) => {
      state.elements = action.payload;
    },
    clearPaymentState: (state) => {
      state.clientSecret = null;
      state.currentOrder = null;
      state.error = null;
    },
    setCurrentOrder: (state, action) => {
      state.currentOrder = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Payment Intent
      .addCase(createPaymentIntent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPaymentIntent.fulfilled, (state, action) => {
        state.loading = false;
        state.clientSecret = action.payload.clientSecret;
      })
      .addCase(createPaymentIntent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to create payment intent';
      })
      // Confirm Payment
      .addCase(confirmPayment.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.currentOrder = action.payload;
      })
      .addCase(confirmPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to confirm payment';
      })
      // Fetch Payment Methods
      .addCase(fetchPaymentMethods.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentMethods = action.payload;
      })
      .addCase(fetchPaymentMethods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to fetch payment methods';
      });
  },
});

export const {
  setStripe,
  setElements,
  clearPaymentState,
  setCurrentOrder,
  clearError,
} = paymentSlice.actions;

export default paymentSlice.reducer; 