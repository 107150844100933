// Environment-based URLs
export const BACKEND_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_backend_baseUrl_live
  : process.env.REACT_APP_backend_baseUrl_localTest;

export const FRONTEND_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_sheyou_baseUrl_live
  : process.env.REACT_APP_sheyou_baseUrl_localTest;

// API endpoints
export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: '/auth/local',
    REGISTER: '/auth/local/register',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    ME: '/users/me'
  },
  USERS: {
    BASE: '/users',
    UPDATE: (id) => `/users/${id}`,
    PROFILE: (id) => `/users/${id}/profile`
  },
  PRODUCTS: {
    BASE: '/products',
    BY_ID: (id) => `/products/${id}`,
    BY_TYPE: (type) => `/products?productType=${type}`,
    BY_CATEGORY: (id, type) => `/products?categories.id=${id}&productType=${type}`,
    BY_GENDER: (id, type) => `/products?genders.id=${id}&productType=${type}`,
    BY_SIZE: (id, type) => `/products?sizes.id=${id}&productType=${type}`
  },
  ORDERS: {
    BASE: '/orders',
    BY_USER: (username) => `/orders?users_permissions_user.username=${username}`,
    BY_ID: (id) => `/orders/${id}`
  },
  CATEGORIES: {
    BASE: '/categories',
    BY_ID: (id) => `/categories/${id}`
  },
  MESSAGES: {
    BASE: '/messages',
    BY_CHAT: (chatId) => `/messages?chat=${chatId}`
  }
};

// Constants
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';
export const SOCKET_CONFIG = {
  reconnection: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 3000
}; 