import { BACKEND_URL } from '../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../utils/config';
import React from 'react'
import {
  Grid,
  Icon,
  Segment,
  Container,
  Header,
  Button,
  Message
} from 'semantic-ui-react'
import Foot from '../foot/Foot'
import { QRCodeSVG } from 'qrcode.react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage

class WechatPay extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      paid: false,
      value: 0,
      token: '',
      identifier: '',
      password: '',
      membership: '',
      isAMember: '',
      loading: true,
      AliPay: '',
      AliPayIcon: '',
      wechatPayIcon: '',
      wechatPay: '',
      expireDate: '',
      paymentChecking: false,
      code: '',
      result: '',
      paymentChecked: '',
      somethingWentWrong: false
    }
    this.checkPayment = this.checkPayment.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const code_url = this.props.history.location.search.substring(10)
    const self = this

    self.setState({
      code: code_url
    })

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 500)
  }

  checkPayment () {
    const self = this
    var orderId = localStorage.getItem('orderId')
    orderId = 'sheyoufashion' + orderId
    const token = localStorage.getItem('token')

    this.setState({
      paymentChecking: true
    })

    axios(
      `${BACKEND_URL}/pay/wechatpay?out_trade_no=` +
        orderId,
      {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    )
      .then(function (res) {
        console.log(res)
        self.setState({
          paymentChecking: false,
          result: res.data.status,
          paymentChecked: true
        })
        const orderID = localStorage.getItem('orderId')
        axios(`${BACKEND_URL}/orders/` + orderID, {
          method: 'put',
          data: {
            paid: true,
            payment: 'wechat'
          },
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
          .then(function (res) {
            window.setTimeout(() => {
              self.props.history.push('/mypage')
            }, 500)

            console.log(res)
          })
          .catch(function (err) {
            self.setState({ somethingWentWrong: true })
            console.log(err)
          })
      })
      .catch(function (err) {
        console.log(err)
        self.setState({
          paymentChecking: false,
          result: err,
          paymentChecked: true
        })
      })
  }

  render () {
    const loading = this.state.loading
    const self = this
    console.log(self.state.paymentChecked)

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <div className='bg_image'>
              <br />
              <br />

              {userLang === 'zh-CN' && (
                <Container>
                  <Segment>
                    <br />
                    <br />
                    <Grid container centered>
                      <Grid.Row>
                        <Header>请用微信扫码付款</Header>
                      </Grid.Row>
                      <Grid.Row>
                        <QRCodeSVG value={this.state.code} />
                      </Grid.Row>

                      {self.state.paymentChecked === true &&
                        self.state.result === 200 && (
                          <Grid.Row style={{ width: 600 }}>
                            <Message positive>
                              <Message.Header>成功</Message.Header>
                              <p>您已成功支付 :)</p>
                            </Message>
                          </Grid.Row>
                        )}

                      {self.state.paymentChecked === true &&
                        self.state.result !== 200 && (
                          <Grid.Row style={{ width: 600 }}>
                            <Message negative>
                              <Message.Header>失败</Message.Header>
                              <p>出问题了 :(</p>
                            </Message>
                          </Grid.Row>
                        )}

                      {self.state.somethingWentWrong === true && (
                        <Grid.Row style={{ width: 600 }}>
                          <Message negative>
                            <Message.Header>失败</Message.Header>
                            <p>出问题了 :(</p>
                          </Message>
                        </Grid.Row>
                      )}

                      <Grid.Row>
                        <Button
                          icon
                          labelPosition='left'
                          color='teal'
                          onClick={this.checkPayment}
                          loading={this.state.paymentChecking}
                        >
                          <Icon name='check' />
                          我已完成支付
                        </Button>
                      </Grid.Row>
                    </Grid>
                    <br />
                    <br />
                    <br />
                  </Segment>
                </Container>
              )}

              {userLang !== 'zh-CN' && (
                <Container>
                  <Segment>
                    <br />
                    <br />
                    <Grid container centered>
                      <Grid.Row>
                        <Header>Please scan with Wechat to pay</Header>
                      </Grid.Row>
                      <Grid.Row>
                        <QRCodeSVG value={this.state.code} />,{' '}
                      </Grid.Row>
                      {self.paymentChecked === true && self.result === 200 && (
                        <Grid.Row style={{ width: 600 }}>
                          <Message positive>
                            <Message.Header>Success</Message.Header>
                            <p>You have paid for your order :)</p>
                          </Message>
                        </Grid.Row>
                      )}

                      {self.paymentChecked === true && self.result !== 200 && (
                        <Grid.Row style={{ width: 600 }}>
                          <Message negative>
                            <Message.Header>Failure</Message.Header>
                            <p>Something Went Wrong</p>
                          </Message>
                        </Grid.Row>
                      )}

                      {self.state.somethingWentWrong === true && (
                        <Grid.Row style={{ width: 600 }}>
                          <Message negative>
                            <Message.Header>Failure</Message.Header>
                            <p>Something Went Wrong</p>
                          </Message>
                        </Grid.Row>
                      )}
                      <Grid.Row>
                        <Button
                          icon
                          labelPosition='left'
                          color='teal'
                          onClick={this.checkPayment}
                          loading={this.state.paymentChecking}
                        >
                          <Icon name='check' />I have completed the payment
                        </Button>
                      </Grid.Row>
                    </Grid>
                    <br />
                    <br />
                    <br />
                  </Segment>
                </Container>
              )}

              <br />
              <br />
              <Foot />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(WechatPay)
