import { BACKEND_URL } from '../../utils/config';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunks
export const validateAddress = createAsyncThunk(
  'shipping/validateAddress',
  async (addressData, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const response = await axios.post(
        `${BACKEND_URL}/expresses/validateAddress`,
        {
          address_line1: addressData.address1,
          address_line2: addressData.address2,
          city_locality: addressData.city,
          state_province: addressData.state,
          postal_code: addressData.zipcode,
          country_code: 'US',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const calculateShippingRate = createAsyncThunk(
  'shipping/calculateRate',
  async ({ origin, destination, weight }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const response = await axios.post(
        `${BACKEND_URL}/expresses/uspsRate`,
        {
          Service: 'PRIORITY',
          ZipOrigination: origin,
          ZipDestination: destination,
          Pounds: Math.floor(weight),
          Ounces: Math.round((weight % 1) * 16),
          Container: null,
          Width: null,
          Length: null,
          Height: null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateShippingLabel = createAsyncThunk(
  'shipping/generateLabel',
  async ({ orderId, shippingDetails }, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const response = await axios.post(
        `${BACKEND_URL}/expresses/uspsLabel`,
        {
          orderId,
          ...shippingDetails,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const schedulePickup = createAsyncThunk(
  'shipping/schedulePickup',
  async (pickupDetails, { getState, rejectWithValue }) => {
    try {
      const { token } = getState().user;
      const response = await axios.post(
        `${BACKEND_URL}/expresses/uspsCarrierPickupSchedule`,
        pickupDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const shippingSlice = createSlice({
  name: 'shipping',
  initialState: {
    validatedAddress: null,
    shippingRates: null,
    shippingLabel: null,
    pickupDetails: null,
    loading: false,
    error: null,
    currentOrderShipping: null,
  },
  reducers: {
    clearShippingState: (state) => {
      state.validatedAddress = null;
      state.shippingRates = null;
      state.shippingLabel = null;
      state.pickupDetails = null;
      state.error = null;
      state.currentOrderShipping = null;
    },
    setCurrentOrderShipping: (state, action) => {
      state.currentOrderShipping = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Validate Address
      .addCase(validateAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(validateAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.validatedAddress = action.payload.result;
      })
      .addCase(validateAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to validate address';
      })
      // Calculate Shipping Rate
      .addCase(calculateShippingRate.pending, (state) => {
        state.loading = true;
      })
      .addCase(calculateShippingRate.fulfilled, (state, action) => {
        state.loading = false;
        state.shippingRates = action.payload.result;
      })
      .addCase(calculateShippingRate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to calculate shipping rate';
      })
      // Generate Shipping Label
      .addCase(generateShippingLabel.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateShippingLabel.fulfilled, (state, action) => {
        state.loading = false;
        state.shippingLabel = action.payload.result;
      })
      .addCase(generateShippingLabel.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to generate shipping label';
      })
      // Schedule Pickup
      .addCase(schedulePickup.pending, (state) => {
        state.loading = true;
      })
      .addCase(schedulePickup.fulfilled, (state, action) => {
        state.loading = false;
        state.pickupDetails = action.payload.result;
      })
      .addCase(schedulePickup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to schedule pickup';
      });
  },
});

export const {
  clearShippingState,
  setCurrentOrderShipping,
  clearError,
} = shippingSlice.actions;

export default shippingSlice.reducer; 