import { BACKEND_URL } from '../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../utils/config';
import React from 'react'
import PastOrderITem from './PastOrderItemPhone'
import PastOrderItemRenting from './PastOrderItemRentingPhone'
import { Grid, Header, Confirm, Button,Label } from 'semantic-ui-react'
// import { Redirect } from 'react-router'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import countdown from 'future-count-down'



class PastOrder extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            delivered: false,
            countDown: '',
            expire: '',
            days: '',
            hours: '',
            minutes: '',
            seconds: '',
            open1: false,
        }
        this.onHandleClickDelivery = this.onHandleClickDelivery.bind(this)
        this.onHandleDelivered = this.onHandleDelivered.bind(this)
        this.returnRent = this.returnRent.bind(this)
        this.pay = this.pay.bind(this)
        this.cancel = this.cancel.bind(this)
        this.onHandleClickDeliveryReturn=this.onHandleClickDeliveryReturn.bind(this)

    }

    open1 = () => this.setState({ open1: true })
    close1 = () => this.setState({ open1: false })


    cancel() {
        const p = this.props
        const orderid = p.orderid
        const self = this
        const token = localStorage.getItem('token')

        self.setState({
            loading: true
        })
        axios(`${BACKEND_URL}/orders/` + orderid, {
            method: 'put',
            data: {
                canceled: true
            },
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).catch(error => console.log(error))
            .then(function (res) {
                console.log(res)
                if (res.status !== 200) {
                    alert('something went wrong')
                } else {
                    self.setState({
                        loading: false
                    })

                    window.location.reload(true)
                }
            })
    }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    onHandleDelivered(data, event) {
        const self = this
        console.log(data, event)
        var orderid = 0
        if (event.orderid !== null || event.orderid !== undefined) {
            orderid = event.orderid
        }
        else {
            orderid = data.orderid
        }

        console.log(orderid)
        const token = localStorage.getItem('token')
        this.setState({
            loading: true
        })

        axios(`${BACKEND_URL}/orders/` + orderid, {
            method: 'put',
            data: {
                delivered: true
            },
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).catch(error => console.log(error))
            .then(function (res) {
                console.log(res)
                if (res.status !== 200) {
                    alert('something went wrong')
                } else {
                    self.setState({
                        loading: false,
                        open: false
                    })

                    window.location.reload(true)
                }
            })
    }
    componentDidMount() {
        const p = this.props
        const data = p.data
        const paid = data.paid
        const orderid = p.orderid
        const self = this

        if (paid !== true) {
            const timeOfTheOrder = data.created_at

            var days = parseInt(timeOfTheOrder.substring(8, 10))
            days = days + 2
            // var hour = timeOfTheOrder.substring(11, 13)
            var years = timeOfTheOrder.substring(0, 4)
            var months = timeOfTheOrder.substring(5, 7)
            months = months - 1

            countdown({
                endDate: new Date(years, months, days),
                interval: 1000,
                run: function ({ days, hours, minutes, seconds }) {
                    self.setState({
                        days: days,
                        hours: hours,
                        minutes: minutes,
                        seconds: seconds,
                    })
                },
                finish() {
                    const token = localStorage.getItem('token')

                    axios(`${BACKEND_URL}/orders/` + orderid, {
                        method: 'put',
                        data: {
                            canceled: true
                        },
                        headers: {
                            'Authorization': 'Bearer ' + token,
                        }
                    }).catch(error => console.log(error))
                        .then(function (res) {
                            console.log(res)
                            if (res.status !== 200) {
                                alert('something went wrong')
                            } else {
                                self.setState({
                                    loading: false
                                })

                                window.location.reload(true)
                            }
                        })
                }
            })
        }



    }

    onHandleClickDelivery(event, data) {
        event.preventDefault()
        const self = this
        console.log(self)
        const orderid = data.orderid
        localStorage.setItem('orderToDeliveryId', orderid)
        self.props.history.push('/delivery')
    }

    pay(event, data) {
        const self = this
        const orderid = data.orderid
        event.preventDefault()
        const p = this.props
        const d = p.data.orders
        if (d.info === null || d.info === undefined) {
            localStorage.setItem('orderId', orderid)
            self.props.history.push('/checkout?orderid='+orderid)
        } else {
            localStorage.setItem('orderId', orderid)
            self.props.history.push('/confirm?orderid='+orderid)
        }



    }

    onHandleClickDeliveryReturn(event, data){
        event.preventDefault()
        const self = this
        console.log(self)
        const orderid = data.orderid
        localStorage.setItem('orderToDeliveryId', orderid)
        self.props.history.push('/deliveryreturn')
    }

    returnRent(event, data) {
        const self = this
        const orderid = data.orderid
        event.preventDefault()
        localStorage.setItem('returnorderid', orderid)
        self.props.history.push('/return')
    }


    render() {
        const p = this.props
        console.log(p)
        const data = p.data
        const timeOfTheOrder = data.created_at
        const info = data.orders

        const products = info.data
        const rentOrBuy = products.rentOrBuy
        const returned = data.returned
        const orderid = p.orderid
        const paid = data.paid
        const delivered = p.delivered
        const clothing = products.clothing.filter(function (each) {
            return each.type !== 'sale'
        })
        const saleClothing = products.clothing.filter(function (each) {
            return each.type === 'sale'
        })
        const accessory = products.accessory
        const returnInfo = p.returnInfo
     
        console.log(data.created_at)





        return <Grid.Row columns={4}>
            <Grid.Column width={1}></Grid.Column>

            {paid === true && rentOrBuy === "buy" &&
                <Grid.Column style={{ paddingTop: 12 }} width={7}>
                    {timeOfTheOrder.substring(5, 7) === '01' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  January ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '02' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  February ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '03' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  March ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '04' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  April ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '05' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  May ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '06' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  June ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '07' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  July ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '08' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  August ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '09' &&
                        <Header color='orange' as='h6' textAlign='center'>
                            {'Purchase Order Placed At :  September ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '10' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  October ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '11' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  November ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '12' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Purchase Order Placed At :  December ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }


                </Grid.Column>
            }

            {paid !== true && <Grid.Column style={{ paddingTop: 12 }} width={9}>
                <Header as='h5' color='grey' textAlign='center'>

                    {this.state.days + " days " + this.state.hours + " hours "
                        + this.state.minutes + " minutes " + this.state.seconds + " seconds,"
                        + " before closing the order"}
                </Header>
            </Grid.Column>}

            {paid === true && rentOrBuy !== "buy" &&

                <Grid.Column style={{ paddingTop: 12 }} width={7}>
                    {timeOfTheOrder.substring(5, 7) === '01' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : January ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '02' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : February ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '03' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : March ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '04' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : April ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '05' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : May ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '06' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : June ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '07' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : July ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '08' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : August ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '09' &&
                        <Header color='orange' as='h6' textAlign='center'>
                            {'Renting Order Placed At  : September ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '10' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : October ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '11' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : November ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }
                    {timeOfTheOrder.substring(5, 7) === '12' &&
                        <Header as='h5' color='grey' textAlign='center'>
                            {'Renting Order Placed At  : December ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                        </Header>
                    }


                </Grid.Column>
            }

            {paid === true && delivered !== true && <>
                {this.state.delivered !== true &&
                    <Grid.Column tablet={4} computer={3}>
                        <Button color='orange'
                            onClick={this.open}>
                            Received
                        </Button>
                    </Grid.Column>
                }

                <Confirm
                    orderid={orderid}
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.onHandleDelivered}

                />
            </>
            }

            {paid !== true && <>
                {this.state.delivered !== true &&
                    <Grid.Column tablet={3} computer={2}>
                        <Button.Group>
                            <Button color='yellow'
                                orderid={orderid} style={{ paddingLeft: 35, paddingRight: 35 }}
                                onClick={this.pay}>
                                Pay
                            </Button>
                            <Button.Or />
                            <Button
                                onClick={this.open1}>Cancel</Button>
                        </Button.Group>

                    </Grid.Column>
                }
                <Confirm
                    orderid={orderid}
                    open={this.state.open1}
                    onCancel={this.close1}
                    onConfirm={this.cancel}

                />
            </>
            }
            {(returnInfo === undefined || returnInfo === null) && paid === true && rentOrBuy === 'rent' && delivered === true && (returned === false || returned === null) &&
                <Grid.Column tablet={4} computer={3}>
                    <Button color='teal' orderid={orderid} onClick={this.returnRent}>Return Now</Button>

                </Grid.Column>
            }


            {paid === true &&(returnInfo === undefined || returnInfo === null) && <Grid.Column width={2}>
                <Button content='Status' icon='truck'
                    labelPosition='right'
                    orderid={orderid} onClick={this.onHandleClickDelivery} />
            </Grid.Column>}

            {paid === true &&(returnInfo !== undefined && returnInfo !== null) && <Grid.Column width={2}>
                <Button content='Status' icon='truck'
                    labelPosition='right'
                    orderid={orderid} onClick={this.onHandleClickDeliveryReturn} />
            </Grid.Column>}



            {(returnInfo !== undefined &&returnInfo !== null) && paid === true && rentOrBuy === 'rent' && delivered === true && (returned === false || returned === null) &&
                <Grid.Column tablet={4} computer={3}>
                    <Label as='a' color='yellow' tag>
                        Waiting For Pick Up ...  
                        </Label>
                </Grid.Column>
            }

            {rentOrBuy === "buy" &&

                <Grid.Column width={16}>
                    {clothing.map(function (it) {
                        const id = it.id
                        return <PastOrderITem id={id} orderid={orderid} delivered={delivered} is={'clothing'} />

                    })}

                    {saleClothing.map(function (it) {
                        const id = it.id
                        return <PastOrderITem id={id} orderid={orderid} delivered={delivered} is={'saleClothing'} />

                    })}
                    {accessory.map(function (it) {
                        const id = it.id
                        return <PastOrderITem id={id} orderid={orderid} delivered={delivered} is={'accessory'} />

                    })}
                </Grid.Column>
            }

            {rentOrBuy !== "buy" &&

                <Grid.Column width={16}>


                    {clothing.map(function (it) {
                        const id = it.id
                        return <PastOrderItemRenting id={id} orderid={orderid} returned={returned} delivered={delivered} is={'clothing'} />
                    })}

                    {saleClothing.map(function (it) {
                        const id = it.id
                        return <PastOrderItemRenting id={id} orderid={orderid} returned={returned} delivered={delivered} is={'saleClothing'} />
                    })}
                    {accessory.map(function (it) {
                        const id = it.id
                        return <PastOrderItemRenting id={id} orderid={orderid} returned={returned} delivered={delivered} is={'accessory'} />
                    })}
                </Grid.Column>
            }
        </Grid.Row>

    }
}
 

export default withRouter(PastOrder)