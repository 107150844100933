import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { BACKEND_URL } from '../../utils/config';
import { showErrorWithTimeout } from '../../redux/slices/errorSlice';
import { formatAddress, isValidAddress, formatAddressDisplay, parseAddress } from '../../utils/addressUtils';

const AddressInput = ({ 
  label = 'Address',
  description,
  onAddressChange,
  required = false,
  error
}) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.user);
  const [isNewAddress, setIsNewAddress] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [address, setAddress] = useState(formatAddress({}));
  const [formattedAddresses, setFormattedAddresses] = useState([]);

  // Format addresses when userInfo changes
  useEffect(() => {
    if (userInfo?.shippingaddress) {
      const formatted = userInfo.shippingaddress.map(addr => parseAddress(addr));
      setFormattedAddresses(formatted);
    }
  }, [userInfo?.shippingaddress]);

  const handleExistingAddressSelect = (e) => {
    const value = e.target.value;
    if (value === 'new') {
      setIsNewAddress(true);
      setSelectedAddress(null);
      onAddressChange(null);
    } else {
      setIsNewAddress(false);
      const selected = formattedAddresses.find(addr => addr.id === value);
      if (selected) {
        setSelectedAddress(selected);
        onAddressChange(selected);
      }
    }
  };

  const handleChange = (field, value) => {
    const newAddress = formatAddress({
      ...address,
      [field]: value
    });
    setAddress(newAddress);
    onAddressChange(newAddress);
  };

  const handleSaveAddress = async () => {
    if (!isValidAddress(address)) {
      dispatch(showErrorWithTimeout('Please fill in all address fields', 'error'));
      return;
    }

    setIsSaving(true);
    try {
      // Format the address with a new ID
      const newAddress = formatAddress({
        ...address,
        id: String(Date.now())
      });

      // Update user's shipping addresses
      const response = await axios.put(`${BACKEND_URL}/users/${userInfo.id}`, {
        shippingaddress: [...formattedAddresses, newAddress]
      });

      if (response.data) {
        dispatch(showErrorWithTimeout('Address saved successfully', 'success'));
        // Select the newly saved address
        setIsNewAddress(false);
        setSelectedAddress(newAddress);
        onAddressChange(newAddress);
        // Clear the form
        setAddress(formatAddress({}));
        // Update formatted addresses
        setFormattedAddresses(prev => [...prev, newAddress]);
      }
    } catch (error) {
      dispatch(showErrorWithTimeout(error.response?.data?.message || 'Failed to save address', 'error'));
    } finally {
      setIsSaving(false);
    }
  };

  const getInputClasses = () => {
    const baseClasses = "form-input block w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input";
    return error
      ? `${baseClasses} border-red-300 text-red-900 placeholder-red-300 focus:border-red-500`
      : `${baseClasses} border-gray-300 focus:border-brand-teal`;
  };

  return (
    <div className="space-y-4">
      {description && (
        <p className="text-sm text-gray-500 mb-3">{description}</p>
      )}

      {/* New Address Form */}
      <div className="space-y-4">
        <div>
          <label className="block text-label font-medium text-gray-700 mb-2">Street Address</label>
          <input
            type="text"
            value={address.street}
            onChange={(e) => handleChange('street', e.target.value)}
            className={getInputClasses()}
            placeholder="Street address, apartment, suite, etc."
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-label font-medium text-gray-700 mb-2">City</label>
            <input
              type="text"
              value={address.city}
              onChange={(e) => handleChange('city', e.target.value)}
              className={getInputClasses()}
              placeholder="City"
            />
          </div>
          <div>
            <label className="block text-label font-medium text-gray-700 mb-2">State/Province</label>
            <input
              type="text"
              value={address.state}
              onChange={(e) => handleChange('state', e.target.value)}
              className={getInputClasses()}
              placeholder="State/Province"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-label font-medium text-gray-700 mb-2">Postal Code</label>
            <input
              type="text"
              value={address.postalCode}
              onChange={(e) => handleChange('postalCode', e.target.value)}
              className={getInputClasses()}
              placeholder="Postal code"
            />
          </div>
          <div>
            <label className="block text-label font-medium text-gray-700 mb-2">Country</label>
            <input
              type="text"
              value={address.country}
              onChange={(e) => handleChange('country', e.target.value)}
              className={getInputClasses()}
              placeholder="Country"
            />
          </div>
        </div>
      </div>

      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
};

export default AddressInput; 