import React from 'react';
import { withUser } from '../../../../hocs/withUser';

class ContactAddress extends React.Component {
  state = {
    loading: true,
    address1: '',
    address2: '',
    state: '',
    city: '',
    country: '',
    zipCode: '',
    saved: false,
    merchantInfo: {}
  };

  componentDidMount() {
    const { user, userLoading } = this.props;
    
    if (!userLoading && user) {
      this.initializeFromUser(user);
    }
  }

  componentDidUpdate(prevProps) {
    const { user, userLoading } = this.props;
    
    if (!userLoading && user && prevProps.userLoading) {
      this.initializeFromUser(user);
    }
  }

  initializeFromUser(user) {
    const merchantInfo = user.merchantInfo || {};
    
    if (merchantInfo.address) {
      this.setState({
        address1: merchantInfo.address.address1 || '',
        address2: merchantInfo.address.address2 || '',
        state: merchantInfo.address.state || '',
        city: merchantInfo.address.city || '',
        country: merchantInfo.address.country || '',
        zipCode: merchantInfo.address.zipcode || '',
        saved: true,
        merchantInfo,
        loading: false
      });
    } else {
      this.setState({
        merchantInfo,
        loading: false
      });
    }
  }

  // ... rest of your component code ...

  render() {
    const { userLoading } = this.props;
    const { loading } = this.state;

    if (userLoading || loading) {
      return (
        <div className="flex justify-center items-center min-h-[200px]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-teal"></div>
        </div>
      );
    }

    // ... rest of your render code ...
  }
}

export default withUser(ContactAddress);
