import React, { useState, useEffect } from 'react';
import {
  Menu,
  Button,
  Icon,
  Dropdown,
  Confirm,
  Header,
  Search,
  Label
} from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useGetCurrentUserQuery } from '../../redux/api/apiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/userSlice';

const NavMobile = ({ windowWidth }) => {
  const [activeItem, setActiveItem] = useState('');
  const [open, setOpen] = useState(false);
  
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const { isLoggedIn, userInfo } = useSelector((state) => state.user);
  
  // Use RTK Query to fetch current user data without polling
  const { data: user } = useGetCurrentUserQuery(undefined, {
    skip: !isLoggedIn,
  });

  const identifier = userInfo?.username || userInfo?.email;

  // Calculate cart numbers from user data
  const cartNum = React.useMemo(() => {
    if (!user) return 0;
    return (
      (user.cart?.length || 0) +
      (user.accessoryCart?.length || 0) +
      (user.bag?.length || 0) +
      (user.accessoryBag?.length || 0)
    );
  }, [user]);

  // Update active menu item when route changes
  useEffect(() => {
    const menus = [
      { include: 'browse', activeItem: 'Browse' },
      { include: 'about', activeItem: 'About' },
      { include: 'merchant', activeItem: 'Merchants' },
      { include: 'contactUs', activeItem: 'Contact' },
      { include: 'mypage', activeItem: 'MyPage' },
      { include: 'cart', activeItem: 'Cart' },
      { include: 'heart', activeItem: 'Heart' },
      { include: 'message', activeItem: 'Message' }
    ];

    const pathname = location.pathname.toLowerCase();
    menus.forEach((each) => {
      if (pathname.includes(each.include.toLowerCase())) {
        setActiveItem(each.activeItem);
      }
    });
  }, [location.pathname]);

  const handleItemClick = (e, data) => {
    const link = data.linkto;
    setActiveItem(data.name);

    if (link === '/clothing') {
      history.push('/allProducts');
      return;
    }
    if (link === '/heart') {
      localStorage.setItem('filter', 'All');
    }

    if (link === '/merchants') {
      const merchantLogin = localStorage.getItem('merchantLogin');
      if (merchantLogin !== 'true') {
        history.push('/merchantsLog');
      } else {
        history.push('/merchantPage');
      }
    } else {
      history.push(link);
    }
  };

  const handleSignOut = (event) => {
    event.preventDefault();
    setOpen(false);
    dispatch(logout());
    history.push('/');
  };

  return (
    <>
      <Menu
        borderless
        color='teal'
        size='huge'
        inverted
        style={{ 
          marginTop: 0, 
          marginBottom: 0, 
          paddingTop: 10, 
          paddingBottom: 15 
        }}
      >
        <Menu.Item>
          <Link to='/'>
            <Header
              as='h1'
              inverted
              linkto='/'
              name='Home'
              active={activeItem === 'Home'}
            >
              SHEYOU
              <Label style={{ backgroundColor: '#F5F5DD' }}>BETA</Label>
            </Header>
          </Link>
        </Menu.Item>

        <Dropdown item icon='bars' style={{ width: 200 }}>
          <Dropdown.Menu>
            <Menu.Item
              linkto='/allProducts'
              name='Browse'
              active={activeItem === 'Browse'}
              onClick={handleItemClick}
              className='bottomDivider'
            >
              Browse
            </Menu.Item>
            <Menu.Item
              name='About'
              linkto='/about'
              active={activeItem === 'About'}
              onClick={handleItemClick}
              className='bottomDivider'
            >
              About
            </Menu.Item>

            <Menu.Item
              linkto='/merchants'
              name='Merchants'
              active={activeItem === 'Merchants'}
              onClick={handleItemClick}
              className='bottomDivider'
            >
              Merchants
            </Menu.Item>

            <Menu.Item
              name='Contact'
              linkto='/contactUs'
              active={activeItem === 'Contact'}
              onClick={handleItemClick}
              className='bottomDivider'
            >
              Contact
            </Menu.Item>

            <Menu.Item>
              <Menu.Item>
                <Search placeholder='Search...' />
              </Menu.Item>
            </Menu.Item>

            {isLoggedIn && (
              <>
                <Dropdown.Divider />
                <Menu.Item
                  name='MyPage'
                  linkto='/mypage'
                  active={activeItem === 'MyPage'}
                  onClick={handleItemClick}
                >
                  My Page
                </Menu.Item>
                <Menu.Item
                  name='Cart'
                  linkto='/cart'
                  active={activeItem === 'Cart'}
                  onClick={handleItemClick}
                >
                  <Icon name='shopping cart' style={{ paddingRight: 50 }} />
                 
                  Cart 

                  {user && (user.cart?.length || 0) + (user.accessoryCart?.length || 0) + (user.bag?.length || 0) + (user.accessoryBag?.length || 0) > 0 && (
                    <Label    pointing='left' color='yellow' style={{marginLeft: '20px'  }}>
                      {(user.cart?.length || 0) + (user.accessoryCart?.length || 0) + (user.bag?.length || 0) + (user.accessoryBag?.length || 0)}
                    </Label>
                  )}
                </Menu.Item>
                <Menu.Item
                  name='Heart'
                  linkto='/heart'
                  active={activeItem === 'Heart'}
                  onClick={handleItemClick}
                >
                  <Icon name='heart' style={{ paddingRight: 50 }} />
                  Heart
                </Menu.Item>

                <Menu.Item
                  name='Message'
                  linkto='/message'
                  active={activeItem === 'Message'}
                  onClick={handleItemClick}
                >
                  <Icon name='chat' style={{ paddingRight: 50 }} />
                  Message
                </Menu.Item>
              </>
            )}

            {windowWidth < 655 && (
              <>
                <Dropdown.Divider />
                {isLoggedIn ? (
                  <Menu.Item onClick={() => setOpen(true)} style={{marginBottom:8}}>
                    <Icon name='sign-out' style={{ paddingRight: 50 }} />
                    Sign out
                  </Menu.Item>
                ) : (
                  <Menu.Item as={Link} to='/signin' style={{marginBottom:8}}>
                    <Icon name='sign-in' style={{ paddingRight: 50 }} /> Sign
                    In / Sign Up
                  </Menu.Item>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>

        {windowWidth >= 655 && (
          <Menu.Menu position='right'>
            {isLoggedIn ? (
              <Menu.Item>
                <Button size='huge' inverted onClick={() => setOpen(true)}>
                  Sign out
                </Button>
              </Menu.Item>
            ) : (
              <Menu.Item>
                <Link to='/signin'>
                  <Button size='huge' inverted>
                    Sign In / Sign Up
                  </Button>
                </Link>
              </Menu.Item>
            )}
          </Menu.Menu>
        )}
      </Menu>

      <Confirm
        open={open}
        cancelButton='Never mind'
        confirmButton='Yes'
        content='Are you sure you want to log out?'
        onCancel={() => setOpen(false)}
        onConfirm={handleSignOut}
      />
    </>
  );
};

export default NavMobile;
