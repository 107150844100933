import { BACKEND_URL } from '../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../utils/config';
import React from 'react'
import { Card, Image, Label, Placeholder, Dimmer } from 'semantic-ui-react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage



class HeartTemplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userInfo: [],
      userID: [],
      heartList: [],
      membership: [],
      color: '',
      identifier: '',
      token: '',
      log: '',
      loading: true

    }
    this.onHandleClick = this.onHandleClick.bind(this)
    this.ifHearted = this.ifHearted.bind(this)
    this.heartTag = this.heartTag.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)

  }


  componentDidMount() {

    const self = this
    const log = localStorage.getItem('login')
    self.setState({ log: log })
    if (log === 'true') {

      self.getUserInfo()
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 7000);
  }

  getUserInfo() {
    const self = this
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')


    self.setState({
      identifier: identifier,
      token: token
    })

    axios(`${BACKEND_URL}/users/me`, {
      method: 'get',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(function (res) {
        if (res.status === 200) {
          const userInfo = [res.data]
          if (userInfo[0].heart === null) {
            userInfo[0].heart = []
          }
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id,
            heartList: userInfo[0].heart,
            membership: userInfo[0].membership,
            loading: false
          })


        }
        else {
          alert('Something went wrong, maybe log in again :(')
          localStorage.setItem('login', 'false')
        }


      })

  }

  ifHearted(product) {
    const self = this
    const productId = product.id
    const productType = product.type || 'product'
    const heartList = self.state.heartList || []

    const heart = heartList.find(item => 
      item.id === productId && item.type === productType
    )

    return heart ? 'yellow' : ''
  }


  onHandleClick(event, data) {
    const self = this
    const productId = event.target.id
    const productType = event.target.name
    const category = event.target.title
    console.log(productType, category)
    event.preventDefault()
    if (productType === 'sale') {
      localStorage.setItem('saleproductId', productId)
      self.props.history.push('/saleproduct')
    } else if (category === 'Accessaries') {
      localStorage.setItem('AcessoryId', productId)
      self.props.history.push('/accessoryItem')
    }
    else {
      localStorage.setItem('productId', productId)
      self.props.history.push('/product')
    }

  }

  heartTag(hearted, productId, productType) {
    const self = this
    var heartList = self.state.heartList || []
    var heartData = []
    const token = self.state.token
    const userID = self.state.userID

    if (hearted === 'yellow') {
      // Remove the item from heartList
      heartData = heartList.filter(item => 
        !(item.id === productId && item.type === productType)
      )
    } else {
      // Add the item to heartList
      heartData = [...heartList, { id: productId, type: productType }]
    }

    axios(`${BACKEND_URL}/users/${userID}`, {
      method: 'put',
      data: {
        heart: heartData
      },
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }).then(function (res) {
      if (res.status === 200) {
        self.getUserInfo()
      } else {
        alert('Something went wrong, please log in again :(')
        localStorage.setItem('login', false)
      }
    })
  }

  render() {
    const p = this.props
    const self = this
    const products = p.products
    // console.log(products)
    const loading = self.state.loading
    const log = self.state.log
    // const color=self.state.color

    // const heartList = self.state.heartList


    return (
      <Card.Group centered >

        {products.map(function (product) {
          const heart = self.ifHearted(product)
          return <Card id={product.id} >
            <Dimmer active={product.stock === '0'} inverted />
            {loading ? (
              <>
                <Placeholder>
                  <Placeholder.Image square />

                </Placeholder>

                <Card.Content>
                  <Placeholder>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length='very short' />
                      <Placeholder.Line length='medium' />
                      <Placeholder.Line length='short' />
                    </Placeholder.Paragraph>

                  </Placeholder>
                </Card.Content>
              </>

            ) : (<>
              {log === 'true' &&
                <Label
                  as='a'
                  color={heart}
                  corner='right'
                  icon='heart'
                  onClick={() => self.heartTag(heart, product.id, product.type || 'product')}
                />}
              {product.type === 'sale' &&
                <Image
                  id={product.id}
                  onClick={self.onHandleClick}
                  title={product.categories[0].name}
                  name={product.type}
                  style={{
                    height: '290px',
                    width: '230px',
                    marginLeft: '25px'

                  }}
                  src={product.picture[0].formats.small.url}
                />}
              {
                product.type !== 'sale' &&
                <Image
                  style={{ height: 300 }}
                  id={product.id}
                  title={product.categories[0].name}
                  name={product.type}
                  onClick={self.onHandleClick}
                  src={product.picture[0].formats.small.url}
                />
              }


              <Card.Content
                id={product.id}
                title={[product.type, product.categories[0].name]}
                onClick={self.onHandleClick}>


                <Card.Header id={product.id}>
                  {product.name}
                </Card.Header>

                {
                  product.description!==null&&
                  <Card.Meta id={product.id}>{product.description.substring(0, 20) + "..."}</Card.Meta>
                }
                



                <Card.Description id={product.id}>
                  {userLang === 'zh-CN' && (product.price + 'RMB')}
                  {userLang !== 'zh-CN' && (product.price + 'RMB')}
                </Card.Description>
              </Card.Content> </>)}
          </Card>

        })}
      </Card.Group>
    )
  }
}

export default withRouter(HeartTemplete)