import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainFilter from './MainFilter';
import CardTemplete from './CardTemplete';
import { loadMore, searchWithAlgolia, setMerchantPage } from '../../redux/slices/productSlice';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

import MerchantProductMenu from '../merchants/merchantPage/MerchantProductMenu';
import MerchantProductCard from '../merchants/merchantPage/MerchantProductCard';

const LoadingSpinner = () => (
  <div className="flex justify-center items-center py-8">
    <div className="animate-spin rounded-full h-10 w-10 border-4 border-brand-teal border-t-transparent"></div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded mb-20">
    <p className="text-red-700 font-medium">Error Loading Products</p>
    <p className="text-red-600">{message || 'Please try again later.'}</p>
  </div>
);

const NoResultsMessage = ({ searchTerm }) => (
  <div className="bg-brand-beige/10 border-l-4 border-brand-beige p-4 rounded mb-20">
    <p className="text-brand-orange font-medium">No Products Found</p>
    <p className="text-brand-orange-dark">
      {searchTerm 
        ? `No results found for "${searchTerm}". Try different search terms.`
        : 'Try adjusting your filters to see more results.'}
    </p>
  </div>
);

const EndOfResultsMessage = () => (
  <div className="mt-8 mb-20 bg-brand-beige/10 border-l-4 border-brand-beige p-4 rounded">
    <p className="text-brand-orange font-medium">End of Results</p>
    <p className="text-brand-orange-dark">You've seen all the products that match your criteria.</p>
  </div>
);

const AllProductPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  
  const { 
    products, 
    loading, 
    error, 
    hasMore, 
    filters,
    sortBy,
    searchTerm,
    totalResults,
    offset,
    isMerchantPage
  } = useSelector(state => state.products);

  // Create refs for scroll handling
  const scrollContainerRef = useRef(null);
  const isLoadingRef = useRef(false);

  // Add effect to handle merchant page mode based on URL
  useEffect(() => {
    const isMerchantProductsPage = location.pathname === '/merchantpage/products';
    
    // Only dispatch if the mode needs to change
    if (isMerchantProductsPage !== isMerchantPage) {
      console.log('AllProductPage: Setting merchant page mode based on URL:', { isMerchantProductsPage });
      dispatch(setMerchantPage(isMerchantProductsPage));
    }
  }, [location.pathname]);

 

  // Setup scroll container and handlers
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) {
      console.log('Scroll container not found');
      return;
    }

    const handleScroll = () => {
      const scrollPosition = Math.ceil(container.scrollTop + container.clientHeight);
      const scrollThreshold = container.scrollHeight - 100; // Reduced threshold for earlier trigger
      console.log('Scroll debug:', {
        scrollPosition,
        scrollThreshold,
        scrollTop: container.scrollTop,
        clientHeight: container.clientHeight,
        scrollHeight: container.scrollHeight,
        hasMore,
        loading,
        isLoadingRef: isLoadingRef.current
      });
      
      if (scrollPosition >= scrollThreshold && !loading && hasMore && !isLoadingRef.current) {
        console.log('Triggering load more...', {
          currentOffset: offset,
          filters,
          sortBy
        });

        isLoadingRef.current = true;
        const params = new URLSearchParams(location.search);
        const urlSort = params.get('sort') || 'newest';
        const urlSearchTerm = params.get('q') || '';

        dispatch(loadMore());
        dispatch(searchWithAlgolia({ 
          query: urlSearchTerm,
          filters,
          sortBy: urlSort,
          offset: offset + 20
        })).finally(() => {
          isLoadingRef.current = false;
        });
      }
    };

    const debouncedScroll = debounce(handleScroll, 150);
    container.addEventListener('scroll', debouncedScroll);
    
    // Initial check in case content doesn't fill the container
    
    return () => {
      container.removeEventListener('scroll', debouncedScroll);
      debouncedScroll.cancel();
    };
  }, [hasMore, offset, loading, filters, sortBy, location.search, dispatch]);


  const renderContent = () => {
    // Initial loading state - show when loading and no products yet
    

    // Error state with no products
    if (error && !loading && products.length === 0) {
      return <ErrorMessage message={error} />;
    }

    // No results state - only show when not in initial loading
    if (!loading && products.length === 0) {
      return <NoResultsMessage searchTerm={searchTerm} />;
    }

    // Results state
    return (
      <>
        {searchTerm && (
          <div className="mb-4 text-sm text-gray-600">
            Found {totalResults} results for "{searchTerm}"
          </div>
        )}
        
        {isMerchantPage ? (
          <MerchantProductCard />
        ) : (
          <CardTemplete  />
        )}
                
        {/* Add extra padding at the bottom for better scroll experience */}
        <div className="pb-20"></div>
        
        {!hasMore && !loading && <EndOfResultsMessage />}
      </>
    );
  };

  return (
    <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 mt-4">
      {loading && <LoadingSpinner />}
      {isMerchantPage ? (
        <div className="flex flex-col gap-6">
          <div className="w-full">
            <MerchantProductMenu />
          </div>
          <div 
            ref={scrollContainerRef}
            className="w-full overflow-y-auto"
            style={{ maxHeight: 'calc(100vh - 200px)' }}
          >
            {renderContent()}
          </div>
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-1/4 xl:w-1/5 lg:sticky lg:top-4 lg:h-[calc(100vh-2rem)]">
            <MainFilter />
          </div>
          <div 
            ref={scrollContainerRef}
            className="w-full lg:w-3/4 xl:w-4/5 overflow-y-auto"
            style={{ maxHeight: 'calc(100vh - 100px)' }}
          >
            {renderContent()}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProductPage; 