import { useMemo, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setFilters, setSearchTerm, setSort, fetchAdditionalFilterOptions, searchWithAlgolia, fetchFilterOptions } from '../../redux/slices/productSlice'
import SearchBox from '../search/SearchBox'
import store from '../../redux/store'

const MainFilter = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [moreFiltersOpen, setMoreFiltersOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const {loading} = useSelector(state => state.products)

  // Add initialization effect
  useEffect(() => {
    const initializeFilters = async () => {
      // Fetch filter options if needed
      await dispatch(fetchFilterOptions());
      await dispatch(fetchAdditionalFilterOptions());

      // Get initial values from URL
      const params = new URLSearchParams(location.search);
      const newSearchTerm = params.get('q') || '';
      const newSort = params.get('sort') || 'newest';
      const newFilters = getInitialFiltersFromURL(params);

      console.log('MainFilter: Starting initialization/URL change', {
        searchTerm: newSearchTerm,
        sort: newSort,
        filters: newFilters,
        search: location.search
      });

      // Set initial state and perform search
      dispatch(setSearchTerm(newSearchTerm));
      dispatch(setSort(newSort));
      dispatch(setFilters(newFilters));
      dispatch(searchWithAlgolia({
        query: newSearchTerm,
        filters: newFilters,
        sortBy: newSort,
        offset: 0
      }));
    };

    initializeFilters();
  }, [dispatch, location.search]); // Include all dependencies

  // Remove the duplicate URL change effect since it's now combined with initialization
  
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const { 
    filterOptions, 
    filters, 
    sortBy,
    isMerchantPage 
  } = useSelector(state => state.products)

  // Memoize filter options
  const { catOpt, sizeOpt, colorOpt, occasionOpt, styleOpt, genderOpt } = useMemo(
    () => ({
      catOpt: [
        {
          text: 'All Categories',
          value: 'category0',
          key: 'category0',
          filter: 'category'
        },
        ...(filterOptions.categories || []).map(cat => ({
          text: cat.name,
          value: 'category' + cat.id,
          key: 'category' + cat.id,
          filter: 'category'
        }))
      ],
      sizeOpt: [
        { text: 'All Size', value: 'size0', key: 'size0', filter: 'size' },
        ...(filterOptions.sizes || []).map(size => ({
          text: size.name,
          value: 'size' + size.id,
          key: 'size' + size.id,
          filter: 'size'
        }))
      ],
      colorOpt: [
        { text: 'All Colors', value: '0', key: 'color0' },
        ...(filterOptions.colors || []).map(color => ({
          text: color.name,
          value: color.id.toString(),
          key: `color${color.id}`
        }))
      ],
      occasionOpt: [
        { text: 'All Occasions', value: '0', key: 'occasion0' },
        ...(filterOptions.occasions || []).map(occasion => ({
          text: occasion.name,
          value: occasion.id.toString(),
          key: `occasion${occasion.id}`
        }))
      ],
      styleOpt: [
        { text: 'All Styles', value: '0', key: 'style0' },
        ...(filterOptions.styles || []).map(style => ({
          text: style.name,
          value: style.id.toString(),
          key: `style${style.id}`
        }))
      ],
      genderOpt: [
        { text: 'All Genders', value: '0', key: 'gender0' },
        ...(filterOptions.genders || []).map(gender => ({
          text: gender.name,
          value: gender.id.toString(),
          key: `gender${gender.id}`
        }))
      ]
    }),
    [filterOptions]
  )

  // Remove initialization effect and keep only the filter options memos
  const productTypeOptions = useMemo(
    () => [
      { key: 'all', text: 'All', value: 'all' },
      { key: 'designer', text: 'Original Design', value: 'designer' },
      { key: 'sharing', text: 'Closet Sharing', value: 'sharing' },
      { key: 'virtual', text: 'Virtual Fashion', value: 'virtual' },
      { key: 'others', text: 'Others', value: 'others' }
    ],
    []
  )

  const transactionOptions = useMemo(
    () => [
      { key: 'all', text: 'All', value: 'all' },
      { key: 'sell', text: 'Buy', value: 'sell' },
      { key: 'rent', text: 'Rent', value: 'rent' },
      { key: 'swap', text: 'Swap', value: 'swap' }
    ],
    []
  )

  const sortOptions = useMemo(
    () => [
      { key: 'newest', text: 'Newest Items', value: 'newest' },
      { key: 'price_asc', text: 'Price: Low to High', value: 'price_asc' },
      { key: 'price_desc', text: 'Price: High to Low', value: 'price_desc' },
      { key: 'likes', text: 'Most Liked', value: 'likes' }
    ],
    []
  )

  // Handle More Filters click
  const handleMoreFiltersClick = async () => {
    setMoreFiltersOpen(!moreFiltersOpen);
  };



  // Update search term
  const handleSearch = (query) => {
    dispatch(setSearchTerm(query));
    const params = new URLSearchParams(location.search);
    if (query) {
      params.set('q', query);
    } else {
      params.delete('q');
    }
    history.push({
      pathname: location.pathname,
      search: params.toString()
    });

    
  };

  const getInitialFiltersFromURL = (searchParams) => {
    return {
      productTypes: searchParams.get('productTypes')?.split(',').filter(Boolean) || [],
      transactionTypes: searchParams.get('transactionTypes')?.split(',').filter(Boolean) || [],
      category: searchParams.get('category') || null,
      size: searchParams.get('size') || null,
      color: searchParams.get('color') || null,
      occasion: searchParams.get('occasion') || null,
      style: searchParams.get('style') || null,
      gender: searchParams.get('gender') || null,
      localPickup: searchParams.get('localPickup') ? {
        enabled: true,
        distance: parseInt(searchParams.get('pickupDistance')) || 5
      } : undefined
    };
  };

  const handleFilterChange = async (e, data) => {
    const { value } = data;
    const params = new URLSearchParams(location.search);
    
    // Update URL parameters based on filter type
    if (value.startsWith('category')) {
      const categoryId = value.replace('category', '');
      if (categoryId === '0') {
        params.delete('category');
      } else {
        params.set('category', categoryId);
      }
    } else if (value.startsWith('size')) {
      const sizeId = value.replace('size', '');
      if (sizeId === '0') {
        params.delete('size');
      } else {
        params.set('size', sizeId);
      }
    } else if (value.startsWith('color')) {
      const colorId = value.replace('color', '');
      if (colorId === '0') {
        params.delete('color');
      } else {
        params.set('color', colorId);
      }
    } else if (value.startsWith('occasion')) {
      const occasionId = value.replace('occasion', '');
      if (occasionId === '0') {
        params.delete('occasion');
      } else {
        params.set('occasion', occasionId);
      }
    } else if (value.startsWith('style')) {
      const styleId = value.replace('style', '');
      if (styleId === '0') {
        params.delete('style');
      } else {
        params.set('style', styleId);
      }
    } else if (value.startsWith('gender')) {
      const genderId = value.replace('gender', '');
      if (genderId === '0') {
        params.delete('gender');
      } else {
        params.set('gender', genderId);
      }
    }

    // Update URL without reloading page
    history.push({
      pathname: location.pathname,
      search: params.toString()
    });

    // Update Redux state
    const newFilters = getInitialFiltersFromURL(params);
    dispatch(setFilters(newFilters));
  };

  const handleProductTypeChange = (e) => {
    const value = e.target.value;
    const params = new URLSearchParams(location.search);
    
    if (value === 'all') {
      params.delete('productTypes');
    } else {
      params.set('productTypes', value);
    }
    
    history.push({
      pathname: location.pathname,
      search: params.toString()
    });

    const newFilters = getInitialFiltersFromURL(params);
    dispatch(setFilters(newFilters));
  };

  const handleTransactionTypeChange = (e) => {
    const value = e.target.value;
    const params = new URLSearchParams(location.search);
    
    if (value === 'all') {
      params.delete('transactionTypes');
    } else {
      params.set('transactionTypes', value);
    }
    
    history.push({
      pathname: location.pathname,
      search: params.toString()
    });

    const newFilters = getInitialFiltersFromURL(params);
    dispatch(setFilters(newFilters));
  };

  const handleLocalPickupChange = (e) => {
    const checked = e.target.checked;
    const params = new URLSearchParams(location.search);
    
    if (checked) {
      params.set('localPickup', 'true');
      params.set('pickupDistance', (filters.localPickup?.distance || 5).toString());
    } else {
      params.delete('localPickup');
      params.delete('pickupDistance');
    }
    
    history.push({
      pathname: location.pathname,
      search: params.toString()
    });

    const newFilters = getInitialFiltersFromURL(params);
    dispatch(setFilters(newFilters));
  };

  const handleDistanceChange = (e) => {
    const distance = parseInt(e.target.value);
    const params = new URLSearchParams(location.search);
    
    params.set('localPickup', 'true');
    params.set('pickupDistance', distance.toString());
    
    history.push({
      pathname: location.pathname,
      search: params.toString()
    });

    const newFilters = getInitialFiltersFromURL(params);
    dispatch(setFilters(newFilters));
  };

  // Update handleSortChange function
  const handleSortChange = async (e) => {
    const value = e.target.value;
    console.log('Sort change triggered:', {
      newValue: value,
      currentSort: sortBy,
      currentURL: location.search
    });
    
    
    
    // Update URL
    const params = new URLSearchParams(location.search);
    params.set('sort', value);
    history.push({
      pathname: location.pathname,
      search: params.toString()
    });

   // Update Redux state
   dispatch(setSort(value));
  };

  // Add clearAllFilters handler
  const handleClearAllFilters = () => {
    // Clear URL parameters
    history.push({
      pathname: location.pathname,
      search: '' // Remove all query parameters
    });

    // Reset filters in Redux
    dispatch(setFilters({}));
    dispatch(setSearchTerm(''));
    dispatch(setSort('newest'));

   
  };

  // First, let's create a component for the additional filters
  const AdditionalFilters = ({ filters, handleFilterChange }) => (
    <>
      {/* Color Filter */}
      <div className="min-w-[200px] flex-shrink-0">
        <label className="block text-sm font-medium text-gray-700 mb-2">Color</label>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
          value={filters.color ? `color${filters.color}` : 'color0'}
          onChange={(e) => handleFilterChange(e, { value: e.target.value })}
        >
          {colorOpt.map(option => (
            <option key={option.key} value={`color${option.value}`}>
              {option.text}
            </option>
          ))}
        </select>
      </div>

      {/* Occasion Filter */}
      <div className="min-w-[200px] flex-shrink-0">
        <label className="block text-sm font-medium text-gray-700 mb-2">Occasion</label>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
          value={filters.occasion ? `occasion${filters.occasion}` : 'occasion0'}
          onChange={(e) => handleFilterChange(e, { value: e.target.value })}
        >
          {occasionOpt.map(option => (
            <option key={option.key} value={`occasion${option.value}`}>
              {option.text}
            </option>
          ))}
        </select>
      </div>

      {/* Style Filter */}
      <div className="min-w-[200px] flex-shrink-0">
        <label className="block text-sm font-medium text-gray-700 mb-2">Style</label>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
          value={filters.style ? `style${filters.style}` : 'style0'}
          onChange={(e) => handleFilterChange(e, { value: e.target.value })}
        >
          {styleOpt.map(option => (
            <option key={option.key} value={`style${option.value}`}>
              {option.text}
            </option>
          ))}
        </select>
      </div>

      {/* Gender Filter */}
      <div className="min-w-[200px] flex-shrink-0">
        <label className="block text-sm font-medium text-gray-700 mb-2">Gender</label>
        <select
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
          value={filters.gender ? `gender${filters.gender}` : 'gender0'}
          onChange={(e) => handleFilterChange(e, { value: e.target.value })}
        >
          {genderOpt.map(option => (
            <option key={option.key} value={`gender${option.value}`}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </>
  );

  return (
    <div className="bg-white rounded-lg shadow-sm p-4">
      {!loading && (
      <div className={`space-y-4 ${windowWidth >= 1024 && !isMerchantPage ? 'lg:w-64' : 'w-full'}`}>
        {windowWidth >= 1024 && !isMerchantPage ? (
          // Desktop layout - Vertical stack
          <>
            {/* Search Box */}
            <div className="mb-4">
              <SearchBox 
                filters={filters}
                sortBy={sortBy}
                onSearch={handleSearch}
              />
            </div>

            {/* Local Pickup */}
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id="localPickup"
                  checked={filters.localPickup?.enabled || false}
                  onChange={handleLocalPickupChange}
                  className="h-4 w-4 text-brand-teal focus:ring-brand-teal border-gray-300 rounded"
                />
                <label htmlFor="localPickup" className="ml-2 text-sm text-gray-700">
                  Open to Local Pickup
                </label>
              </div>
              
              {filters.localPickup?.enabled && (
                <div>
                  <input
                    type="range"
                    min="0"
                    max="10"
                    value={filters.localPickup?.distance || 5}
                    onChange={handleDistanceChange}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  />
                  <div className="text-xs text-gray-500 mt-1">
                    {filters.localPickup?.distance || 10} km
                  </div>
                </div>
              )}
            </div>

            {/* Sort Dropdown */}
            <div className="mb-4">
              <div className="flex items-center gap-2">
                <svg className="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                </svg>
                <select
                  className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-sm"
                  onChange={handleSortChange}
                  value={sortBy}
                >
                  {sortOptions.map(option => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Product Type Filter */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Product Type</label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                value={filters.productTypes?.[0] || 'all'}
                onChange={handleProductTypeChange}
              >
                {productTypeOptions.map(option => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            {/* Transaction Type Filter */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Transaction Type</label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                value={filters.transactionTypes?.[0] || 'all'}
                onChange={handleTransactionTypeChange}
              >
                {transactionOptions.map(option => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            {/* Category Filter */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Category</label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                value={filters.category ? `category${filters.category}` : 'category0'}
                onChange={(e) => handleFilterChange(e, { value: e.target.value })}
              >
                {catOpt.map(option => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            {/* Size Filter */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">Size</label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                value={filters.size ? `size${filters.size}` : 'size0'}
                onChange={(e) => handleFilterChange(e, { value: e.target.value })}
              >
                {sizeOpt.map(option => (
                  <option key={option.key} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            {/* More Filters Toggle and Clear All */}
            <div className="mb-4 flex items-center justify-between">
              <button
                className="text-brand-teal hover:text-brand-teal-dark focus:outline-none text-sm font-medium"
                onClick={handleMoreFiltersClick}
              >
                {moreFiltersOpen ? '- Less Filters' : '+ More Filters'}
              </button>
              
              <button
                className="text-red-500 hover:text-red-600 focus:outline-none text-sm font-medium"
                onClick={handleClearAllFilters}
              >
                Clear All Filters
              </button>
            </div>

            {/* More Filters Section */}
            {moreFiltersOpen && (
              <div className="space-y-4">
                <AdditionalFilters filters={filters} handleFilterChange={handleFilterChange} />
              </div>
            )}
          </>
        ) : windowWidth >= 525 || isMerchantPage ? (
          // Tablet layout - Four rows with extra filters to the right
          <>
            {/* First Row - Search and Sort */}
            <div className="flex items-center gap-4 mb-4">
              <div className="w-2/3">
                <SearchBox 
                  filters={filters}
                  sortBy={sortBy}
                  onSearch={handleSearch}
                />
              </div>

              <div className="w-1/3 flex items-center gap-2">
                <svg className="w-4 h-4 text-gray-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                </svg>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-sm"
                  onChange={handleSortChange}
                  value={sortBy}
                >
                  {sortOptions.map(option => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Second Row - Main Filters */}
            <div className="grid grid-cols-4 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Product Type</label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                  value={filters.productTypes?.[0] || 'all'}
                  onChange={handleProductTypeChange}
                >
                  {productTypeOptions.map(option => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Transaction Type</label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                  value={filters.transactionTypes?.[0] || 'all'}
                  onChange={handleTransactionTypeChange}
                >
                  {transactionOptions.map(option => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Category</label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                  value={filters.category ? `category${filters.category}` : 'category0'}
                  onChange={(e) => handleFilterChange(e, { value: e.target.value })}
                >
                  {catOpt.map(option => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Size</label>
                <select
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                  value={filters.size ? `size${filters.size}` : 'size0'}
                  onChange={(e) => handleFilterChange(e, { value: e.target.value })}
                >
                  {sizeOpt.map(option => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Last Row - Local Pickup, More Filters and Clear All */}
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="localPickup"
                    checked={filters.localPickup?.enabled || false}
                    onChange={handleLocalPickupChange}
                    className="h-4 w-4 text-brand-teal focus:ring-brand-teal border-gray-300 rounded"
                  />
                  <label htmlFor="localPickup" className="whitespace-nowrap text-sm text-gray-700">
                    Open to Local Pickup
                  </label>
                  {filters.localPickup?.enabled && (
                    <div className="w-32">
                      <input
                        type="range"
                        min="0"
                        max="10"
                        value={filters.localPickup?.distance || 5}
                        onChange={handleDistanceChange}
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      />
                      <div className="text-xs text-gray-500 text-center">
                        {filters.localPickup?.distance || 10} km
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              <div className="flex items-center gap-4">
                <button
                  className="text-brand-teal hover:text-brand-teal-dark focus:outline-none text-sm font-medium whitespace-nowrap"
                  onClick={handleMoreFiltersClick}
                >
                  {moreFiltersOpen ? '- Less Filters' : '+ More Filters'}
                </button>
                
                <button
                  className="text-red-500 hover:text-red-600 focus:outline-none text-sm font-medium whitespace-nowrap"
                  onClick={handleClearAllFilters}
                >
                  Clear All Filters
                </button>
              </div>
            </div>

            {/* Extra Filters */}
            {moreFiltersOpen && (
              <div className="grid grid-cols-4 gap-4">
                <AdditionalFilters filters={filters} handleFilterChange={handleFilterChange} />
              </div>
            )}
          </>
        ) : (
          // Mobile layout - Similar to tablet but with horizontal scroll
          <>
            {/* First Row - Search */}
            <div className="mb-4">
              <SearchBox 
                filters={filters}
                sortBy={sortBy}
                onSearch={handleSearch}
              />
            </div>

            {/* Second Row - Local Pickup */}
            <div className="flex items-center gap-2 mb-4">
              <div className="flex items-center gap-2 flex-1">
                <input
                  type="checkbox"
                  id="localPickup"
                  checked={filters.localPickup?.enabled || false}
                  onChange={handleLocalPickupChange}
                  className="h-4 w-4 text-brand-teal focus:ring-brand-teal border-gray-300 rounded"
                />
                <label htmlFor="localPickup" className="text-sm text-gray-700 whitespace-nowrap">
                  Open to Local Pickup
                </label>
                {filters.localPickup?.enabled && (
                  <div className="w-20">
                    <input
                      type="range"
                      min="0"
                      max="10"
                      value={filters.localPickup?.distance || 5}
                      onChange={handleDistanceChange}
                      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    />
                    <div className="text-xs text-gray-500 text-center">
                      {filters.localPickup?.distance || 10} km
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center gap-1 w-[140px] flex-shrink-0">
                <svg className="w-4 h-4 text-gray-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                </svg>
                <select
                  className="w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-sm"
                  onChange={handleSortChange}
                  value={sortBy}
                >
                  {sortOptions.map(option => (
                    <option key={option.key} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Third Row - Main Filters Scrollable */}
            <div className="overflow-x-auto -mx-4 px-4 mb-4">
              <div className="flex gap-4 pb-2">
                {/* Product Type */}
                <div className="w-[180px] flex-shrink-0">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Product Type</label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                    value={filters.productTypes?.[0] || 'all'}
                    onChange={handleProductTypeChange}
                  >
                    {productTypeOptions.map(option => (
                      <option key={option.key} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Transaction Type */}
                <div className="w-[180px] flex-shrink-0">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Transaction Type</label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                    value={filters.transactionTypes?.[0] || 'all'}
                    onChange={handleTransactionTypeChange}
                  >
                    {transactionOptions.map(option => (
                      <option key={option.key} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Category */}
                <div className="w-[180px] flex-shrink-0">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Category</label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                    value={filters.category ? `category${filters.category}` : 'category0'}
                    onChange={(e) => handleFilterChange(e, { value: e.target.value })}
                  >
                    {catOpt.map(option => (
                      <option key={option.key} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Size */}
                <div className="w-[180px] flex-shrink-0">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Size</label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
                    value={filters.size ? `size${filters.size}` : 'size0'}
                    onChange={(e) => handleFilterChange(e, { value: e.target.value })}
                  >
                    {sizeOpt.map(option => (
                      <option key={option.key} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* Fourth Row - More Filters and Clear All */}
            <div className="flex items-center justify-between mb-4">
              <button
                className="text-brand-teal hover:text-brand-teal-dark focus:outline-none text-sm font-medium"
                onClick={handleMoreFiltersClick}
              >
                {moreFiltersOpen ? '- Less Filters' : '+ More Filters'}
              </button>
              
              <button
                className="text-red-500 hover:text-red-600 focus:outline-none text-sm font-medium"
                onClick={handleClearAllFilters}
              >
                Clear All
              </button>
            </div>

            {/* More Filters Section */}
            {moreFiltersOpen && (
              <div className="overflow-x-auto -mx-4 px-4">
                <div className="flex gap-4 pb-2">
                  <AdditionalFilters filters={filters} handleFilterChange={handleFilterChange} />
                </div>
              </div>
            )}
          </>
        )}
      </div>)}
    </div>
  );
};

export default MainFilter;
