import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleUnauthorized } from '../../../redux/slices/userSlice';
import { showErrorWithTimeout } from '../../../redux/slices/errorSlice';
import AllProductPage from '../../allProduct/AllProductPage';
import { 
  deleteProduct, 
  deleteMultipleProducts,
  setDeleteModalOpen,
  setProductToDelete,
  clearSelectedProducts,
  setProducts
} from '../../../redux/slices/productSlice';

const MerchantProduct = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const { userInfo } = useSelector(state => state.user);
  const { 
    selectedProducts, 
    deleteModalOpen, 
    productToDelete,
    products 
  } = useSelector(state => state.products);
  
  const merchantId = useMemo(() => {
    console.log('MerchantProduct: Calculating merchantId', { userInfo });
    if (!userInfo?.id) {
      console.error('No merchant ID available:', userInfo);
      dispatch(handleUnauthorized());
      history.push('/merchantLog');
      return null;
    }
    console.log('Using merchant ID from Redux store:', userInfo.id);
    return userInfo.id;
  }, []);

  const handleDeleteConfirm = async () => {
    if (!merchantId) return;
    
    try {
      if (selectedProducts.length > 0) {
        // Handle bulk delete using selectedProducts
        await dispatch(deleteMultipleProducts(selectedProducts)).unwrap();
        // Update UI by filtering out deleted products
        const updatedProducts = products.filter(p => !selectedProducts.includes(p.id));
        dispatch(setProducts(updatedProducts));
        dispatch(showErrorWithTimeout('Products deleted successfully', 'success'));
      } else if (productToDelete) {
        // Handle single delete using productToDelete
        await dispatch(deleteProduct(productToDelete)).unwrap();
        // Update UI by filtering out deleted product
        const updatedProducts = products.filter(p => p.id !== productToDelete);
        dispatch(setProducts(updatedProducts));
        dispatch(showErrorWithTimeout('Product deleted successfully', 'success'));
      }
      // Clear all states after successful deletion
      dispatch(setDeleteModalOpen(false));
      dispatch(setProductToDelete(null));
      dispatch(clearSelectedProducts());
    } catch (error) {
      console.error('Error deleting product(s):', error);
      dispatch(showErrorWithTimeout('Failed to delete product(s)', 'error'));
    }
  };

  if (!merchantId) return null;

  return (
    <>
      <AllProductPage />

      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Delete</h3>
            <p className="text-gray-500 mb-6">
              {selectedProducts.length > 0
                ? `Are you sure you want to delete ${selectedProducts.length} selected products? This action cannot be undone.`
                : 'Are you sure you want to delete this product? This action cannot be undone.'}
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => {
                  dispatch(setDeleteModalOpen(false));
                  dispatch(setProductToDelete(null));
                  dispatch(clearSelectedProducts());
                }}
                className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition duration-150"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MerchantProduct;
