import { BACKEND_URL } from '../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../utils/config';
import React from 'react'
import axios from 'axios'
import {
  Grid,
  Image,
  Icon,
  Container,
  Button,
  Header,
  Segment,
  Popup,
  Dimmer,
  Menu,
  Label
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import LoadingPhone from '../RedirectPages/LoadingPhone'
import ToPrevious from '../RedirectPages/ToPrevious'
// import HandleBuyCartDelete from '../cartFunction/HandleBuyCartDelete'

class CartPagePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: '',
      productsRental: '',
      buyProducts: [],
      rentProducts: [],
      buyEmpty: true,
      login: false,
      quantity: 0,
      addCartSuccess: true,
      colors: '',
      buyCartInfo: '',
      rentCartInfo: '',
      productPrice: 0,
      accessoryPrice: 0,
      productRentPrice: 0,
      accessoryRentPrice: 0,
      activeItem: 'Buy',
      accessoryCartInfo: '',

      userInfo: [],
      userID: '',
      cart: [],
      membership: [],
      identifier: '',
      token: '',
      accessory: [],

      open: false,
      open1: false,

      bag: [],
      accessoryBag: [],

      loading: true,
      active: false
    }

    // this.handleSelectMore=this.handleSelectMore.bind(this)
    // this.handleSelectLess=this.handleSelectLess.bind(this)
    this.onHandleDelete = this.onHandleDelete.bind(this)
    this.onHandleRentDelete = this.onHandleRentDelete.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.toClothingPage = this.toClothingPage.bind(this)
    this.handleSelectRentLess = this.handleSelectRentLess.bind(this)
    this.handleSelectLess = this.handleSelectLess.bind(this)
    this.handleSelectRentMore = this.handleSelectRentMore.bind(this)
    this.handleSelectMore = this.handleSelectMore.bind(this)
    this.getAccessories = this.getAccessories.bind(this)
    this.close = this.close.bind(this)
    this.close1 = this.close1.bind(this)
    this.onHandleCheckOut = this.onHandleCheckOut.bind(this)
    this.getProductInAccessoryRentCart = this.getProductInAccessoryRentCart.bind(
      this
    )
    this.getProductInBuyCart = this.getProductInBuyCart.bind(this)
    this.getProductInRentCart = this.getProductInRentCart.bind(this)
  }

  componentDidMount () {
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    const log = localStorage.getItem('login')
    const self = this

    const loading = self.state.loading

    const ifRent = this.props.location.search.substring(6)

    if (ifRent === 'true') {
      self.setState({
        activeItem: 'Rent'
      })
    }

    window.setTimeout(() => {
      if (loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 2000)

    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })

      axios(`${BACKEND_URL}/users/me`, {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          var userInfo = res.data
          if (userInfo.cart === null) {
            userInfo.cart = []
          }
          if (userInfo.accessoryCart === null) {
            userInfo.accessoryCart = []
          }
          if (userInfo.bag === null) {
            userInfo.bag = []
          }
          if (userInfo.accessoryBag === null) {
            userInfo.accessoryBag = []
          }

          self.setState({
            userInfo: userInfo,
            userID: userInfo.id,
            cart: userInfo.cart,
            accessory: userInfo.accessoryCart,
            bag: userInfo.bag,
            accessoryBag: userInfo.accessoryBag,
            membership: userInfo.membership
          })

          self.getProductInBuyCart()
          self.getAccessories()

          self.getProductInRentCart()
          self.getProductInAccessoryRentCart()
        } else {
          self.setState({
            loading: false,
            addCartSuccess: false
          })
        }
      })
    }

    self.setState({
      identifier: identifier,
      token: token
    })
  }

  getProductInBuyCart () {
    const self = this
    var price = 0
    // const cartIt=localStorage.getItem('buyItem')

    // const parsedCart = JSON.parse(cartIt)

    // if(cartIt===[]||cartIt===null){
    //     self.setState({buyEmpty:true})
    // }else{self.setState({buyEmpty:false})
    const userID = self.state.userID
    const token = self.state.token

    const cartItems = self.state.cart

    self.setState({ buyProducts: [] })
    if (cartItems.length === 0) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)

        self.setState({
          loading: false
        })
      })
    } else if (cartItems.length === undefined) {
      alert('something went wrong :(')
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          alert('your cart has been cleared sorry')
        } else {
          alert('something went wrong again')
        }
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        if (cart.type !== 'sale') {
          axios
            .get(`${BACKEND_URL}/products?id=` + cart.id)
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice
                  // console.log(price)
                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    productPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    productPrice: price
                  })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }

              self.setState({
                loading: false
              })
            })
        } else if (cart.type === 'sale') {
          axios
            .get(
              `${BACKEND_URL}/second-hand-products?id=` +
                cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.type = 'sale'

                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    productPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.type = 'sale'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    productPrice: price
                  })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }
              self.setState({
                loading: false
              })
            })
        }
      })
    }
  }

  getAccessories () {
    const self = this
    const userID = self.state.userID
    const token = self.state.token
    var price = 0

    const cartItems = self.state.accessory

    self.setState({ buyProducts: [] })

    if (cartItems.length === 0) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          accessoryCart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)

        self.setState({
          loading: false
        })
      })
    } else if (cartItems.length === undefined) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          accessoryCart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)

        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        if (cart.type !== 'sale') {
          axios
            .get(
              `${BACKEND_URL}/accessaries?id=` + cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.is = 'Accessaries'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    accessoryPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.is = 'Accessaries'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    accessoryPrice: price
                  })
                }
              } else {
                self.props.history.push('/loginfirst')
              }
              self.setState({
                loading: false
              })
            })
        } else if (cart.type === 'sale') {
          axios
            .get(
              `${BACKEND_URL}/second-hand-products?id=` +
                cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.is = 'Accessaries'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    accessoryPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.is = 'Accessaries'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    accessoryPrice: price
                  })
                }
              } else {
                self.props.history.push('/loginfirst')
              }
              self.setState({
                loading: false
              })
            })
        }
      })
    }
  }

  getProductInRentCart () {
    const self = this
    var price = 0
    const userID = self.state.userID
    const token = self.state.token

    const cartItems = self.state.bag

    self.setState({
      rentProducts: []
    })

    if (cartItems === null || cartItems.length === 0) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        self.setState({
          loading: false
        })
        if (res.status === 200) {
          console.log(res.status)
        } else {
          self.setState({
            loading: false
          })
        }
      })
    } else if (cartItems.length === undefined) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        axios
          .get(`${BACKEND_URL}/products?id=` + cart.id)
          .then(function (res) {
            const productSelected = res.data[0]
            self.setState({
              loading: false
            })
            if (self.state.rentProducts.length===0) {
              var product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ rentCartInfo: cart })
              // }})

              product.quantity = self.state.rentCartInfo.number
              product.colors[0] = self.state.rentCartInfo.colors
              product.sizes[0] = self.state.rentCartInfo.sizes
              product.endDate = self.state.rentCartInfo.endDate
              product.startDate = self.state.rentCartInfo.startDate
              product.rentTimeSelect = self.state.rentCartInfo.rentTimeSelect

              const itemPrice = product.price * product.quantity
              price = price + itemPrice

              const stringProduct = [product]
              self.setState({
                rentProducts: stringProduct,
                productRentPrice: price
              })
            } else if (self.state.rentProducts.length!== 0) {
              product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ rentCartInfo: cart })
              // }})
              product.quantity = self.state.rentCartInfo.number
              product.colors[0] = self.state.rentCartInfo.colors
              product.sizes[0] = self.state.rentCartInfo.sizes
              product.endDate = self.state.rentCartInfo.endDate
              product.startDate = self.state.rentCartInfo.startDate
              product.rentTimeSelect = self.state.rentCartInfo.rentTimeSelect
              const itemPrice = product.price * product.quantity
              price = price + itemPrice

              const stringProduct = product
              product = self.state.rentProducts

              product.push(stringProduct)
              self.setState({
                rentProducts: product,
                productRentPrice: price
              })
            }
          })
      })
    }
  }
  getProductInAccessoryRentCart () {
    const self = this
    const userID = self.state.userID
    const token = self.state.token
    var price = 0

    const accessoryItems = self.state.accessoryBag

    self.setState({
      rentProducts: []
    })

    if (
      accessoryItems === null ||
      accessoryItems.length === 0
    ) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          accessoryBag: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        self.setState({
          loading: false
        })
        if (res.status === 200) {
          console.log(res.status)
        } else {
          self.setState({
            loading: false
          })
        }
      })
    }
    if (accessoryItems.length === undefined) {
      alert('something went wrong :(')
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          accessoryBag: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        self.setState({
          loading: false,
          addCartSuccess: false
        })
        if (res.status === 200) {
          alert('your bag has been cleared sorry')
        } else {
          alert('something went wrong again')
          localStorage.setItem('login', 'false')
        }
      })
    } else {
      accessoryItems.forEach(function (accessory) {
        axios
          .get(
            `${BACKEND_URL}/accessaries?id=` +
              accessory.id
          )
          .then(function (res) {
            console.log(res)
            self.setState({
              loading: false
            })
            const productSelected = res.data[0]

            if (self.state.rentProducts.length === 0) {
              var product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ accessoryCartInfo: accessory })
              // }})

              product.quantity = self.state.accessoryCartInfo.number
              product.colors[0] = self.state.accessoryCartInfo.colors
              product.sizes[0] = self.state.accessoryCartInfo.sizes
              product.endDate = self.state.rentCartInfo.endDate
              product.startDate = self.state.rentCartInfo.startDate
              product.rentTimeSelect = self.state.rentCartInfo.rentTimeSelect
              product.is = 'Accessaries'
              const itemPrice = product.price * product.quantity
              price = price + itemPrice

              const stringProduct = [product]
              self.setState({
                rentProducts: stringProduct,
                productRentPrice: price
              })
            } else if (self.state.rentProducts.length!== 0) {
              product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ accessoryCartInfo: accessory })

              // }})
              product.quantity = self.state.accessoryCartInfo.number
              product.colors[0] = self.state.accessoryCartInfo.colors
              product.sizes[0] = self.state.accessoryCartInfo.sizes
              product.endDate = self.state.rentCartInfo.endDate
              product.startDate = self.state.rentCartInfo.startDate
              product.rentTimeSelect = self.state.rentCartInfo.rentTimeSelect
              const itemPrice = product.price * product.quantity
              product.is = 'Accessaries'
              price = price + itemPrice

              const stringProduct = product
              product = self.state.rentProducts

              product.push(stringProduct)
              self.setState({
                rentProducts: product,
                productRentPrice: price
              })
            }
          })
      })
    }
  }

  handleSelectLess (event, data) {
    const self = this
    const userID = self.state.userID
    const token = self.state.token

    const thisId = data.id
    event.preventDefault()
    const type = data.type
    const category = data.category

    const cartItems = self.state.cart.filter(function (item) {
      return item.type !== 'sale'
    })
    const saleCartItem = self.state.cart.filter(function (item) {
      return item.type === 'sale'
    })
    const accessoryCartItem = self.state.accessory
    const newCart = []
    const newAccessoryCart = []
    var cartNum = -1
    self.setState({
      loading: true
    })

    if (type === 'sale') {
      saleCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
        if (cartNum <= 1) {
          self.setState({
            open: [data.id, type, category],
            loading: false
          })
        } else {
          if (cartItem.id !== thisId) {
            newCart.push(cartItem)
          }

          if (cartItem.id === thisId) {
            cartItem.number = cartItem.number - 1
            newCart.push(cartItem)
          }

          cartItems.forEach(function (cartItem) {
            newCart.push(cartItem)
          })
          accessoryCartItem.forEach(function (cartItem) {
            newAccessoryCart.push(cartItem)
          })

          axios(`${BACKEND_URL}/users/` + userID, {
            method: 'put',
            data: {
              cart: newCart,
              accessoryCart: newAccessoryCart
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                accessory: newAccessoryCart,
                cart: newCart,
                open: false,
                open1: false
              })
              self.getProductInBuyCart()
              self.getAccessories()
              //   alert('add successfully, now you can check in your cart :)')
            } else {
              self.setState({
                loading: false,
                addCartSuccess: false
              })
            }
          })
        }
      })
    }

    if (type !== 'sale' && category !== 'Accessaries') {
      cartItems.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
        if (cartNum <= 1) {
          self.setState({
            open: [data.id, type, category],
            loading: false
          })
        } else {
          if (cartItem.id !== thisId) {
            newCart.push(cartItem)
          }

          if (cartItem.id === thisId) {
            cartItem.number = cartItem.number - 1
            newCart.push(cartItem)
          }
          saleCartItem.forEach(function (cartItem) {
            newCart.push(cartItem)
          })
          accessoryCartItem.forEach(function (cartItem) {
            newAccessoryCart.push(cartItem)
          })
          axios(`${BACKEND_URL}/users/` + userID, {
            method: 'put',
            data: {
              cart: newCart,
              accessoryCart: newAccessoryCart
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                accessory: newAccessoryCart,
                cart: newCart,
                open: false,
                open1: false
              })
              self.getProductInBuyCart()
              self.getAccessories()
              //   alert('add successfully, now you can check in your cart :)')
            } else {
              self.setState({
                addCartSuccess: false
              })
            }
          })
        }
      })
    }

    if (category === 'Accessaries' && type === undefined) {
      accessoryCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
        if (cartNum <= 1) {
          self.setState({
            open: [data.id, type, category],
            loading: false
          })
        } else {
          if (cartItem.id !== thisId) {
            newAccessoryCart.push(cartItem)
          }

          if (cartItem.id === thisId) {
            cartItem.number = cartItem.number - 1
            newAccessoryCart.push(cartItem)
          }
          cartItems.forEach(function (cartItem) {
            newCart.push(cartItem)
          })
          saleCartItem.forEach(function (cartItem) {
            newCart.push(cartItem)
          })

          axios(`${BACKEND_URL}/users/` + userID, {
            method: 'put',
            data: {
              cart: newCart,
              accessoryCart: newAccessoryCart
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                cart: newCart,
                accessory: newAccessoryCart,
                open: false,
                open1: false
              })
              self.getAccessories()
              self.getProductInBuyCart()

              //   alert('add successfully, now you can check in your cart :)')
            } else {
              self.setState({
                addCartSuccess: false
              })
            }
          })
        }
      })
    }
  }

  handleSelectRentLess (event, data) {
    const self = this
    const userID = self.state.userID
    const token = self.state.token

    const thisId = data.id
    event.preventDefault()
    const type = data.type
    const category = data.category

    const cartItems = self.state.bag.filter(function (item) {
      return item.type !== 'sale'
    })
    const saleCartItem = self.state.bag.filter(function (item) {
      return item.type === 'sale'
    })
    const accessoryCartItem = self.state.accessoryBag
    const newCart = []
    const newAccessoryCart = []
    var cartNum = -1
    self.setState({
      loading: true
    })

    if (type === 'sale') {
      saleCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
        if (cartNum <= 1) {
          self.setState({
            open: [data.id, type, category],
            loading: false
          })
        } else {
          if (cartItem.id !== thisId) {
            newCart.push(cartItem)
          }

          if (cartItem.id === thisId) {
            cartItem.number = cartItem.number - 1
            newCart.push(cartItem)
          }

          cartItems.forEach(function (cartItem) {
            newCart.push(cartItem)
          })
          accessoryCartItem.forEach(function (cartItem) {
            newAccessoryCart.push(cartItem)
          })

          axios(`${BACKEND_URL}/users/` + userID, {
            method: 'put',
            data: {
              bag: newCart,
              accessoryBag: newAccessoryCart
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                addBagSuccess: true,
                accessoryBag: newAccessoryCart,
                bag: newCart,
                open: false,
                open1: false
              })
              self.getProductInRentCart()
              self.getProductInAccessoryRentCart()
              //   alert('add successfully, now you can check in your cart :)')
            } else {
              self.setState({
                loading: false,
                addCartSuccess: false
              })
            }
          })
        }
      })
    }

    if (type !== 'sale' && category !== 'Accessaries') {
      cartItems.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
        if (cartNum <= 1) {
          self.setState({
            open: [data.id, type, category],
            loading: false
          })
        } else {
          if (cartItem.id !== thisId) {
            newCart.push(cartItem)
          }

          if (cartItem.id === thisId) {
            cartItem.number = cartItem.number - 1
            newCart.push(cartItem)
          }
          saleCartItem.forEach(function (cartItem) {
            newCart.push(cartItem)
          })
          accessoryCartItem.forEach(function (cartItem) {
            newAccessoryCart.push(cartItem)
          })
          axios(`${BACKEND_URL}/users/` + userID, {
            method: 'put',
            data: {
              bag: newCart,
              accessoryBag: newAccessoryCart
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                addBagSuccess: true,
                accessoryBag: newAccessoryCart,
                bag: newCart,
                open: false,
                open1: false
              })
              self.getProductInRentCart()
              self.getProductInAccessoryRentCart()
              //   alert('add successfully, now you can check in your cart :)')
            } else {
              self.setState({
                loading: false,
                addCartSuccess: 'Something Went Wrong'
              })
            }
          })
        }
      })
    }

    if (category === 'Accessaries' && type === undefined) {
      accessoryCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
        if (cartNum <= 1) {
          self.setState({
            open: [data.id, type, category],
            loading: false
          })
        } else {
          if (cartItem.id !== thisId) {
            newAccessoryCart.push(cartItem)
          }

          if (cartItem.id === thisId) {
            cartItem.number = cartItem.number - 1
            newAccessoryCart.push(cartItem)
          }
          cartItems.forEach(function (cartItem) {
            newCart.push(cartItem)
          })
          saleCartItem.forEach(function (cartItem) {
            newCart.push(cartItem)
          })

          axios(`${BACKEND_URL}/users/` + userID, {
            method: 'put',
            data: {
              bag: newCart,
              accessoryBag: newAccessoryCart
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                addBagSuccess: true,
                bag: newCart,
                accessoryBag: newAccessoryCart,
                open: false,
                open1: false
              })
              self.getProductInAccessoryRentCart()
              self.getProductInRentCart()

              //   alert('add successfully, now you can check in your cart :)')
            } else {
              self.setState({
                loading: false,
                addCartSuccess: 'Something Went Wrong'
              })
            }
          })
        }
      })
    }
  }

  handleSelectMore (event, data) {
    const self = this

    const userID = self.state.userID
    const token = self.state.token

    const thisId = data.id
    event.preventDefault()
    const type = data.type
    const category = data.category

    const cartItems = self.state.cart.filter(function (item) {
      return item.type !== 'sale'
    })
    const saleCartItem = self.state.cart.filter(function (item) {
      return item.type === 'sale'
    })
    const accessoryCartItem = self.state.accessory
    const newCart = []
    const newAccessoryCart = []
    self.setState({
      loading: true
    })

    var stock = -1
    var cartNum = -1

    if (type === 'sale') {
      saleCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
      })
      axios
        .get(
          `${BACKEND_URL}/second-hand-products?id=` +
            thisId
        )
        .then(function (res) {
          if (res.status === 200) {
            const productSelected = res.data[0]
            stock = productSelected.stock
            console.log(stock)

            if (cartNum >= stock) {
              self.setState({
                open1: [data.id, type, category],
                loading: false
              })
            } else {
              saleCartItem.forEach(function (cartItem) {
                if (cartItem.id !== thisId) {
                  newCart.push(cartItem)
                }

                if (cartItem.id === thisId) {
                  cartItem.number = cartItem.number + 1
                  newCart.push(cartItem)
                }
              })
              cartItems.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              accessoryCartItem.forEach(function (cartItem) {
                newAccessoryCart.push(cartItem)
              })

              axios(`${BACKEND_URL}/users/` + userID, {
                method: 'put',
                data: {
                  cart: newCart,
                  accessoryCart: newAccessoryCart
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    accessory: newAccessoryCart,
                    cart: newCart,
                    open: false,
                    open1: false
                  })
                  self.getProductInBuyCart()
                  self.getAccessories()
                  //   alert('add successfully, now you can check in your cart :)')
                } else {
                  self.setState({
                    loading: false,
                    addCartSuccess: 'Something Went Wrong'
                  })
                }
              })
            }
          } else {
            self.setState({
              loading: false,
              addCartSuccess: 'Something Went Wrong'
            })
          }
        })
    }

    if (type !== 'sale' && category !== 'Accessaries') {
      cartItems.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
      })

      axios
        .get(`${BACKEND_URL}/products?id=` + thisId)
        .then(function (res) {
          if (res.status === 200) {
            const productSelected = res.data[0]
            stock = productSelected.stock
            console.log(stock)

            if (cartNum >= stock) {
              self.setState({
                open1: [data.id, type, category],
                loading: false
              })
            } else {
              cartItems.forEach(function (cartItem) {
                if (cartItem.id !== thisId) {
                  newCart.push(cartItem)
                }

                if (cartItem.id === thisId) {
                  cartItem.number = cartItem.number + 1
                  newCart.push(cartItem)
                }
              })
              saleCartItem.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              accessoryCartItem.forEach(function (cartItem) {
                newAccessoryCart.push(cartItem)
              })
              axios(`${BACKEND_URL}/users/` + userID, {
                method: 'put',
                data: {
                  cart: newCart,
                  accessoryCart: newAccessoryCart
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    accessory: newAccessoryCart,
                    cart: newCart,
                    open: false,
                    open1: false
                  })
                  self.getProductInBuyCart()
                  self.getAccessories()
                  //   alert('add successfully, now you can check in your cart :)')
                } else {
                  self.setState({
                    addCartSuccess: false
                  })
                }
              })
            }
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
    }

    if (category === 'Accessaries' && type === undefined) {
      accessoryCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
      })
      axios
        .get(`${BACKEND_URL}/accessaries?id=` + thisId)
        .then(function (res) {
          if (res.status === 200) {
            const productSelected = res.data[0]
            stock = productSelected.stock
            console.log(stock)

            if (cartNum >= stock) {
              self.setState({
                open1: [data.id, type, category],
                loading: false
              })
            } else {
              accessoryCartItem.forEach(function (cartItem) {
                if (cartItem.id !== thisId) {
                  newAccessoryCart.push(cartItem)
                }

                if (cartItem.id === thisId) {
                  cartItem.number = cartItem.number + 1
                  newAccessoryCart.push(cartItem)
                }
              })
              cartItems.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              saleCartItem.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              axios(`${BACKEND_URL}/users/` + userID, {
                method: 'put',
                data: {
                  cart: newCart,
                  accessoryCart: newAccessoryCart
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    accessory: newAccessoryCart,
                    cart: newCart,
                    open: false,
                    open1: false
                  })
                  self.getAccessories()
                  self.getProductInBuyCart()
                  //   alert('add successfully, now you can check in your cart :)')
                } else {
                  self.setState({
                    addCartSuccess: false
                  })
                }
              })
            }
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
    }
  }

  handleSelectRentMore (event, data) {
    const self = this

    const userID = self.state.userID
    const token = self.state.token
    self.setState({
      loading: true
    })

    const thisId = data.id
    event.preventDefault()
    const type = data.type
    const category = data.category

    const cartItems = self.state.bag.filter(function (item) {
      return item.type !== 'sale'
    })
    const saleCartItem = self.state.bag.filter(function (item) {
      return item.type === 'sale'
    })
    const accessoryCartItem = self.state.accessoryBag
    console.log(accessoryCartItem)
    const newCart = []
    const newAccessoryCart = []

    var stock = -1
    var cartNum = -1

    if (type === 'sale') {
      saleCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
      })
      axios
        .get(
          `${BACKEND_URL}/second-hand-products?id=` +
            thisId
        )
        .then(function (res) {
          if (res.status === 200) {
            const productSelected = res.data[0]
            stock = productSelected.stock
            console.log(stock)

            if (cartNum >= stock) {
              self.setState({
                open1: [data.id, type, category],
                loading: false
              })
            } else {
              saleCartItem.forEach(function (cartItem) {
                if (cartItem.id !== thisId) {
                  newCart.push(cartItem)
                }

                if (cartItem.id === thisId) {
                  cartItem.number = cartItem.number + 1
                  newCart.push(cartItem)
                }
              })
              cartItems.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              accessoryCartItem.forEach(function (cartItem) {
                newAccessoryCart.push(cartItem)
              })

              axios(`${BACKEND_URL}/users/` + userID, {
                method: 'put',
                data: {
                  bag: newCart,
                  accessoryBag: newAccessoryCart
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    addBagSuccess: true,
                    accessoryBag: newAccessoryCart,
                    Bag: newCart,
                    open: false,
                    open1: false
                  })
                  self.getProductInRentCart()
                  self.getProductInAccessoryRentCart()
                  //   alert('add successfully, now you can check in your cart :)')
                } else {
                  self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
                }
              })
            }
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
    }

    if (type !== 'sale' && category !== 'Accessaries') {
      cartItems.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
      })

      axios
        .get(`${BACKEND_URL}/products?id=` + thisId)
        .then(function (res) {
          if (res.status === 200) {
            const productSelected = res.data[0]
            stock = productSelected.stock
            console.log(stock)

            if (cartNum >= stock) {
              self.setState({
                open1: [data.id, type, category],
                loading: false
              })
            } else {
              cartItems.forEach(function (cartItem) {
                if (cartItem.id !== thisId) {
                  newCart.push(cartItem)
                }

                if (cartItem.id === thisId) {
                  cartItem.number = cartItem.number + 1
                  newCart.push(cartItem)
                }
              })
              saleCartItem.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              accessoryCartItem.forEach(function (cartItem) {
                newAccessoryCart.push(cartItem)
              })
              axios(`${BACKEND_URL}/users/` + userID, {
                method: 'put',
                data: {
                  bag: newCart,
                  accessoryBag: newAccessoryCart
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    addBagSuccess: true,
                    accessoryBag: newAccessoryCart,
                    bag: newCart,
                    open: false,
                    open1: false
                  })
                  self.getProductInRentCart()
                  self.getProductInAccessoryRentCart()
                  //   alert('add successfully, now you can check in your cart :)')
                } else {
                  self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
                }
              })
            }
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
    }

    if (category === 'Accessaries' && type === undefined) {
      accessoryCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
      })
      axios
        .get(`${BACKEND_URL}/accessaries?id=` + thisId)
        .then(function (res) {
          if (res.status === 200) {
            const productSelected = res.data[0]
            stock = productSelected.stock
            console.log(stock)

            if (cartNum >= stock) {
              self.setState({
                open1: [data.id, type, category],
                loading: false
              })
            } else {
              accessoryCartItem.forEach(function (cartItem) {
                if (cartItem.id !== thisId) {
                  newAccessoryCart.push(cartItem)
                }

                if (cartItem.id === thisId) {
                  cartItem.number = cartItem.number + 1
                  newAccessoryCart.push(cartItem)
                }
              })
              cartItems.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              saleCartItem.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              axios(`${BACKEND_URL}/users/` + userID, {
                method: 'put',
                data: {
                  bag: newCart,
                  accessoryBag: newAccessoryCart
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    addBagSuccess: true,
                    accessoryBag: newAccessoryCart,
                    bag: newCart,
                    open: false,
                    open1: false
                  })
                  self.getProductInAccessoryRentCart()
                  self.getProductInRentCart()
                  //   alert('add successfully, now you can check in your cart :)')
                } else {
                  self.setState({
                    loading: false,
                    addCartSuccess: false
                  })
                }
              })
            }
          } else {
            self.setState({
              loading: false,
              addCartSuccess: false
            })
          }
        })
    }
  }

  onHandleDelete (event, data) {
    const self = this

    const thisId = data.id
    const type = data.type
    const category = data.category

    self.setState({ loading: true })

    event.preventDefault()
    // var thisId=event.target.id
    // thisId=JSON.parse(thisId)
    const cartItems = self.state.cart.filter(function (item) {
      return item.type !== 'sale'
    })
    const saleCartItem = self.state.cart.filter(function (item) {
      return item.type === 'sale'
    })
    const accessoryCartItem = self.state.accessory

    const newCart = []
    const newAccessoryCart = []

    if (type === 'sale') {
      saleCartItem.forEach(function (cartItem) {
        if (cartItem.id !== thisId) {
          newCart.push(cartItem)
        }
      })
      cartItems.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
      accessoryCartItem.forEach(function (cartItem) {
        newAccessoryCart.push(cartItem)
      })
    }

    if (type !== 'sale' && category !== 'Accessaries') {
      cartItems.forEach(function (cartItem) {
        if (cartItem.id !== thisId) {
          newCart.push(cartItem)
        }
      })
      saleCartItem.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
      accessoryCartItem.forEach(function (cartItem) {
        newAccessoryCart.push(cartItem)
      })
    }

    if (category === 'Accessaries' && type === undefined) {
      accessoryCartItem.forEach(function (cartItem) {
        if (cartItem.id !== thisId) {
          newAccessoryCart.push(cartItem)
        }
      })
      cartItems.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
      saleCartItem.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
    }

    const userID = self.state.userID
    const token = self.state.token

    if (
      newCart.length === 0 && newAccessoryCart.length === 0
    ) {
      self.setState({
        loading: false
      })
    }

    axios(`${BACKEND_URL}/users/` + userID, {
      method: 'put',
      data: {
        cart: newCart,
        accessoryCart: newAccessoryCart
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      console.log(res)
      if (res.status === 200) {
        self.setState({
          cart: newCart,
          accessory: newAccessoryCart,
          open: false,
          open1: false
          // loading: false
        })

        if (
          newCart.length === 0 && newAccessoryCart.length === 0
        ) {
          self.setState({
            loading: false
          })
        }
        self.getProductInBuyCart()
        self.getAccessories()
        //   alert('add successfully, now you can check in your cart :)')
      } else {
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      }
    })
  }

  onHandleRentDelete (event, data) {
    const self = this
    self.setState({
      loading: true
    })
    const thisId = data.id
    const type = data.type
    const category = data.category
    console.log(category, type)
    event.preventDefault()
    const cartItems = self.state.bag.filter(function (item) {
      return item.type !== 'sale'
    })
    const saleCartItem = self.state.bag.filter(function (item) {
      return item.type === 'sale'
    })
    const accessoryCartItem = self.state.accessoryBag

    const newCart = []
    const newAccessoryCart = []

    console.log(newCart, newAccessoryCart)

    if (type === 'sale') {
      saleCartItem.forEach(function (cartItem) {
        if (cartItem.id !== thisId) {
          newCart.push(cartItem)
        }
      })
      cartItems.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
      accessoryCartItem.forEach(function (cartItem) {
        newAccessoryCart.push(cartItem)
      })
    }

    if (type !== 'sale' && category !== 'Accessaries') {
      cartItems.forEach(function (cartItem) {
        if (cartItem.id !== thisId) {
          newCart.push(cartItem)
        }
      })
      saleCartItem.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
      accessoryCartItem.forEach(function (cartItem) {
        newAccessoryCart.push(cartItem)
      })
    }

    if (category === 'Accessaries' && type === undefined) {
      accessoryCartItem.forEach(function (cartItem) {
        if (cartItem.id !== thisId) {
          newAccessoryCart.push(cartItem)
        }
      })
      cartItems.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
      saleCartItem.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
    }

    const userID = self.state.userID
    const token = self.state.token

    if (
      newCart.length === 0 && newAccessoryCart.length === 0
    ) {
      self.setState({
        loading: false
      })
    }
    axios(`${BACKEND_URL}/users/` + userID, {
      method: 'put',
      data: {
        bag: newCart,
        accessoryBag: newAccessoryCart
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      console.log(res)
      if (res.status === 200) {
        self.setState({
          addBagSuccess: true,
          bag: newCart,
          accessoryBag: newAccessoryCart,
          open: false,
          open1: false
        })

        if (
          newCart.length === 0 && newAccessoryCart.length === 0
        ) {
          self.setState({
            loading: false
          })
        }
        self.getProductInRentCart()
        self.getProductInAccessoryRentCart()
        //   alert('add successfully, now you can check in your cart :)')
      } else {
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      }
    })
  }

  toClothingPage () {
    const self = this
    self.props.history.push('/clothing')
  }

  close () {
    const self = this
    self.setState({
      open: false
    })
  }

  close1 () {
    const self = this
    self.setState({
      open1: false
    })
  }

  onHandleCheckOut () {
    const self = this
    const cart = this.state.cart
    const accessories = this.state.accessory
    const price = this.state.accessoryPrice + this.state.productPrice
    const identifier = localStorage.getItem('identifier')

    var theCart = cart.forEach(function (each) {
      if (each.type !== 'sale') {
        return each.id
      }
    })

    var theSale = cart.forEach(function (each) {
      if (each.type === 'sale') {
        return each.id
      }
    })

    var theAccessories = accessories.map(function (each) {
      return each.id
    })

    const order = {
      data: {
        clothing: cart,
        accessory: accessories,
        rentOrBuy: 'buy',
        price: price,
        identifier: identifier
      }
    }
    var merchant = []
    cart.forEach(function (i) {
      if (i.merchant !== null && !merchant.includes(i.merchant)) {
        merchant.push(i.merchant)
      }
    })
    accessories.forEach(function (i) {
      if (i.merchant !== null && !merchant.includes(i.merchant)) {
        merchant.push(i.merchant)
      }
    })
    console.log(merchant, cart, accessories)

    const token = localStorage.getItem('token')
    const userID = this.state.userID

    axios(`${BACKEND_URL}/users/` + userID, {
      method: 'put',
      data: {
        cart: [],
        accessoryCart: []
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        if (res.status === 200) {
          axios(`${BACKEND_URL}/orders`, {
            method: 'post',
            data: {
              orders: order,
              users_permissions_user: userID,
              products: theCart,
              accessories: theAccessories,
              second_hand_products: theSale,
              merchants: merchant
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            if (res.status === 200) {
              localStorage.setItem('orderId', res.data.id)
            }
            self.props.history.push('/checkout?orderid=' + res.data.id)
          })
        } else {
          alert('something went wrong' + res)
        }
      })
      .catch(function (e) {
        alert('something went wrong' + e)
      })
  }

  handleItemClick (event, data) {
    const self = this
    console.log(event, data)
    const name = data.name

    if (name === 'Buy') {
      self.props.history.push('/cart')
      this.setState({ activeItem: name })
    } else {
      self.props.history.push('/cart?rent=true')
      this.setState({ activeItem: name })
    }
  }

  render () {
    const s = this.state

    const self = this
    const login = s.login
    var fee = 0
    var products = s.buyProducts
    var rentProducts = s.rentProducts

    const style = {
      borderRadius: 12,
      opacity: 0.9,
      padding: '1.5em',
      color: 'orange'
    }
    console.log(products)

    const open = s.open
    const open1 = s.open1
    var loading = s.loading

    return (
      <div>
        {/* <Nav /> */}

        {loading === true && <LoadingPhone />}

        {loading === false && login === true && products.length > 0 && (
          <Grid stackable style={{ paddingTop: 10 }}>
            <Grid.Row style={{ marginBottom: 20 }}>
              <ToPrevious color='grey' />
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Container>
                  <br />

                  <Grid
                    divided='vertically'
                    style={{ paddingBottom: 0, marginBottom: 0 }}
                  >
                    <Grid.Row
                      columns={2}
                      style={{ paddingTop: 0, marginTop: 0 }}
                    >
                      <Grid.Column width={16}>
                        <Header as='h3'>
                          <Icon name='shopping cart' />

                          <Header.Content>Shopping Cart</Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <Menu attached='top' tabular>
                          <Menu.Item
                            name='Buy'
                            active={s.activeItem === 'Buy'}
                            onClick={this.handleItemClick}
                          >
                            Buy
                            <Label color='teal' basic>
                              {products.length}
                            </Label>
                          </Menu.Item>

                          <Menu.Item
                            name='Rent'
                            active={s.activeItem === 'Rent'}
                            onClick={this.handleItemClick}
                          >
                            Rent
                            <Label color='teal' basic>
                              {rentProducts.length}
                            </Label>
                          </Menu.Item>
                        </Menu>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Segment attached='bottom'>
                    <Grid
                      divided='vertically'
                      style={{ paddingTop: 0, marginTop: 0 }}
                    >
                      <Grid.Row
                        columns={4}
                        divided
                        style={{ paddingTop: 0, marginTop: 0 }}
                      >
                        {s.activeItem === 'Buy' && (
                          <>
                            <Grid.Column width={9}>PRODUCT</Grid.Column>
                            <Grid.Column width={7}>PRICE/ QTY</Grid.Column>
                          </>
                        )}

                        {s.activeItem === 'Rent' && (
                          <>
                            <Grid.Column width={9}>PRODUCT</Grid.Column>
                            <Grid.Column width={7}>PRICE/ DATE</Grid.Column>
                          </>
                        )}
                      </Grid.Row>

                      {s.activeItem === 'Buy' &&
                        products.length > 0 &&
                        products.map(function (product) {
                          return (
                            <Grid.Row columns={3}>
                              <Dimmer active={product.stock === '0'} inverted>
                                <Icon
                                  id={product.id}
                                  size='large'
                                  type={product.type}
                                  category={product.categories[0].name}
                                  onClick={self.onHandleDelete}
                                  circular
                                  color='grey'
                                  name='close'
                                />
                              </Dimmer>

                              <Grid.Column width={3}>
                                {product.stock !== '0' && (
                                  <Icon
                                    id={product.id}
                                    type={product.type}
                                    category={product.categories[0].name}
                                    onClick={self.onHandleDelete}
                                    circular
                                    color='orange'
                                    name='close'
                                  />
                                )}
                              </Grid.Column>

                              <Grid.Column width={7}>
                                <Grid>
                                  <Grid.Row>
                                    <Image
                                      size='medium'
                                      src={product.picture[0].url}
                                    />
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>

                              <Grid.Column width={5}>
                                <Grid>
                                  <Grid.Row>
                                    <Popup
                                      trigger={
                                        <Icon
                                          id={product.id}
                                          type={product.type}
                                          category={product.categories[0].name}
                                          onClick={self.handleSelectLess}
                                          name='minus'
                                          color='orange'
                                        />
                                      }
                                      content={
                                        <Grid centered divided>
                                          <Grid.Column textAlign='center'>
                                            <p>Do you want to delete it?</p>
                                            <Button
                                              id={product.id}
                                              color='teal'
                                              type={product.type}
                                              category={
                                                product.categories[0].name
                                              }
                                              onClick={self.onHandleDelete}
                                            >
                                              Yes
                                            </Button>
                                            <Button
                                              id={product.id}
                                              color='teal'
                                              basic
                                              onClick={self.close}
                                            >
                                              No
                                            </Button>
                                          </Grid.Column>
                                        </Grid>
                                      }
                                      style={style}
                                      position='top center'
                                      open={
                                        product.id === open[0] &&
                                        product.type === open[1] &&
                                        product.categories[0].name === open[2]
                                      }
                                    />
                                    {product.stock === '0' && (
                                      <Grid.Column width={1}>0</Grid.Column>
                                    )}
                                    {product.stock !== '0' && (
                                      <Grid.Column width={1}>
                                        {product.quantity}
                                      </Grid.Column>
                                    )}
                                    <Grid.Column width={2}>
                                      <Popup
                                        trigger={
                                          <Icon
                                            id={product.id}
                                            type={product.type}
                                            category={
                                              product.categories[0].name
                                            }
                                            onClick={self.handleSelectMore}
                                            name='plus'
                                            color='orange'
                                          />
                                        }
                                        content={
                                          <Grid centered divided>
                                            <Grid.Column textAlign='center'>
                                              <p>
                                                Sorry, not enough in stock, you
                                                can't keep adding
                                              </p>
                                              <Button
                                                id={product.id}
                                                color='teal'
                                                onClick={self.close1}
                                              >
                                                OK
                                              </Button>
                                            </Grid.Column>
                                          </Grid>
                                        }
                                        style={style}
                                        position='top center'
                                        open={
                                          product.id === open1[0] &&
                                          product.type === open1[1] &&
                                          product.categories[0].name ===
                                            open1[2]
                                        }
                                      />
                                    </Grid.Column>
                                    <br />
                                    color: {product.colors}
                                    <br />
                                    size: {product.sizes}
                                  </Grid.Row>

                                  <Grid.Row>{'$ ' + product.price}</Grid.Row>
                                </Grid>
                              </Grid.Column>
                            </Grid.Row>
                          )
                        })}

                      {s.activeItem === 'Buy' &&
                        products.length > 0 &&
                        products.forEach(function (product) {
                          fee = fee + product.price * product.quantity
                          localStorage.setItem('fee', fee)
                        })}

                      {s.activeItem === 'Rent' &&
                        rentProducts.length > 0 &&
                        rentProducts.map(function (product) {
                          console.log(product)
                          return (
                            <Grid.Row columns={3}>
                              <Dimmer active={product.stock === '0'} inverted>
                                <Icon
                                  id={product.id}
                                  size='large'
                                  type={product.type}
                                  category={product.categories[0].name}
                                  onClick={self.onHandleRentDelete}
                                  circular
                                  color='grey'
                                  name='close'
                                />
                              </Dimmer>

                              <Grid.Column width={3}>
                                {product.stock !== '0' && (
                                  <Icon
                                    id={product.id}
                                    type={product.type}
                                    category={product.is}
                                    onClick={self.onHandleRentDelete}
                                    circular
                                    color='orange'
                                    name='close'
                                  />
                                )}
                              </Grid.Column>
                              <Grid.Column width={6}>
                                <Grid>
                                  <Grid.Row>
                                    <Image
                                      size='small'
                                      src={product.picture[0].url}
                                    />
                                  </Grid.Row>
                                  <Grid.Row>
                                    <Popup
                                      trigger={
                                        <Icon
                                          id={product.id}
                                          type={product.type}
                                          category={product.is}
                                          onClick={self.handleSelectLess}
                                          name='minus'
                                          color='orange'
                                        />
                                      }
                                      content={
                                        <Grid centered divided>
                                          <Grid.Column textAlign='center'>
                                            <p>Do you want to delete it?</p>
                                            <Button
                                              id={product.id}
                                              color='teal'
                                              type={product.type}
                                              category={product.is}
                                              onClick={self.onHandleDelete}
                                            >
                                              Yes
                                            </Button>
                                            <Button
                                              id={product.id}
                                              color='teal'
                                              basic
                                              onClick={self.close}
                                            >
                                              No
                                            </Button>
                                          </Grid.Column>
                                        </Grid>
                                      }
                                      style={style}
                                      position='top center'
                                      open={
                                        product.id === open[0] &&
                                        product.type === open[1] &&
                                        product.is === open[2]
                                      }
                                    />
                                    {product.stock === '0' && (
                                      <Grid.Column width={1}>0</Grid.Column>
                                    )}
                                    {product.stock !== '0' && (
                                      <Grid.Column width={1}>
                                        {product.quantity}
                                      </Grid.Column>
                                    )}
                                    <Grid.Column width={2}>
                                      <Popup
                                        trigger={
                                          <Icon
                                            id={product.id}
                                            type={product.type}
                                            category={product.is}
                                            onClick={self.handleSelectMore}
                                            name='plus'
                                            color='orange'
                                          />
                                        }
                                        content={
                                          <Grid centered divided>
                                            <Grid.Column textAlign='center'>
                                              <p>
                                                Sorry, not enough in stock, you
                                                can't keep adding
                                              </p>
                                              <Button
                                                id={product.id}
                                                color='teal'
                                                onClick={self.close1}
                                              >
                                                OK
                                              </Button>
                                            </Grid.Column>
                                          </Grid>
                                        }
                                        style={style}
                                        position='top center'
                                        open={
                                          product.id === open1[0] &&
                                          product.type === open1[1] &&
                                          product.is === open1[2]
                                        }
                                      />
                                    </Grid.Column>
                                    <br />
                                    color: {product.colors}
                                    <br />
                                    size: {product.sizes}
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>

                              {/* <Grid.Column width={5}>
                                <Grid>
                                  <Grid.Row>{product.name}</Grid.Row>
                                  <Grid.Row>Color: {product.colors}</Grid.Row>
                                  <Grid.Row>Size: {product.sizes}</Grid.Row>
                                </Grid>
                              </Grid.Column>

                              <Grid.Column width={1}></Grid.Column> */}

                              <Grid.Column width={6}>
                                <Grid>
                                  <Grid.Row>
                                    {product.rentTimeSelect !== undefined &&
                                      'Rent Price: $ ' +
                                        product.rentTimeSelect.price}
                                    {product.rentTimeSelect === undefined &&
                                      'Error! Contact Seller'}
                                  </Grid.Row>
                                  <Grid.Row>
                                    {product.startDate.substring(5, 7) ===
                                      '01' && (
                                      <p textAlign='center'>
                                        {'Start Date  : January ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '02' && (
                                      <p textAlign='center'>
                                        {'Start Date  : February ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '03' && (
                                      <p textAlign='center'>
                                        {'Start Date  : March ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '04' && (
                                      <p textAlign='center'>
                                        {'Start Date  : April ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '05' && (
                                      <p textAlign='center'>
                                        {'Start Date  : May ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '06' && (
                                      <p textAlign='center'>
                                        {'Start Date  : June ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '07' && (
                                      <p textAlign='center'>
                                        {'Start Date  : July ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '08' && (
                                      <p textAlign='center'>
                                        {'Start Date  : August ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '09' && (
                                      <p
                                        color='orange'
                                        as='h6'
                                        textAlign='center'
                                      >
                                        {'Start Date  : September ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '10' && (
                                      <p textAlign='center'>
                                        {'Start Date  : October ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '11' && (
                                      <p textAlign='center'>
                                        {'Start Date  : November ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.startDate.substring(5, 7) ===
                                      '12' && (
                                      <p textAlign='center'>
                                        {'Start Date  : December ' +
                                          product.startDate.substring(8, 10) +
                                          ', ' +
                                          product.startDate.substring(0, 4)}
                                      </p>
                                    )}

                                    {/* Start Date: {product.startDate} */}
                                  </Grid.Row>
                                  <Grid.Row>
                                    {product.endDate.substring(5, 7) ===
                                      '01' && (
                                      <p textAlign='center'>
                                        {'End Date  : January ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '02' && (
                                      <p textAlign='center'>
                                        {'End Date  : February ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '03' && (
                                      <p textAlign='center'>
                                        {'End Date  : March ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '04' && (
                                      <p textAlign='center'>
                                        {'End Date  : April ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '05' && (
                                      <p textAlign='center'>
                                        {'End Date  : May ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '06' && (
                                      <p textAlign='center'>
                                        {'End Date  : June ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '07' && (
                                      <p textAlign='center'>
                                        {'End Date  : July ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '08' && (
                                      <p textAlign='center'>
                                        {'End Date  : August ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '09' && (
                                      <p
                                        color='orange'
                                        as='h6'
                                        textAlign='center'
                                      >
                                        {'End Date  : September ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '10' && (
                                      <p textAlign='center'>
                                        {'End Date  : October ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '11' && (
                                      <p textAlign='center'>
                                        {'End Date  : November ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                    {product.endDate.substring(5, 7) ===
                                      '12' && (
                                      <p textAlign='center'>
                                        {'End Date  : December ' +
                                          product.endDate.substring(8, 10) +
                                          ', ' +
                                          product.endDate.substring(0, 4)}
                                      </p>
                                    )}
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                            </Grid.Row>
                          )
                        })}

                      {s.activeItem === 'Rent' &&
                        rentProducts.length > 0 &&
                        rentProducts.forEach(function (product) {
                          fee =
                            fee +
                            product.rentTimeSelect.price * product.quantity
                          localStorage.setItem('rentFee', fee)
                        })}

                      <Grid.Row>
                        <Grid.Column floated='right' width={11}>
                          Subtotal
                        </Grid.Column>

                        <Grid.Column floated='left' width={2}>
                          ${fee}
                        </Grid.Column>

                        <Grid.Column floated='right' width={12}>
                          Shipping Fee
                        </Grid.Column>

                        <Grid.Column floated='left' width={3}>
                          $ 0
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column floated='right' width={11}>
                          <Header>TOTAL</Header>
                        </Grid.Column>

                        <Grid.Column floated='left' width={2}>
                          <Header>${fee}</Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <br />
                    <br />
                    <Grid>
                      <Grid.Row centered columns={3}>
                        <Grid.Column width={10}>
                          <Button
                            fluid
                            color='teal'
                            content='Check Out'
                            onClick={this.onHandleCheckOut}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <br />
                    <br />
                  </Segment>
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        {loading === false && login === false && (
          <div
            className='bg_color_image'
            style={{ height: '110vh', width: '100%' }}
          >
            <ToPrevious color='' />

            <Segment
              style={{ position: 'absolute', top: '50%', height: '100vh' }}
            >
              <Grid>
                <Grid.Row centered>
                  <Header> You Should login first</Header>
                </Grid.Row>

                <Grid.Row centered>
                  <Link to='/signin'>
                    <Button size='huge' color='orange'>
                      Login
                    </Button>
                  </Link>
                </Grid.Row>
              </Grid>
            </Segment>
          </div>
        )}

        {loading === false && login === true && products.length === 0 && (
          <div
            className='bg_color_image'
            style={{ height: '110vh', width: '100%' }}
          >
            <ToPrevious color='' />

            <Segment
              style={{ position: 'absolute', top: '30%', height: '30vh' }}
            >
              <Grid>
                <Grid.Row centered>
                  <Header>You Don't Have Anything in Your Cart</Header>
                </Grid.Row>

                <Grid.Row centered>
                  <Link to='/clothing'>
                    <Button size='huge' color='orange'>
                      Go Check Out Our Closet
                    </Button>
                  </Link>
                </Grid.Row>
              </Grid>
            </Segment>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(CartPagePhone)
