import React from 'react'
import { useHistory } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

const userLang = navigator.language || navigator.userLanguage

const MerchantSec = ({ pic }) => {
  const history = useHistory();

  const handleOpenShop = () => {
    history.push('/openShop')
  }

  return (
    <section 
      className="h-screen bg-cover bg-center relative"
      style={{ backgroundImage: `url(${pic})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center">
        <div className="container mx-auto px-4 md:px-12">
          {/* Main Content */}
          <div className="flex flex-col items-center">
            {/* Header */}
            <h1 className="text-white text-5xl md:text-6xl mb-16 md:mb-24 text-center">
              {userLang === 'zh-CN' ? '成为舍予商户' : 'Become Merchant in SHEYOU'}
            </h1>

            {/* Features Grid */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-16 max-w-7xl mx-auto">
              {/* Feature 1 */}
              <div className="text-center p-4">
                <h2 className="text-white text-2xl font-bold mb-4">
                  {userLang === 'zh-CN' ? '长期利润' : 'Higher Long Term Profit'}
                </h2>
                <p className="text-white text-base md:text-lg">
                  {userLang === 'zh-CN'
                    ? '租借不会产生退货，租金加卖出的钱意味着更多的利润'
                    : 'Renting means no sales return, and rent plus purchase produce more profit in the long term.'}
                </p>
              </div>

              {/* Feature 2 */}
              <div className="text-center p-4">
                <h2 className="text-white text-2xl font-bold mb-4">
                  {userLang === 'zh-CN' ? '租借自由' : 'Freedom To Rent Out'}
                </h2>
                <p className="text-white text-base md:text-lg">
                  {userLang === 'zh-CN'
                    ? '商户自定出租时间与租金'
                    : 'Merchants customize their renting time and money charge'}
                </p>
              </div>

              {/* Feature 3 */}
              <div className="text-center p-4">
                <h2 className="text-white text-2xl font-bold mb-4">
                  {userLang === 'zh-CN' ? '吸引用户' : 'Attract Customers'}
                </h2>
                <p className="text-white text-base md:text-lg">
                  {userLang === 'zh-CN'
                    ? '租借对于用户更划算，也意味着更多笔交易'
                    : 'Renting is more cost effective for customers, which means more orders'}
                </p>
              </div>

              {/* Feature 4 */}
              <div className="text-center p-4">
                <h2 className="text-white text-2xl font-bold mb-4">
                  {userLang === 'zh-CN' ? '更少成本' : 'Lower Production Costs'}
                </h2>
                <p className="text-white text-base md:text-lg">
                  {userLang === 'zh-CN'
                    ? '租借需要生产更少的商品，每个季节也有更少的剩余'
                    : 'Renting requires less production, which reduces out-of-season leftovers'}
                </p>
              </div>
            </div>

            {/* CTA Button */}
            <button
              onClick={handleOpenShop}
              className="bg-yellow-400 hover:bg-yellow-500 text-black px-16 py-4 rounded-full text-xl font-bold transition-colors duration-200 flex items-center justify-center space-x-3 w-80"
            >
              <span>{userLang === 'zh-CN' ? '现在开店' : 'Open Shop Now'}</span>
              <ChevronRightIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MerchantSec
