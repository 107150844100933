import { BACKEND_URL } from '../../../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../../../utils/config';
import React from 'react'
import axios from 'axios'
import {
  Grid,
  Icon,
  Divider,
  Image,
  Segment,
  Container,
  Button,
  Header,
  Checkbox
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class OrderPickUpConfirm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      identifier: '',
      token: '',
      rentOrBuy: 'buy',
      userInfo: [],
      loading: true,
      useAnother: false,
      save: false,
      orderid: '',
      missingInput: false,
      accessoryProduct: [],
      accessoryProductRent: [],
      products: '',
      productsRental: '',
      buyProducts: [],
      rentProducts: [],
      buyEmpty: true,
      login: false,
      quantity: 0,
      addCartSuccess: true,
      colors: '',
      buyCartInfo: '',
      rentCartInfo: '',
      productPrice: 0,
      accessoryPrice: 0,
      productRentPrice: 0,
      accessoryRentPrice: 0,
      activeItem: 'Buy',
      accessoryCartInfo: '',
      userID: '',
      cart: [],
      membership: [],
      accessory: [],

      open: false,
      open1: false,

      bag: [],
      accessoryBag: [],

      active: false
    }
    this.getUserInfo = this.getUserInfo.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.useAnother = this.useAnother.bind(this)
    this.useList = this.useList.bind(this)
    this.toPrevios = this.toPrevios.bind(this)
    this.getAccessories = this.getAccessories.bind(this)
    this.getProductInAccessoryRentCart =
      this.getProductInAccessoryRentCart.bind(this)
    this.getProductInBuyCart = this.getProductInBuyCart.bind(this)
    this.getProductInRentCart = this.getProductInRentCart.bind(this)
  }
  componentDidMount () {
    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(
      orderidPosition + 8
    )
    const token = localStorage.getItem('token')

    const identifier = localStorage.getItem('identifier')
    const self = this

    self.setState({
      rentOrBuy: 'buy',
      identifier: identifier,
      orderid: orderid
    })

    self.setState({
      buyProducts: [],
      accessoryProduct: [],
      rentProducts: [],
      accessoryProductRent: []
    })

    axios(`${BACKEND_URL}/orders/` + orderid, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        if (res.status === 200) {
          console.log(res)
          const orderData = res.data.orders.data
          const rentOrBuy = orderData.rentOrBuy
          const clothingData = orderData.clothing
          const accessoryData = orderData.accessory

          const receiverInfo = orderData.info
          const senderInfo = orderData.senderInfo

          self.setState({
            rentOrBuy: rentOrBuy,
            receiverInfo: receiverInfo,
            senderInfo: senderInfo
          })

          clothingData.forEach(function (e) {
            if (e.type !== 'sale') {
              axios
                .get(`${BACKEND_URL}/products?id=` + e.id)
                .then(function (res) {
                  if (res.status === 200) {
                    const productSelected = res.data[0]
                    var product=productSelected
                    if (rentOrBuy === 'buy') {
                      if (self.state.buyProducts === '') {
                         product = productSelected

                        self.setState({ buyCartInfo: e })

                        product.quantity = self.state.buyCartInfo.number
                        product.colors[0] = self.state.buyCartInfo.colors
                        product.sizes[0] = self.state.buyCartInfo.sizes

                        const stringProduct = [product]
                        self.setState({
                          buyProducts: stringProduct
                        })
                      } else if (self.state.buyProducts !== '') {
                        product = productSelected

                        self.setState({ buyCartInfo: e })

                        product.quantity = self.state.buyCartInfo.number
                        product.colors[0] = self.state.buyCartInfo.colors
                        product.sizes[0] = self.state.buyCartInfo.sizes

                        const stringProduct = product
                        product = self.state.buyProducts

                        product.push(stringProduct)
                        self.setState({
                          buyProducts: product
                        })
                      }
                    } else {
                      if (self.state.rentProducts.length === 0) {
                         product = productSelected

                        self.setState({ rentCartInfo: e })

                        product.quantity = self.state.rentCartInfo.number
                        product.colors[0] = self.state.rentCartInfo.colors
                        product.sizes[0] = self.state.rentCartInfo.sizes
                        product.endDate = self.state.rentCartInfo.endDate
                        product.startDate = self.state.rentCartInfo.startDate
                        product.rentTimeSelect =
                          self.state.rentCartInfo.rentTimeSelect

                        const stringProduct = [product]
                        self.setState({
                          rentProducts: stringProduct
                        })
                      } else if (self.state.rentProducts.length!==0) {
                        product = productSelected

                        self.setState({ rentCartInfo: e })

                        product.quantity = self.state.rentCartInfo.number
                        product.colors[0] = self.state.rentCartInfo.colors
                        product.sizes[0] = self.state.rentCartInfo.sizes
                        product.endDate = self.state.rentCartInfo.endDate
                        product.startDate = self.state.rentCartInfo.startDate
                        product.rentTimeSelect =
                          self.state.rentCartInfo.rentTimeSelect

                        const stringProduct = product
                        product = self.state.rentProducts

                        product.push(stringProduct)
                        self.setState({
                          rentProducts: product
                        })
                      }
                    }
                  } else {
                    // alert('something went wrong')
                    localStorage.setItem('login', 'false')
                  }
                })
            } else if (e.type === 'sale') {
              axios
                .get(
                  `${BACKEND_URL}/second-hand-products?id=` +
                    e.id
                )
                .then(function (res) {
                  if (res.status === 200) {
                    const productSelected = res.data[0]

                    if (self.state.buyProducts === '') {
                      var product = productSelected

                      self.setState({ buyCartInfo: e })

                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes
                      product.type = 'sale'

                      const stringProduct = [product]
                      self.setState({
                        buyProducts: stringProduct
                      })
                    } else if (self.state.buyProducts !== '') {
                      product = productSelected

                      self.setState({ buyCartInfo: e })
                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes
                      product.type = 'sale'

                      const stringProduct = product
                      product = self.state.buyProducts

                      product.push(stringProduct)
                      self.setState({
                        buyProducts: product
                      })
                    }
                  } else {
                    // alert('something went wrong')
                    localStorage.setItem('login', 'false')
                  }
                  self.setState({
                    loading: false
                  })
                })
            }
          })

          accessoryData.forEach(function (e) {
            if (e.type !== 'sale') {
              axios
                .get(`${BACKEND_URL}/products?id=` + e.id)
                .then(function (res) {
                  if (res.status === 200) {
                    const productSelected = res.data[0]
                    var product = productSelected
                    if (self.state.accessoryProduct === '') {
                       product = productSelected

                      self.setState({ buyCartInfo: e })

                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes

                      const stringProduct = [product]
                      self.setState({
                        accessoryProduct: stringProduct
                      })
                    } else if (self.state.accessoryProduct !== '') {
                      product = productSelected

                      self.setState({ buyCartInfo: e })

                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes

                      const stringProduct = product
                      product = self.state.accessoryProduct

                      product.push(stringProduct)
                      self.setState({
                        accessoryProduct: product
                      })
                    }
                  } else {
                    // alert('something went wrong')
                    localStorage.setItem('login', 'false')
                  }
                })
            } else if (e.type === 'sale') {
              axios
                .get(
                  `${BACKEND_URL}/second-hand-products?id=` +
                    e.id
                )
                .then(function (res) {
                  if (res.status === 200) {
                    const productSelected = res.data[0]
                    var product = productSelected
                    if (self.state.accessoryProduct === '') {
                       product = productSelected

                      self.setState({ buyCartInfo: e })

                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes
                      product.type = 'sale'

                      const stringProduct = [product]
                      self.setState({
                        accessoryProduct: stringProduct
                      })
                    } else if (self.state.accessoryProduct !== '') {
                      product = productSelected

                      self.setState({ buyCartInfo: e })

                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes
                      product.type = 'sale'

                      const stringProduct = product
                      product = self.state.accessoryProduct

                      product.push(stringProduct)
                      self.setState({
                        accessoryProduct: product
                      })
                    }
                  } else {
                    // alert('something went wrong')
                    localStorage.setItem('login', 'false')
                  }
                  self.setState({
                    loading: false
                  })
                })
            }
          })
        }
      })
      .catch(function (err) {
        alert('something went wrong: ' + err)
      })

    this.getUserInfo()
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 2000)
    // console.log(allCountries.GetCode('China'))
  }

  getProductInBuyCart () {
    const self = this
    var price = 0
    // const cartIt=localStorage.getItem('buyItem')

    // const parsedCart = JSON.parse(cartIt)

    // if(cartIt===[]||cartIt===null){
    //     self.setState({buyEmpty:true})
    // }else{self.setState({buyEmpty:false})
    const userID = self.state.userID
    const token = self.state.token

    const cartItems = self.state.cart

    self.setState({ buyProducts: [] })
    if (cartItems.length === 0) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)

        self.setState({
          loading: false
        })
      })
    } else if (cartItems.length === undefined) {
      alert('something went wrong :(')
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          alert('your cart has been cleared sorry')
        } else {
          alert('something went wrong again')
        }
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        if (cart.type !== 'sale') {
          axios
            .get(`${BACKEND_URL}/products?id=` + cart.id)
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]
                console.log(productSelected)

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice
                  // console.log(price)
                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    productPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    productPrice: price
                  })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }

              self.setState({
                loading: false
              })
            })
        } else if (cart.type === 'sale') {
          axios
            .get(
              `${BACKEND_URL}/second-hand-products?id=` +
                cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.type = 'sale'

                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    productPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.type = 'sale'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    productPrice: price
                  })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }
              self.setState({
                loading: false
              })
            })
        }
      })
    }
  }

  getAccessories () {
    const self = this
    const userID = self.state.userID
    const token = self.state.token
    var price = 0

    const cartItems = self.state.accessory

    self.setState({ buyProducts: [] })

    if (cartItems.length === 0) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          accessoryCart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)

        self.setState({
          loading: false
        })
      })
    } else if (cartItems.length === undefined) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          accessoryCart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)

        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        if (cart.type !== 'sale') {
          axios
            .get(
              `${BACKEND_URL}/accessaries?id=` + cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.is = 'Accessaries'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    accessoryPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.is = 'Accessaries'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    accessoryPrice: price
                  })
                }
              } else {
                self.props.history.push('/loginfirst')
              }
              self.setState({
                loading: false
              })
            })
        } else if (cart.type === 'sale') {
          axios
            .get(
              `${BACKEND_URL}/second-hand-products?id=` +
                cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.is = 'Accessaries'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    accessoryPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.is = 'Accessaries'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    accessoryPrice: price
                  })
                }
              } else {
                self.props.history.push('/loginfirst')
              }
              self.setState({
                loading: false
              })
            })
        }
      })
    }
  }

  getProductInRentCart () {
    const self = this
    var price = 0
    const userID = self.state.userID
    const token = self.state.token

    const cartItems = self.state.bag

    self.setState({
      rentProducts: []
    })

    if (cartItems === null ||  cartItems.length === 0) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        self.setState({
          loading: false
        })
        if (res.status === 200) {
          console.log(res.status)
        } else {
          self.setState({
            loading: false
          })
        }
      })
    } else if (cartItems.length === undefined) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        self.setState({
          loading: false,
          addCartSuccess: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        axios
          .get(`${BACKEND_URL}/products?id=` + cart.id)
          .then(function (res) {
            const productSelected = res.data[0]
            self.setState({
              loading: false
            })
            if (self.state.rentProducts.length === 0) {
              var product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ rentCartInfo: cart })
              // }})

              product.quantity = self.state.rentCartInfo.number
              product.colors[0] = self.state.rentCartInfo.colors
              product.sizes[0] = self.state.rentCartInfo.sizes
              product.endDate = self.state.rentCartInfo.endDate
              product.startDate = self.state.rentCartInfo.startDate
              product.rentTimeSelect = self.state.rentCartInfo.rentTimeSelect

              const itemPrice = product.price * product.quantity
              price = price + itemPrice

              const stringProduct = [product]
              self.setState({
                rentProducts: stringProduct,
                productRentPrice: price
              })
            } else if (self.state.rentProducts.length!==0) {
              product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ rentCartInfo: cart })
              // }})
              product.quantity = self.state.rentCartInfo.number
              product.colors[0] = self.state.rentCartInfo.colors
              product.sizes[0] = self.state.rentCartInfo.sizes
              product.endDate = self.state.rentCartInfo.endDate
              product.startDate = self.state.rentCartInfo.startDate
              product.rentTimeSelect = self.state.rentCartInfo.rentTimeSelect
              const itemPrice = product.price * product.quantity
              price = price + itemPrice

              const stringProduct = product
              product = self.state.rentProducts

              product.push(stringProduct)
              self.setState({
                rentProducts: product,
                productRentPrice: price
              })
            }
          })
      })
    }
  }
  getProductInAccessoryRentCart () {
    const self = this
    const userID = self.state.userID
    const token = self.state.token
    var price = 0

    const accessoryItems = self.state.accessoryBag

    self.setState({
      rentProducts: []
    })

    if (
      accessoryItems === null ||
      accessoryItems.length === 0
    ) {
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          accessoryBag: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        self.setState({
          loading: false
        })
        if (res.status === 200) {
          console.log(res.status)
        } else {
          self.setState({
            loading: false
          })
        }
      })
    }
    if (accessoryItems.length === undefined) {
      alert('something went wrong :(')
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          accessoryBag: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        self.setState({
          loading: false,
          addCartSuccess: false
        })
        if (res.status === 200) {
          alert('your bag has been cleared sorry')
        } else {
          alert('something went wrong again')
          localStorage.setItem('login', 'false')
        }
      })
    } else {
      accessoryItems.forEach(function (accessory) {
        axios
          .get(
            `${BACKEND_URL}/accessaries?id=` +
              accessory.id
          )
          .then(function (res) {
            console.log(res)
            self.setState({
              loading: false
            })
            const productSelected = res.data[0]

            if (self.state.rentProducts.legth===0) {
              var product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ accessoryCartInfo: accessory })
              // }})

              product.quantity = self.state.accessoryCartInfo.number
              product.colors[0] = self.state.accessoryCartInfo.colors
              product.sizes[0] = self.state.accessoryCartInfo.sizes
              product.endDate = self.state.rentCartInfo.endDate
              product.startDate = self.state.rentCartInfo.startDate
              product.rentTimeSelect = self.state.rentCartInfo.rentTimeSelect
              product.is = 'Accessaries'
              const itemPrice = product.price * product.quantity
              price = price + itemPrice

              const stringProduct = [product]
              self.setState({
                rentProducts: stringProduct,
                productRentPrice: price
              })
            } else if (self.state.rentProducts.length!==0) {
              product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ accessoryCartInfo: accessory })

              // }})
              product.quantity = self.state.accessoryCartInfo.number
              product.colors[0] = self.state.accessoryCartInfo.colors
              product.sizes[0] = self.state.accessoryCartInfo.sizes
              product.endDate = self.state.rentCartInfo.endDate
              product.startDate = self.state.rentCartInfo.startDate
              product.rentTimeSelect = self.state.rentCartInfo.rentTimeSelect
              const itemPrice = product.price * product.quantity
              product.is = 'Accessaries'
              price = price + itemPrice

              const stringProduct = product
              product = self.state.rentProducts

              product.push(stringProduct)
              self.setState({
                rentProducts: product,
                productRentPrice: price
              })
            }
          })
      })
    }
  }
  getUserInfo () {
    const self = this
    // const token=this.state.token
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(
      orderidPosition + 8
    )
    self.setState({
      orderid: orderid
    })

    self.setState({
      identifier: identifier,
      token: token
    })
    axios(`${BACKEND_URL}/users/me`, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        const userInfo = [res.data]
        console.log(userInfo)
        if (userInfo === null) {
          self.setState({
            userInfo: []
          })
        } else if (userInfo.length > 0) {
          if (userInfo[0].merchantInfo.address === null) {
            userInfo[0].merchantInfo.address = []
          }

          self.setState({
            userInfo: userInfo
          })
        }

        self.setState({
          firstName: userInfo[0].merchantInfo.identity.firstname,
          lastName: userInfo[0].merchantInfo.identity.lastname,
          country: userInfo[0].merchantInfo.address.country,
          state: userInfo[0].merchantInfo.address.state,
          city: userInfo[0].merchantInfo.address.city,
          zipcode: userInfo[0].merchantInfo.address.zipcode,
          phonenumber: userInfo[0].merchantInfo.mobilephone.phonenum,
          address1: userInfo[0].merchantInfo.address.address1,
          address2: userInfo[0].merchantInfo.address.address1
        })
        //   }
        // }

        console.log(userInfo)
        self.setState({
          loading: false
        })
      })
      .catch(function (err) {
        self.props.history.push('merchantsLog')
      })
  }

  onHandleSubmit (event) {
    this.props.history.push('/orderPickUp/confirm')
    // event.preventDefault()
    // const self = this
    // const token = localStorage.getItem('token')
    // const orderidPosition = this.props.history.location.search.search('orderid')
    // const orderid = this.props.history.location.search.substring(
    //   orderidPosition + 8
    // )
    // console.log(orderid)
    // var userInfo = localStorage.getItem('userInfo')
    // userInfo = JSON.parse(userInfo)
    // const id = userInfo[0].id
  }

  useAnother () {
    this.setState({
      useAnother: true,
      firstName: '',
      lastName: '',
      state: '',
      city: '',
      zipcode: '',
      phonenumber: '',
      address1: '',
      address2: ''
    })
  }

  useList () {
    this.setState({
      useAnother: false
    })
  }

  toPrevios () {
    window.history.go(-1)
  }

  render () {
    const self = this
    const s = self.state

    var rentProducts = s.rentProducts

    console.log(s.senderInfo, s.receiverInfo)
    const loading = this.state.loading
    console.log(s.toConfirm)


    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <div className='bg_color_image'>
              <br />
              <br />
              <Header inverted size='huge' textAlign='center'>
                Ready For Pick Up?
              </Header>
              <br />
              <br />
              <Container>
                <Segment>
                  <Grid container>
                    <Grid.Row columns={5} style={{ marginTop: 20 }}>
                      <Grid.Column width={2}></Grid.Column>
                      <Grid.Column width={2}>
                        <Icon size='big' name='dot circle' color='grey' />
                        <Header
                          as='h6'
                          textAlign='left'
                          style={{ padding: 0, marginTop: 7 }}
                        >
                          Start
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={3}>
                        <Divider />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Icon
                          size='big'
                          name='dot circle outline'
                          color='orange'
                        />

                        <Header
                          as='h6'
                          textAlign='left'
                          style={{ padding: 0, marginTop: 7 }}
                        >
                          Verify
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={3}>
                        <Divider />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Icon size='big' name='dot circle' color='grey' />

                        <Header
                          as='h6'
                          textAlign='left'
                          style={{ padding: 0, marginTop: 7 }}
                        >
                          Confirm
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>

                    <Divider></Divider>

                    <Grid.Row width={16}>
                      <Segment>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={8}>
                              <Header
                                as='h5'
                                textAlign='left'
                                style={{ padding: 0, marginTop: 7 }}
                              >
                                Checklist:
                              </Header>
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Column width={16}>
                            <Checkbox label='Pack the package' />
                              
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Column width={16}>
                            <Checkbox label='Print the label for pick up' />
                            </Grid.Column>
                            <Grid.Column width={16}>
                            <Button color='teal'> download label link</Button>
                            </Grid.Column>
                          </Grid.Row>

                          {/* <Grid.Row>
                            <Grid.Column width={16}>

                              <Grid>
                                <Grid.Row>
                                  <Checkbox label='Choose time for carrier pick up' />
                                </Grid.Row>

                                <Grid.Row>
                            <Dropdown placeholder='Pickup Time' fluid multiple selection options={options} />

                            </Grid.Row>
                              
                              </Grid>

                            </Grid.Column>
                          </Grid.Row> */}
                          
                        </Grid>
                      </Segment>
                    </Grid.Row>

                    {/* {s.rentOrBuy === 'buy' &&
                      products.length > 0 &&
                      products.map(function (product) {
                        console.log(product, product.type)
                        return (
                          <Grid.Row columns={9} centered>
                            <Grid.Column width={1}>
                              {product.stock !== '0' && (
                                <Icon
                                  id={product.id}
                                  type={product.type}
                                  category={product.is}
                                  onClick={self.onHandleDelete}
                                  circular
                                  color='orange'
                                  name='circle outline'
                                />
                              )}
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Image
                                size='medium'
                                src={product.picture[0].url}
                              />
                            </Grid.Column>

                            <Grid.Column width={4}>
                              <Grid>
                                <Grid.Row>{product.name}</Grid.Row>
                                <Grid.Row>Color: {product.colors[0]}</Grid.Row>
                                <Grid.Row>Size: {product.sizes[0]}</Grid.Row>
                              </Grid>
                            </Grid.Column>

                            {product.stock === '0' && (
                              <Grid.Column width={1}>0</Grid.Column>
                            )}
                            {product.stock !== '0' && (
                              <Grid.Column width={3}>
                                {'Quantity: ' + product.quantity}
                              </Grid.Column>
                            )}

                            <Grid.Column>{'$ ' + product.price}</Grid.Column>
                          </Grid.Row>
                        )
                      })} */}
                    {s.rentOrBuy !== 'buy' &&
                      rentProducts.length > 0 &&
                      rentProducts.map(function (product) {
                        console.log(product)
                        return (
                          <Grid.Row columns={8}>
                            {/* <Dimmer active={product.stock === '0'} inverted>
                              <Icon
                                id={product.id}
                                size='large'
                                type={product.type}
                                category={product.is}
                                onClick={self.onHandleRentDelete}
                                circular
                                color='grey'
                                name='circle outline'
                              />
                            </Dimmer> */}

                            {/* <Grid.Column width={1}>
                              {product.stock !== '0' && (
                                <Icon
                                  id={product.id}
                                  type={product.type}
                                  category={product.is}
                                  onClick={self.onHandleRentDelete}
                                  circular
                                  color='orange'
                                  name='circle outline'
                                />
                              )}
                            </Grid.Column> */}
                            <Grid.Column width={3}>
                              <Image
                                size='medium'
                                src={product.picture[0].url}
                              />
                            </Grid.Column>

                            <Grid.Column width={3}>
                              <Grid>
                                <Grid.Row>{product.name}</Grid.Row>
                                <Grid.Row>Color: {product.colors}</Grid.Row>
                                <Grid.Row>Size: {product.sizes}</Grid.Row>
                              </Grid>
                            </Grid.Column>

                            <Grid.Column width={1}></Grid.Column>

                            {product.stock === '0' && (
                              <Grid.Column width={1}>0</Grid.Column>
                            )}
                            {product.stock !== '0' && (
                              <Grid.Column width={3}>
                                {'Quantity: ' + product.quantity}
                              </Grid.Column>
                            )}

                            <Grid.Column width={4}>
                              <Grid>
                                <Grid.Row>
                                  {product.rentTimeSelect !== undefined &&
                                    'Rent Price: $ ' +
                                      product.rentTimeSelect.price}
                                  {product.rentTimeSelect === undefined &&
                                    'Error! Contact Seller'}
                                </Grid.Row>
                                <Grid.Row>
                                  {product.startDate.substring(5, 7) ===
                                    '01' && (
                                    <p textAlign='center'>
                                      {'Start Date  : January ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '02' && (
                                    <p textAlign='center'>
                                      {'Start Date  : February ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '03' && (
                                    <p textAlign='center'>
                                      {'Start Date  : March ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '04' && (
                                    <p textAlign='center'>
                                      {'Start Date  : April ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '05' && (
                                    <p textAlign='center'>
                                      {'Start Date  : May ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '06' && (
                                    <p textAlign='center'>
                                      {'Start Date  : June ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '07' && (
                                    <p textAlign='center'>
                                      {'Start Date  : July ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '08' && (
                                    <p textAlign='center'>
                                      {'Start Date  : August ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '09' && (
                                    <p
                                      color='orange'
                                      as='h6'
                                      textAlign='center'
                                    >
                                      {'Start Date  : September ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '10' && (
                                    <p textAlign='center'>
                                      {'Start Date  : October ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '11' && (
                                    <p textAlign='center'>
                                      {'Start Date  : November ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '12' && (
                                    <p textAlign='center'>
                                      {'Start Date  : December ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}

                                  {/* Start Date: {product.startDate} */}
                                </Grid.Row>
                                <Grid.Row>
                                  {product.endDate.substring(5, 7) === '01' && (
                                    <p textAlign='center'>
                                      {'End Date  : January ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '02' && (
                                    <p textAlign='center'>
                                      {'End Date  : February ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '03' && (
                                    <p textAlign='center'>
                                      {'End Date  : March ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '04' && (
                                    <p textAlign='center'>
                                      {'End Date  : April ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '05' && (
                                    <p textAlign='center'>
                                      {'End Date  : May ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '06' && (
                                    <p textAlign='center'>
                                      {'End Date  : June ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '07' && (
                                    <p textAlign='center'>
                                      {'End Date  : July ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '08' && (
                                    <p textAlign='center'>
                                      {'End Date  : August ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '09' && (
                                    <p
                                      color='orange'
                                      as='h6'
                                      textAlign='center'
                                    >
                                      {'End Date  : September ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '10' && (
                                    <p textAlign='center'>
                                      {'End Date  : October ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '11' && (
                                    <p textAlign='center'>
                                      {'End Date  : November ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '12' && (
                                    <p textAlign='center'>
                                      {'End Date  : December ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                </Grid.Row>
                              </Grid>
                            </Grid.Column>
                          </Grid.Row>
                        )
                      })}



                    <Grid.Row centered>
                      <Button
                        style={{ paddingLeft: 40, paddingRight: 40 }}
                        onClick={self.toPrevios}
                      >
                        Back
                      </Button>
                      <Button
                        color='orange'
                        style={{ paddingLeft: 40, paddingRight: 40 }}
                        onClick={this.onHandleSubmit}
                      >
                        Confirm
                      </Button>
                    </Grid.Row>

                    <Grid.Row></Grid.Row>
                  </Grid>
                </Segment>
              </Container>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(OrderPickUpConfirm)
