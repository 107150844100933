import { BACKEND_URL } from '../../utils/config';
import React from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  Header,
  Segment,
  Icon,
  Container,
  Button,
  Input,
  Divider,
  List,
  Image,
  Card
} from 'semantic-ui-react'
import 'react-chat-elements/dist/main.css'
import { MessageList } from 'react-chat-elements'
import { withRouter } from 'react-router-dom'
import WebSocketService from '../../services/websocket'
import {
  fetchChatList,
  fetchMessages,
  sendMessage,
  setActiveChat,
  addMessage,
  setTypingStatus,
  clearError
} from '../../redux/slices/chatSlice'
import Foot from '../foot/Foot'
import axios from 'axios'

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sender: null,
      senderName: '',
      receiver: null,
      receiverName: '',
      inputText: '',
      error: null,
      isSending: false,
      visibleMessages: {},
      isLoadingMore: false,
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || [],
      productContext: null // Store product context when chat is initiated from product page
    };
    this.messageContainer = null;
    this.MESSAGES_PER_PAGE = 20;
  }

  componentDidMount() {
    this.initializeChat();
    this.setupWebSocket();
  }

  async initializeChat() {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (!userInfo || !userInfo[0]) {
        throw new Error('No user info found');
      }

      const sender = userInfo[0].id;
      const senderName = userInfo[0].username;

      this.setState({
        sender,
        senderName
      });

      // Fetch initial chat list
      await this.props.dispatch(fetchChatList());

      // Check for product context in location state
      const { state } = this.props.location;
      if (state?.product && state?.merchantId) {
        this.setState({ 
          productContext: state.product
        }, () => {
          this.fetchMerchantInfo(state.merchantId);
        });
      }
    } catch (error) {
      console.error('Error initializing chat:', error);
      this.setState({ error: 'Failed to initialize chat' });
    }
  }

  async fetchMerchantInfo(merchantId) {
    try {
      const response = await fetch(`${BACKEND_URL}/users/${merchantId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const merchantData = await response.json();
      
      if (merchantData) {
        // Just select the chat without creating an initial message
        await this.props.dispatch(fetchChatList());
        this.selectChat(merchantId, merchantData.username);
      }
    } catch (error) {
      console.error('Error fetching merchant info:', error);
      this.setState({ error: 'Failed to connect with merchant' });
    }
  }

  renderProductContext() {
    const { productContext } = this.state;
    if (!productContext) return null;

    // Get the correct thumbnail URL (now using Cloudinary URL directly)
    let thumbnailUrl = '';
    if (productContext.picture && productContext.picture[0]) {
      thumbnailUrl = productContext.picture[0].url;
    }

    return (
      <Card fluid style={{ 
        margin: '0',
        boxShadow: 'none',
        border: '1px solid #eee',
        borderRadius: '4px',
        marginBottom: '1rem'
      }}>
        <Card.Content style={{ padding: '0.5rem' }}>
          <Grid verticalAlign='middle'>
            <Grid.Column width={3} style={{ padding: '0.5rem' }}>
              {thumbnailUrl && (
                <div style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '4px',
                  overflow: 'hidden',
                  border: '1px solid #eee'
                }}>
                  <Image
                    src={thumbnailUrl}
                    style={{ 
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </div>
              )}
            </Grid.Column>
            <Grid.Column width={13}>
              <Card.Header style={{ 
                fontSize: '1rem', 
                marginBottom: '0.5rem',
                color: '#333'
              }}>
                {productContext.name}
              </Card.Header>
              <Card.Meta style={{ 
                fontSize: '0.9rem', 
                color: '#666',
                fontWeight: '500'
              }}>
                ${productContext.price}
              </Card.Meta>
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    );
  }

  componentDidUpdate(prevProps) {
    const { messages } = this.props;
    const { sender, receiver } = this.state;

    // If messages changed or receiver changed, scroll to bottom
    const prevChatId = prevProps.receiver ? [sender, prevProps.receiver].sort().join('-') : null;
    const currentChatId = receiver ? [sender, receiver].sort().join('-') : null;
    
    if (prevChatId !== currentChatId || 
        (messages[currentChatId]?.length !== prevProps.messages[currentChatId]?.length)) {
      this.scrollToBottom();
    }

    // Check if we're navigating from a product page with new state
    const { state } = this.props.location;
    const prevState = prevProps.location.state;
    if (state?.product && state?.merchantId && 
        (!prevState?.product || prevState?.merchantId !== state.merchantId)) {
      this.setState({ 
        productContext: state.product
      }, () => {
        this.fetchMerchantInfo(state.merchantId);
      });
    }
  }

  scrollToBottom = () => {
    if (this.messageContainer) {
      this.messageContainer.scrollTop = this.messageContainer.scrollHeight;
    }
  };

  componentWillUnmount() {
    if (WebSocketService.isConnected()) {
      WebSocketService.disconnect();
    }
  }

  setupWebSocket() {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found for WebSocket connection');
      return;
    }

    WebSocketService.connect(token).then(() => {
      // Handle new messages
      WebSocketService.onNewMessage((message) => {
        console.log('New message received via WebSocket:', message);
        this.props.dispatch(addMessage(message));
        
        // Only update chat list if it's a new conversation
        const chatId = [message.messageSender[0].id, message.messageReceiver[0].id].sort().join('-');
        const currentChatId = [this.state.sender, this.state.receiver].sort().join('-');
        
        if (chatId !== currentChatId) {
          this.props.dispatch(fetchChatList());
        }
        
        this.scrollToBottom();
      });

      // Handle typing status
      WebSocketService.onTyping(({ userId, isTyping }) => {
        this.props.dispatch(setTypingStatus({ userId, isTyping }));
      });
    }).catch(error => {
      console.error('Failed to connect to WebSocket:', error);
      this.setState({ error: 'Failed to connect to chat service' });
    });
  }

  selectChat = (userId, username) => {
    const chatId = [this.state.sender, userId].sort().join('-');
    
    // Only clear product context if we're not coming from a product page
    // or if we're switching to a different merchant
    const { state } = this.props.location;
    const shouldClearProduct = !state?.product || state.merchantId !== userId;
    
    this.setState({ 
      receiver: userId, 
      receiverName: username,
      inputText: '',
      productContext: shouldClearProduct ? null : state.product,
      visibleMessages: {
        ...this.state.visibleMessages,
        [chatId]: this.MESSAGES_PER_PAGE
      }
    }, async () => {
      if (WebSocketService.isConnected()) {
        WebSocketService.joinChat(chatId);
      }

      try {
        await this.props.dispatch(fetchMessages({ 
          senderId: this.state.sender, 
          receiverId: userId 
        }));
        this.scrollToBottom();
      } catch (error) {
        console.error('Error fetching messages:', error);
        this.setState({ error: 'Failed to load messages' });
      }
    });
  };

  handleScroll = (e) => {
    const { sender, receiver, visibleMessages } = this.state;
    if (!sender || !receiver || this.state.isLoadingMore) return;

    const chatId = [sender, receiver].sort().join('-');
    const container = e.target;
    const currentMessages = this.props.messages[chatId] || [];
    const currentVisible = visibleMessages[chatId] || this.MESSAGES_PER_PAGE;

    // If scrolled near top and there are more messages to load
    if (container.scrollTop < 100 && currentVisible < currentMessages.length) {
      // Get current scroll position and height
      const oldScrollHeight = container.scrollHeight;

      // Update visible messages count
      this.setState(prevState => ({
        visibleMessages: {
          ...prevState.visibleMessages,
          [chatId]: Math.min(currentVisible + this.MESSAGES_PER_PAGE, currentMessages.length)
        }
      }), () => {
        // Maintain scroll position after loading more messages
        const newScrollHeight = container.scrollHeight;
        container.scrollTop = newScrollHeight - oldScrollHeight;
      });
    }
  };

  handleSendMessage = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { inputText, sender, receiver, productContext } = this.state;
    if (!inputText.trim() || !sender || !receiver || this.state.isSending) return;

    const messageText = inputText.trim();
    const timestamp = new Date().toISOString();

    // Create optimistic message
    const optimisticMessage = {
      id: `temp-${Date.now()}`,
      messageText,
      messageSender: [{ id: sender }],
      messageReceiver: [{ id: receiver }],
      created_at: timestamp,
      isSender: true,
      status: 'sending'
    };

    // Add product to optimistic message if it's the first message with product context
    if (productContext?.id) {
      optimisticMessage.product = productContext;
    }

    try {
      this.setState({ isSending: true, inputText: '' });
      
      // Add optimistic message to Redux store
      this.props.dispatch(addMessage(optimisticMessage));
      
      // Send through WebSocket for real-time updates
      if (WebSocketService.isConnected()) {
        WebSocketService.sendMessage({
          text: messageText,
          senderId: sender,
          receiverId: receiver,
          productId: productContext?.id
        });
      }

      // Send through HTTP
      const response = await this.props.dispatch(sendMessage({
        receiverId: receiver,
        text: messageText,
        productId: productContext?.id
      })).unwrap();

      // Update the optimistic message with the real one
      this.props.dispatch(addMessage({ ...response, isSender: true }));

      // Clear product context after first message is sent
      if (productContext) {
        this.setState({ productContext: null });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      this.setState({ error: 'Failed to send message' });
    } finally {
      this.setState({ isSending: false });
    }
  };

  handleInputChange = (e) => {
    const { sender, receiver } = this.state;
    const text = e.target.value;
    this.setState({ inputText: text });

    // Only emit typing status if we have both sender and receiver
    if (WebSocketService.isConnected() && sender && receiver) {
      WebSocketService.emitTyping(sender, receiver, text.length > 0);
    }
  };

  renderMessageProduct(product) {
    if (!product) return null;

    // Get the correct thumbnail URL
    let thumbnailUrl = '';
    if (product.picture && product.picture[0]) {
      thumbnailUrl = product.picture[0].url;
    }

    return (
      <div style={{ 
        marginTop: '0.5rem',
        padding: '0.5rem',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '4px',
        border: '1px solid #eee'
      }}>
        <Grid verticalAlign='middle'>
          <Grid.Column width={4} style={{ padding: '0.25rem' }}>
            {thumbnailUrl && (
              <div style={{
                width: '40px',
                height: '40px',
                borderRadius: '4px',
                overflow: 'hidden'
              }}>
                <Image
                  src={thumbnailUrl}
                  style={{ 
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </div>
            )}
          </Grid.Column>
          <Grid.Column width={12}>
            <div style={{ 
              fontSize: '0.9rem',
              fontWeight: '500',
              color: '#333',
              marginBottom: '0.25rem'
            }}>
              {product.name}
            </div>
            <div style={{ 
              fontSize: '0.8rem',
              color: '#666'
            }}>
              ${product.price}
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  render() {
    const { inputText, error, isSending } = this.state;
    const { chatUsers = [], messages = {}, typingUsers = {}, loading, reduxError } = this.props;
    const { sender, receiver } = this.state;

    if (error || reduxError) {
      return (
        <Container style={{ marginTop: '2rem' }}>
          <Segment color='red' padded='very'>
            <Header as='h2' icon textAlign='center'>
              <Icon name='warning sign' />
              Error Loading Chat
              <Header.Subheader>{error || reduxError}</Header.Subheader>
            </Header>
          </Segment>
        </Container>
      );
    }

    // Get the current chat messages
    const chatId = receiver ? [sender, receiver].sort().join('-') : null;
    const currentChatMessages = chatId ? (messages[chatId] || []) : [];

    return (
      <>
        {loading && !currentChatMessages.length ? (
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading Chat...
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <div className='gradient_limeade'>
            <Container style={{ paddingBottom: '20px' }}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Segment>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <Header as='h2'>Messages</Header>
                            <Divider />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={4}>
                            <List divided relaxed style={{ 
                              maxHeight: '70vh', 
                              overflowY: 'auto',
                              border: '1px solid #ddd',
                              borderRadius: '4px',
                              padding: '0.5rem',
                              margin: 0
                            }}>
                              {(!Array.isArray(chatUsers) || chatUsers.length === 0) ? (
                                <List.Item style={{
                                  padding: '1rem',
                                  textAlign: 'center',
                                  color: 'gray'
                                }}>
                                  <List.Content>
                                    <List.Header>No Chat History</List.Header>
                                    <List.Description>
                                      Start a conversation to see your chats here
                                    </List.Description>
                                  </List.Content>
                                </List.Item>
                              ) : (
                                chatUsers.map((user) => (
                                  <List.Item 
                                    key={user.id}
                                    onClick={() => this.selectChat(user.id, user.username)}
                                    style={{
                                      padding: '0.8rem',
                                      cursor: 'pointer',
                                      backgroundColor: this.state.receiver === user.id ? '#e6f3ff' : 'transparent',
                                      borderRadius: '4px',
                                      marginBottom: '0.5rem',
                                      transition: 'background-color 0.2s',
                                      border: this.state.receiver === user.id ? '1px solid #b3d9ff' : '1px solid transparent'
                                    }}
                                  >
                                    <List.Content>
                                      <List.Header style={{ 
                                        marginBottom: '0.3rem',
                                        color: this.state.receiver === user.id ? '#1a75ff' : 'inherit'
                                      }}>
                                        {user.merchantInfo?.storeName || user.username}
                                        {typingUsers[user.id] && (
                                          <span style={{ marginLeft: '0.5rem', color: 'gray', fontSize: '0.9em' }}>
                                            typing...
                                          </span>
                                        )}
                                      </List.Header>
                                      <List.Description style={{ color: 'gray', fontSize: '0.8em' }}>
                                        {user.lastMessage && (
                                          <>
                                            <div style={{ color: '#666' }}>
                                              {user.lastMessage.length > 30 
                                                ? user.lastMessage.substring(0, 30) + '...' 
                                                : user.lastMessage}
                                            </div>
                                            <div style={{ fontSize: '0.75em', color: '#999', marginTop: '0.2rem' }}>
                                              {new Date(user.lastMessageDate).toLocaleString()}
                                            </div>
                                          </>
                                        )}
                                      </List.Description>
                                    </List.Content>
                                  </List.Item>
                                ))
                              )}
                            </List>
                          </Grid.Column>

                          <Grid.Column width={12}>
                            {receiver ? (
                              <div style={{ 
                                display: 'flex', 
                                flexDirection: 'column',
                                height: '70vh',
                                border: '1px solid #ddd',
                                borderRadius: '4px'
                              }}>
                                <Header as='h3' style={{ 
                                  padding: '1rem', 
                                  margin: 0, 
                                  borderBottom: '1px solid #ddd',
                                  backgroundColor: '#f8f9fa'
                                }}>
                                  {this.state.receiverName}
                                </Header>
                                
                                <div
                                  ref={(el) => { this.messageContainer = el; }}
                                  style={{
                                    flex: 1,
                                    overflowY: 'auto',
                                    padding: '1rem',
                                    minHeight: 0,
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                  onScroll={this.handleScroll}
                                >
                                  {this.renderProductContext()}
                                  <MessageList
                                    className='message-list'
                                    lockable={true}
                                    toBottomHeight={'100%'}
                                    dataSource={currentChatMessages.map((msg) => {
                                      // Get the sender's display name
                                      let displayName = msg.isSender ? 'You' : this.state.receiverName;
                                      if (!msg.isSender && msg.messageSender[0]?.merchantInfo?.storeName) {
                                        displayName = msg.messageSender[0].merchantInfo.storeName;
                                      } else if (msg.isSender && this.state.userInfo[0]?.merchantInfo?.storeName) {
                                        displayName = this.state.userInfo[0].merchantInfo.storeName;
                                      }

                                      return {
                                        position: msg.isSender ? 'right' : 'left',
                                        type: 'text',
                                        text: (
                                          <div>
                                            <div>{msg.messageText}</div>
                                            {msg.product && this.renderMessageProduct(msg.product)}
                                          </div>
                                        ),
                                        date: new Date(msg.created_at),
                                        title: displayName,
                                        status: msg.id && msg.id.toString().startsWith('temp-') ? 'waiting' : 'sent'
                                      };
                                    })}
                                  />

                                  {typingUsers[receiver] && (
                                    <div style={{ 
                                      padding: '0.5rem', 
                                      color: '#666',
                                      fontSize: '0.9em'
                                    }}>
                                      {this.state.receiverName} is typing...
                                    </div>
                                  )}
                                </div>

                                <div style={{ 
                                  padding: '1rem', 
                                  borderTop: '1px solid #ddd',
                                  backgroundColor: '#f8f9fa'
                                }}>
                                  <form onSubmit={this.handleSendMessage}>
                                    <Grid>
                                      <Grid.Column width={14}>
                                        <Input
                                          fluid
                                          value={inputText}
                                          placeholder="Type a message..."
                                          onChange={this.handleInputChange}
                                          disabled={isSending}
                                          style={{ width: '100%' }}
                                        />
                                      </Grid.Column>
                                      <Grid.Column width={2}>
                                        <Button
                                          icon
                                          color='teal'
                                          type="submit"
                                          disabled={!inputText.trim() || isSending}
                                          loading={isSending}
                                          fluid
                                        >
                                          <Icon name='send' />
                                        </Button>
                                      </Grid.Column>
                                    </Grid>
                                  </form>
                                </div>
                              </div>
                            ) : (
                              <div style={{ 
                                height: '70vh',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                backgroundColor: '#f8f9fa'
                              }}>
                                <Header as='h3' textAlign='center' color='grey'>
                                  <Icon name='comments outline' size='large' />
                                  <Header.Content>
                                    Select a chat to start messaging
                                    <Header.Subheader>
                                      Choose a conversation from the list on the left
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </div>
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
            <Foot />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  chatUsers: state.chat?.chatList || [],
  messages: state.chat?.messages || {},
  typingUsers: state.chat?.typingUsers || {},
  loading: state.chat?.loading || false,
  error: state.chat?.error || null,
});

export default connect(mapStateToProps)(withRouter(ContactPage));

