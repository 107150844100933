import { createSlice } from '@reduxjs/toolkit';
import { showErrorWithTimeout } from './errorSlice';
import { clearFilterOptionsCache } from './productSlice';
import { navigate } from '../../utils/navigation';

const SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// Initialize state from localStorage
const initialState = {
  userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
  token: localStorage.getItem('token') || null,
  loginTime: parseInt(localStorage.getItem('loginTime')) || null,
  isLoggedIn: !!localStorage.getItem('token'),
  merchantLogin: localStorage.getItem('merchantLogin') === 'true'
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
    },
    setToken: (state, action) => {
      state.token = action.payload;
      state.loginTime = Date.now();
      state.isLoggedIn = true;
      localStorage.setItem('token', action.payload);
      localStorage.setItem('loginTime', Date.now().toString());
      localStorage.setItem('login', 'true');
    },
    setMerchantLogin: (state, action) => {
      state.merchantLogin = action.payload;
      localStorage.setItem('merchantLogin', action.payload.toString());
    },
    logout: (state) => {
      state.userInfo = null;
      state.token = null;
      state.loginTime = null;
      state.isLoggedIn = false;
      state.merchantLogin = false;
      // Clear localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('merchantLogin');
      localStorage.removeItem('login');
      localStorage.removeItem('loginTime');
    }
  }
});

export const { setUserInfo, setToken, setMerchantLogin, logout } = userSlice.actions;

// Thunk to check session expiration
export const checkSessionExpiration = () => (dispatch) => {
  const loginTime = parseInt(localStorage.getItem('loginTime'));
  const token = localStorage.getItem('token');
  
  // If there's no login time or token, but we have other session data, clear everything
  if ((!loginTime || !token) && (localStorage.getItem('userInfo') || localStorage.getItem('login'))) {
    dispatch(logout());
    dispatch(clearFilterOptionsCache());
    return true;
  }

  // Check if session has expired
  if (loginTime && Date.now() - loginTime > SESSION_DURATION) {
    dispatch(showErrorWithTimeout('Your login has expired. Please login again.', 'warning'));
    dispatch(logout());
    dispatch(clearFilterOptionsCache());
    return true;
  }
  return false;
};

// Thunk to handle 401 errors
export const handleUnauthorized = () => (dispatch, getState) => {
  const state = getState();
  const isMerchant = state.user.merchantLogin;
  
  dispatch(showErrorWithTimeout('Your session has expired. Please login again.', 'warning'));
  dispatch(logout());
  dispatch(clearFilterOptionsCache());

  // Use navigation utility for redirection
  navigate(isMerchant ? '/merchantsLog' : '/signin', { replace: true });
  return true;
};

// Thunk to handle merchant redirection
export const handleMerchantRedirect = () => (dispatch, getState) => {
  const state = getState();
  const { isLoggedIn, merchantLogin } = state.user;
  
  if (isLoggedIn && merchantLogin) {
    navigate('/merchantpage', { replace: true });
    return true;
  }
  return false;
};

export default userSlice.reducer; 