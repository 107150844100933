import { useUser } from '../hooks/useUser';

export const withUser = (WrappedComponent) => {
  return function WithUserComponent(props) {
    const { user, isLoading, error, refetch } = useUser();
    
    return (
      <WrappedComponent
        {...props}
        user={user}
        userLoading={isLoading}
        userError={error}
        refetchUser={refetch}
      />
    );
  };
}; 