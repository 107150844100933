import { BACKEND_URL } from '../../../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../../../utils/config';
import React from 'react'
import {
  Grid,
  Header,
  Button,
  Form,
  Divider,
  Message
} from 'semantic-ui-react'

import axios from 'axios'

import newOtp from 'otp-generators'

import { withRouter } from 'react-router-dom'
import { countrySelect } from './CountrySelect'

class MobilePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      log: '',
      identifier: '',
      token: '',
      userInfo: [],
      userID: '',
      merchantInfo: {},

      country: '',
      phoneNum: '',
      smsCode: '',
      sentCode: '',
      sentCodeSuccess: '',
      verified: '',

      saved: false,
      nextShow: false,
      loading: true,
      somethingWrong: false,
      saveLoading: false
    }
    this.getUserInfo = this.getUserInfo.bind(this)

    this.onCountry = this.onCountry.bind(this)
    this.onPhoneNum = this.onPhoneNum.bind(this)
    this.onSendSMS = this.onSendSMS.bind(this)
    this.onSMSCode = this.onSMSCode.bind(this)
    this.onVerifyCode = this.onVerifyCode.bind(this)

    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleNextClick = this.handleNextClick.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
    this.handleBackToStart = this.handleBackToStart.bind(this)
  }

  componentDidMount () {
    const self = this
    const log = localStorage.getItem('login')
    self.setState({ log: log })
    if (log === 'true') {
      self.getUserInfo()
    }
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getUserInfo () {
    const self = this
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    self.setState({
      identifier: identifier,
      token: token
    })

    axios(`${BACKEND_URL}/users/me`, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res)
        const userInfo = [res.data]
        if (userInfo === null) {
          self.setState({
            userInfo: [],
            userID: userInfo[0].id
          })
        } else if (userInfo.length > 0) {
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id
          })
        }

        const merchantInfo = userInfo[0].merchantInfo
        console.log(merchantInfo)
        if (merchantInfo !== null) {
          self.setState({ merchantInfo: merchantInfo })
        }

        if (
          merchantInfo.mobilephone !== null &&
          merchantInfo.mobilephone !== undefined
        ) {
          self.setState({
            country: merchantInfo.mobilephone.country,
            phoneNum: merchantInfo.mobilephone.phonenum,
            nextShow: true
          })
        }

        console.log(userInfo)
      })

      .catch(function (err) {
        // self.props.history.push('/loginfirst')
        console.log(err)
      })
  }

  onCountry (event, data) {
    this.setState({
      country: data.value,
      saved: false
    })
    if (this.state.merchantInfo.mobilephone !== undefined) {
      if (this.state.merchantInfo.mobilephone.country === data.value) {
        this.setState({
          verified: true
        })
      } else {
        this.setState({
          verified: ''
        })
      }
    }
  }

  onPhoneNum (event) {
    this.setState({
      phoneNum: event.target.value,
      saved: false
    })
    if (this.state.merchantInfo.mobilephone !== undefined) {
      if (this.state.merchantInfo.mobilephone.phonenum === event.target.value) {
        this.setState({
          verified: true
        })
      } else {
        this.setState({
          verified: ''
        })
      }
    }
  }

  onSendSMS () {
    const self = this
    const phoneNum = self.state.phoneNum
    const sentCode = newOtp.generate(4, {
      alphabets: false,
      upperCase: false,
      specialChar: false
    })

    this.setState({
      sentCode: sentCode
    })
    const token = this.state.token
    axios({
      method: 'post',
      url: `${BACKEND_URL}/sms/send`,
      data: {
        verificationCode: sentCode,
        PhoneNumberSet: [phoneNum]
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res)
        self.setState({ sentCodeSuccess: true })
      })
      .catch(function (err) {
        console.log(err)
        self.setState({
          sentCodeSuccess: false,
          somethingWrong:
            'SMS code not sent, please make sure you have entered a valid phone number. ' +
            err
        })
      })
  }

  onSMSCode (event) {
    this.setState({
      smsCode: event.target.value
    })
  }

  onVerifyCode () {
    const smsCode = this.state.smsCode
    const sentCode = this.state.sentCode
    console.log(smsCode, sentCode)
    if (smsCode === sentCode) {
      this.setState({
        verified: true,
        nextShow: true
      })
    } else if (smsCode !== sentCode) {
      this.setState({
        verified: false,
        nextShow: false
      })
    }
  }

  handleSaveClick () {
    const self = this
    const s = self.state
    const userID = this.state.userID
    const token = localStorage.getItem('token')

    if (s.verified === true) {
      const mobilePhone = {
        mobilephone: {
          country: s.country,
          phonenum: s.phoneNum
        }
      }

      self.setState({ saveLoading: true })

      const merchantInfo = Object.assign(s.merchantInfo, mobilePhone)
      console.log(merchantInfo)

      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: { merchantInfo },
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(function (response) {
          console.log(response)
          self.setState({
            saved: true,
            somethingWrong: false,
            saveLoading: false
          })
        })

        .catch(function (err) {
          self.setState({
            somethingWrong: 'something is wrong' + err,
            saveLoading: false
          })
        })
    } else {
      self.setState({
        somethingWrong:
          'You have not verified your mobile phone number, please verify before you save the information.'
      })
    }
  }

  handleBackClick () {
    const self = this
    const link = '/merchantVerify/card'
    window.location.assign('https://sheyoufashion.com/#' + link)
    self.props.history.push(link)
    window.location.reload(true)
  }

  handleNextClick () {
    const self = this
    const s = self.state
    const link = '/merchantVerify/id'
    const mobilephone = s.merchantInfo.mobilephone

    if (mobilephone !== undefined) {
      if (
        s.verified === true &&
        mobilephone.country === s.country &&
        mobilephone.phonenum === s.phoneNum
      ) {
        self.setState({
          saved: true
        })
      }
    }

    if (s.saved === true && s.verified === true) {
      window.location.assign('https://sheyoufashion.com/#' + link)
      self.props.history.push(link)
      window.location.reload(true)
    } else if (s.saved !== true) {
      self.setState({
        somethingWrong:
          'You have not saved your information, please click the save button before you proceed.'
      })
    } else if (s.verified !== true) {
      self.setState({
        somethingWrong:
          'You have not verified your new mobile phone number, please verify it before you proceed.'
      })
    }
  }

  handleBackToStart () {
    const self = this
    const link = '/merchantVerify/address'
    window.location.assign('https://sheyoufashion.com/#' + link)
    self.props.history.push(link)
    window.location.reload(true)
  }

  render () {
    const self = this
    const s = self.state
    const verified = s.verified
    const sentCodeSuccess = s.sentCodeSuccess
    const countries = countrySelect()

    return (
      <>
        <Grid.Row style={{ marginTop: 20 }} columns={1}>
          <Grid.Column width={16}>
            <Header>Phone Number</Header>
            <Divider />
            <Form>
              <Form.Group>
                <Form.Field>
                  <Form.Select
                    fluid
                    label='Country'
                    placeholder='Select Country'
                    options={countries}
                    style={{ width: 211 }}
                    onChange={this.onCountry}
                    value={this.state.country}
                  />
                </Form.Field>
                <Form.Field>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column width={10}>
                        <Form.Input
                          fluid
                          label='Phone Number'
                          placeholder='Enter Phone Number'
                          style={{ width: 300 }}
                          value={this.state.phoneNum}
                          onChange={this.onPhoneNum}
                        />
                      </Grid.Column>
                      <Grid.Column
                        width={6}
                        style={{ position: 'relative', top: 25 }}
                      >
                        <Button fluid onClick={this.onSendSMS}>
                          Send SMS Code
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                {verified !== false && (
                  <Form.Field>
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column width={10}>
                          <Form.Input
                            fluid
                            label='SMS Code'
                            placeholder='Enter SMS Code'
                            style={{ width: 202 }}
                            value={this.state.smsCode}
                            onChange={this.onSMSCode}
                          />
                        </Grid.Column>
                        <Grid.Column
                          width={6}
                          style={{ position: 'relative', top: 25 }}
                        >
                          <Button onClick={this.onVerifyCode}>Verify</Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field>
                )}
                {verified === false && (
                  <Form.Field>
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column width={10}>
                          <Form.Input
                            error='Failed: Your SMS code is incorrect.'
                            fluid
                            label='SMS Code'
                            placeholder='Enter SMS Code'
                            style={{ width: 202 }}
                            value={this.state.smsCode}
                            onChange={this.onSMSCode}
                          />
                        </Grid.Column>
                        <Grid.Column
                          width={6}
                          style={{ position: 'relative', top: 25 }}
                        >
                          <Button onClick={this.onVerifyCode}>Verify</Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field>
                )}
              </Form.Group>
            </Form>
            <Grid.Row>
              {sentCodeSuccess === true && (
                <Message
                  positive
                  header='Success'
                  content='You one-time code has been sent.'
                />
              )}
              {verified === true && (
                <Message
                  success
                  header='Verified'
                  content='Your phone number has been successfully verified.'
                />
              )}

              {this.state.somethingWrong !== false && (
                <Message warning>
                  <Message.Header>Somthing Went Wrong</Message.Header>
                  <p>{this.state.somethingWrong}</p>
                </Message>
              )}
              {this.state.saved === true && this.state.saveLoading === false && (
                <Message positive>
                  <Message.Header>Saved</Message.Header>
                  <p>You have saved your mobile phone</p>
                </Message>
              )}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column width={6}>
            <Button
              loading={this.state.saveLoading}
              onClick={this.handleSaveClick}
              fluid
            >
              SAVE
            </Button>
          </Grid.Column>
        </Grid.Row>
      </>
    )
  }
}

export default withRouter(MobilePhone)
