import React, { useState, useEffect } from 'react'
import 'semantic-ui-css/semantic.min.css'
import { Message, Icon, Grid } from 'semantic-ui-react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'
import timestamp from 'time-stamp'
import Nav from './component/nav/Nav'
import NavMobile from './component/nav/NavMobile'
import { isMobileOnly } from 'react-device-detect'
import PcPadVersion from './diffApp/PcPadVersion'
import MobileVersion from './diffApp/MobileVersion'
import ScrollToTop from './ScrollToTop'
import ErrorDisplay from './component/common/ErrorDisplay'
import PrivateRoute from './component/common/PrivateRoute'
import { useDispatch } from 'react-redux'
import { setUserInfo, setToken } from './redux/slices/userSlice'

const userLang = navigator.language || navigator.userLanguage

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      console.log('window.innerWidth', window.innerWidth)
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check and restore login state from localStorage
  useEffect(() => {
    const token = localStorage.getItem('token');
    const userInfo = localStorage.getItem('userInfo');
    const login = localStorage.getItem('login');

    if (token && login === 'true' && userInfo) {
      try {
        const parsedUserInfo = JSON.parse(userInfo);
        dispatch(setUserInfo(parsedUserInfo));
        dispatch(setToken(token));
      } catch (error) {
        console.error('Error restoring user session:', error);
      }
    }
  }, [dispatch]);

  return (
    <Router>
      <ParallaxProvider>
        <ScrollToTop />
        <ErrorDisplay />
        {isMobileOnly && <NavMobile windowWidth={windowWidth} />}
        {!isMobileOnly && windowWidth < 1090 && (
          <NavMobile windowWidth={windowWidth} />
        )}
        {!isMobileOnly && windowWidth >= 1090 && (
          <Nav windowWidth={windowWidth} />
        )}
        <Switch>
          {/* Public routes */}
          <Route exact path="/" component={!isMobileOnly ? PcPadVersion : MobileVersion} />
          <Route path="/signin" component={!isMobileOnly ? PcPadVersion : MobileVersion} />
          <Route path="/signup" component={!isMobileOnly ? PcPadVersion : MobileVersion} />
          
          {/* Protected routes */}
          <PrivateRoute path="/merchantpage" component={!isMobileOnly ? PcPadVersion : MobileVersion} />
          <PrivateRoute path="/mypage" component={!isMobileOnly ? PcPadVersion : MobileVersion} />
          <PrivateRoute path="/cart" component={!isMobileOnly ? PcPadVersion : MobileVersion} />
          <PrivateRoute path="/checkout" component={!isMobileOnly ? PcPadVersion : MobileVersion} />
          <PrivateRoute path="/message" component={!isMobileOnly ? PcPadVersion : MobileVersion} />
          <PrivateRoute path="/profile" component={!isMobileOnly ? PcPadVersion : MobileVersion} />
          
          {/* Default route */}
          <Route component={!isMobileOnly ? PcPadVersion : MobileVersion} />
        </Switch>
      </ParallaxProvider>
    </Router>
  );
}

export default App;
