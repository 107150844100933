import axios from 'axios';
import { BACKEND_URL } from './config';
import { navigate } from './navigation';

let store;
export const injectStore = _store => {
  store = _store;
};

const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
});

// Request interceptor to add auth token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Handle unauthorized using navigation utility
      const isMerchant = localStorage.getItem('merchantLogin') === 'true';
      
      // Clear all auth-related localStorage items
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('merchantLogin');
      localStorage.removeItem('login');
      localStorage.removeItem('loginTime');
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('identifier');
      localStorage.removeItem('password');
      
      // Use navigation utility for redirection
      navigate(isMerchant ? '/merchantsLog' : '/signin', { replace: true });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance; 