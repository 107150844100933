import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Image,
  Icon,
  Container,
  Button,
  Header,
  Segment,
  Popup,
  Dimmer,
  Menu,
  Label,
  Message,
  Divider
} from 'semantic-ui-react';
import CenteredBackground from '../utils/CenteredBackground';
import LoadingPage from '../utils/LoadingPage';
import axiosInstance from '../../utils/axiosInstance';
import {
  fetchCartItems,
  updateCartItems,
  selectBuyProducts,
  selectRentProducts,
  selectCartLoading,
  selectCartError,
  selectAddCartSuccess,
  selectGroupedBuyProducts,
  selectGroupedRentProducts,
  TRANSACTION_TYPES,
  PRODUCT_TYPES
} from '../../redux/slices/cartSlice';

const CartPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Local state
  const [activeItem, setActiveItem] = useState('Buy');
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [targetItem, setTargetItem] = useState(null);
  const [fee, setFee] = useState(0);

  // Get cart state from Redux
  const buyProducts = useSelector(selectBuyProducts);
  const rentProducts = useSelector(selectRentProducts);
  const groupedBuyProducts = useSelector(selectGroupedBuyProducts);
  const groupedRentProducts = useSelector(selectGroupedRentProducts);
  const loading = useSelector(selectCartLoading);
  const error = useSelector(selectCartError);
  const addCartSuccess = useSelector(selectAddCartSuccess);

  // Check authentication and fetch cart items
  useEffect(() => {
    const token = localStorage.getItem('token');
    const isLoggedIn = localStorage.getItem('login') === 'true';

    if (!token || !isLoggedIn) {
      history.push('/signin');
      return;
    }

    dispatch(fetchCartItems());
    console.log('Buy Products:', buyProducts);
    console.log('Rent Products:', rentProducts);

    // Check URL params for rent/buy view
    const ifRent = window.location.search.substring(6) === 'true';
    if (ifRent) {
      setActiveItem('Rent');
    }
  }, [dispatch, history]);

  // Calculate total fee when products change
  useEffect(() => {
    let newFee = 0;
    if (activeItem === 'Buy') {
      buyProducts.forEach(product => {
        if (product.stock !== '0') {
          newFee += product.price * product.quantity;
        }
      });
      localStorage.setItem('fee', newFee);
    } else {
      rentProducts.forEach(product => {
        if (product.stock !== '0' && product.rentTimeSelect) {
          newFee += product.rentTimeSelect.price * product.quantity;
        }
      });
      localStorage.setItem('rentFee', newFee);
    }
    setFee(newFee);
  }, [buyProducts, rentProducts, activeItem]);

  const handleSelectLess = async (event, data) => {
    event.preventDefault();
    const { id, type, category, quantity } = data;
    
    if (quantity === 1) {
      setTargetItem(data);
      setOpen(true);
      return;
    }
    
    try {
      await dispatch(updateCartItems({ 
        type: activeItem.toLowerCase(),
        id,
        type,
        category,
        action: 'decrease',
        isRent: activeItem === 'Rent'
      }));
    } catch (error) {
      console.error('Error decreasing quantity:', error);
      if (error.message === 'Authentication required') {
        history.push('/signin');
      }
    }
  };

  const handleSelectMore = async (event, data) => {
    event.preventDefault();
    const { id, type, category, quantity, stock } = data;
    
    if (quantity >= stock) {
      setTargetItem(data);
      setOpen1(true);
      return;
    }
    
    try {
      await dispatch(updateCartItems({
        type: activeItem.toLowerCase(),
        id,
        type,
        category,
        action: 'increase',
        isRent: activeItem === 'Rent'
      }));
    } catch (error) {
      console.error('Error increasing quantity:', error);
      if (error.message === 'Authentication required') {
        history.push('/signin');
      }
    }
  };

  const handleDelete = async (event, data) => {
    event.preventDefault();
    setTargetItem(data);
    setOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!targetItem) return;
    
    try {
      await dispatch(updateCartItems({
        type: activeItem.toLowerCase(),
        id: targetItem.id,
        type: targetItem.type,
        category: targetItem.is,
        action: 'delete',
        isRent: activeItem === 'Rent'
      }));
      setOpen(false);
      setTargetItem(null);
    } catch (error) {
      console.error('Error confirming delete:', error);
      if (error.message === 'Authentication required') {
        history.push('/signin');
      }
    }
  };

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    history.push(name === 'Rent' ? '/cart?rent=true' : '/cart');
  };

  const handleCheckout = async () => {
    const currentItems = activeItem === 'Buy' ? buyProducts : rentProducts;
    const userID = localStorage.getItem('userID');
    const token = localStorage.getItem('token');
    const identifier = localStorage.getItem('identifier');

    try {
      // Prepare order data
      const order = {
        data: {
          clothing: currentItems.filter(item => !item.is || item.is !== 'Accessaries'),
          accessory: currentItems.filter(item => item.is === 'Accessaries'),
          rentOrBuy: activeItem.toLowerCase(),
          price: fee,
          identifier: identifier
        }
      };

      // Get unique merchants
      const merchants = [...new Set(currentItems
        .filter(i => i.merchantId)
        .map(i => i.merchantId)
      )];

      // First clear the cart
      await axiosInstance.put('/users/' + userID, {
        cart: activeItem === 'Buy' ? [] : undefined,
        accessoryCart: activeItem === 'Buy' ? [] : undefined,
        bag: activeItem === 'Rent' ? [] : undefined,
        accessoryBag: activeItem === 'Rent' ? [] : undefined
      }, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });

      // Then create the order
      const response = await axiosInstance.post('/orders', {
        orders: order,
        users_permissions_user: userID,
        products: currentItems
          .filter(each => !each.is && each.type !== 'sale')
          .map(each => each.id),
        accessories: currentItems
          .filter(each => each.is === 'Accessaries')
          .map(each => each.id),
        second_hand_products: currentItems
          .filter(each => each.type === 'sale')
          .map(each => each.id),
        merchants: merchants
      }, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });

      if (response.status === 200) {
        localStorage.setItem('orderId', response.data.id);
        history.push('/checkout?orderid=' + response.data.id);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  const close = () => {
    setOpen(false);
    setTargetItem(null);
  };

  const close1 = () => {
    setOpen1(false);
    setTargetItem(null);
  };

  if (loading) {
    return (
      <LoadingPage 
        backgroundImg="https://res.cloudinary.com/sheyou/image/upload/v1641196507/IMG_0879_072c82fdcf.png"
        minHeight="50vh"
      />
    );
  }

  if (!localStorage.getItem('token') || localStorage.getItem('login') !== 'true') {
    return (
      <CenteredBackground
        backgroundImg="https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png"
        headerText="You Should login first"
        buttonText="Login"
        buttonLink="/signin"
      />
    );
  }

  const productsLength = buyProducts.length + rentProducts.length;

  if (!productsLength) {
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <CenteredBackground
              backgroundImg="https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png"
              headerText="You Don't Have Anything in Your Cart"
              buttonText="Start Shopping"
              buttonLink="/clothing"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  const style = {
    borderRadius: 12,
    opacity: 0.9,
    padding: '1.5em',
    color: 'orange'
  };

  // Add detailed logging in render
  if (activeItem === 'Buy') {
    console.log('Rendering Buy Products:', buyProducts.map(p => ({
      id: p.id,
      name: p.name,
      colors: p.colors,
      sizes: p.sizes,
      merchant: p.merchant,
      users_permissions_user: p.users_permissions_user
    })));
  } else {
    console.log('Rendering Rent Products:', rentProducts.map(p => ({
      id: p.id,
      name: p.name,
      colors: p.colors,
      sizes: p.sizes,
      merchant: p.merchant,
      users_permissions_user: p.users_permissions_user
    })));
  }

  return (
    <Grid stackable style={{ paddingTop: 10 }}>
      <Grid.Row>
        <Grid.Column>
          <Container>
            <br />
            {!addCartSuccess && (
              <Message negative>
                <Message.Header>Failure</Message.Header>
                <p>Something Went Wrong</p>
              </Message>
            )}

            <Grid divided='vertically' style={{ paddingBottom: 0, marginBottom: 0 }}>
              <Grid.Row columns={2} style={{ paddingTop: 0, marginTop: 0 }}>
                <Grid.Column width={16}>
                  <Header as='h2'>
                    <Icon name='shopping cart' />
                    <Header.Content>Shopping Cart</Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Menu tabular>
                    <Menu.Item
                      name='Buy'
                      active={activeItem === 'Buy'}
                      onClick={handleItemClick}
                    >
                      Buy
                      <Label color='teal' basic>
                        {buyProducts.length}
                      </Label>
                    </Menu.Item>
                    <Menu.Item
                      name='Rent'
                      active={activeItem === 'Rent'}
                      onClick={handleItemClick}
                    >
                      Rent
                      <Label color='teal' basic>
                        {rentProducts.length}
                      </Label>
                    </Menu.Item>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <div style={{ marginTop: '2em' }}>
              {activeItem === 'Buy' && buyProducts.length === 0 ? (
                <CenteredBackground
                  backgroundImg="https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png"
                  headerText="You Don't Have Anything to Buy"
                  buttonText="Start Shopping"
                  buttonLink="/clothing"
                  minHeight="50vh"
                />
              ) : activeItem === 'Rent' && rentProducts.length === 0 ? (
                <CenteredBackground
                  backgroundImg="https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png"
                  headerText="You Don't Have Anything to Rent"
                  buttonText="Start Shopping"
                  buttonLink="/clothing"
                  minHeight="50vh"
                />
              ) : (
                <>
                  {Object.entries(activeItem === 'Buy' ? groupedBuyProducts : groupedRentProducts).map(([merchantId, group]) => {
                    const merchant = group.products[0]?.users_permissions_user;
                    const merchantName = merchant?.username || 'Unknown Merchant';
                    const merchantInfo = merchant?.merchantInfo || {};
                    const storeName = merchantInfo.storeName || merchantName;
                    const initials = storeName.split(' ').map(word => word[0]).join('').toUpperCase();
                    
                    return (
                      <Segment key={merchantId} style={{ marginBottom: '2em' }}>
                        {/* Merchant Header */}
                        <Grid>
                          <Grid.Row verticalAlign="middle">
                           
                              <div 
                                style={{ 
                                  width: '45px', 
                                  height: '45px', 
                                  borderRadius: '50%',
                                  overflow: 'hidden',
                                  display: 'flex',
                                  marginLeft: '20px',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16),
                                  marginRight: '10px'
                                }}
                              >
                                {merchant?.headPortrait?.[0]?.url ? (
                                  <img 
                                    src={merchant.headPortrait[0].url}
                                    style={{ 
                                      width: '100%', 
                                      height: '100%', 
                                      objectFit: 'cover'
                                    }}
                                    alt={storeName}
                                  />
                                ) : (
                                  <span style={{
                                    color: 'white',
                                    fontSize: '18px',
                                    fontWeight: 'bold'
                                  }}>
                                    {initials}
                                  </span>
                                )}
                              </div>

                              <div>
                                <Header as='h3' style={{ margin: 0 }}>
                                  {storeName}
                                  {merchantInfo.storeDetail?.description && (
                                    <Header.Subheader>
                                      {merchantInfo.storeDetail.description}
                                    </Header.Subheader>
                                  )}
                                </Header>
                              </div>
                          
                          </Grid.Row>
                        </Grid>

                        <Divider />

                        {/* Product Headers */}
                        <Grid>
                          <Grid.Row columns={4} divided style={{ paddingTop: '1em', marginTop: 0, fontWeight: 'bold' }}>
                            <Grid.Column width={8}>Product</Grid.Column>
                            <Grid.Column width={4}>Quantity</Grid.Column>
                            <Grid.Column width={4}>
                              {activeItem === 'Buy' ? 'Price' : 'Info'}
                            </Grid.Column>
                          </Grid.Row>

                          {group.products.map(product => {
                            const isOutOfStock = product.stock === '0';
                            return (
                              <Grid.Row key={product.id} columns={9} style={isOutOfStock ? {opacity: 0.5} : {}}>
                                {/* Existing product row content */}
                                <Dimmer active={isOutOfStock} inverted>
                                  <Grid centered>
                                    <Grid.Row>
                                      <Header as='h3' color='grey'>
                                        Sorry, this item is out of stock
                                      </Header>
                                    </Grid.Row>
                                    <Grid.Row>
                                      <Icon
                                        id={product.id}
                                        size='large'
                                        type={product.type}
                                        category={product.is}
                                        onClick={handleDelete}
                                        circular
                                        color='grey'
                                        name='close'
                                      />
                                    </Grid.Row>
                                  </Grid>
                                </Dimmer>

                                <Grid.Column width={1}>
                                  {!isOutOfStock && (
                                    <Icon
                                      id={product.id}
                                      type={product.type}
                                      category={product.is}
                                      onClick={handleDelete}
                                      circular
                                      color='orange'
                                      name='close'
                                    />
                                  )}
                                </Grid.Column>

                                <Grid.Column width={3}>
                                  <Image size='medium' src={product.picture?.[0]?.url} />
                                </Grid.Column>

                                <Grid.Column width={4}>
                                  <Grid>
                                    <Grid.Row>{product.name || ''}</Grid.Row>
                                    <Grid.Row>Color: {Array.isArray(product.colors) ? product.colors[0]?.name : product.color}</Grid.Row>
                                    <Grid.Row>Size: {Array.isArray(product.sizes) ? product.sizes[0]?.name : product.size}</Grid.Row>
                                  </Grid>
                                </Grid.Column>

                                <Grid.Column width={1}>
                                  <Popup
                                    trigger={
                                      <Icon
                                        id={product.id}
                                        type={product.type}
                                        category={product.is}
                                        quantity={product.quantity}
                                        onClick={handleSelectLess}
                                        name='minus'
                                        color='orange'
                                      />
                                    }
                                    content={
                                      <Grid centered divided>
                                        <Grid.Column textAlign='center'>
                                          <p>Do you want to delete it?</p>
                                          <Button
                                            color='teal'
                                            onClick={handleConfirmDelete}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            color='teal'
                                            basic
                                            onClick={close}
                                          >
                                            No
                                          </Button>
                                        </Grid.Column>
                                      </Grid>
                                    }
                                    style={style}
                                    position='top center'
                                    open={targetItem?.id === product.id && open}
                                  />
                                </Grid.Column>

                                <Grid.Column width={1}>
                                  {product.quantity}
                                </Grid.Column>

                                <Grid.Column width={2}>
                                  <Popup
                                    trigger={
                                      <Icon
                                        id={product.id}
                                        type={product.type}
                                        category={product.is}
                                        quantity={product.quantity}
                                        stock={product.stock}
                                        onClick={handleSelectMore}
                                        name='plus'
                                        color='orange'
                                      />
                                    }
                                    content={
                                      <Grid centered divided>
                                        <Grid.Column textAlign='center'>
                                          <p>Not enough stock available</p>
                                          <Button color='teal' onClick={close1}>
                                            OK
                                          </Button>
                                        </Grid.Column>
                                      </Grid>
                                    }
                                    style={style}
                                    position='top center'
                                    open={targetItem?.id === product.id && open1}
                                  />
                                </Grid.Column>

                                {activeItem === 'Buy' ? (
                                  <Grid.Column>${product.price}</Grid.Column>
                                ) : (
                                  <Grid.Column width={4}>
                                    <Grid>
                                      <Grid.Row>
                                        {product.rentTimeSelect?.price && 
                                          `Rent Price: $ ${product.rentTimeSelect.price}`}
                                        {!product.rentTimeSelect?.price && 
                                          'Error! Contact Seller'}
                                      </Grid.Row>
                                      <Grid.Row>
                                        Start Date: {new Date(product.startDate).toLocaleDateString()}
                                      </Grid.Row>
                                      <Grid.Row>
                                        End Date: {new Date(product.endDate).toLocaleDateString()}
                                      </Grid.Row>
                                    </Grid>
                                  </Grid.Column>
                                )}
                              </Grid.Row>
                            );
                          })}
                        </Grid>

                        {/* Merchant Subtotal */}
                        <Divider />
                        <Grid>
                          <Grid.Row>
                            <Grid.Column floated='right' width={11}>
                              Merchant Subtotal
                            </Grid.Column>
                            <Grid.Column floated='left' width={2}>
                              ${group.products.reduce((sum, item) => 
                                item.stock !== '0' ? 
                                  sum + (activeItem === 'Buy' ? 
                                    item.price * item.quantity : 
                                    (item.rentTimeSelect?.price || 0) * item.quantity
                                  ) : sum, 0
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    );
                  })}

                  {/* Overall total */}
                  <Grid>
                    <Grid.Row>
                      <Grid.Column floated='right' width={11}>
                        Total
                      </Grid.Column>
                      <Grid.Column floated='left' width={2}>
                        <Header>${fee}</Header>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={5} />
                      <Grid.Column width={5}>
                        <Button
                          fluid
                          color='teal'
                          content='Check Out'
                          onClick={handleCheckout}
                        />
                      </Grid.Column>
                      <Grid.Column width={4} />
                    </Grid.Row>
                  </Grid>
                </>
              )}
            </div>
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CartPage;
