import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BACKEND_URL } from '../../utils/config';

// Validation helper
const validateProduct = (productData) => {
  const fieldErrors = {
    name: '',
    description: '',
    categories: '',
    genders: '',
    sizes: '',
    colors: '',
    price: '',
    rentPrices: '',
    pickUpAddress: '',
    rentDeposit: '',
    stock: '',
    picture: ''
  };

  // Required fields validation
  if (!productData.name?.trim()) fieldErrors.name = 'Product name is required';
  if (!productData.description?.trim()) fieldErrors.description = 'Product description is required';
  if (!productData.categories?.length) fieldErrors.categories = 'Category is required';
  if (!productData.genders?.length) fieldErrors.genders = 'Gender is required';
  if (!productData.sizes?.length) fieldErrors.sizes = 'Size is required';
  if (!productData.colors?.length) fieldErrors.colors = 'Color is required';
  if (!productData.stock || productData.stock <= 0) fieldErrors.stock = 'Stock must be greater than 0';
  if (!productData.picture?.length) fieldErrors.picture = 'At least one product photo is required';

  // Sale/Rent validation
  if (!productData.forSell && !productData.forRent) {
    fieldErrors.price = 'Product must be available for sale or rent';
  }

  // Sale price validation when for sale is checked
  if (productData.forSell && (!productData.price || productData.price <= 0)) {
    fieldErrors.price = 'Sale price must be greater than 0';
  }

  // Rent price validation when for rent is checked
  if (productData.forRent) {
    if (!productData.rentPriceAndTime?.length) {
      fieldErrors.rentPrices = 'At least one rental period is required';
    } else if (productData.rentPriceAndTime.some(p => !p.price || p.price <= 0)) {
      fieldErrors.rentPrices = 'All rental prices must be greater than 0';
    }
  }

  // Pickup address validation
  if (productData.openToPickUp && !productData.pickUpAddress) {
    fieldErrors.pickUpAddress = 'Pickup address is required when pickup is enabled';
  }

  // Security deposit validation
  if (productData.requireDeposit && (!productData.rentDeposit || productData.rentDeposit <= 0)) {
    fieldErrors.rentDeposit = 'Security deposit amount is required when deposit is enabled';
  }

  // Return both field-specific errors and a combined error message
  return {
    fieldErrors,
    hasErrors: Object.values(fieldErrors).some(error => error !== ''),
    errorMessage: Object.values(fieldErrors).filter(error => error !== '').join(', ')
  };
};

export const createProduct = createAsyncThunk(
  'newProduct/createProduct',
  async (productData, { rejectWithValue }) => {
    try {
      // Validate product data
      const validation = validateProduct(productData);
      if (validation.hasErrors) {
        return rejectWithValue({ message: validation.errorMessage, fieldErrors: validation.fieldErrors });
      }

      // Format rent prices if needed
      if (productData.forRent) {
        productData.rentPriceAndTime = productData.rentPrices.map(price => ({
          number: parseInt(price.number),
          option: price.option,
          price: parseInt(price.price)
        }));
      }

      // Clean up pickup address if pickup is not enabled
      if (!productData.openToPickUp) {
        productData.pickUpAddress = null;
      }

      // Clean up deposit if not required
      if (!productData.requireDeposit) {
        productData.rentDeposit = 0;
      }

      const response = await axios.post(`${BACKEND_URL}/products`, productData);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.response?.data?.message || 'Failed to create product',
        fieldErrors: {}
      });
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  success: false,
  filterOptions: {
    categories: [],
    genders: [],
    sizes: [],
    colors: [],
    occasions: [],
    styles: []
  },
  fieldErrors: {},
  formData: {
    name: '',
    description: '',
    price: 0,
    stock: 0,
    productType: 'designer',
    forSell: true,
    forRent: true,
    openToSwap: false,
    openToShare: false,
    openToPickUp: false,
    pickUpAddress: null,
    rentPriceAndTime: [],
    rentDeposit: 0,
    requireDeposit: false,
    chatBeforeRent: false,
    categories: [],
    genders: [],
    sizes: [],
    colors: [],
    occasions: [],
    styles: [],
    picture: [],
    detail: []
  }
};

const newProductSlice = createSlice({
  name: 'newProduct',
  initialState,
  reducers: {
    resetState: () => initialState,
    setFilterOptions: (state, action) => {
      state.filterOptions = action.payload;
    },
    clearFieldErrors: (state) => {
      state.fieldErrors = {};
    },
    clearSuccess: (state) => {
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
        state.fieldErrors = {};
      })
      .addCase(createProduct.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        state.fieldErrors = action.payload.fieldErrors || {};
      });
  }
});

export const { resetState, setFilterOptions, clearFieldErrors, clearSuccess } = newProductSlice.actions;

export default newProductSlice.reducer; 