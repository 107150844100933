import { BACKEND_URL } from '../../../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../../../utils/config';
import React from 'react'
import {
  Grid,
  Header,
  Button,
  Form,
  Divider,
  Message
} from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'

import checkCreditCard from 'check-credit-card'
import axios from 'axios'

import ChineseCreditCard from 'bankcardinfo'

class CreditCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      log: '',
      token: '',
      identifier: '',
      userInfo: [],
      userID: '',
      merchantInfo: {},

      bankName: '',
      cardHolder: '',
      cardNum: '',
      cardNumConfirm: '',
      cardVerify: '',
      verifyLoading: false,
      nextShow: false,
      cardNumMatch: true,

      loading: true,
      somethingWrong: false,
      saved: false,
      saveLoading: false
    }
    this.getUserInfo = this.getUserInfo.bind(this)
    this.onBankName = this.onBankName.bind(this)
    this.onCardHolder = this.onCardHolder.bind(this)
    this.onCardNum = this.onCardNum.bind(this)
    this.onCardNumConfirm = this.onCardNumConfirm.bind(this)
    this.onCardVerify = this.onCardVerify.bind(this)

    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleNextClick = this.handleNextClick.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
  }

  componentDidMount () {
    const self = this
    const log = localStorage.getItem('login')
    self.setState({ log: log })
    if (log === 'true') {
      self.getUserInfo()
    }
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getUserInfo () {
    const self = this
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    self.setState({
      identifier: identifier,
      token: token
    })

    axios(`${BACKEND_URL}/users/me`, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res)
        const userInfo = [res.data]
        if (userInfo === null) {
          self.setState({
            userInfo: [],
            userID: userInfo[0].id
          })
        } else {
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id
          })
        }

        const merchantInfo = userInfo[0].merchantInfo
        console.log(merchantInfo)
        if (merchantInfo !== null) {
          self.setState({ merchantInfo: merchantInfo })
        }

        if (
          merchantInfo.creditcard !== null &&
          merchantInfo.creditcard !== undefined
        ) {
          self.setState({
            bankName: merchantInfo.creditcard.bankname,
            cardHolder: merchantInfo.creditcard.cardholder,
            cardNum: merchantInfo.creditcard.cardnum,
            cardNumConfirm: merchantInfo.creditcard.cardnum,
            nextShow: true,
          })
        }

        console.log(userInfo)
      })

      .catch(function (err) {
        // self.props.history.push('/loginfirst')
        console.log(err)
      })
  }

  onBankName (event) {
    this.setState({
      bankName: event.target.value,
      saved: false
    })
  }

  onCardHolder (event) {
    this.setState({
      cardHolder: event.target.value,
      saved: false
    })
  }

  onCardNum (event) {
    this.setState({
      cardNum: event.target.value,
      saved: false
    })
    if (this.state.merchantInfo.creditcard !== undefined) {
      if (this.state.merchantInfo.creditcard.cardnum === event.target.value) {
        this.setState({
          cardVerify: true
        })
      } else {
        this.setState({
          cardVerify: false
        })
      }
    }
  }

  onCardNumConfirm (event) {
    this.setState({
      cardNumConfirm: event.target.value,
      saved: false
    })
  }

  onCardVerify (event) {
    const self = this
    self.setState({ verifyLoading: true })
    const cardNum = self.state.cardNum
    const cardNumConfirm = self.state.cardNumConfirm
    const verify1 = checkCreditCard.check(cardNum).valid
    const verify2 = ChineseCreditCard.getBankBin(cardNum)._rejectionHandler0
    if (cardNum === cardNumConfirm) {
      this.setState({
        cardNumMatch: true
      })
      if (verify1 === true || verify2 !== undefined) {
        self.setState({
          cardVerify: true,
          verifyLoading: false,
          nextShow: true
        })
      } else {
        self.setState({
          cardVerify: false,
          verifyLoading: false
        })
      }
    } else if (cardNum !== cardNumConfirm) {
      this.setState({
        cardNumMatch: false,
        verifyLoading: false
      })
    }
  }

  handleSaveClick () {
    const self = this
    const s = self.state
    const userID = s.userID
    const token = this.state.token

    if (s.cardVerify === true) {
      const creditCard = {
        creditcard: {
          bankname: s.bankName,
          cardholder: s.cardHolder,
          cardnum: s.cardNum
        }
      }

      self.setState({ saveLoading: true })

      const merchantInfo = Object.assign(s.merchantInfo, creditCard)
      console.log(merchantInfo)
      axios(`${BACKEND_URL}/users/` + userID, {
        method: 'put',
        data: {
          merchantInfo: merchantInfo
        },

        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(function (response) {
          console.log(response)
          self.setState({
            saved: true,
            somethingWrong: false,
            saveLoading: false
          })
        })

        .catch(function (err) {
          self.setState({
            somethingWrong: 'something is wrong' + err,
            saveLoading: false
          })
        })
    } else {
      self.setState({
        somethingWrong:
          'You have not verified your new credit card number, please verify before you save the information.'
      })
    }
  }

  handleNextClick () {
    const self = this
    const s = self.state
    // const link = '/merchantVerify/phone'
    const creditcard = s.merchantInfo.creditcard

    if (creditcard !== undefined) {
      if (
        s.cardVerify === true &&
        creditcard.cardholder === s.cardHolder &&
        creditcard.bankname === s.bankName
      ) {
        self.setState({
          saved: true
        })
      }
    }

    if (s.saved === true && s.cardVerify === true) {
      self.props.history.push('/merchantVerify/phone')
    } else if (s.saved !== true) {
      self.setState({
        somethingWrong:
          'You have not saved your information, please click the save button before you proceed.'
      })
    } else if (s.cardVerify !== true) {
      self.setState({
        somethingWrong:
          'You have not verified your new credit card number, please verify it before you proceed.'
      })
    }
  }

  handleBackClick () {
    const self = this
    // const link = '/merchantVerify/address'
    self.props.history.push('/merchantVerify/address')
  }

  render () {
    const self = this

    return (
      <>
          <Grid.Row columns={1} style={{marginTop:20}}>
            <Grid.Column width={16}>
              <Header>Bank Account</Header>
              <Divider />
              <Form>
                <Form.Group>
                  <Form.Field>
                    <Form.Input
                      required
                      label='Financial Instituition Name'
                      placeholder='Financial Instituition Name'
                      style={{ width: 300 }}
                      value={this.state.bankName}
                      onChange={this.onBankName}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      required
                      label='Bank Account Holder Name'
                      placeholder='Bank Account Holder Name'
                      style={{ width: 300 }}
                      value={this.state.cardHolder}
                      onChange={this.onCardHolder}
                    />
                  </Form.Field>
                </Form.Group>

                {self.state.cardNumMatch === true && (
                  <Form.Group>
                    <Form.Field>
                      <Form.Input
                        required
                        label='Credit Card Number'
                        placeholder='Credit Card Number'
                        style={{ width: 300 }}
                        value={this.state.cardNum}
                        onChange={this.onCardNum}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        required
                        label='Confirm Credit Card Number'
                        placeholder='Confirm Credit Card Number'
                        style={{ width: 300 }}
                        value={this.state.cardNumConfirm}
                        onChange={this.onCardNumConfirm}
                      />
                    </Form.Field>
                  </Form.Group>
                )}

                {self.state.cardNumMatch !== true && (
                  <Form.Group>
                    <Form.Field>
                      <Form.Input
                        required
                        label='Credit Card Number'
                        placeholder='Credit Card Number'
                        style={{ width: 300 }}
                        value={this.state.cardNum}
                        onChange={this.onCardNum}
                        error={{
                          content: 'Your card number must match',
                          pointing: 'below'
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column width={10}>
                            <Form.Input
                              required
                              label='Confirm Credit Card Number'
                              placeholder='Confirm Credit Card Number'
                              style={{ width: 300 }}
                              value={this.state.cardNumConfirm}
                              onChange={this.onCardNumConfirm}
                              error={{
                                content: 'Your card number must match',
                                pointing: 'below'
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <Button
                              loading={this.state.verifyLoading}
                              onClick={this.onCardVerify}
                            >
                              Verify
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form.Field>
                  </Form.Group>
                )}
              </Form>
              <Grid.Row >
                {this.state.cardVerify === true &&
                  this.state.verifyLoading === false && (
                    <Message positive >
                      <Message.Header>Verify Successful</Message.Header>
                      <p>
                        Your credit card number has been successfully verified
                      </p>
                    </Message>
                  )}
                {this.state.cardVerify === false &&
                  this.state.verifyLoading === false && (
                    <Message negative >
                      <Message.Header>Verify Failed</Message.Header>
                      <p>
                        Invalid: you have entered an invalid credit card number
                      </p>
                    </Message>
                  )}
                {this.state.saved === true && this.state.saveLoading === false && (
                  <Message positive>
                    <Message.Header>Saved</Message.Header>
                    <p>
                      You have saved your credit card, please confirm and then
                      click NEXT
                    </p>
                  </Message>
                )}
              </Grid.Row>
         

              <Grid.Row centered>
                {this.state.somethingWrong !== false && (
                  <>
                    <br />
                    <Message warning>
                      <Message.Header>Somthing Went Wrong</Message.Header>
                      <p>{this.state.somethingWrong}</p>
                    </Message>
                  </>
                )}
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column width={6}>
              <Button
                loading={self.state.saveLoading}
                fluid
                onClick={this.handleSaveClick}
              >
                SAVE
              </Button>
            </Grid.Column>
          </Grid.Row>
      
      </>
    )
  }
}
export default withRouter(CreditCard)
