import { BACKEND_URL } from '../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../utils/config';
import React from 'react'
import axios from 'axios'

import {
  Grid,
  Divider,
  Segment,
  Form,
  Modal,
  Button,
  Header,
  Icon,
  Message
} from 'semantic-ui-react'

// import { Parallax, } from 'react-parallax';
// import Nav from '../nav/Nav'
import { withRouter } from 'react-router-dom'
import DistPicker from 'react-distpicker'
import LoadingPhone from '../RedirectPages/LoadingPhone'

class Setting extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: [],
      open: false,
      addSA: false,
      FirstName: '',
      LastName: '',
      CreditCard: '',
      MMYY: '',
      CVV: '',
      addBISuccess: false,
      addSASuccess: false,

      firstNameSA: '',
      lastNameSA: '',
      country: '',
      state: '',
      city: '',
      phonenumber: '',
      detailAddress: '',
      district: '',
      loading: true,
      checkedBI: false,
      checkedSA: false
    }
    this.getUserInfo = this.getUserInfo.bind(this)
    this.addBI = this.addBI.bind(this)
    this.addSA = this.addSA.bind(this)
    this.onFirstName = this.onFirstName.bind(this)
    this.onLastName = this.onLastName.bind(this)
    this.onCreditCard = this.onCreditCard.bind(this)
    this.onMMYY = this.onMMYY.bind(this)
    this.onCVV = this.onCVV.bind(this)
    this.onBICancel = this.onBICancel.bind(this)
    this.onBISave = this.onBISave.bind(this)
    this.changeBI = this.changeBI.bind(this)

    this.onFirstNameSA = this.onFirstNameSA.bind(this)
    this.onLastNameSA = this.onLastNameSA.bind(this)
    // this.onCountry = this.onCountry.bind(this)
    // this.onstate = this.onstate.bind(this)
    // this.onCity = this.onCity.bind(this)
    this.districts = this.districts.bind(this)
    this.onPhonenumber = this.onPhonenumber.bind(this)
    this.onDetailAddress = this.onDetailAddress.bind(this)
    this.onSACancel = this.onSACancel.bind(this)
    this.onSASave = this.onSASave.bind(this)
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onOpen1 = this.onOpen1.bind(this)
    this.onClose1 = this.onClose1.bind(this)
    this.changeSA = this.changeSA.bind(this)
    this.defaultBI = this.defaultBI.bind(this)
    this.defaultSA = this.defaultSA.bind(this)
  }

  componentDidMount () {
    // const self = this

    this.getUserInfo()
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getUserInfo () {
    const self = this
    // const token=this.state.token
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    self.setState({
      identifier: identifier,
      token: token
    })

    axios(`${BACKEND_URL}/users/me`, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      const userInfo = [res.data]
      if (userInfo === null) {
        self.setState({
          userInfo: [],
          userID: userInfo[0].id
        })
      } else if (userInfo.length > 0) {
        if (userInfo[0].billinginformation === null) {
          userInfo[0].billinginformation = []
        }
        if (userInfo[0].shippingaddress === null) {
          userInfo[0].shippingaddress = []
        }
      }

      console.log(userInfo)
      self.setState({
        userInfo: userInfo,
        userID: userInfo[0].id,
        loading: false
      })
    })
  }

  onFirstName (event) {
    this.setState({
      FirstName: event.target.value
    })
  }

  onLastName (event) {
    this.setState({
      LastName: event.target.value
    })
  }

  onCreditCard (event) {
    this.setState({
      CreditCard: event.target.value
    })
  }

  onMMYY (event) {
    this.setState({
      MMYY: event.target.value
    })
  }

  onCVV (event) {
    this.setState({
      CVV: event.target.value
    })
  }

  addBI () {
    const self = this
    self.setState({ addBI: true })
  }

  addSA () {
    const self = this
    self.setState({ addSA: true })
  }

  onBISave (event) {
    const self = this
    const userinfo = self.state.userInfo
    var originalBI = userinfo[0].billinginformation
    if (originalBI === null || undefined) {
      originalBI = []
    }
    const checkBI = self.state.checkedBI
    if (checkBI === true) {
      originalBI.forEach(function (each) {
        if (each.checkedBI === true) {
          each.checkedBI = false
        }
      })
    }
    console.log(originalBI)
    var billinginformation = {
      FirstName: self.state.FirstName,
      LastName: self.state.LastName,
      CreditCard: self.state.CreditCard,
      MMYY: self.state.MMYY,
      CVV: self.state.CVV,
      id: event.target.id,
      checkedBI: self.state.checkedBI
    }

    originalBI.push(billinginformation)
    billinginformation = originalBI
    const id = self.state.userID
    const token = self.state.token

    axios(`${BACKEND_URL}/users/` + id, {
      method: 'put',
      data: {
        billinginformation: billinginformation
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      console.log(res)
      if (res.status === 200) {
        self.setState({
          addBISuccess: true,
          addBI: false
        })
        self.getUserInfo()
      }
    })
  }

  onBICancel () {
    const self = this
    self.setState({ addBI: false })
  }

  onFirstNameSA (event) {
    this.setState({
      firstNameSA: event.target.value
    })
  }

  onLastNameSA (event) {
    this.setState({
      lastNameSA: event.target.value
    })
  }

  districts (event) {
    console.log(event)
    this.setState({
      country: 'China',
      state: event.state,
      city: event.city,
      district: event.district
    })
  }

  onPhonenumber (event) {
    this.setState({
      phonenumber: event.target.value
    })
  }

  onDetailAddress (event) {
    this.setState({
      detailAddress: event.target.value
    })
  }

  onSASave (event) {
    const self = this
    const userinfo = self.state.userInfo
    var originalSA = userinfo[0].shippingaddress
    if (originalSA === null || undefined) {
      originalSA = []
    }

    const checkSA = self.state.checkedSA
    if (checkSA === true) {
      originalSA.forEach(function (each) {
        if (each.checkedSA === true) {
          each.checkedSA = false
        }
      })
    }

    var shippingaddress = {
      firstNameSA: self.state.firstNameSA,
      lastNameSA: self.state.lastNameSA,
      country: self.state.country,
      state: self.state.state,
      district: self.state.district,
      city: self.state.city,
      phonenumber: self.state.phonenumber,
      detailAddress: self.state.detailAddress,
      id: event.target.id,
      checkedSA: self.state.checkedSA
    }
    originalSA.push(shippingaddress)
    shippingaddress = originalSA
    const id = self.state.userID
    const token = self.state.token

    axios(`${BACKEND_URL}/users/` + id, {
      method: 'put',
      data: {
        shippingaddress: shippingaddress
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      console.log(res)
      if (res.status === 200) {
        self.setState({
          addSASuccess: true,
          addSA: false
        })
        self.getUserInfo()
      }
    })
  }

  onSACancel () {
    const self = this
    self.setState({ addSA: false })
  }

  changeBI (event, data) {
    var id = ''
    if (data.id !== null || data.id !== undefined) {
      id = data.id
    } else {
      id = event.id
    }
    console.log(event, data)
    const self = this
    const userinfo = this.state.userInfo
    const userId = userinfo[0].id
    const token = self.state.token
    this.setState({
      loading: true
    })

    const billinginformation = userinfo[0].billinginformation.map(function (
      each
    ) {
      var lol = each.checkedBI
      if (each.id === id) {
        each.FirstName = self.state.FirstName
        each.LastName = self.state.LastName
        each.CreditCard = self.state.CreditCard
        each.MMYY = self.state.MMYY
        each.CVV = self.state.CVV
        each.checkedBI = self.state.checkedBI
        if (self.state.checkedBI === true) {
          lol = false
        }
      } else {
        each.checkedBI = lol
      }
      return each
    })
    console.log(billinginformation)
    axios(`${BACKEND_URL}/users/` + userId, {
      method: 'put',
      data: {
        billinginformation: billinginformation
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res)
        if (res.status === 200) {
          self.setState({
            open1: false
          })
          self.getUserInfo()
        } else {
          alert('something went wrong:' + res)
        }
      })
      .catch(function (err) {
        alert(err)
      })
  }

  changeSA (event, data) {
    var id = ''
    if (data.id !== null || data.id !== undefined) {
      id = data.id
    } else {
      id = event.id
    }
    const self = this
    const userinfo = this.state.userInfo
    const userId = userinfo[0].id
    const token = self.state.token
    this.setState({
      loading: true
    })

    const shippingaddress = userinfo[0].shippingaddress.map(function (each) {
      var lol = each.checkedSA
      if (each.id === id) {
        each.firstNameSA = self.state.firstNameSA
        each.lastNameSA = self.state.lastNameSA
        each.country = self.state.country
        each.state = self.state.state
        each.city = self.state.city
        each.phonenumber = self.state.phonenumber
        each.detailAddress = self.state.detailAddress
        each.checkedSA = self.state.checkedSA
        each.district = self.state.district
        if (self.state.checkedSA === true) {
          lol = false
        }
      } else {
        each.checkedSA = lol
      }
      return each
    })
    axios(`${BACKEND_URL}/users/` + userId, {
      method: 'put',
      data: {
        shippingaddress: shippingaddress
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      console.log(res)
      if (res.status === 200) {
        self.setState({
          open: false
        })
        self.getUserInfo()
      } else {
        alert('something went wrong, please log in again :(')
        localStorage.setItem('login', false)
      }
    })
  }
  defaultBI (event, data) {
    var checked = ''
    if (data.checked !== null || data.checked !== undefined) {
      checked = data.checked
    } else {
      checked = event.checked
    }
    console.log(checked)
    if (checked) {
      this.setState({
        checkedBI: true
      })
    } else {
      this.setState({
        checkedBI: false
      })
    }
  }
  defaultSA (event, data) {
    var checked = ''
    if (data.checked !== null || data.checked !== undefined) {
      checked = data.checked
    } else {
      checked = event.checked
    }

    console.log(checked)
    if (checked) {
      this.setState({
        checkedSA: checked
      })
    } else {
      this.setState({
        checkedSA: checked
      })
    }
  }
  onOpen (event, data) {
    var id = ''
    if (data.id !== null || data.id !== undefined) {
      id = data.id
    } else {
      id = event.id
    }
    const self = this
    this.setState({
      open: true
    })
    const userinfo = this.state.userInfo
    userinfo[0].shippingaddress.forEach(function (each) {
      if (each.id === id) {
        self.setState({
          firstNameSA: each.firstNameSA,
          lastNameSA: each.lastNameSA,
          country: each.country,
          state: each.state,
          city: each.city,
          phonenumber: each.phonenumber,
          detailAddress: each.detailAddress
        })
      }
    })
  }
  onOpen1 (event, data) {
    var id = ''
    if (data.id !== null || data.id !== undefined) {
      id = data.id
    } else {
      id = event.id
    }
    const self = this
    this.setState({
      open1: true
    })
    const userinfo = this.state.userInfo
    userinfo[0].billinginformation.forEach(function (each) {
      if (each.id === id) {
        self.setState({
          FirstName: each.FirstName,
          LastName: each.LastName,
          CreditCard: each.CreditCard,
          MMYY: each.MMYY,
          CVV: each.CVV
        })
      }
    })
  }
  onClose () {
    this.setState({
      open: false
    })
  }
  onClose1 () {
    this.setState({
      open1: false
    })
  }

  toPrevios () {
    window.history.go(-1)
  }

  render () {
    const self = this
    const state = self.state
    console.log(state)

    const addSA = state.addSA
    const addSASuccess = state.addSASuccess
    const userInfo = state.userInfo

    const open = this.state.open

    return (
      <>
        {this.state.loading ? (
          <LoadingPhone />
        ) : (
          <Grid container>
            <Grid.Row></Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Icon name='chevron left' onClick={self.toPrevios} />
              </Grid.Column>
              <Grid.Column>
                <Header> Shipping Address</Header>
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column width={16}>
                {addSA === true && (
                  <Segment>
                    <Form>
                      <Form.Group widths='equal'>
                        <Form.Input
                          fluid
                          label='First name'
                          placeholder='First name'
                          onChange={this.onFirstNameSA}
                        />
                        <Form.Input
                          fluid
                          label='Last name'
                          placeholder='Last name'
                          onChange={this.onLastNameSA}
                        />
                      </Form.Group>
                    </Form>

                    <br />
                    <DistPicker onSelect={self.districts} />
                    <br />

                    <Form>
                      <Form.Input
                        label='Phonenumber'
                        type='phonenumber'
                        placeholder='Phonenumber'
                        onChange={this.onPhonenumber}
                      />

                      <Form.Input
                        label='Detail Address'
                        type='Detail Address'
                        placeholder='Detail Address'
                        onChange={this.onDetailAddress}
                      />

                      <Form.Checkbox
                        onChange={this.defaultSA}
                        label='save as default address'
                      />
                    </Form>

                    <br />

                    <Grid.Row centered>
                      <Button
                        color='orange'
                        id={this.state.userInfo[0].shippingaddress.length}
                        onClick={this.onSASave}
                      >
                        SAVE
                      </Button>
                      <Button onClick={this.onSACancel}>CANCEL</Button>
                    </Grid.Row>
                  </Segment>
                )}
                {addSASuccess === true && (
                  <Grid.Row>
                    <Message
                      success
                      header='Change Success'
                      content='You have successfully changed your shipping address'
                    />
                  </Grid.Row>
                )}
                {addSA === false && (
                  <Segment>
                    <Header as='h3' textAlign='center' onClick={this.addSA}>
                      +Add New Shipping Address
                    </Header>
                  </Segment>
                )}

                {userInfo.length !== 0 &&
                  userInfo[0].shippingaddress !== null &&
                  userInfo[0].shippingaddress.map(function (each) {
                    return (
                      <Segment>
                        <Grid>
                          <Grid.Row columns={2}>
                            <Grid.Column
                              width={13}
                              style={{
                                paddingTop: 5,
                                paddingBotton: 0,
                                marginTop: 0,
                                marginBottom: 0
                              }}
                            >
                              {each.checkedSA && (
                                <Header as='h5'>
                                  Default Shipping Address
                                </Header>
                              )}
                              {each.checkedSA !== true && (
                                <Header as='h5'>Shipping Address</Header>
                              )}
                            </Grid.Column>

                            <Grid.Column width={1}>
                              <Icon
                                id={each.id}
                                onClick={self.onOpen}
                                name='edit'
                                size='large'
                                color='orange'
                              />
                            </Grid.Column>
                          </Grid.Row>

                          <Modal open={open}>
                            <Modal.Header>New Address</Modal.Header>
                            <Modal.Content>
                              <Grid.Column width={16}>
                                <Form>
                                  <Form.Group widths='equal'>
                                    <Form.Input
                                      fluid
                                      label='First name'
                                      placeholder='First name'
                                      value={self.state.firstNameSA}
                                      onChange={self.onFirstNameSA}
                                    />
                                    <Form.Input
                                      fluid
                                      label='Last name'
                                      placeholder='Last name'
                                      value={self.state.lastNameSA}
                                      onChange={self.onLastNameSA}
                                    />
                                  </Form.Group>
                                </Form>
                                <br />
                                <DistPicker
                                  value={self.state.districts}
                                  onSelect={self.districts}
                                />
                                <br />
                                <Form>
                                  <Form.Input
                                    label='Phonenumber'
                                    type='phonenumber'
                                    placeholder='Phonenumber'
                                    value={self.state.phonenumber}
                                    onChange={self.onPhonenumber}
                                  />

                                  <Form.Input
                                    label='Detail Address'
                                    type='Detail Address'
                                    placeholder='Detail Address'
                                    value={self.state.detailAddress}
                                    onChange={self.onDetailAddress}
                                  />

                                  <Form.Checkbox
                                    onChange={self.defaultSA}
                                    checked={self.state.checkedSA}
                                    label='save as default address'
                                  />
                                </Form>
                              </Grid.Column>
                            </Modal.Content>
                            <Modal.Actions>
                              <Button color='black' onClick={self.onClose}>
                                Cancel
                              </Button>
                              <Button
                                content='Done'
                                labelPosition='right'
                                icon='checkmark'
                                id={each.id}
                                onClick={self.changeSA}
                                positive
                              />
                            </Modal.Actions>
                          </Modal>

                          <Grid.Row columns={2}>
                            <Grid.Column width={16}>
                              <Header as='h5' textAlign='left' color='grey'>
                                {each.firstNameSA + ', ' + each.lastNameSA}
                              </Header>
                            </Grid.Column>

                            <Grid.Column width={16}>
                              <Header as='h5' color='grey'>
                                {' '}
                                {each.city}
                              </Header>
                            </Grid.Column>

                            <Grid.Column width={16}>
                              <Header as='h5' textAlign='left' color='grey'>
                                {each.detailAddress}
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    )
                  })}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row></Grid.Row>
          </Grid>
        )}
      </>
    )
  }
}

export default withRouter(Setting)
