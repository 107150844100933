import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorWithTimeout } from '../../../redux/slices/errorSlice';

const UploadProductPhoto = ({ onUploadPic, error }) => {
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState([]);
  const [coverIndex, setCoverIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handlePhotoChange = (files) => {
    const validFiles = Array.from(files).filter(file => file.type.startsWith('image/'));

    if (validFiles.length !== files.length) {
      dispatch(showErrorWithTimeout('Please upload only image files', 'error'));
      return;
    }

    const newPhotos = [...photos, ...validFiles];
    setPhotos(newPhotos);
    onUploadPic({ photos: newPhotos, coverIndex: coverIndex || 0 });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      handlePhotoChange(files);
    }
  };

  const removePhoto = (index) => {
    const newPhotos = photos.filter((_, i) => i !== index);
    const newCoverIndex = index === coverIndex 
      ? Math.min(coverIndex, newPhotos.length - 1)
      : index < coverIndex 
        ? coverIndex - 1 
        : coverIndex;

    setPhotos(newPhotos);
    setCoverIndex(newCoverIndex);
    onUploadPic({ photos: newPhotos, coverIndex: newCoverIndex });
  };

  const setCoverPhoto = (index) => {
    setCoverIndex(index);
    onUploadPic({ photos, coverIndex: index });
  };

  return (
    <div className="space-y-4">
      <div
        className={`border-2 border-dashed rounded-lg p-6 transition-colors duration-200 
          ${isDragging ? 'border-brand-teal bg-brand-teal bg-opacity-5' : error ? 'border-red-300 bg-red-50' : 'border-gray-300 hover:border-brand-teal'}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="text-center">
          <label className="cursor-pointer block">
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={(e) => handlePhotoChange(e.target.files)}
              className="hidden"
            />
            <div className="space-y-2">
              <svg
                className={`mx-auto h-12 w-12 ${error ? 'text-red-400' : isDragging ? 'text-brand-teal' : 'text-gray-400'}`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="text-sm text-gray-600">
                <span className="font-medium text-brand-teal hover:text-brand-teal-dark">
                  Upload photos
                </span>
                {' or drag and drop'}
                <span className="text-red-500 ml-1">*</span>
              </div>
              <p className="text-xs text-gray-500">PNG, JPG up to 10MB</p>
            </div>
          </label>
        </div>
      </div>

      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}

      {photos.length > 0 && (
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">
            Product Photos ({photos.length})
            <span className="text-gray-500 ml-2">
              First photo will be the default cover photo
            </span>
          </h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {photos.map((photo, index) => (
              <div key={index} className="relative group">
                <div className="relative h-48 w-full rounded-lg bg-gray-100 overflow-hidden">
                  <img
                    src={URL.createObjectURL(photo)}
                    alt={`Preview ${index + 1}`}
                    className={`w-full h-full object-contain ${index === coverIndex ? 'ring-2 ring-brand-teal' : ''}`}
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity" />
                </div>
                <div className="absolute top-2 right-2 flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity">
                  {index !== coverIndex && (
                    <button
                      type="button"
                      onClick={() => setCoverPhoto(index)}
                      className="px-2 py-1 bg-white rounded-md text-sm text-gray-700 hover:text-brand-teal shadow-sm"
                      title="Set as cover photo"
                    >
                      Set as cover photo
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => removePhoto(index)}
                    className="p-1.5 bg-white rounded-full text-gray-700 hover:text-red-500"
                    title="Remove photo"
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                {index === coverIndex && (
                  <div className="absolute bottom-2 left-2 px-2 py-1 bg-brand-teal text-white text-xs rounded-full">
                    Cover Photo
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadProductPhoto;
