import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkSessionExpiration } from '../../redux/slices/userSlice';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // Check session only on route changes
    if (dispatch(checkSessionExpiration())) {
      history.push('/signin');
    }
  }, [dispatch, history]);

  return (
    <Route
      {...rest}
      render={props => {
        // Check session before rendering
        if (dispatch(checkSessionExpiration())) {
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          );
        }

        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute; 