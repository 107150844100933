import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
import { debounce } from 'lodash';
import { searchWithAlgolia, getAlgoliaIndex } from '../../redux/slices/productSlice';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useLocation, useHistory } from 'react-router-dom';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

const productsIndex = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_PRODUCTS_INDEX || 'products');

const SearchBox = ({ onSearch, filters, sortBy }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // Debounced function for suggestions
  const debouncedSearch = useRef(
    debounce(async (searchQuery) => {
      if (!searchQuery.trim()) {
        setSuggestions([]);
        return;
      }

      setIsLoading(true);
      try {
        const suggestionsIndex = searchClient.initIndex('products_query_suggestions');
        const { hits } = await suggestionsIndex.search(searchQuery);
        setSuggestions(hits);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      } finally {
        setIsLoading(false);
      }
    }, 200)
  ).current;

  // Function to perform the actual search
  const performSearch = async (searchQuery) => {
    try {
      setIsLoading(true);
      
      // Update URL with search query
      const params = new URLSearchParams(location.search);
      if (searchQuery) {
        params.set('q', searchQuery);
      } else {
        params.delete('q');
      }
      
      history.push({
        pathname: location.pathname,
        search: params.toString()
      });

     

    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setIsLoading(false);
      setSuggestions([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    performSearch(query);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.query);
    performSearch(suggestion.query);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Add initialization effect
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlQuery = params.get('q') || '';
    if (urlQuery !== query) {
      setQuery(urlQuery);
      performSearch(urlQuery);
    }
  }, [location.search]);

  return (
    <div className="relative">
      <form onSubmit={handleSubmit} className="relative">
        <input
          type="text"
          value={query}
          onChange={(e) => {
            const newQuery = e.target.value;
            setQuery(newQuery);
            debouncedSearch(newQuery);
          }}
          placeholder="Search products..."
          className="w-full px-4 py-2 pr-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal"
        />
        <button
          type="submit"
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-brand-teal"
          disabled={isLoading}
        >
          <MagnifyingGlassIcon className="w-5 h-5" />
        </button>
      </form>

      {/* Loading indicator */}
      {isLoading && (
        <div className="absolute right-12 top-1/2 transform -translate-y-1/2">
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-brand-teal"></div>
        </div>
      )}

      {/* Suggestions dropdown */}
      {suggestions.length > 0 && query && (
        <div className="absolute z-50 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto">
          {suggestions.map((suggestion) => (
            <div
              key={suggestion.objectID}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.query}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBox; 