import { useGetCurrentUserQuery } from '../redux/api/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo, handleUnauthorized } from '../redux/slices/userSlice';
import { useEffect } from 'react';

export const useUser = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.user);

  // Only fetch if we don't have user info in Redux store
  const {
    data: user,
    isLoading,
    error,
    refetch
  } = useGetCurrentUserQuery(undefined, {
    skip: !!userInfo || !localStorage.getItem('token'),
    refetchOnMountOrArgChange: false
  });

  useEffect(() => {
    if (error?.status === 401) {
      console.log('Unauthorized error in useUser hook');
      dispatch(handleUnauthorized());
    } else if (user) {
      dispatch(setUserInfo(user));
    }
  }, [user, error, dispatch]);

  // Return cached user info from Redux store if available
  return {
    user: userInfo || user,
    isLoading: isLoading && !userInfo,
    error,
    refetch
  };
}; 