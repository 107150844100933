import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

// Helper function to generate chat ID
const getChatId = (senderId, receiverId) => [senderId, receiverId].sort().join('-');

const initialState = {
  chatList: [],
  messages: {},
  activeChat: null,
  typingUsers: {},
  unreadCount: 0,
  loading: false,
  error: null
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setChatList: (state, action) => {
      state.chatList = action.payload;
      state.loading = false;
      state.error = null;
    },
    setActiveChat: (state, action) => {
      state.activeChat = action.payload;
    },
    setMessages: (state, action) => {
      const { chatId, messages } = action.payload;
      state.messages[chatId] = messages;
      state.loading = false;
      state.error = null;
    },
    addMessage: (state, action) => {
      const message = action.payload;
      const chatId = getChatId(
        message.messageSender[0]?.id,
        message.messageReceiver[0]?.id
      );

      if (!state.messages[chatId]) {
        state.messages[chatId] = [];
      }

      state.messages[chatId].push(message);
      state.unreadCount += 1;

      // Update chat list with latest message
      const otherUserId = message.isSender ?
        message.messageReceiver[0]?.id :
        message.messageSender[0]?.id;

      const existingChatIndex = state.chatList.findIndex(chat =>
        chat.id === otherUserId
      );

      if (existingChatIndex !== -1) {
        state.chatList[existingChatIndex] = {
          ...state.chatList[existingChatIndex],
          lastMessage: message.messageText,
          lastMessageDate: message.created_at
        };
        // Re-sort chat list
        state.chatList.sort((a, b) =>
          new Date(b.lastMessageDate) - new Date(a.lastMessageDate)
        );
      }
    },
    setTypingStatus: (state, action) => {
      const { userId, isTyping } = action.payload;
      state.typingUsers[userId] = isTyping;
    },
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload;
    },
    incrementUnreadCount: (state) => {
      state.unreadCount += 1;
    },
    clearUnreadCount: (state) => {
      state.unreadCount = 0;
    },
    clearChat: () => initialState
  }
});

export const {
  setLoading,
  setError,
  setChatList,
  setActiveChat,
  setMessages,
  addMessage,
  setTypingStatus,
  setUnreadCount,
  incrementUnreadCount,
  clearUnreadCount,
  clearChat
} = chatSlice.actions;

// Thunk to fetch chat list
export const fetchChatList = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await axiosInstance.get('/messages/chat');
    dispatch(setChatList(response.data));
  } catch (error) {
    dispatch(setError(error.response?.data?.message || error.message));
  }
};

// Thunk to fetch messages for a specific chat
export const fetchMessages = (senderId, receiverId) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await axiosInstance.get(`/messages/conversation/${senderId}/${receiverId}`);
    const chatId = getChatId(senderId, receiverId);
    const messages = response.data.map(msg => ({
      ...msg,
      isSender: msg.messageSender[0]?.id === senderId
    }));
    dispatch(setMessages({ chatId, messages }));
  } catch (error) {
    dispatch(setError(error.response?.data?.message || error.message));
  }
};

// Thunk to send a message
export const sendMessage = (receiverId, text, productId = null) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const messageData = {
      messageText: text,
      messageReceiver: [receiverId]
    };

    if (productId) {
      messageData.product = productId;
    }

    const response = await axiosInstance.post('/messages', messageData);
    dispatch(addMessage(response.data));
  } catch (error) {
    dispatch(setError(error.response?.data?.message || error.message));
  }
};

// Thunk to fetch unread message count
export const fetchUnreadCount = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const response = await axiosInstance.get('/messages/unread/count');
    dispatch(setUnreadCount(response.data.count));
  } catch (error) {
    dispatch(setError(error.response?.data?.message || error.message));
  }
};

// Thunk to mark messages as read
export const markMessagesAsRead = () => async (dispatch) => {
  try {
    await axiosInstance.put('/messages/mark-read');
    dispatch(clearUnreadCount());
  } catch (error) {
    dispatch(setError(error.response?.data?.message || error.message));
  }
};

export default chatSlice.reducer; 