import { BACKEND_URL } from '../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../utils/config';
import React from 'react'
import {
  Grid,
  Form,
  Button,
  Message,
  Container,
  Segment,
  Header,
  Icon
} from 'semantic-ui-react'

import { Parallax } from 'react-parallax'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import ReactCodeInput from 'react-verification-code-input'
var jwt = require('jsonwebtoken')

class EmailConfirmation extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      verificationCode: '',
      code: '',
      codeCorrect: '',
      EmailChangeSuccess: '',
      email: '',
      userInfo: '',
      loadingm: false
    }
    // this.onHandleNewPassword=this.onHandleNewPassword.bind(this)
    // this.onHandleConfirm=this.onHandleConfirm.bind(this)
    this.onHandleCode = this.onHandleCode.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
  }

  componentDidMount () {
    const self = this

    var userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    const token = localStorage.getItem('token')
    const identifier = localStorage.getItem('identifier')
    const email = localStorage.getItem('email')

    if (userInfo !== null && userInfo !== undefined && userInfo !== '') {
      self.setState({
        id: userInfo[0].id
      })
    }

    self.setState({
      email: email,
      token: token,
      identifier: identifier
    })

    const verificationCode = localStorage.getItem('verificationCode')
    self.setState({
      verificationCode: verificationCode
    })

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  onHandleCode (event) {
    this.setState({
      code: event
    })
  }

  onHandleSubmit () {
    const self = this
    const code = self.state.code
    const verificationCode = self.state.verificationCode
    const id = localStorage.getItem('id')
    const token = localStorage.getItem('token')

    var isClosetUser = self.props.location.search
    // const isClosetUserParam = urlParams.get('isClosetUser');
    isClosetUser = isClosetUser ? isClosetUser.split('=')[1] : null
    console.log(isClosetUser)
    var decoded = jwt.verify(verificationCode, 'sheyoufashion')
    decoded = decoded.data
    console.log(decoded)
    self.setState({
      loadingm: true
    })

    if (code === decoded) {
      if (self.props.isMerchant) {
        axios(`${BACKEND_URL}/users/` + id, {
          method: 'put',
          data: {
            merchantConfirmed: true
          }
        })
          .then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                EmailChangeSuccess: true,
                loadingm: false
              })
              window.setTimeout(() => {
                self.props.history.push('/merchantsLog')
              }, 1000)
              localStorage.setItem('toLog', true)
            } else {
              self.setState({
                EmailChangeSuccess: false,
                loadingm: false
              })
            }
          })
          .catch(function (err) {
            console.log(err)
            self.setState({
              EmailChangeSuccess: false,
              loadingm: false
            })
          })
      } else {
        axios(`${BACKEND_URL}/users/` + id, {
          method: 'put',
          data: {
            confirmed: true
          }
        })
          .then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                EmailChangeSuccess: true,
                loadingm: false
              })
              window.setTimeout(() => {
                self.props.history.push('/signin')
              }, 1000)
              localStorage.setItem('toLog', true)
            } else {
              self.setState({
                EmailChangeSuccess: false,
                loadingm: false
              })
            }
          })
          .catch(function (err) {
            console.log(err)
            self.setState({
              EmailChangeSuccess: false,
              loadingm: false
            })
          })
      }
      if (isClosetUser === 'true') {
        axios(`${BACKEND_URL}/users/` + id, {
          method: 'put',
          data: {
            confirmed: true,
            isClosetUser: true
          }
        })
          .then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                EmailChangeSuccess: true,
                loadingm: false
              })
           
                window.location.href = `https://susucloset.com/#/?user=${id}&success=true&token=${token}`
             
              localStorage.setItem('toLog', true)
            } else {
              self.setState({
                EmailChangeSuccess: false,
                loadingm: false
              })
            }
          })
          .catch(function (err) {
            console.log(err)
            self.setState({
              EmailChangeSuccess: false,
              loadingm: false
            })
          })
      }
    } else {
      self.setState({
        codeCorrect: false,
        loadingm: false
      })
    }
  }

  render () {
    const EmailChangeSuccess = this.state.EmailChangeSuccess
    const codeCorrect = this.state.codeCorrect
    const loading = this.state.loading
    const loadingm = this.state.loadingm
    return (
      <div>
        {/* <Nav /> */}

        {loading === true && (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        )}
        {loading === false && (
          <Parallax className='gradient_limeade' strength={400}>
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Container>
                <Segment>
                  <Form stackable>
                    <Message info>
                      <Message.Header>
                        We have sent a verification code in your email
                      </Message.Header>
                    </Message>
                    <Grid centered columns={2}>
                      <Grid.Column>
                        <ReactCodeInput onChange={this.onHandleCode} />
                      </Grid.Column>
                    </Grid>

                    {codeCorrect === false && (
                      <Message negative>
                        <Message.Header>
                          Your Verification Code Is Not Correct
                        </Message.Header>
                      </Message>
                    )}
                    {EmailChangeSuccess === true && (
                      <Message positive>
                        <Message.Header>
                          Your Have Verified Email Successfully, Please Login
                          Now
                        </Message.Header>
                      </Message>
                    )}
                    {EmailChangeSuccess === false && (
                      <Message warning>
                        <Message.Header>Something Went wrong</Message.Header>
                      </Message>
                    )}
                  </Form>

                  <br />
                  {loadingm === true && (
                    <Header size='big'>
                      <Icon
                        loading
                        name='asterisk'
                        size='huge'
                        color='yellow'
                      />
                      Loading...
                    </Header>
                  )}
                  <br />

                  <Grid columns={4} centered>
                    <Grid.Column width={4}>
                      <Button
                        onClick={this.onHandleSubmit}
                        type='submit'
                        fluid
                        size='large'
                        floated='right'
                        content='Continue'
                        color='orange'
                      />
                    </Grid.Column>
                  </Grid>
                </Segment>

                {/* {sentEmail === true &&
                            <Segment>

                                <Grid centered colums={2}>
                                    <Grid.Row></Grid.Row>
                                    <Grid.Row></Grid.Row>
                                    <Grid.Row>
                                        <Header size='huge' color='orange'>
                                            <Icon name='smile outline' />
                                            <Header.Content>Rest Link Has Sent To Your Email</Header.Content>

                                        </Header>
                                    </Grid.Row>

                                    <Grid.Row></Grid.Row>
                                    <Grid.Row></Grid.Row>
                                </Grid>
                            </Segment>

                        }

                        {sentEmail === false &&
                            <Segment>

                                <Grid centered colums={2}>
                                    <Grid.Row></Grid.Row>
                                    <Grid.Row></Grid.Row>
                                    <Grid.Row>
                                        <Header size='huge' color='orange'>
                                            <Icon name='exclamation triangle' />
                                            <Header.Content>Something Went Wrong, Please Try Again</Header.Content>

                                        </Header>
                                    </Grid.Row>

                                    <Grid.Row></Grid.Row>
                                    <Grid.Row></Grid.Row>
                                </Grid>
                            </Segment>
                        } */}
              </Container>
            </div>

            <div style={{ height: '300px' }} />
          </Parallax>
        )}
      </div>
    )
  }
}

export default withRouter(EmailConfirmation)
