import { BACKEND_URL } from '../../utils/config';
import { useState, useEffect, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toggleLike, selectLikeStatus } from '../../redux/slices/likeSlice';
import { HeartIcon } from '@heroicons/react/24/solid';
import { HeartIcon as HeartOutlineIcon } from '@heroicons/react/24/outline';

const CardTemplete = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  
  const { products, loading: productsLoading } = useSelector(state => state.products);
  const { isLoggedIn, userInfo } = useSelector(state => state.user);
  const likeStatuses = useSelector(state => state.likes?.likeStatuses || {});

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (loading === true) {
        setLoading(false);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const ifHearted = (product) => {
    if (!product || !product.id) return '';
    return likeStatuses[product.id]?.isLiked ? 'yellow' : '';
  };

  const onHandleClick = (event) => {
    const productId = event.target.id;
    event.preventDefault();
    history.push('/product?id=' + productId);
  };

  const heartTag = (hearted, productId) => {
    if (!isLoggedIn) {
      alert('Please log in to like products');
      return;
    }
    dispatch(toggleLike(productId));
  };

  if (!products) {
    return null;
  }

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {productsLoading ? (
        Array(8).fill(0).map((_, index) => (
          <div 
            key={`skeleton-${index}`}
            className="relative bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
          >
            <div className="animate-pulse">
              <div className="aspect-[3/4] w-full bg-gray-200" />
              <div className="p-3 bg-white">
                <div className="h-[20px] bg-gray-200 rounded w-3/4 mb-1" />
                <div className="space-y-1 mb-1">
                  <div className="h-[16px] bg-gray-200 rounded w-full" />
                  <div className="h-[16px] bg-gray-200 rounded w-2/3" />
                </div>
                <div className="h-[20px] bg-gray-200 rounded w-1/3 mb-1" />
                <div className="flex items-center mt-1">
                  <div className="h-4 w-4 bg-gray-200 rounded-full mr-1" />
                  <div className="h-[16px] bg-gray-200 rounded w-12" />
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        products.map((product, index) => {
          const heart = ifHearted(product);
          const isLastElement = index === products.length - 1;
          
          if (product.stock === '0') return null;
          
          const likeCount = product.likes_count || product.being_liked_by?.length || 0;

          return (
            <div 
              key={product.id} 
              ref={isLastElement ? ref : null}
              className="relative bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
            >
              {isLoggedIn && (
                <button
                  onClick={() => heartTag(heart, product.id)}
                  className={`absolute top-2 right-2 z-10 w-8 h-8 flex items-center justify-center rounded-full transition-colors ${
                    heart ? 'bg-brand-orange text-white' : 'bg-white/80 text-gray-400'
                  } hover:bg-brand-orange hover:text-white`}
                >
                  {heart ? (
                    <HeartIcon className="w-5 h-5" />
                  ) : (
                    <HeartOutlineIcon className="w-5 h-5" />
                  )}
                </button>
              )}
              <div 
                className="relative h-64 w-full cursor-pointer group"
                onClick={onHandleClick}
                id={product.id}
              >
                <img
                  id={product.id}
                  src={
                    (product.picture && product.picture.length > 0) 
                      ? (typeof product.picture[0] === 'string' ? product.picture[0] : product.picture[0].url) 
                      : ''
                  }
                  alt={product.name}
                  className="object-contain w-full h-full transition-transform duration-300 group-hover:scale-105"
                />
              </div>
              <div 
                className="p-3 cursor-pointer bg-white"
                onClick={onHandleClick}
                id={product.id}
              >
                <h3 className="text-sm font-semibold mb-1 text-gray-900 line-clamp-1" id={product.id}>
                  {product.name}
                </h3>
                {product.description !== null && (
                  <p className="text-xs text-gray-600 mb-1 line-clamp-2" id={product.id}>
                    {product.description.substring(0, 50) + "..."}
                  </p>
                )}
                <p className="text-brand-teal font-bold text-sm" id={product.id}>
                  {product.price} USD
                </p>
                <div className="mt-1 flex items-center text-xs text-gray-500">
                  {heart ? (
                    <HeartIcon className="w-4 h-4 text-brand-orange mr-1" />
                  ) : (
                    <HeartOutlineIcon className="w-4 h-4 text-gray-400 mr-1" />
                  )}
                  <span>{likeCount} likes</span>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
});

export default CardTemplete;