import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../utils/config';
import { handleUnauthorized } from '../slices/userSlice';

// Create a custom base query with error handling
const baseQueryWithReauth = fetchBaseQuery({
  baseUrl: BACKEND_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
  const result = await baseQueryWithReauth(args, api, extraOptions);
  
  // Handle 401 Unauthorized errors
  if (result.error && result.error.status === 401) {
    console.log('401 error detected in API call, dispatching handleUnauthorized');
    api.dispatch(handleUnauthorized());
  }
  
  return result;
};

// Function to handle webhook updates
export const handleUserWebhookUpdate = (store) => {
  // Invalidate the User tag to trigger a refetch
  store.dispatch(apiSlice.util.invalidateTags(['User']));
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['User', 'Product', 'Cart', 'Order'],
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => '/users/me',
      providesTags: ['User'],
      transformResponse: (response) => {
        return {
          ...response,
          cart: response.cart || [],
          accessoryCart: response.accessoryCart || [],
          bag: response.bag || [],
          accessoryBag: response.accessoryBag || [],
          membership: response.membership || { isAMember: false }
        };
      },
      // Only fetch once and rely on invalidation through webhooks
      keepUnusedDataFor: 24 * 3600,
      refetchOnMountOrArgChange: false,
      refetchOnFocus: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }),
    updateUserAddress: builder.mutation({
      query: ({ userId, addresses }) => ({
        url: `/users/${userId}`,
        method: 'PUT',
        body: { shippingaddress: addresses }
      }),
      invalidatesTags: ['User']
    }),
    getUser: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: (result, error, id) => [{ type: 'User', id }],
    }),
    getProduct: builder.query({
      query: (id) => `/products?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'Product', id }],
    }),
    getCartProducts: builder.query({
      query: (ids) => ({
        url: '/products',
        params: { id_in: ids },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Product', id })),
              { type: 'Cart', id: 'LIST' },
            ]
          : [{ type: 'Cart', id: 'LIST' }],
    }),
    createOrder: builder.mutation({
      query: (orderData) => ({
        url: '/orders',
        method: 'POST',
        body: orderData,
      }),
      invalidatesTags: ['Cart'],
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/products/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Product'],
    }),
    deleteProducts: builder.mutation({
      query: (ids) => ({
        url: '/products/bulk-delete',
        method: 'POST',
        body: { ids },
      }),
      invalidatesTags: ['Product'],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetUserQuery,
  useGetProductQuery,
  useGetCartProductsQuery,
  useCreateOrderMutation,
  useDeleteProductMutation,
  useDeleteProductsMutation,
  useUpdateUserAddressMutation,
} = apiSlice; 