import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { hideError } from '../../redux/slices/errorSlice';

const ErrorDisplay = () => {
  const { error, severity, isVisible } = useSelector((state) => state.error);
  const dispatch = useDispatch();

  if (!isVisible || !error) {
    return null;
  }

  const handleDismiss = () => {
    dispatch(hideError());
  };

  // Map severity to Semantic UI Message props
  const messageProps = {
    error: severity === 'error',
    warning: severity === 'warning',
    info: severity === 'info',
    success: severity === 'success',
    onDismiss: handleDismiss,
  };

  return (
    <div style={{ 
      position: 'fixed', 
      top: '20px', 
      left: '50%', 
      transform: 'translateX(-50%)',
      zIndex: 1000,
      width: '80%',
      maxWidth: '500px'
    }}>
      <Message {...messageProps}>
        <Message.Header>
          {severity.charAt(0).toUpperCase() + severity.slice(1)}
        </Message.Header>
        <p>{error}</p>
      </Message>
    </div>
  );
};

export default ErrorDisplay; 