import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { BACKEND_URL } from '../../../utils/config';
import { createProduct } from '../../../redux/slices/productSlice';
import { showErrorWithTimeout } from '../../../redux/slices/errorSlice';
import { useGetCurrentUserQuery, useUpdateUserAddressMutation } from '../../../redux/api/apiSlice';
import UploadProductPhoto from './UploadProductPhoto';
import FormInput from '../../common/FormInput';
import AddressInput from '../../common/AddressInput';

const NewProduct = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: currentUser, refetch: refetchUser } = useGetCurrentUserQuery();
  const [updateUserAddress] = useUpdateUserAddressMutation();
  const { createProductLoading, createProductError } = useSelector(state => state.products);

  const [newAddress, setNewAddress] = useState(null);
  const [isSavingAddress, setIsSavingAddress] = useState(false);

  useEffect(() => {
    // Refetch user data when component mounts
    refetchUser();
  }, [refetchUser]);

  const [fieldErrors, setFieldErrors] = useState({
    name: '',
    description: '',
    categories: '',
    genders: '',
    sizes: '',
    colors: '',
    price: '',
    rentPrices: '',
    pickUpAddress: '',
    rentDeposit: '',
    stock: '',
    photos: '',
    productType: ''
  });

  const [formData, setFormData] = useState({
      name: '',
    description: '',
    price: 0,
      stock: 0,
    productType: '',
    forSale: true,
    forRent: true,
    openToSwap: false,
    openToShare: false,
    openToPickUp: false,
    pickUpAddress: null,
    selectedAddressId: '',
    rentPriceAndTime: [],
    rentDeposit: 0,
    requireDeposit: false,
    chatBeforeRent: false,
    hasCustomRules: false,
    customRules: '',
    categories: [],
    genders: [],
      sizes: [],
      colors: [],
    occasions: [],
      styles: [],
    photos: [],
    coverImageIndex: 0
  });

  const [rentPrices, setRentPrices] = useState([
    { number: 0, option: '', price: 0 }
  ]);

  const [filterOptions, setFilterOptions] = useState({
    categories: [],
    genders: [],
    sizes: [],
    colors: [],
    occasions: [],
    styles: []
  });

  const getInputClasses = (error) => `form-input block w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${error ? 'border-red-300' : 'border-gray-300'}`;
  const getSelectClasses = (error) => `form-select block w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${error ? 'border-red-300' : 'border-gray-300'}`;
  const getTextareaClasses = (error) => `form-textarea block w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${error ? 'border-red-300' : 'border-gray-300'}`;

  useEffect(() => {
    // Fetch filter options
    const fetchOptions = async () => {
      try {
        const [categories, genders, sizes, colors, occasions, styles] = await Promise.all([
          axios.get(`${BACKEND_URL}/categories`),
          axios.get(`${BACKEND_URL}/genders`),
          axios.get(`${BACKEND_URL}/sizes`),
          axios.get(`${BACKEND_URL}/colors`),
          axios.get(`${BACKEND_URL}/occasions`),
          axios.get(`${BACKEND_URL}/styles`)
        ]);

        setFilterOptions({
          categories: categories.data,
          genders: genders.data,
          sizes: sizes.data,
          colors: colors.data,
          occasions: occasions.data,
          styles: styles.data
        });
      } catch (error) {
        dispatch(showErrorWithTimeout('Failed to load filter options', 'error'));
      }
    };

    fetchOptions();
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'number' ? Number(value) : value
    }));
  };

  const handleToggle = (field) => {
    setFormData(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleRentPriceChange = (index, field, value) => {
    const newRentPrices = [...rentPrices];
    newRentPrices[index] = {
      ...newRentPrices[index],
      [field]: field === 'price' ? Number(value) : value
    };
    setRentPrices(newRentPrices);
  };

  const addRentPrice = () => {
    setRentPrices(prev => [...prev, { number: 1, option: '', price: 0 }]);
  };

  const removeRentPrice = (index) => {
    setRentPrices(prev => prev.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    const errors = {};

    // Required fields validation
    if (!formData.name?.trim()) errors.name = 'Product name is required';
    if (!formData.description?.trim()) errors.description = 'Product description is required';
    if (!formData.productType) errors.productType = 'Product type is required';
    if (!formData.categories?.length) errors.categories = 'Category is required';
    if (!formData.genders?.length) errors.genders = 'Gender is required';
    if (!formData.sizes?.length) errors.sizes = 'Size is required';
    if (!formData.colors?.length) errors.colors = 'Color is required';
    if (!formData.stock || formData.stock <= 0) errors.stock = 'Stock must be greater than 0';
    if (!formData.photos?.length) errors.photos = 'At least one product photo is required';

    // Sale/Rent validation
    if (!formData.forSale && !formData.forRent) {
      errors.price = 'Product must be available for sale or rent';
    }

    // Sale price validation when for sale is checked
    if (formData.forSale && (!formData.price || formData.price <= 0)) {
      errors.price = 'Sale price must be greater than 0';
    }

    // Rent price validation when for rent is checked
    if (formData.forRent) {
      if (!rentPrices?.length) {
        errors.rentPrices = 'At least one rental period is required';
      } else if (rentPrices.some(p => !p.price || p.price <= 0)) {
        errors.rentPrices = 'All rental prices must be greater than 0';
      }

      // Validate deposit amount when deposit is required
      if (formData.requireDeposit && (!formData.rentDeposit || formData.rentDeposit <= 0)) {
        errors.rentDeposit = 'Security deposit amount must be greater than 0';
      }
    }

    // Pickup address validation
    if (formData.openToPickUp && !formData.pickUpAddress) {
      errors.pickUpAddress = 'Pickup address is required when pickup is enabled';
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      dispatch(showErrorWithTimeout('Please fill in all required fields', 'error'));
      return;
    }

    const productData = {
      ...formData,
      rentPriceAndTime: rentPrices,
      users_permissions_user: currentUser.id
    };

    dispatch(createProduct(productData))
      .unwrap()
      .then(() => {
        dispatch(showErrorWithTimeout('Product created successfully', 'success'));
        history.push('/merchantpage/products');
      })
      .catch((error) => {
        dispatch(showErrorWithTimeout(error || 'Failed to create product', 'error'));
      });
  };

  const handleSaveAddress = async () => {
    if (!newAddress) {
      dispatch(showErrorWithTimeout('Please enter a valid address', 'error'));
      return;
    }

    setIsSavingAddress(true);
    try {
      const existingAddresses = Array.isArray(currentUser?.shippingaddress) ? currentUser.shippingaddress : [];
      const newAddressWithId = { ...newAddress, id: Date.now().toString() };
      
      await updateUserAddress({
        userId: currentUser.id,
        addresses: [...existingAddresses, newAddressWithId]
      }).unwrap();

      setFormData(prev => ({
        ...prev,
        selectedAddressId: newAddressWithId.id,
        pickUpAddress: newAddressWithId
      }));

      dispatch(showErrorWithTimeout('Address saved successfully', 'success'));
    } catch (error) {
      dispatch(showErrorWithTimeout(error.message || 'Failed to save address', 'error'));
    } finally {
      setIsSavingAddress(false);
    }
  };

    return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white shadow-card rounded-lg p-6">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Create New Product</h1>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Basic Info */}
          <div className="space-y-4">
            <h2 className="text-heading font-semibold text-gray-800 mb-4">Basic Information</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormInput
                label="Name"
                name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                error={fieldErrors.name}
                required
                  placeholder="Enter product name"
                />

              <FormInput
                label="Product Type"
                  name="productType"
                  value={formData.productType}
                  onChange={handleInputChange}
                error={fieldErrors.productType}
                required
                as="select"
                >
                  <option value="">Select Product Type</option>
                  <option value="designer">Original Design</option>
                  <option value="sharing">Closet Sharing</option>
                  <option value="virtual">Virtual Fashion</option>
                  <option value="others">Others</option>
              </FormInput>
            </div>

            <FormInput
              label="Description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              error={fieldErrors.description}
              required
              as="textarea"
                rows={4}
                placeholder="Enter product description"
              />
          </div>

          {/* Pricing & Availability */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Pricing & Availability</h2>
            
            {/* First Row: Stock and Pickup */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Left: Stock */}
              <div>
              <FormInput
                label="Stock"
                name="stock"
                type="number"
                value={formData.stock}
                onChange={handleInputChange}
                error={fieldErrors.stock}
                required
                min="0"
                placeholder="0"
              />
              </div>

              {/* Right: Pickup Option */}
              <div>
                <label className="block text-label font-medium text-gray-700">Pickup Option</label>
                <div className="mt-2">
                  <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.openToPickUp}
                    onChange={() => handleToggle('openToPickUp')}
                    className="h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal"
                  />
                  <span className="ml-2 text-sm text-gray-700">Allow in-person pickup</span>
                </label>
                </div>
              </div>
            </div>

            {/* Pickup Address Section - Full Width */}
            {formData.openToPickUp && (
              <div className="mt-4 space-y-4 bg-white p-4 rounded-lg shadow-card">
                <p className="text-sm text-gray-600 bg-yellow-50 p-2 rounded">
                  <span className="font-medium">💡 Tip:</span> Buyers/renters can pick up the item from your location or a meetup spot (and skip shipping)
                </p>

                <div>
                  <label className="block text-label font-medium text-gray-700">
                    Pickup Location {fieldErrors.pickUpAddress && <span className="text-red-500 ml-1">*</span>}
                  </label>
                  <div className="mt-2 flex gap-4">
                    <div className="flex-grow">
                      <select
                        value={formData.selectedAddressId || ''}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData(prev => ({
                            ...prev,
                            selectedAddressId: value,
                            pickUpAddress: value === 'new' ? null : 
                              (currentUser?.shippingaddress?.find(addr => addr.id === value) || null)
                          }));
                        }}
                        className={getSelectClasses(fieldErrors.pickUpAddress)}
                      >
                        <option value="">Select an address</option>
                        {Array.isArray(currentUser?.shippingaddress) && currentUser.shippingaddress.map(address => (
                          <option key={address.id} value={address.id}>
                            {address.street}, {address.city}, {address.state} {address.postalCode}, {address.country}
                          </option>
                        ))}
                        <option value="new">+ Add New Address</option>
                      </select>
                    </div>
                    {formData.selectedAddressId === 'new' && (
                      <button
                        type="button"
                        onClick={handleSaveAddress}
                        disabled={isSavingAddress || !newAddress}
                        className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brand-teal hover:bg-brand-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {isSavingAddress ? 'Saving...' : 'Save Address'}
                      </button>
                    )}
                  </div>

                  {formData.selectedAddressId === 'new' && (
                    <div className="mt-4">
                      <AddressInput
                        description="This address will be shared with approved buyers/renters only"
                        required
                        error={fieldErrors.pickUpAddress}
                        onAddressChange={(address) => {
                          setNewAddress(address);
                          setFormData(prev => ({
                            ...prev,
                            pickUpAddress: address
                          }));
                        }}
                      />
                    </div>
                  )}
                  {fieldErrors.pickUpAddress && (
                    <p className="mt-1 text-sm text-red-600">{fieldErrors.pickUpAddress}</p>
                  )}
                </div>
              </div>
            )}

            {/* Availability Options */}
            <div className="space-y-4">
              {/* Primary Options: Sale and Rent */}
              <div className="grid grid-cols-2 gap-6">
                {/* For Sale */}
                <div className="flex items-end gap-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                      checked={formData.forSale}
                      onChange={() => handleToggle('forSale')}
                    className={`h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal ${fieldErrors.price ? 'border-red-300' : ''}`}
                  />
                  <span className="ml-2 text-sm text-gray-700">For Sale</span>
                </label>

                  {formData.forSale && (
                    <div className="flex-1 max-w-xs">
                  <FormInput
                        label="Sale Price ($)"
                    name="price"
                    type="number"
                    value={formData.price}
                    onChange={handleInputChange}
                    error={fieldErrors.price}
                    required
                    min="0"
                    placeholder="0"
                  />
                </div>
              )}
                </div>

                {/* For Rent */}
                <div className="flex items-end">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.forRent}
                      onChange={() => handleToggle('forRent')}
                      className={`h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal ${fieldErrors.rentPrices ? 'border-red-300' : ''}`}
                    />
                    <span className="ml-2 text-sm text-gray-700">For Rent</span>
                  </label>
                </div>
              </div>

              {/* Secondary Options: Swap and Share */}
              <div className="grid grid-cols-2 gap-6">
                {/* Open to Swap */}
                <div>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.openToSwap}
                      onChange={() => handleToggle('openToSwap')}
                      className="h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal"
                    />
                    <span className="ml-2 text-sm text-gray-700">Open to Swap</span>
                  </label>
                  {formData.openToSwap && (
                    <p className="mt-2 text-sm text-gray-600 bg-yellow-50 p-2 rounded ml-7">
                      <span className="font-medium">💡 Tip:</span> Users can propose item exchanges instead of purchasing
                    </p>
                  )}
                </div>

                {/* Open to Share */}
                <div>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={formData.openToShare}
                      onChange={() => handleToggle('openToShare')}
                      className="h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal"
                    />
                    <span className="ml-2 text-sm text-gray-700">Open to Share</span>
                  </label>
                  {formData.openToShare && (
                    <p className="mt-2 text-sm text-gray-600 bg-yellow-50 p-2 rounded ml-7">
                      <span className="font-medium">💡 Tip:</span> Allow stylists or trusted connections to borrow items for photoshoots or special occasions without charging
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Rent Prices Section */}
            {formData.forRent && (
              <div className="mt-6 space-y-4 bg-gray-50 rounded-lg p-6">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium text-gray-800">Rent Prices</h3>
                  <button
                    type="button"
                    onClick={addRentPrice}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brand-teal hover:bg-brand-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal"
                  >
                    Add Price Option
                  </button>
                </div>

                <div className="space-y-4">
                  {rentPrices.map((price, index) => (
                    <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-4 items-end bg-white p-4 rounded-lg shadow-card">
                      <FormInput
                        label="Price ($)"
                        type="number"
                        value={price.price}
                        onChange={(e) => handleRentPriceChange(index, 'price', e.target.value)}
                        error={fieldErrors.rentPrices}
                        required
                        min="0"
                        placeholder="0"
                      />

                      <div className="flex items-end">
                        <span className="text-sm text-gray-600 mb-2 mx-2">for</span>
                      </div>

                      <FormInput
                        label="Duration"
                        type="number"
                        value={price.number}
                        onChange={(e) => handleRentPriceChange(index, 'number', e.target.value)}
                        required
                        min="0"
                        placeholder="0"
                      />

                      <FormInput
                        label="Period"
                        value={price.option}
                        onChange={(e) => handleRentPriceChange(index, 'option', e.target.value)}
                        as="select"
                        required
                      >
                        <option value="">Select Period</option>
                        <option value="day">Day(s)</option>
                        <option value="week">Week(s)</option>
                        <option value="month">Month(s)</option>
                        <option value="year">Year(s)</option>
                      </FormInput>

                      {index > 0 && (
                        <button
                          type="button"
                          onClick={() => removeRentPrice(index)}
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          Remove
                        </button>
                      )}

                      {/* Preview text */}
                      {price.price > 0 && price.number > 0 && price.option && (
                        <div className="md:col-span-4 text-sm text-gray-600 bg-gray-50 p-2 rounded">
                          <span className="font-medium">Preview: </span>
                          ${price.price} for {price.number} {price.option}{price.number > 1 ? 's' : ''}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* Help text */}
                <div className="mt-2 text-sm text-gray-500">
                  <p className="font-medium mb-1">Examples:</p>
                  <ul className="list-disc list-inside space-y-1 pl-2">
                    <li>$50 for 4 days</li>
                    <li>$200 for 1 week</li>
                    <li>$80 for 1 month</li>
                  </ul>
                  <p className="mt-3 text-sm text-gray-600 bg-yellow-50 p-2 rounded">
                    <span className="font-medium">💡 Tip:</span> Remember to factor in maintenance costs (e.g., laundry, repairs) when setting rental prices.
                  </p>
                </div>

                {/* Rental Options */}
                <div className="mt-6 space-y-3 bg-white p-4 rounded-lg">
                  <h4 className="font-medium text-gray-700">Rental Options</h4>
                  <div className="space-y-4">
                    <div className="w-full">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={formData.requireDeposit}
                          onChange={() => handleToggle('requireDeposit')}
                          className="h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal"
                        />
                        <span className="ml-2 text-sm text-gray-700">Require Security Deposit</span>
                      </label>
                      {formData.requireDeposit && (
                        <div className="mt-2 ml-7">
                          <div className="max-w-xs">
                            <FormInput
                              label="Deposit Amount ($)"
                              name="rentDeposit"
                              type="number"
                              value={formData.rentDeposit}
                              onChange={handleInputChange}
                              error={fieldErrors.rentDeposit}
                              required
                              min="0"
                              placeholder="0"
                            />
                          </div>
                          <p className="mt-1 text-sm text-gray-500">This amount will be refunded after the item is returned in good condition.</p>
                          <p className="mt-2 text-sm text-gray-600 bg-yellow-50 p-2 rounded">
                            <span className="font-medium">💡 Tip:</span> Deposit requirements discourage potential renters but are necessary for pricy items.
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="w-full">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={formData.chatBeforeRent}
                          onChange={() => handleToggle('chatBeforeRent')}
                          className="h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal"
                        />
                        <span className="ml-2 text-sm text-gray-700">Require Request Before Rental Approval</span>
                      </label>
                      {formData.chatBeforeRent && (
                        <div className="mt-2 ml-7">
                          <p className="text-sm text-gray-600 bg-yellow-50 p-2 rounded">
                            <span className="font-medium">💡 Tip:</span> For safer rentals:
                            <ul className="list-disc list-inside mt-1 ml-2">
                              <li>Check renter profiles before approving</li>
                              <li>Rent to people you know</li>
                              <li>Prioritize users with 4+ star ratings</li>
                            </ul>
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="w-full">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={formData.hasCustomRules}
                          onChange={() => handleToggle('hasCustomRules')}
                          className="h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal"
                        />
                        <span className="ml-2 text-sm text-gray-700">Custom Rules</span>
                      </label>
                      {formData.hasCustomRules && (
                        <div className="mt-2 ml-7">
                          <FormInput
                            label="Custom Rental Rules"
                            name="customRules"
                            value={formData.customRules}
                            onChange={handleInputChange}
                            as="textarea"
                            placeholder="Enter your rental rules (e.g., no alterations, dry clean only, etc.)"
                            rows={3}
                          />
                          <p className="mt-2 text-sm text-gray-600 bg-yellow-50 p-2 rounded">
                            <span className="font-medium">💡 Tip:</span> Add any specific rules or requirements for renting your item, such as:
                            <ul className="list-disc list-inside mt-1 ml-2">
                              <li>No alterations allowed</li>
                              <li>Dry clean only</li>
                              <li>Handle with care instructions</li>
                            </ul>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Categories and Tags */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Categories & Tags</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormInput
                label="Category"
                name="categories"
                value={formData.categories[0]?.id || ''}
                onChange={(e) => {
                  const category = filterOptions.categories.find(c => c.id === Number(e.target.value));
                  setFormData(prev => ({
                    ...prev,
                    categories: category ? [category] : []
                  }));
                }}
                error={fieldErrors.categories}
                required
                as="select"
              >
                <option value="">Select Category</option>
                {filterOptions.categories.map(category => (
                  <option key={category.id} value={category.id}>{category.name}</option>
                ))}
              </FormInput>

              <FormInput
                label="Gender"
                name="genders"
                value={formData.genders[0]?.id || ''}
                onChange={(e) => {
                  const gender = filterOptions.genders.find(g => g.id === Number(e.target.value));
                  setFormData(prev => ({
                    ...prev,
                    genders: gender ? [gender] : []
                  }));
                }}
                error={fieldErrors.genders}
                required
                as="select"
              >
                <option value="">Select Gender</option>
                {filterOptions.genders.map(gender => (
                  <option key={gender.id} value={gender.id}>{gender.name}</option>
                ))}
              </FormInput>

              <FormInput
                label="Size"
                name="sizes"
                value={formData.sizes[0]?.id || ''}
                onChange={(e) => {
                  const size = filterOptions.sizes.find(s => s.id === Number(e.target.value));
                  setFormData(prev => ({
                    ...prev,
                    sizes: size ? [size] : []
                  }));
                }}
                error={fieldErrors.sizes}
                required
                as="select"
              >
                <option value="">Select Size</option>
                {filterOptions.sizes.map(size => (
                  <option key={size.id} value={size.id}>{size.name}</option>
                ))}
              </FormInput>

              <FormInput
                label="Color"
                name="colors"
                value={formData.colors[0]?.id || ''}
                onChange={(e) => {
                  const color = filterOptions.colors.find(c => c.id === Number(e.target.value));
                  setFormData(prev => ({
                    ...prev,
                    colors: color ? [color] : []
                  }));
                }}
                error={fieldErrors.colors}
                required
                as="select"
              >
                <option value="">Select Color</option>
                {filterOptions.colors.map(color => (
                  <option key={color.id} value={color.id}>{color.name}</option>
                ))}
              </FormInput>

              <FormInput
                label="Occasion"
                name="occasions"
                value={formData.occasions[0]?.id || ''}
                onChange={(e) => {
                  const occasion = filterOptions.occasions.find(o => o.id === Number(e.target.value));
                  setFormData(prev => ({
                    ...prev,
                    occasions: occasion ? [occasion] : []
                  }));
                }}
                as="select"
              >
                <option value="">Select Occasion (Optional)</option>
                {filterOptions.occasions.map(occasion => (
                  <option key={occasion.id} value={occasion.id}>{occasion.name}</option>
                ))}
              </FormInput>

              <FormInput
                label="Style"
                name="styles"
                value={formData.styles[0]?.id || ''}
                onChange={(e) => {
                  const style = filterOptions.styles.find(s => s.id === Number(e.target.value));
                  setFormData(prev => ({
                    ...prev,
                    styles: style ? [style] : []
                  }));
                }}
                as="select"
              >
                <option value="">Select Style (Optional)</option>
                {filterOptions.styles.map(style => (
                  <option key={style.id} value={style.id}>{style.name}</option>
                ))}
              </FormInput>
            </div>
          </div>

          {/* Photos */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Product Photos {fieldErrors.photos && <span className="text-red-500 ml-1">*</span>}
            </h2>
            
              <div>
                <UploadProductPhoto
                onUploadPic={({ photos, coverIndex }) => 
                  setFormData(prev => ({ 
                    ...prev, 
                    photos, 
                    coverImageIndex: coverIndex 
                  }))}
                error={fieldErrors.photos}
              />
            </div>
          </div>

          {/* Submit */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => history.goBack()}
              className="px-4 py-2 border border-gray-300 shadow-sm text-button font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={createProductLoading}
              className="px-4 py-2 border border-transparent text-button font-medium rounded-md text-white bg-brand-orange hover:bg-brand-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-orange"
            >
              {createProductLoading ? 'Creating...' : 'Create Product'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewProduct;
