import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BACKEND_URL } from '../../utils/config';
import qs from 'qs';
import algoliasearch from 'algoliasearch';
import { handleUnauthorized } from './userSlice';

// Initialize Algolia client
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

// Add createProduct thunk
export const createProduct = createAsyncThunk(
  'products/createProduct',
  async (productData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/products`, productData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to create product');
    }
  }
);

// Helper to convert filters to Algolia format
const getAlgoliaFilters = (filters, merchantId) => {
  if (!filters) return '';
  
  const filterStrings = [];

  // Add merchant filter if provided
  if (merchantId) {
    filterStrings.push(`users_permissions_user.id:${merchantId}`);
  }
  
  if (filters.productTypes?.length && filters.productTypes[0] !== 'all') {
    filterStrings.push(`productType:${filters.productTypes[0]}`);
  }

  if (filters.transactionTypes?.length && filters.transactionTypes[0] !== 'all') {
    switch (filters.transactionTypes[0]) {
      case 'sell':
        filterStrings.push('forSell:true');
        break;
      case 'rent':
        filterStrings.push('forRent:true');
        break;
      case 'swap':
        filterStrings.push('openToSwap:true');
        break;
    }
  }

  if (filters.category && filters.category !== '0') {
    filterStrings.push(`categories.id:${filters.category}`);
  }

  if (filters.size && filters.size !== '0') {
    filterStrings.push(`sizes.id:${filters.size}`);
  }

  if (filters.color && filters.color !== '0') {
    filterStrings.push(`colors.id:${filters.color}`);
  }

  if (filters.occasion && filters.occasion !== '0') {
    filterStrings.push(`occasions.id:${filters.occasion}`);
  }

  if (filters.style && filters.style !== '0') {
    filterStrings.push(`styles.id:${filters.style}`);
  }

  if (filters.gender && filters.gender !== '0') {
    filterStrings.push(`genders.id:${filters.gender}`);
  }

  if (filters.localPickup?.enabled) {
    filterStrings.push('openToPickUp:1');
  }

  console.log('Generated Algolia filters:', filterStrings);
  
  return filterStrings.join(' AND ');
};

// Export the getAlgoliaIndex function and rename it to getIndex
export const getIndex = (sortBy) => {
  const baseIndexName = process.env.REACT_APP_ALGOLIA_PRODUCTS_INDEX || 'products';
  console.log('Current sortBy:', sortBy);
  
  switch (sortBy) {
    case 'newest':
      return baseIndexName;
    case 'price_asc':
      return 'products_by_price_asc';
    case 'price_desc':
      return 'products_by_price_desc';
    case 'likes':
      return 'products_by_likes_desc';
    default:
      console.log('Using default index due to unknown sortBy:', sortBy);
      return baseIndexName;
  }
};

// Add new helper to compare search params
const areSearchParamsEqual = (params1, params2) => {
  if (!params1 || !params2) return false;
  return (
    params1.query === params2.query &&
    params1.sortBy === params2.sortBy &&
    params1.offset === params2.offset &&
    JSON.stringify(params1.filters) === JSON.stringify(params2.filters)
  );
};

// Update Algolia search to handle all product fetching
export const searchWithAlgolia = createAsyncThunk(
  'products/searchWithAlgolia',
  async ({ query = '', filters = {}, sortBy = 'newest', offset = 0 }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const { isMerchantPage } = state.products;
      const { userInfo } = state.user;
      
      console.log('searchWithAlgolia: Starting search', { 
        isMerchantPage,
        userInfo: userInfo?.id,
        query,
        filters,
        sortBy,
        offset
      });
      
      // Prevent duplicate searches with same parameters
      if (areSearchParamsEqual(state.lastSearchParams, { query, filters, sortBy, offset })) {
        console.log('Skipping duplicate search with same parameters');
        return null;
      }

      // Get the appropriate index based on sort
      const indexName = getIndex(sortBy);
      console.log('Using Algolia index:', indexName);
      const index = searchClient.initIndex(indexName);
      
      // Build the search parameters
      const searchParams = {
        query,
        hitsPerPage: 20,
        page: Math.floor(offset / 20),
        attributesToRetrieve: [
          'objectID',
          'id',
          'name',
          'description',
          'price',
          'picture',
          'productType',
          'being_liked_by',
          'likes_count',
          'created_at',
          'users_permissions_user',
          'forSell',
          'forRent',
          'openToSwap',
          'stock'
        ]
      };

      // Get Algolia filters using the helper function
      const merchantId = isMerchantPage && userInfo?.id ? userInfo.id : null;
      console.log('searchWithAlgolia: Merchant filter', { 
        isMerchantPage, 
        merchantId,
        userInfoId: userInfo?.id 
      });
      
      const filterString = getAlgoliaFilters(filters, merchantId);
      if (filterString) {
        searchParams.filters = filterString;
        console.log('Final Algolia filters:', searchParams.filters);
      }

      console.log('Final Algolia search params:', searchParams);

      try {
        // Perform the search using the correct index
        const { hits, nbHits, page, nbPages } = await index.search(query, searchParams);

        console.log('Algolia search results:', {
          totalHits: hits.length,
          totalResults: nbHits,
          currentPage: page,
          totalPages: nbPages,
          firstHit: hits[0]
        });

        // Calculate likes_count for each hit if it's undefined
        const productsWithLikes = hits.map(hit => ({
          ...hit,
          id: parseInt(hit.objectID),
          likes_count: hit.likes_count ?? (Array.isArray(hit.being_liked_by) ? hit.being_liked_by.length : 0)
        }));

        return {
          products: productsWithLikes,
          totalResults: nbHits,
          currentPage: page,
          totalPages: nbPages
        };
      } catch (searchError) {
        // Check if the error is due to unauthorized access
        if (searchError.status === 401 || searchError.message?.includes('unauthorized')) {
          console.error('Unauthorized error in Algolia search:', searchError);
          dispatch(handleUnauthorized());
          return rejectWithValue('Unauthorized');
        }
        throw searchError;
      }
    } catch (error) {
      console.error('Algolia search error:', error);
      if (error.status === 401 || error.message?.includes('unauthorized')) {
        dispatch(handleUnauthorized());
      }
      return rejectWithValue(error.message);
    }
  }
);

// Add new thunk for fetching basic filter options
export const fetchFilterOptions = createAsyncThunk(
  'products/fetchFilterOptions',
  async (_, { getState, rejectWithValue }) => {
    const state = getState().products;
    
    // Enhanced cache check - verify data quality and freshness
    if (
      state.filterOptions.hasLoaded &&
      state.filterOptions.categories?.length > 0 &&
      state.filterOptions.sizes?.length > 0 &&
      // Add timestamp check to potentially refetch after some time
      state.filterOptions.lastFetchedAt &&
      Date.now() - state.filterOptions.lastFetchedAt < 24 * 60 * 60 * 1000 // 24 hours
    ) {
      console.log('Using cached filter options, last fetched:', new Date(state.filterOptions.lastFetchedAt));
      return null;
    }

    try {
      console.log('Fetching basic filter options');
      const [categoriesRes, sizesRes] = await Promise.all([
        axios.get(`${BACKEND_URL}/categories`),
        axios.get(`${BACKEND_URL}/sizes`)
      ]);

      return {
        categories: categoriesRes.data,
        sizes: sizesRes.data,
        hasLoaded: true,
        lastFetchedAt: Date.now(),
        error: null
      };
    } catch (error) {
      console.error('Failed to fetch filter options:', error);
      return rejectWithValue({
        message: error.response?.data?.message || 'Failed to fetch filter options',
        timestamp: Date.now()
      });
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState().products;
      // Enhanced concurrency check
      if (state.filterOptions.isLoading || state.filterOptionsBeingFetched) {
        console.log('Skipping filter options fetch - already in progress');
        return false;
      }
      // Add error retry logic
      if (state.filterOptions.error && Date.now() - state.filterOptions.error.timestamp < 5000) {
        console.log('Skipping filter options fetch - recent error, waiting before retry');
        return false;
      }
      return true;
    }
  }
);

// Add new thunk for fetching additional filter options
export const fetchAdditionalFilterOptions = createAsyncThunk(
  'products/fetchAdditionalFilterOptions',
  async (_, { getState, rejectWithValue }) => {
    const state = getState().products;
    
    // Enhanced cache check - verify data quality and freshness
    if (
      state.filterOptions.additionalOptionsLoaded &&
      state.filterOptions.colors?.length > 0 &&
      state.filterOptions.occasions?.length > 0 &&
      state.filterOptions.styles?.length > 0 &&
      state.filterOptions.genders?.length > 0 &&
      // Add timestamp check to potentially refetch after some time
      state.filterOptions.additionalLastFetchedAt &&
      Date.now() - state.filterOptions.additionalLastFetchedAt < 24 * 60 * 60 * 1000 // 24 hours
    ) {
      console.log('Using cached additional filter options, last fetched:', new Date(state.filterOptions.additionalLastFetchedAt));
      return null;
    }

    try {
      console.log('Fetching additional filter options');
      const [colorsRes, occasionsRes, stylesRes, gendersRes] = await Promise.all([
        axios.get(`${BACKEND_URL}/colors`),
        axios.get(`${BACKEND_URL}/occasions`), //typo in backend, keep it "occasions"
        axios.get(`${BACKEND_URL}/styles`),
        axios.get(`${BACKEND_URL}/genders`)
      ]);

      return {
        colors: colorsRes.data,
        occasions: occasionsRes.data,
        styles: stylesRes.data,
        genders: gendersRes.data,
        additionalOptionsLoaded: true,
        additionalLastFetchedAt: Date.now(),
        error: null
      };
    } catch (error) {
      console.error('Failed to fetch additional filter options:', error);
      return rejectWithValue({
        message: error.response?.data?.message || 'Failed to fetch additional filter options',
        timestamp: Date.now()
      });
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState().products;
      // Enhanced concurrency check
      if (state.additionalFilterOptionsLoading || state.filterOptions.additionalFilterOptionsBeingFetched) {
        console.log('Skipping additional filter options fetch - already in progress');
        return false;
      }
      // Add error retry logic
      if (state.filterOptions.additionalError && Date.now() - state.filterOptions.additionalError.timestamp < 5000) {
        console.log('Skipping additional filter options fetch - recent error, waiting before retry');
        return false;
      }
      return true;
    }
  }
);

// Add these new thunks
export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (productId, { dispatch, rejectWithValue }) => {
    try {
      await axios.delete(`${BACKEND_URL}/products/${productId}`);
      return productId;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete product');
    }
  }
);

export const deleteMultipleProducts = createAsyncThunk(
  'products/deleteMultipleProducts',
  async (productIds, { dispatch, rejectWithValue }) => {
    try {
      await axios.post(`${BACKEND_URL}/products/bulk-delete`, { ids: productIds });
      return productIds;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete products');
    }
  }
);

// At the top of the file, after imports
console.log('BACKEND_URL:', BACKEND_URL);

// Update the slice
const productSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    filters: {},
    searchTerm: '',
    sortBy: 'newest',
    offset: 0,
    hasMore: true,
    loading: true,
    error: null,
    filterOptions: {
      isLoading: false,
      hasLoaded: false,
      additionalOptionsLoaded: false,
      filterOptionsBeingFetched: false,
      additionalFilterOptionsBeingFetched: false,
      categories: [],
      sizes: [],
      colors: [],
      occasions: [],
      styles: [],
      genders: []
    },
    totalResults: 0,
    lastSearchParams: null,
    isMerchantPage: false,
    selectedProducts: [],
    deleteModalOpen: false,
    productToDelete: null,
    createProductLoading: false,
    createProductError: null
  },
  reducers: {
    setFilters: (state, action) => {
      // Only update if filters actually changed
      if (JSON.stringify(state.filters) !== JSON.stringify(action.payload)) {
        state.filters = action.payload;
        state.products = [];
        state.offset = 0;
        state.hasMore = true;
        state.error = null;
        state.lastSearchParams = null;
      }
    },
    setMerchantPage: (state, action) => {
      console.log('productSlice: Setting merchant page mode', { 
        newValue: action.payload, 
        currentValue: state.isMerchantPage 
      });
      state.isMerchantPage = action.payload;
      // Clear products when switching modes
      state.products = [];
      state.offset = 0;
      state.hasMore = true;
      state.lastSearchParams = null;
    },
    setSearchTerm: (state, action) => {
      if (state.searchTerm !== action.payload) {
        state.searchTerm = action.payload;
        state.products = [];
        state.offset = 0;
        state.hasMore = true;
        state.lastSearchParams = null; // Reset last search params
      }
    },
    setSort: (state, action) => {
      if (state.sortBy !== action.payload) {
        state.sortBy = action.payload;
        state.products = [];
        state.offset = 0;
        state.hasMore = true;
        state.lastSearchParams = null; // Reset last search params
      }
    },
    loadMore: (state) => {
      if (state.hasMore && !state.loading) {
        state.offset += 20;
      }
    },
    setProducts: (state, action) => {
      try {
        // Ensure action.payload is an array
        if (!Array.isArray(action.payload)) {
          console.warn('setProducts received non-array payload:', action.payload);
          return;
        }

        // Create a new array with the updated products
        const newProducts = action.payload.map(product => ({
          ...product,
          id: parseInt(product.id || product.objectID),
          objectID: (product.objectID || product.id).toString()
        }));

        // Update products array
        state.products = newProducts;
        state.totalResults = newProducts.length;
        
        // Log the update for debugging
        console.log('Products state updated:', {
          newCount: newProducts.length,
          products: newProducts.map(p => ({ id: p.id, objectID: p.objectID }))
        });
      } catch (error) {
        console.error('Error in setProducts reducer:', error);
      }
    },
    // Add a new reducer to clear filter options cache
    clearFilterOptionsCache: (state) => {
      state.filterOptions = {
        ...state.filterOptions,
        hasLoaded: false,
        additionalOptionsLoaded: false,
        categories: [],
        sizes: [],
        colors: [],
        occasions: [],
        styles: [],
        genders: []
      };
    },
    toggleProductSelection: (state, action) => {
      const productId = action.payload;
      const index = state.selectedProducts.indexOf(productId);
      if (index === -1) {
        state.selectedProducts.push(productId);
      } else {
        state.selectedProducts.splice(index, 1);
      }
    },
    toggleSelectAll: (state) => {
      if (state.selectedProducts.length === state.products.length) {
        state.selectedProducts = [];
      } else {
        state.selectedProducts = state.products.map(p => p.id);
      }
    },
    clearSelectedProducts: (state) => {
      state.selectedProducts = [];
    },
    setDeleteModalOpen: (state, action) => {
      state.deleteModalOpen = action.payload;
    },
    setProductToDelete: (state, action) => {
      state.productToDelete = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchWithAlgolia.pending, (state, action) => {
        const { offset = 0 } = action.meta.arg;
        if (offset === 0) {
          state.loading = true;
          state.error = null;
        }
      })
      .addCase(searchWithAlgolia.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        
        // Skip update if the response is null (duplicate search prevented)
        if (!action.payload) return;
        
        // Map products to ensure consistent ID and likes handling
        const mappedProducts = action.payload.products.map(product => ({
          ...product,
          id: parseInt(product.id || product.objectID),
          objectID: product.objectID || product.id,
          likes_count: product.likes_count || (Array.isArray(product.being_liked_by) ? product.being_liked_by.length : 0)
        }));
        
        // Always replace products when isMerchantPage is true
        if (action.meta.arg.isMerchantPage) {
          state.products = mappedProducts;
        } else {
          // Normal search behavior
          if (action.meta.arg.offset === 0) {
            state.products = mappedProducts;
          } else {
            // Filter out any duplicates before appending
            const existingIds = new Set(state.products.map(p => p.id));
            const newProducts = mappedProducts.filter(p => !existingIds.has(p.id));
            state.products = [...state.products, ...newProducts];
          }
        }
        
        state.hasMore = state.products.length < action.payload.totalResults;
        state.totalResults = action.payload.totalResults;
        state.lastSearchParams = action.meta.arg;
      })
      .addCase(searchWithAlgolia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to search products';
      })
      // Filter options with caching
      .addCase(fetchFilterOptions.pending, (state) => {
        state.filterOptions.isLoading = true;
        state.filterOptions.filterOptionsBeingFetched = true;
      })
      .addCase(fetchFilterOptions.fulfilled, (state, action) => {
        if (action.payload) {
          state.filterOptions = {
            ...state.filterOptions,
            ...action.payload,
            isLoading: false,
            filterOptionsBeingFetched: false
          };
        } else {
          state.filterOptions.isLoading = false;
          state.filterOptions.filterOptionsBeingFetched = false;
        }
      })
      .addCase(fetchFilterOptions.rejected, (state, action) => {
        state.filterOptions.isLoading = false;
        state.filterOptions.filterOptionsBeingFetched = false;
        state.error = action.error.message;
      })
      // Additional filter options with caching
      .addCase(fetchAdditionalFilterOptions.pending, (state) => {
        state.filterOptions.additionalFilterOptionsLoading = true;
        state.filterOptions.additionalFilterOptionsBeingFetched = true;
      })
      .addCase(fetchAdditionalFilterOptions.fulfilled, (state, action) => {
        if (action.payload) {
          state.filterOptions = {
            ...state.filterOptions,
            ...action.payload,
            additionalFilterOptionsLoading: false,
            additionalFilterOptionsBeingFetched: false
          };
        } else {
          state.filterOptions.additionalFilterOptionsLoading = false;
          state.filterOptions.additionalFilterOptionsBeingFetched = false;
        }
      })
      .addCase(fetchAdditionalFilterOptions.rejected, (state, action) => {
        state.filterOptions.additionalFilterOptionsLoading = false;
        state.filterOptions.additionalFilterOptionsBeingFetched = false;
        state.error = action.error.message;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.products = state.products.filter(p => p.id !== action.payload);
        state.deleteModalOpen = false;
        state.productToDelete = null;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.deleteModalOpen = false;
        state.productToDelete = null;
      })
      .addCase(deleteMultipleProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMultipleProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = state.products.filter(p => !action.payload.includes(p.id));
        state.selectedProducts = [];
      })
      .addCase(deleteMultipleProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createProduct.pending, (state) => {
        state.createProductLoading = true;
        state.createProductError = null;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.createProductLoading = false;
        state.products = [action.payload, ...state.products];
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.createProductLoading = false;
        state.createProductError = action.payload;
      });
  }
});

export const { 
  setFilters, 
  setSearchTerm, 
  setSort, 
  loadMore, 
  setProducts, 
  clearFilterOptionsCache,
  setMerchantPage,
  toggleProductSelection,
  toggleSelectAll,
  clearSelectedProducts,
  setDeleteModalOpen,
  setProductToDelete
} = productSlice.actions;

export default productSlice.reducer; 