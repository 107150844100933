import { BACKEND_URL } from '../../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../../utils/config';
import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import CheckoutForm from './CheckOutForm';
import { Header, Icon } from 'semantic-ui-react';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe("pk_test_51KLjxrBS42P1i4PWMHrFWUkvxsts0IRevUXwgdhVTdpGtNEBauZ6xVoNBAzQslIpeYhloEZoxVNBOiAT2HAiphwc002qeo3nEl");

const StripePay = ({ amount, orderId }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    // Get the token from localStorage
    const token = localStorage.getItem('token');
    
    setIsProcessing(true); // Start loading
    
    // Create a PaymentIntent on the server
    axios.post(`${BACKEND_URL}/pay/stripeCheckout`, {
      amount: amount, // The amount from props
      currency: 'usd',
      transfer_group: `ORDER_${orderId}`, // Use orderId to track related transfers
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      console.log(response.data);
      setClientSecret(response.data.clientSecret);

    })
    .catch((error) => {
      console.error('Error creating PaymentIntent:', error);
      // End loading regardless of success/failure

    })
  }, [amount, orderId]);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
    }
  };

  const options = {
    clientSecret,
    appearance,
  };

  

  return (
    <div>
      {isProcessing && <Header style={{marginBottom:'50px', marginTop:'50px'}}>Loading...<Icon name='asterisk' loading color='yellow'/></Header>}
      {clientSecret && (
        <Elements 
          stripe={stripePromise} 
          options={options}
        >
          <CheckoutForm 
            orderId={orderId}
            onLoadingChange={setIsProcessing}  // Pass loading state handler
          />
        </Elements>
      )}
    </div>
  );
};

export default StripePay;
