import { BACKEND_URL } from '../../../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../../../utils/config';
import React from 'react'
import axios from 'axios'
import {
  Grid,
  Icon,
  Divider,
  Image,
  Segment,
  Container,
  Button,
  Header,
  Dimmer
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class OrderPickUpConfirm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      identifier: '',
      token: '',
      rentOrBuy: 'buy',
      userInfo: [],
      loading: true,
      useAnother: false,
      save: false,
      orderid: '',
      missingInput: false,
      buyProducts: [],
      accessoryProduct: [],
      buyCartInfo: '',
      rentProducts: [],
      accessoryProductRent: [],
      rentCartInfo: ''
    }
    this.getUserInfo = this.getUserInfo.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.useAnother = this.useAnother.bind(this)
    this.useList = this.useList.bind(this)
    this.toPrevios=this.toPrevios.bind(this)
  }
  componentDidMount () {
    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(
      orderidPosition + 8
    )
    const token = localStorage.getItem('token')

    const identifier = localStorage.getItem('identifier')
    const self = this

    self.setState({
      rentOrBuy: 'buy',
      identifier: identifier,
      orderid: orderid
    })

    self.setState({
      buyProducts: [],
      accessoryProduct: [],
      rentProducts: [],
      accessoryProductRent: []
    })

    axios(`${BACKEND_URL}/orders/` + orderid, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        if (res.status === 200) {
          console.log(res)
          const orderData = res.data.orders.data
          const rentOrBuy = orderData.rentOrBuy
          const clothingData = orderData.clothing
          const accessoryData = orderData.accessory

          const receiverInfo= orderData.info
          const senderInfo=orderData.senderInfo

          self.setState({
            rentOrBuy: rentOrBuy,
            receiverInfo:receiverInfo,
            senderInfo:senderInfo
          })

          clothingData.forEach(function (e) {
            if (e.type !== 'sale') {
              axios
                .get(`${BACKEND_URL}/products?id=` + e.id)
                .then(function (res) {
                  if (res.status === 200) {
                    const productSelected = res.data[0]
                    var product=null
                    if (rentOrBuy === 'buy') {
                      if (self.state.buyProducts === '') {
                         product = productSelected

                        self.setState({ buyCartInfo: e })

                        product.quantity = self.state.buyCartInfo.number
                        product.colors[0] = self.state.buyCartInfo.colors
                        product.sizes[0] = self.state.buyCartInfo.sizes

                        const stringProduct = [product]
                        self.setState({
                          buyProducts: stringProduct
                        })
                      } else if (self.state.buyProducts !== '') {
                        product = productSelected

                        self.setState({ buyCartInfo: e })

                        product.quantity = self.state.buyCartInfo.number
                        product.colors[0] = self.state.buyCartInfo.colors
                        product.sizes[0] = self.state.buyCartInfo.sizes

                        const stringProduct = product
                        product = self.state.buyProducts

                        product.push(stringProduct)
                        self.setState({
                          buyProducts: product
                        })
                      }
                    } else {
                      if (self.state.rentProducts.length === 0) {
                         product = productSelected

                        self.setState({ rentCartInfo: e })

                        product.quantity = self.state.rentCartInfo.number
                        product.colors[0] = self.state.rentCartInfo.colors
                        product.sizes[0] = self.state.rentCartInfo.sizes
                        product.endDate = self.state.rentCartInfo.endDate
                        product.startDate = self.state.rentCartInfo.startDate
                        product.rentTimeSelect =
                          self.state.rentCartInfo.rentTimeSelect

                        const stringProduct = [product]
                        self.setState({
                          rentProducts: stringProduct
                        })
                      } else if (self.state.rentProducts.length!== 0) {
                        product = productSelected

                        self.setState({ rentCartInfo: e })

                        product.quantity = self.state.rentCartInfo.number
                        product.colors[0] = self.state.rentCartInfo.colors
                        product.sizes[0] = self.state.rentCartInfo.sizes
                        product.endDate = self.state.rentCartInfo.endDate
                        product.startDate = self.state.rentCartInfo.startDate
                        product.rentTimeSelect =
                          self.state.rentCartInfo.rentTimeSelect

                        const stringProduct = product
                        product = self.state.rentProducts

                        product.push(stringProduct)
                        self.setState({
                          rentProducts: product
                        })
                      }
                    }
                  } else {
                    alert('something went wrong')
                    localStorage.setItem('login', 'false')
                  }
                })
            } else if (e.type === 'sale') {
              axios
                .get(
                  `${BACKEND_URL}/second-hand-products?id=` +
                    e.id
                )
                .then(function (res) {
                  if (res.status === 200) {
                    const productSelected = res.data[0]
                    var product=null
                    if (self.state.buyProducts === '') {
                       product = productSelected

                      self.setState({ buyCartInfo: e })

                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes
                      product.type = 'sale'

                      const stringProduct = [product]
                      self.setState({
                        buyProducts: stringProduct
                      })
                    } else if (self.state.buyProducts !== '') {
                      product = productSelected

                      self.setState({ buyCartInfo: e })
                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes
                      product.type = 'sale'

                      const stringProduct = product
                      product = self.state.buyProducts

                      product.push(stringProduct)
                      self.setState({
                        buyProducts: product
                      })
                    }
                  } else {
                    alert('something went wrong')
                    localStorage.setItem('login', 'false')
                  }
                  self.setState({
                    loading: false
                  })
                })
            }
          })

          accessoryData.forEach(function (e) {
            if (e.type !== 'sale') {
              axios
                .get(`${BACKEND_URL}/products?id=` + e.id)
                .then(function (res) {
                  if (res.status === 200) {
                    const productSelected = res.data[0]
                    var product=null
                    if (self.state.accessoryProduct === '') {
                       product = productSelected

                      self.setState({ buyCartInfo: e })

                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes

                      const stringProduct = [product]
                      self.setState({
                        accessoryProduct: stringProduct
                      })
                    } else if (self.state.accessoryProduct !== '') {
                      product = productSelected

                      self.setState({ buyCartInfo: e })

                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes

                      const stringProduct = product
                      product = self.state.accessoryProduct

                      product.push(stringProduct)
                      self.setState({
                        accessoryProduct: product
                      })
                    }
                  } else {
                    alert('something went wrong')
                    localStorage.setItem('login', 'false')
                  }
                })
            } else if (e.type === 'sale') {
              axios
                .get(
                  `${BACKEND_URL}/second-hand-products?id=` +
                    e.id
                )
                .then(function (res) {
                  if (res.status === 200) {
                    const productSelected = res.data[0]
                    var product=null
                    if (self.state.accessoryProduct === '') {
                       product = productSelected

                      self.setState({ buyCartInfo: e })

                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes
                      product.type = 'sale'

                      const stringProduct = [product]
                      self.setState({
                        accessoryProduct: stringProduct
                      })
                    } else if (self.state.accessoryProduct !== '') {
                      product = productSelected

                      self.setState({ buyCartInfo: e })

                      product.quantity = self.state.buyCartInfo.number
                      product.colors[0] = self.state.buyCartInfo.colors
                      product.sizes[0] = self.state.buyCartInfo.sizes
                      product.type = 'sale'

                      const stringProduct = product
                      product = self.state.accessoryProduct

                      product.push(stringProduct)
                      self.setState({
                        accessoryProduct: product
                      })
                    }
                  } else {
                    alert('something went wrong')
                    localStorage.setItem('login', 'false')
                  }
                  self.setState({
                    loading: false
                  })
                })
            }
          })
        }
      })
      .catch(function (err) {
        alert('something went wrong: ' + err)
      })

    this.getUserInfo()
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 2000)
    // console.log(allCountries.GetCode('China'))
  }
  getUserInfo () {
    const self = this
    // const token=this.state.token
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(
      orderidPosition + 8
    )
    self.setState({
      orderid: orderid
    })

    self.setState({
      identifier: identifier,
      token: token
    })
    axios(`${BACKEND_URL}/users/me`, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        const userInfo = [res.data]
        console.log(userInfo)
        if (userInfo === null) {
          self.setState({
            userInfo: []
          })
        } else if (userInfo.length > 0) {
          if (userInfo[0].merchantInfo.address === null) {
            userInfo[0].merchantInfo.address = []
          }

          self.setState({
            userInfo: userInfo
          })
        }

        self.setState({
          firstName: userInfo[0].merchantInfo.identity.firstname,
          lastName: userInfo[0].merchantInfo.identity.lastname,
          country: userInfo[0].merchantInfo.address.country,
          state: userInfo[0].merchantInfo.address.state,
          city: userInfo[0].merchantInfo.address.city,
          zipcode: userInfo[0].merchantInfo.address.zipcode,
          phonenumber: userInfo[0].merchantInfo.mobilephone.phonenum,
          address1: userInfo[0].merchantInfo.address.address1,
          address2: userInfo[0].merchantInfo.address.address1
        })
        //   }
        // }

        console.log(userInfo)
        self.setState({
          loading: false
        })
      })
      .catch(function (err) {
        self.props.history.push('merchantsLog')
      })
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const orderidPosition = this.props.history.location.search.search('orderid')
    const orderid = this.props.history.location.search.substring(
      orderidPosition + 8
    )
    console.log(orderid)
  }

  useAnother () {
    this.setState({
      useAnother: true,
      firstName: '',
      lastName: '',
      state: '',
      city: '',
      zipcode: '',
      phonenumber: '',
      address1: '',
      address2: ''
    })
  }

  useList () {
    this.setState({
      useAnother: false
    })
  }

  toPrevios(){
    window.history.go(-1)
  }

  render () {
    const self = this
    const s = self.state

    var products = s.buyProducts
    var rentProducts = s.rentProducts


    console.log(s.senderInfo,s.receiverInfo)
    const loading = this.state.loading
    console.log(s.toConfirm)

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <div className='bg_color_image'>
              <br />
              <br />
              <Header inverted size='huge' textAlign='center'>
                Confirm Shipment :)
              </Header>
              <br />
              <br />
              <Container>
                <Segment>
                  <Grid container>
                    <Grid.Row columns={5} style={{ marginTop: 20 }}>
                      <Grid.Column width={2}></Grid.Column>
                      <Grid.Column width={2}>
                        <Icon size='big' name='dot circle' color='grey' />
                        <Header
                          as='h6'
                          textAlign='left'
                          style={{ padding: 0, marginTop: 7 }}
                        >
                          Start
                        </Header>
                      </Grid.Column>

                      <Grid.Column width={8}>
                        <Divider />
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <Icon
                          size='big'
                          name='dot circle outline'
                          color='orange'
                        />
                        <Header
                          as='h6'
                          textAlign='left'
                          style={{ padding: 0, marginTop: 7 }}
                        >
                          Confirm
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>

                    <Divider></Divider>

                    {s.rentOrBuy === 'buy' &&
                      products.length > 0 &&
                      products.map(function (product) {
                        console.log(product, product.type)
                        return (
                          <Grid.Row columns={9} centered>
                            <Grid.Column width={1}>
                              {product.stock !== '0' && (
                                <Icon
                                  id={product.id}
                                  type={product.type}
                                  category={product.is}
                                  onClick={self.onHandleDelete}
                                  circular
                                  color='orange'
                                  name='circle outline'
                                />
                              )}
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Image
                                size='medium'
                                src={product.picture[0].url}
                              />
                            </Grid.Column>

                            <Grid.Column width={4}>
                              <Grid>
                                <Grid.Row>{product.name}</Grid.Row>
                                <Grid.Row>Color: {product.colors[0]}</Grid.Row>
                                <Grid.Row>Size: {product.sizes[0]}</Grid.Row>
                              </Grid>
                            </Grid.Column>

                            {product.stock === '0' && (
                              <Grid.Column width={1}>0</Grid.Column>
                            )}
                            {product.stock !== '0' && (
                              <Grid.Column width={3}>
                                {'Quantity: ' + product.quantity}
                              </Grid.Column>
                            )}

                            <Grid.Column>{'$ ' + product.price}</Grid.Column>
                          </Grid.Row>
                        )
                      })}
                    {s.rentOrBuy !== 'buy' &&
                      rentProducts.length > 0 &&
                      rentProducts.map(function (product) {
                        console.log(product)
                        return (
                          <Grid.Row columns={8}>
                            <Dimmer active={product.stock === '0'} inverted>
                              <Icon
                                id={product.id}
                                size='large'
                                type={product.type}
                                category={product.is}
                                onClick={self.onHandleRentDelete}
                                circular
                                color='grey'
                                name='circle outline'
                              />
                            </Dimmer>

                            <Grid.Column width={1}>
                              {product.stock !== '0' && (
                                <Icon
                                  id={product.id}
                                  type={product.type}
                                  category={product.is}
                                  onClick={self.onHandleRentDelete}
                                  circular
                                  color='orange'
                                  name='circle outline'
                                />
                              )}
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Image
                                size='medium'
                                src={product.picture[0].url}
                              />
                            </Grid.Column>

                            <Grid.Column width={3}>
                              <Grid>
                                <Grid.Row>{product.name}</Grid.Row>
                                <Grid.Row>Color: {product.colors}</Grid.Row>
                                <Grid.Row>Size: {product.sizes}</Grid.Row>
                              </Grid>
                            </Grid.Column>

                            <Grid.Column width={1}></Grid.Column>

                            {product.stock === '0' && (
                              <Grid.Column width={1}>0</Grid.Column>
                            )}
                            {product.stock !== '0' && (
                              <Grid.Column width={3}>
                                {'Quantity: ' + product.quantity}
                              </Grid.Column>
                            )}

                            <Grid.Column width={4}>
                              <Grid>
                                <Grid.Row>
                                  {product.rentTimeSelect !== undefined &&
                                    'Rent Price: $ ' +
                                      product.rentTimeSelect.price}
                                  {product.rentTimeSelect === undefined &&
                                    'Error! Contact Seller'}
                                </Grid.Row>
                                <Grid.Row>
                                  {product.startDate.substring(5, 7) ===
                                    '01' && (
                                    <p textAlign='center'>
                                      {'Start Date  : January ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '02' && (
                                    <p textAlign='center'>
                                      {'Start Date  : February ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '03' && (
                                    <p textAlign='center'>
                                      {'Start Date  : March ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '04' && (
                                    <p textAlign='center'>
                                      {'Start Date  : April ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '05' && (
                                    <p textAlign='center'>
                                      {'Start Date  : May ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '06' && (
                                    <p textAlign='center'>
                                      {'Start Date  : June ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '07' && (
                                    <p textAlign='center'>
                                      {'Start Date  : July ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '08' && (
                                    <p textAlign='center'>
                                      {'Start Date  : August ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '09' && (
                                    <p
                                      color='orange'
                                      as='h6'
                                      textAlign='center'
                                    >
                                      {'Start Date  : September ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '10' && (
                                    <p textAlign='center'>
                                      {'Start Date  : October ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '11' && (
                                    <p textAlign='center'>
                                      {'Start Date  : November ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.startDate.substring(5, 7) ===
                                    '12' && (
                                    <p textAlign='center'>
                                      {'Start Date  : December ' +
                                        product.startDate.substring(8, 10) +
                                        ', ' +
                                        product.startDate.substring(0, 4)}
                                    </p>
                                  )}

                                  {/* Start Date: {product.startDate} */}
                                </Grid.Row>
                                <Grid.Row>
                                  {product.endDate.substring(5, 7) === '01' && (
                                    <p textAlign='center'>
                                      {'End Date  : January ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '02' && (
                                    <p textAlign='center'>
                                      {'End Date  : February ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '03' && (
                                    <p textAlign='center'>
                                      {'End Date  : March ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '04' && (
                                    <p textAlign='center'>
                                      {'End Date  : April ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '05' && (
                                    <p textAlign='center'>
                                      {'End Date  : May ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '06' && (
                                    <p textAlign='center'>
                                      {'End Date  : June ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '07' && (
                                    <p textAlign='center'>
                                      {'End Date  : July ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '08' && (
                                    <p textAlign='center'>
                                      {'End Date  : August ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '09' && (
                                    <p
                                      color='orange'
                                      as='h6'
                                      textAlign='center'
                                    >
                                      {'End Date  : September ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '10' && (
                                    <p textAlign='center'>
                                      {'End Date  : October ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '11' && (
                                    <p textAlign='center'>
                                      {'End Date  : November ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                  {product.endDate.substring(5, 7) === '12' && (
                                    <p textAlign='center'>
                                      {'End Date  : December ' +
                                        product.endDate.substring(8, 10) +
                                        ', ' +
                                        product.endDate.substring(0, 4)}
                                    </p>
                                  )}
                                </Grid.Row>
                              </Grid>
                            </Grid.Column>
                          </Grid.Row>
                        )
                      })}

                    <Grid.Row></Grid.Row>
                    <Grid.Row>
                      <Segment>

                      </Segment>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>

                    <Grid.Row centered>
                    <Button
                          style={{ paddingLeft: 40, paddingRight: 40 }}
                          onClick={self.toPrevios}
                        >
                          Back
                        </Button>
                      <Button
                        color='orange'
                        style={{ paddingLeft: 40, paddingRight: 40 }}
                        onClick={this.onHandleSubmit}
                      >
                        Confirm
                      </Button>
                    </Grid.Row>

                    <Grid.Row></Grid.Row>
                  </Grid>
                </Segment>
              </Container>
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(OrderPickUpConfirm)
