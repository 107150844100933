import  { useEffect, useState } from 'react';
import { useUser } from '../../hooks/useUser';

const ProfilePage = () => {
  const [loading, setLoading] = useState(true);
  const { user, isLoading } = useUser();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  // Show loading state
  if (isLoading || loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brand-teal"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-600">Please log in to view your profile.</p>
      </div>
    );
  }

  return (
    <div>
      {/* Profile content using user data */}
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-6">Profile</h1>
        
        <div className="bg-white shadow rounded-lg p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h2 className="text-lg font-semibold mb-4">Personal Information</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-600">Name</label>
                  <p className="mt-1">{user.firstname} {user.lastname}</p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-600">Email</label>
                  <p className="mt-1">{user.email}</p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-600">Phone</label>
                  <p className="mt-1">{user.phonenumber || 'Not provided'}</p>
                </div>
              </div>
            </div>
            
            <div>
              <h2 className="text-lg font-semibold mb-4">Body Measurements</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-600">Height</label>
                  <p className="mt-1">{user.height || 'Not provided'}</p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-600">Weight</label>
                  <p className="mt-1">{user.weight || 'Not provided'}</p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-600">Body Type</label>
                  <p className="mt-1">{user.bodytype || 'Not provided'}</p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-600">Bust Size</label>
                  <p className="mt-1">{user.bustsize || 'Not provided'}</p>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-600">Clothing Size</label>
                  <p className="mt-1">{user.clothingsize || 'Not provided'}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Shipping Addresses */}
          {user.shippingaddress && user.shippingaddress.length > 0 && (
            <div className="mt-8">
              <h2 className="text-lg font-semibold mb-4">Shipping Addresses</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {user.shippingaddress.map((address, index) => (
                  <div key={address.id || index} className="border rounded-lg p-4">
                    <p className="font-medium">{address.firstNameSA} {address.lastNameSA}</p>
                    <p>{address.detailAddress}</p>
                    <p>{address.district}, {address.city}</p>
                    <p>{address.state}, {address.country}</p>
                    <p>Phone: {address.phonenumber}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Billing Information */}
          {user.billinginformation && user.billinginformation.length > 0 && (
            <div className="mt-8">
              <h2 className="text-lg font-semibold mb-4">Billing Information</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {user.billinginformation.map((billing, index) => (
                  <div key={billing.id || index} className="border rounded-lg p-4">
                    <p className="font-medium">{billing.firstNameBI} {billing.lastNameBI}</p>
                    <p>{billing.detailAddress}</p>
                    <p>{billing.district}, {billing.city}</p>
                    <p>{billing.state}, {billing.country}</p>
                    <p>Phone: {billing.phonenumber}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;


