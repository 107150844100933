import { BACKEND_URL } from '../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../utils/config';
import React from 'react'
import {
    Grid, Icon, Divider, Image,
    Segment, Container, Button, Header, Popup, Checkbox,
    Message
} from 'semantic-ui-react'
import axios from 'axios'

// import Nav from '../nav/Nav'
import { withRouter } from 'react-router-dom'
import TheProductInfoToComment from '../pastOrders/TheProductInfoToComment'



class KeepPurchase extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            paid: false,
            value: 0,
            token: '',
            identifier: '',
            password: '',
            rentOrBuy: '',
            membership: '',
            extraFee: '',
            isAMember: '',
            fee: '',
            loading: true,
            productInfoComment: '',
            productToCommentId: '',
            commentData: '',
            somethingWrong: false,
            productKept: false
        }
        this.onHandleOkay = this.onHandleOkay.bind(this)
        this.onHandleSubmit = this.onHandleSubmit.bind(this)
        this.getProducts = this.getProducts.bind(this)
        this.getAccessory = this.getAccessory.bind(this)
        this.getSaleProducts = this.getSaleProducts.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        const identifier = localStorage.getItem('identifier')
        const password = localStorage.getItem('password')
        const self = this

        const orderId = localStorage.getItem('orderToKeepId')
        const token = localStorage.getItem('token')

        const is = localStorage.getItem('is')
        if (is === 'clothing') {
            this.getProducts()
        } else if (is === 'saleClothing') {
            this.getSaleProducts()
        } else {
            this.getAccessory()
        }

        window.setTimeout(() => {
            if (self.state.loading === true) {
                this.setState({
                    loading: false
                })
            }

        }, 700);

        axios(`${BACKEND_URL}/pictures`, {
            method: 'get',
           
        })
            .then(function (res) {
                const data=res.data
                console.log(data[0])
                self.setState({
                  wechatPay:data[3].pictures[0].url,
                  wechatPayIcon:data[3].pictures[1].url,
                  AliPay:data[3].pictures[2].url,
                  AliPayIcon:data[3].pictures[3].url,

                })
                
            })

        axios(`${BACKEND_URL}/orders/` + orderId, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(function (res) {
            if (res.status === 200) {
                const orderInfo = res.data.orders
                const membership = orderInfo.data.membership

                self.setState({
                    membership: membership,
                    commentData: orderInfo
                })

            } else {
                // alert('something went wrong :' + res)
                self.setState({
                    somethingWrong: 'something went wrong: ' + res
                })
            }
        })
            .catch(function (err) {
                // alert('something went wrong: ' + err)
                self.setState({
                    somethingWrong: 'something went wrong: ' + err
                })
            })
        self.setState({
            identifier: identifier,
            password: password,
            is: is
        })

    }

    handleChange = (e, { value }) => this.setState({ value })

    getProducts() {
        const self = this
        const productId = localStorage.getItem("productToKeepId")

        axios.get(`${BACKEND_URL}/products?id=` + productId)
            .then(function (res) {
                var data = res.data[0];

                self.setState({
                    productInfoComment: data,
                    productToCommentId: productId,
                    price: Math.round((data.price * 0.9 * 100) / 100)

                })
            })
    }
    getSaleProducts() {
        const self = this
        const productId = localStorage.getItem("productToKeepId")

        axios.get(`${BACKEND_URL}/second-hand-products?id=` + productId)
            .then(function (res) {
                var data = res.data[0];
                // data=JSON.stringify(data)
                // localStorage.setItem("productInfoComment",data)
                self.setState({
                    productInfoComment: data,
                    productToCommentId: productId,
                    price: (data.price) * 0.9

                })
            })
    }

    getAccessory() {
        const self = this
        const productId = localStorage.getItem("productToKeepId")

        axios.get(`${BACKEND_URL}/accessaries?id=` + productId)
            .then(function (res) {
                var data = res.data[0];
                // data=JSON.stringify(data)
                // localStorage.setItem("productInfoComment",data)
                self.setState({
                    productInfoComment: data,
                    productToCommentId: productId,
                    price: (data.price) * 0.9

                })
            })
    }

    onHandleSubmit(event) {
        event.preventDefault()
        const orderId = localStorage.getItem('orderToKeepId')
        var productId = localStorage.getItem('productToKeepId')
        productId = parseInt(productId)
        console.log(productId)
        const token = localStorage.getItem('token')
        const self = this

        const is = localStorage.getItem('is')
        const commentData = this.state.commentData
        const payment=this.state.value
        const price=this.state.price
        if (is === 'clothing') {
            commentData.data.clothing.forEach(function (each) {
                if (each.type !== 'sale' && each.id === productId) {
                    each.keep = true
                    each.payment=payment
                    each.price=price
                }
            })
        } else if (is === 'saleClothing') {
            commentData.data.clothing.forEach(function (each) {
                if (each.type === 'sale' && each.id === productId) {
                    each.keep = true
                    each.payment=payment
                    each.price=price
                }
            })
        } else {
            commentData.data.accessory.forEach(function (each) {
                if (each.id === productId) {
                    each.keep = true
                    each.payment=payment
                    each.price=price
                }
            })
        }

        axios(`${BACKEND_URL}/orders/` + orderId, {
            method: 'put',
            data: {
                orders:commentData
            },
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(function (res) {
            if (res.status === 200) {
                console.log(res)
                // alert('successfully kept this product')
                self.setState({
                    productKept: 'successfully kept this product'
                })
                self.props.history.push('/mypage')

            } else {
                // alert('something went wrong :' + res)
                self.setState({
                    somethingWrong: 'something went wrong :' + res
                })
            }
        })
            .catch(function (err) {
                // alert('something went wrong: ' + err)
                self.setState({
                    somethingWrong: 'something went wrong: ' + err
                })
            })
    }

    onHandleOkay(event) {
        const self = this
        event.preventDefault()
        const rentOrBuy = this.state.rentOrBuy

        if (rentOrBuy === 'buy') {
            self.props.history.push("/confirm")

        } else {
            self.props.history.push("/confirmrent")

        }
    }

    render() {
        const s = this.state
        // const membership = this.state.membership
        // const extraFee = this.state.extraFee
        const fee = this.state.price
        // const paid = this.state.paid
        const loading = this.state.loading
        console.log(this.state)

        return <div>
            {/* <Nav /> */}

            {loading ? (
                <Grid >
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>


                    <Grid.Row colums={2}>
                        <Grid.Column width={7}></Grid.Column>
                        <Grid.Column width={6}>
                            <Header size='huge'>
                                <Icon loading name='asterisk' size='massive' color='yellow' />
                                Loading...
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                </Grid>
            ) : (<>
                <div className='bg_image'>
                    <br />
                    <br />
                    <Container>
                        <Segment>
                            <Grid container centered>
                                <Grid.Row>
                                    {this.state.somethingWrong !== false && (
                                        <Message negative>
                                            <Message.Header>Something Wrong</Message.Header>
                                            <p>{this.state.somethingWrong}</p>
                                        </Message>
                                    )}
                                    {this.state.productKept !== false && (
                                        <Message positive>
                                            <Message.Header>Product Kept</Message.Header>
                                            <p>{this.state.productKept}</p>
                                        </Message>
                                    )}
                                </Grid.Row>
                                {s.commentData && <TheProductInfoToComment productInfoComment={s.productInfoComment}
                                    commentData={s.commentData} />}

                                <Grid.Row centered >
                                    <Grid.Column>
                                        <Divider />
                                    </Grid.Column>
                                </Grid.Row>


                                <>
                                    <Grid.Row centered>
                                        <p>You should pay {fee} yuan </p>
                                    </Grid.Row>
                                    <Grid.Row centered>
                                        <p> If you have finished your payment，please click NEXT</p>
                                    </Grid.Row>

                                    <Grid.Row columns={4}>

                                        <Grid.Column width={1}></Grid.Column>
                                        <Grid.Column width={7}>
                                            <Grid centered>
                                                <Grid.Row>
                                                    <Image size='tiny' centered src={"${BACKEND_URL}/" + this.state.AliPayIcon} />
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Popup
                                                        content={<Image src={"${BACKEND_URL}/" + this.state.AliPay }/>}
                                                        on='checked'
                                                        pinned
                                                        trigger={<Checkbox label='Alipay'
                                                            radio
                                                            name='Alipay'
                                                            value='Alipay'
                                                            checked={this.state.value === 'Alipay'}
                                                            onChange={this.handleChange} />}
                                                    />

                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                        <Grid.Column width={7}>
                                            <Grid centered>
                                                <Grid.Row>
                                                    <Image size='tiny' centered src={"${BACKEND_URL}/ "+ this.state.wechatPayIcon} />
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Popup
                                                        content={<Image src={"${BACKEND_URL}/ "+ this.state.wechatPay} />}
                                                        on='checked'
                                                        pinned
                                                        trigger={<Checkbox label='WechatPay'
                                                            radio
                                                            name='WechatPay'
                                                            value='WechatPay'
                                                            checked={this.state.value === 'WechatPay'}
                                                            onChange={this.handleChange} />}
                                                    />
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column >



                                        <Grid.Column width={1}></Grid.Column>

                                    </Grid.Row>
                                    <Grid.Row></Grid.Row>

                                    <Grid.Row centered>

                                        <Button color='orange' style={{ paddingLeft: 40, paddingRight: 40 }} onClick={this.onHandleSubmit}>Done</Button>

                                    </Grid.Row>
                                    <Grid.Row></Grid.Row>

                                </>





                            </Grid>
                        </Segment>
                    </Container>


                    <br />
                    <br />
                </div>

            </>)}
        </div>
    }

}

export default withRouter(KeepPurchase)
