import MainFilter from '../../allProduct/MainFilter';
import { useDispatch } from 'react-redux';
import { setDeleteModalOpen, setProductToDelete } from '../../../redux/slices/productSlice';

const MerchantProductMenu = ({
  selectedCount,
  totalCount,
  onSelectAll,
  selectedProducts
}) => {
  const dispatch = useDispatch();

  const handleBulkDelete = () => {
    dispatch(setProductToDelete(selectedProducts));
    dispatch(setDeleteModalOpen(true));
  };

  return (
    <div className="sticky top-0 z-50 bg-white shadow-md">
      <div className="bg-white p-6">
        {/* Header with Merchant Actions */}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-medium text-gray-900">My Products</h1>
          <div className="flex items-center gap-4">
            {totalCount > 0 && (
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectedCount === totalCount}
                  ref={checkbox => {
                    if (checkbox) {
                      checkbox.indeterminate = selectedCount > 0 && selectedCount < totalCount;
                    }
                  }}
                  onChange={onSelectAll}
                  className="h-4 w-4 text-brand-teal focus:ring-brand-teal border-gray-300 rounded"
                />
                <span className="text-sm font-medium text-gray-600">
                  {selectedCount === 0 
                    ? `${totalCount} products` 
                    : `Selected ${selectedCount} of ${totalCount}`}
                </span>
              </div>
            )}
            {selectedCount > 0 && (
              <button
                onClick={handleBulkDelete}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition duration-150 flex items-center gap-2"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                Delete Selected ({selectedCount})
              </button>
            )}
            <button
              onClick={() => window.location.href = '/merchantpage/product/new'}
              className="bg-brand-teal hover:bg-brand-teal-dark text-white px-4 py-2 rounded-md transition duration-150"
            >
              Add New Product
            </button>
          </div>
        </div>

        {/* Main Filter Component */}
        <MainFilter />
      </div>
    </div>
  );
};

export default MerchantProductMenu; 