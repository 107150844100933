import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const ROUTES = {
  LOGIN: '/loginfirst',
  CART: '/cart',
  CART_RENT: '/cart?rent=true',
  PAYMENT: '/payment',
  MESSAGE: '/message',
  HOME: '/'
};

export const navigate = (route, options = {}) => {
  const { state, replace = false } = options;
  
  if (state) {
    history.push(route, state);
  } else if (replace) {
    history.replace(route);
  } else {
    history.push(route);
  }
};

export const redirectToLogin = () => {
  navigate(ROUTES.LOGIN, { replace: true });
};

export const redirectToCart = (isRent = false) => {
  navigate(isRent ? ROUTES.CART_RENT : ROUTES.CART);
};

export const redirectToPayment = () => {
  navigate(ROUTES.PAYMENT);
};

export const redirectToMessage = (productInfo, merchantId) => {
  navigate(ROUTES.MESSAGE, {
    state: {
      product: productInfo,
      merchantId
    }
  });
};

export default {
  history,
  ROUTES,
  navigate,
  redirectToLogin,
  redirectToCart,
  redirectToPayment,
  redirectToMessage
}; 