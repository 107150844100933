import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleProductSelection, setDeleteModalOpen, setProductToDelete } from '../../../redux/slices/productSlice';

const MerchantProductCard = ({ onEdit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { products, loading } = useSelector(state => state.products);
  const selectedProducts = useSelector(state => state.products.selectedProducts);

  const handleClick = (e, productId) => {
    if (!e.target.closest('button')) {
      history.push('/product?id=' + productId);
    }
  };

  const handleSelect = (productId) => {
    dispatch(toggleProductSelection(productId));
  };

  const handleDelete = (productId) => {
    dispatch(setProductToDelete(productId));
    dispatch(setDeleteModalOpen(true));
  };

  if (!products) {
    return null;
  }

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {loading ? (
        Array(8).fill(0).map((_, index) => (
          <div 
            key={`skeleton-${index}`}
            className="bg-white rounded-lg shadow-sm overflow-hidden"
          >
            <div className="animate-pulse">
              <div className="aspect-w-3 aspect-h-4 bg-gray-200" />
              <div className="p-4">
                <div className="h-4 bg-gray-200 rounded w-3/4 mb-2" />
                <div className="h-4 bg-gray-200 rounded w-1/2 mb-2" />
                <div className="flex justify-between items-center mt-2">
                  <div className="h-4 bg-gray-200 rounded w-1/4" />
                  <div className="h-4 bg-gray-200 rounded w-1/4" />
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                  <div className="h-8 bg-gray-200 rounded w-16" />
                  <div className="h-8 bg-gray-200 rounded w-16" />
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        products.map(product => {
          // Calculate likes count safely
          console.log(product)
          const likeCount = product?.likes_count ?? (Array.isArray(product?.being_liked_by) ? product.being_liked_by.length : 0);

          return (
            <div key={product.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="relative">
                <input
                  type="checkbox"
                  checked={selectedProducts.includes(product.id)}
                  onChange={() => handleSelect(product.id)}
                  className="absolute top-2 left-2 h-4 w-4 text-brand-teal focus:ring-brand-teal border-gray-300 rounded z-10"
                />
                <div className="relative h-64 w-full">
                  <img
                    src={product.picture?.[0]?.url || 'placeholder-image-url'}
                    alt={product.name}
                    className="object-contain w-full h-full"
                  />
                </div>
              </div>
              <div className="p-4" onClick={(e) => handleClick(e, product.id)}>
                <h3 className="text-lg font-medium text-gray-900">{product.name}</h3>
                <div className="mt-2 flex items-center justify-between">
                  <span className="text-brand-orange font-medium">${product.price}</span>
                  <div className="flex items-center space-x-4">
                    <span className="text-gray-500 text-sm">Stock: {product.stock || 0}</span>
                    <div className="flex items-center space-x-1">
                      <svg className="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                      </svg>
                      <span className="text-gray-500 text-sm">{likeCount}</span>
                    </div>
                  </div>
                </div>
                {/* Transaction type labels commented out
                <div className="mt-2">
                  {product.forSell && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 mr-2">
                      For Sale
                    </span>
                  )}
                  {product.forRent && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mr-2">
                      For Rent
                    </span>
                  )}
                  {product.openToSwap && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                      Open to Swap
                    </span>
                  )}
                </div>
                */}
                <div className="mt-4 grid grid-cols-2 gap-2">
                  <button
                    onClick={() => onEdit(product.id)}
                    className="w-full bg-brand-teal hover:bg-brand-teal-dark text-white px-3 py-1.5 rounded-md text-sm transition duration-150"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(product.id)}
                    className="w-full bg-red-500 hover:bg-red-600 text-white px-3 py-1.5 rounded-md text-sm transition duration-150"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default MerchantProductCard; 