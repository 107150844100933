import React from 'react'
import OccasionAnimated from './introSec/occasionAnimated'
import SharingExchange from './introSec/sharingExchange'
import PickupMap from './introSec/sharingMap'
import IntroSec from './introSec/IntroSecVertical'
import SustainSec from './sustainSec/SustainSec'
import MerchantSec from './merchantSec/MerchantSec'
import Foot from '../foot/Foot'

const HomePage = () => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log(window.location);

    const handleLoad = () => {
      setLoading(false);
    };

    window.onload = handleLoad;

    // Fallback timer to hide loading state
    const timer = setTimeout(() => {
      setLoading(false);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-yellow-500 mb-4"></div>
          <h2 className="text-2xl font-semibold">Loading...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <div className="space-y-0">
        {/* Existing sections */}
        <section className="w-full">
          <SharingExchange />
        </section>

        <section className="w-full">
          <IntroSec />
        </section>

        <section className="w-full">
          <OccasionAnimated />
        </section>

        <section className="w-full">
          <PickupMap />
        </section>

        <section className="w-full">
          <SustainSec />
        </section>

        {/* Merchant Section */}
        <section className="w-full">
          <MerchantSec 
            pic="https://res.cloudinary.com/sheyou/image/upload/v1654418878/smiling_asian_girl_with_tablet_looking_at_camera_2022_01_19_00_09_04_utc_23ba1dbfe9.jpg"
          />
        </section>
      </div>

      <Foot />
    </div>
  );
};

export default HomePage;
