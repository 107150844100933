import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import productReducer from './slices/productSlice'
import newProductReducer from './slices/newProductSlice'
import errorReducer from './slices/errorSlice'
import { apiSlice } from './api/apiSlice'
import addressReducer from './slices/addressSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    products: productReducer,
    newProduct: newProductReducer,
    error: errorReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    address: addressReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware)
});

export default store;