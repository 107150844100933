import { BACKEND_URL } from '../../utils/config';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo, setToken, setMerchantLogin, handleMerchantRedirect } from '../../redux/slices/userSlice';
import axios from 'axios';
import Foot from '../foot/Foot';
import newOTP from 'otp-generators';
import emailjs from 'emailjs-com';
import MerchantSec from '../homePage/merchantSec/MerchantSec';
import jwt from 'jsonwebtoken';

const userLang = navigator.language || navigator.userLanguage;

const MerchantLog = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn, merchantLogin } = useSelector(state => state.user);

  const [formState, setFormState] = useState({
    username: '',
    password: '',
    rememberMe: false,
    open: false,
    somethingWrong: false,
    wrongOpen: false,
    buttonLoading: false
  });

  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const initializeComponent = async () => {
      try {
        // Check if already logged in as merchant and redirect if needed
        if (dispatch(handleMerchantRedirect())) {
          return;
        }

        // Handle remember me
        const remember = localStorage.getItem('rememberMe');
        if (remember === 'true') {
          const identifier = localStorage.getItem('identifier');
          const password = localStorage.getItem('password');
          setFormState(prev => ({
            ...prev,
            rememberMe: true,
            username: identifier,
            password: password
          }));
        }
      } catch (error) {
        console.error('Error initializing component:', error);
      } finally {
        setIsInitializing(false);
      }
    };

    initializeComponent();
  }, [isLoggedIn, merchantLogin, dispatch]);

  const handleCancel = () => {
    setFormState(prev => ({ ...prev, open: false }));
  };

  const onRigisterMerchant = () => {
    history.push('/merchantOpenShop');
  };

  const handleInputChange = (e, { name, value }) => {
    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const handleRememberMe = () => {
    setFormState(prev => ({ 
      ...prev,
      rememberMe: !prev.rememberMe 
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormState(prev => ({ ...prev, buttonLoading: true, somethingWrong: false }));

    const { username, password, rememberMe } = formState;
    
    try {
      const res = await axios.post(`${BACKEND_URL}/auth/local`, {
        identifier: username,
        password: password
      });

      if (res.status === 200) {
        const { user, jwt: token } = res.data;
        const { merchantInfo, merchantConfirmed, username: userName, email } = user;
        
        if (!merchantInfo?.isMerchant) {
          setFormState(prev => ({
            ...prev,
            open: true,
            username: userName,
            buttonLoading: false
          }));
          return;
        }

          // Store in Redux
          dispatch(setUserInfo(user));
          dispatch(setToken(token));
          dispatch(setMerchantLogin(true));

          // Handle remember me
          if (rememberMe) {
            localStorage.setItem('identifier', userName);
            localStorage.setItem('password', password);
            localStorage.setItem('rememberMe', 'true');
          }

        if (!merchantConfirmed) {
          // Handle email verification
          const verificationCode = newOTP.generate(6, {
            alphabets: false,
            upperCase: false,
            specialChar: false
          });

          const token = jwt.sign(
            { data: verificationCode },
            'sheyoufashion',
            { expiresIn: '5min' }
          );

          try {
            await emailjs.send(
              'service_orld5qb',
              'template_c1h9qsr',
              {
                email,
                reply_to: 'susanna.xinshu@gmail.com',
                name: userName,
                verificationCode
              },
              'user_4rMSnDYBigUutDlR5RSib'
            );
            
            localStorage.setItem('verificationCode', token);
            setFormState(prev => ({ ...prev, buttonLoading: false }));
            history.push('/emailConfirmationMerchant');
          } catch (error) {
            setFormState(prev => ({
              ...prev,
              buttonLoading: false,
              somethingWrong: 'Email confirmation failed. Please contact support.'
            }));
          }
          return;
        }

        // Use Redux for redirection for confirmed merchants
        setFormState(prev => ({ ...prev, buttonLoading: false }));
        history.push('/merchantpage');
      }
    } catch (error) {
      console.error('Login error:', error);
      setFormState(prev => ({
        ...prev,
        buttonLoading: false,
        somethingWrong: error.response?.status === 400
          ? 'Wrong identifier & password combination'
          : 'An error occurred. Please try again.'
      }));
    }
  };

  // Show loader while initializing
  if (isInitializing) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  const { username, password, rememberMe, buttonLoading, somethingWrong } = formState;
  const toLog = localStorage.getItem('toLog');

  return (
    <div className="min-h-screen">
      {/* Login Section - 100vh */}
      <div className="h-screen flex flex-col md:flex-row">
        {/* Left side - afrogirl */}
        {window.innerWidth >= 766 && (
          <div className="hidden md:block md:w-1/3 h-full afrogirl"></div>
        )}

        {/* Right side - login form */}
        <div className={`w-full md:w-2/3 h-full overflow-auto bg_color_image flex flex-col justify-center items-center px-8 md:px-12`}>
          {/* Welcome Header */}
          <div className="text-center mb-8">
            <h1 className="text-5xl text-white font-bold">Welcome, Merchants!</h1>
          </div>

          {/* Main Form Container */}
          <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-8">
                  {toLog === 'true' && (
              <div className="mb-6 bg-blue-50 text-blue-700 p-4 rounded">
                <h4 className="font-bold">Success</h4>
                      <p>Now You Can Log In</p>
              </div>
            )}

            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold">Log In</h2>
            </div>

            <form className="space-y-6">
              {/* Username/Email Input */}
              <div>
                <label className="block text-gray-700 mb-2">Username / Email</label>
                <div className="relative">
                  <input
                    type="text"
                        name="username"
                        value={username}
                    onChange={(e) => handleInputChange(e, { name: 'username', value: e.target.value })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent pl-10"
                    placeholder="Username / Email"
                  />
                  <span className="absolute left-3 top-3 text-gray-400">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
              </div>

              {/* Password Input */}
              <div>
                <label className="block text-gray-700 mb-2">Password</label>
                <div className="relative">
                  <input
                    type="password"
                        name="password"
                        value={password}
                    onChange={(e) => handleInputChange(e, { name: 'password', value: e.target.value })}
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent pl-10"
                    placeholder="Password"
                  />
                  <span className="absolute left-3 top-3 text-gray-400">
                    <i className="fas fa-lock"></i>
                  </span>
                </div>
              </div>

              {/* Remember Me and Forget Password Row */}
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={handleRememberMe}
                    className="h-4 w-4 text-brand-teal focus:ring-brand-teal border-gray-300 rounded"
                  />
                  <label className="ml-2 text-gray-700">Remember Me</label>
                </div>
                <Link to="/forgetpassword" className="text-brand-teal hover:text-brand-teal-dark">
                  Forget Password
                </Link>
              </div>

              {/* Error Message */}
              {somethingWrong && (
                <div className="bg-red-50 text-red-700 p-4 rounded mb-4">
                  <h4 className="font-bold">Login Failed</h4>
                  <p>{somethingWrong}</p>
                </div>
              )}

              {/* Buttons */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <button
                  onClick={handleSubmit}
                  disabled={buttonLoading}
                  className={`w-full px-6 py-2 text-white bg-brand-teal rounded-lg hover:bg-brand-teal-dark focus:outline-none focus:ring-2 focus:ring-brand-teal focus:ring-opacity-50 ${
                    buttonLoading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {buttonLoading ? (
                    <span className="flex items-center justify-center">
                      <span className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-white rounded-full"></span>
                      Loading...
                    </span>
                  ) : (
                    'Log In'
                  )}
                </button>

                <Link to="/merchantOpenShop" className="w-full">
                  <button className="w-full px-6 py-2 text-brand-teal bg-white border border-brand-teal rounded-lg hover:bg-brand-teal hover:text-white focus:outline-none focus:ring-2 focus:ring-brand-teal focus:ring-opacity-50">
                    Create Account
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Quote Section - 100vh */}
      <div className="h-screen relative bg-cover bg-center flex items-center" style={{
        backgroundImage: "url(https://res.cloudinary.com/sheyou/image/upload/v1709289479/fast_fashion_background_with_pile_of_cheap_low_qu_2022_02_04_19_14_24_utc_0c32e6bbb4.jpg)"
      }}>
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        <div className="relative container mx-auto px-4">
          <div className="max-w-4xl">
            <div className="text-white text-8xl mb-4">"</div>
            <h2 className="text-white text-5xl mb-8">
              {userLang === 'zh-CN' ? '没有衣服穿' : 'I Have Nothing To Wear'}
            </h2>
            <h3 className="text-white text-xl mb-6">
              {userLang === 'zh-CN'
                ? '研究表明，80%的女性平均仅将衣柜里的衣服穿五次'
                : 'Research shows 80% of women wear the clothing in their closets an average of 5 times.'}
            </h3>
            <h3 className="text-white text-xl">
              {userLang === 'zh-CN'
                ? '租可以减少购买服装的高额支出。既能满足想穿新衣服的愿望，又能减轻整理衣橱和洗衣服造成的负担。'
                : 'Renting can reduce high expenses on garments. Satisfy both the want to wear something new and release the stress of closet organizing and laundry.'}
            </h3>
          </div>
        </div>
      </div>

      {/* How it Works Section - 100vh */}
      <div className="h-screen relative bg-cover bg-center flex items-center" style={{
        backgroundImage: "url(https://res.cloudinary.com/sheyou/image/upload/v1654417910/three_warehouse_workers_doing_garment_stock_take_i_2022_03_07_23_58_38_utc_a576439648.jpg)"
      }}>
        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
        <div className="relative container mx-auto px-4 text-center">
          <h2 className="text-white text-5xl mb-16">
            {userLang === 'zh-CN' ? '商户怎样使用平台' : 'How it Works For Merchants'}
          </h2>
          <div className="max-w-2xl mx-auto">
            <h3 className="text-white text-xl mb-6">
                      {userLang === 'zh-CN'
                        ? '我们为个人、品牌、设计师和商家提供一个平台，用于出租、销售或交换他们的产品。'
                        : 'We provide individuals, brands, designers and merchants with a platform to rent out, sell, or trade their products.'}
            </h3>
            <h3 className="text-white text-xl mb-6">
                      {userLang === 'zh-CN'
                        ? '一切都是高度可定制的。您可以选择您的设计价格以及想要出租的时长。'
                        : 'Everything is highly customizable. You can choose how much your design is and how long you want to rent it out.'}
            </h3>
            <h3 className="text-white text-xl">
                      {userLang === 'zh-CN'
                        ? '您可以对客户进行评分，并决定他们是否需要支付押金。'
                        : 'You can rate your customers and decide if they need to pay deposit.'}
            </h3>
          </div>
        </div>
                </div>

      {/* MerchantSec Section - 100vh */}
      <div className="h-screen">
        <MerchantSec
          pic='https://res.cloudinary.com/sheyou/image/upload/v1654418469/snapshot_of_colorful_summer_stylish_outfits_and_pa_2021_09_02_23_57_46_utc_fa385d6696.jpg'
        />
      </div>

      {/* Confirmation Dialog */}
      {formState.open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm mx-4">
            <h3 className="text-lg font-semibold mb-4">You do not have a merchant account yet</h3>
            <p className="mb-6">Do you want to open one?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                No
              </button>
              <button
                onClick={onRigisterMerchant}
                className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <Foot />
    </div>
  );
};

export default MerchantLog;
