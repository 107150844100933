import { BACKEND_URL } from '../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../utils/config';
import React from 'react'
import axios from 'axios'

import {
  Grid,
  Message,
  Divider,
  Form,
  Button,
  Header,
  Icon
} from 'semantic-ui-react'

// import { Parallax,} from 'react-parallax';
// import Nav from '../nav/Nav'
import { withRouter } from 'react-router-dom'
import LoadingPhone from '../RedirectPages/LoadingPhone'

class ProfilePage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      token: '',
      identifier: '',

      userInfo: [],
      userID: 0,
      billinginformation: [],
      bodytype: '',
      bustsize: '',
      clothingsize: '',
      email: '',
      height: '',
      firstname: '',
      lastname: '',
      phonenumber: '',
      shippingaddress: [],
      weight: '',
      currentpassword: '',
      newpassword: '',
      verifypassword: '',
      CPChange: '',
      loading: true
    }
    this.getUserInfo = this.getUserInfo.bind(this)
    this.onFirstName = this.onFirstName.bind(this)
    this.onLastName = this.onLastName.bind(this)
    this.onBillinginformation = this.onBillinginformation.bind(this)
    this.onBodytype = this.onBodytype.bind(this)
    this.onBustsize = this.onBustsize.bind(this)
    this.onClothingsize = this.onClothingsize.bind(this)
    // this.onEmail=this.onEmail.bind(this)
    this.onHeight = this.onHeight.bind(this)
    this.onPhonenumber = this.onPhonenumber.bind(this)
    this.onShippingaddress = this.onShippingaddress.bind(this)
    this.onWeight = this.onWeight.bind(this)
    this.onCurrentpassword = this.onCurrentpassword.bind(this)
    this.onNewpassword = this.onNewpassword.bind(this)
    this.onVerifypassword = this.onVerifypassword.bind(this)

    this.onAccountDetailSave = this.onAccountDetailSave.bind(this)
    this.onFitPrefernceSave = this.onFitPrefernceSave.bind(this)
    this.onChangePasswordSave = this.onChangePasswordSave.bind(this)
    this.onChangeEmail = this.onChangeEmail.bind(this)
  }

  componentDidMount () {
    this.getUserInfo()

    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getUserInfo () {
    const self = this
    // const token=this.state.token
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    self.setState({
      identifier: identifier,
      token: token
    })

    axios(`${BACKEND_URL}/users/me`, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      const userInfo = [res.data]
      self.setState({
        userInfo: userInfo,
        userID: userInfo[0].id,
        billinginformation: userInfo[0].billinginformation,
        bodytype: userInfo[0].bodytype,
        bustsize: userInfo[0].bustsize,
        clothingsize: userInfo[0].clothingsize,
        email: userInfo[0].email,
        height: userInfo[0].height,
        firstname: userInfo[0].firstname,
        lastname: userInfo[0].lastname,
        phonenumber: userInfo[0].phonenumber,
        shippingaddress: userInfo[0].shippingaddress,
        weight: userInfo[0].weight,
        loading: false
      })
    })
  }

  onFirstName (event) {
    const self = this
    self.setState({
      firstname: event.target.value
    })
  }

  onLastName (event) {
    this.setState({
      lastname: event.target.value
    })
  }

  onBillinginformation (event) {
    this.setState({
      billinginformation: event.target.value
    })
  }

  onBodytype (event) {
    this.setState({
      bodytype: event.target.value
    })
  }

  onBustsize (event) {
    this.setState({
      bustsize: event.target.value
    })
  }

  onClothingsize (event) {
    this.setState({
      clothingsize: event.target.value
    })
  }

  onHeight (event) {
    this.setState({
      height: event.target.value
    })
  }

  onPhonenumber (event) {
    this.setState({
      phonenumber: event.target.value
    })
  }

  onShippingaddress (event) {
    this.setState({
      shippingaddress: event.target.value
    })
  }

  onWeight (event) {
    this.setState({
      weight: event.target.value
    })
  }

  onCurrentpassword (event) {
    this.setState({
      currentpassword: event.target.value
    })
  }

  onNewpassword (event) {
    this.setState({
      newpassword: event.target.value
    })
  }

  onVerifypassword (event) {
    this.setState({
      verifypassword: event.target.value
    })
  }

  onAccountDetailSave () {
    const self = this
    const firstname = self.state.firstname
    const lastname = self.state.lastname
    const phonenumber = self.state.phonenumber

    const id = self.state.userID
    const token = self.state.token

    // axios.put(`${BACKEND_URL}/users/`+id, {
    //     data:{
    //         firstname:firstname,
    //         lastname:lastname,
    //         phonenumber:phonenumber
    //     },
    //     headers: {
    //         'Authorization': 'Bearer '+token,
    //             }
    //   }).then(function(res){
    //     console.log(res)
    //   })

    axios(`${BACKEND_URL}/users/` + id, {
      method: 'put',
      data: {
        firstname: firstname,
        lastname: lastname,
        phonenumber: phonenumber
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res)
        if (res.status === 200) {
          self.setState({
            ADChange: true
          })
        } else {
          self.setState({
            ADChange: false
          })
        }
      })
      .catch(function (err) {
        self.setState({
          ADChange: false
        })
      })
  }

  onFitPrefernceSave () {
    const self = this
    const weight = self.state.weight
    const bodytype = self.state.bodytype
    const bustsize = self.state.bustsize
    const height = self.state.height
    const clothingsize = self.state.clothingsize

    const id = self.state.userID
    const token = self.state.token

    axios(`${BACKEND_URL}/users/` + id, {
      method: 'put',
      data: {
        weight: weight,
        bodytype: bodytype,
        bustsize: bustsize,
        clothingsize: clothingsize,
        height: height
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res)
        if (res.status === 200) {
          self.setState({
            FPChange: true
          })
        } else {
          self.setState({
            FPChange: false
          })
        }
      })
      .catch(function (err) {
        self.setState({
          FPChange: false
        })
      })
  }

  onChangePasswordSave () {
    const self = this
    const currentpassword = self.state.currentpassword
    const newpassword = self.state.newpassword
    const verifypassword = self.state.verifypassword
    const password = localStorage.getItem('password')

    const id = self.state.userID
    const token = self.state.token

    if (password === currentpassword) {
      if (newpassword === verifypassword) {
        axios(`${BACKEND_URL}/users/` + id, {
          method: 'put',
          data: {
            password: newpassword
          },
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
          .then(function (res) {
            console.log(res)
            if (res.status === 200) {
              localStorage.setItem('password', newpassword)
              self.setState({
                CPChange: true
              })
            } else {
              self.setState({
                CPChange: false
              })
            }
          })
          .catch(function (err) {
            self.setState({
              CPChange: false
            })
          })
      } else {
        self.setState({
          inputNewPassword: false
        })
      }
    }
    if (password !== currentpassword) {
      self.setState({ inputCurrentPassword: false })
    }
  }

  onChangeEmail (event) {
    const self = this
    const oldEmail = event.target.name
    console.log(oldEmail)
    localStorage.setItem('oldEmail', oldEmail)
    self.props.history.push('/changeEmail')
  }

   toPrevios(){
        window.history.go(-1)
      }

  render () {
    const self = this
    const userInfo = self.state.userInfo

    console.log(userInfo)
    const ADChange = self.state.ADChange

    return (
      <>
        {this.state.loading ? (
          <LoadingPhone />
        ) : (
          <Grid container columns='equal'>
           
            <Grid.Row></Grid.Row>

            <Grid.Row>
                <Grid.Column>
                <Icon name='chevron left'  onClick={self.toPrevios}/>
                </Grid.Column>
                <Grid.Column>
                <Header>Account Details</Header>
                </Grid.Column>
              
            </Grid.Row>

            <Divider />

            <Grid.Row columns={1}>
              <Grid.Column width={16}>
                {userInfo.length !== 0 && (
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Input
                        disabled
                        label='Email'
                        type='Email'
                        placeholder='Email'
                        value={self.state.email}
                      />

                      <Form.Button
                        style={{ marginTop: 24 }}
                        name={self.state.email}
                        onClick={this.onChangeEmail}
                        content='Change Your Email'
                      />
                    </Form.Group>

                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='First name'
                        value={self.state.firstname}
                        onChange={this.onFirstName}
                      />

                      <Form.Input
                        fluid
                        label='Last name'
                        value={self.state.lastname}
                        onChange={this.onLastName}
                      />
                    </Form.Group>

                    <Form.Input
                      label='Phonenumber'
                      type='Phonenumber'
                      placeholder='Phonenumber'
                      value={self.state.phonenumber}
                      onChange={this.onPhonenumber}
                    />

                    {/* <Form.Checkbox label='save as default address' /> */}
                  </Form>
                )}
              </Grid.Column>
            </Grid.Row>

            {ADChange === true && (
              <Grid.Row>
                <Message
                  success
                  header='Change Success'
                  content='You have successfully changed your account detail'
                />
              </Grid.Row>
            )}
            {ADChange === false && (
              <Grid.Row>
                <Message
                  success
                  header='Change Failure'
                  content='Sorry, Something Went Wrong'
                />
              </Grid.Row>
            )}

            <Grid.Row centered>
              <Button
                color='orange'
                style={{ paddingLeft: 40, paddingRight: 40 }}
                onClick={this.onAccountDetailSave}
              >
                SAVE
              </Button>
            </Grid.Row>
          </Grid>
        )}
      </>
    )
  }
}

export default withRouter(ProfilePage)
