import { BACKEND_URL } from '../../../utils/config';
import { getBackendURL, ENDPOINTS } from '../../../utils/config';
import React from 'react'

import {
  Grid,
  Header,
  Icon,
  Segment,
  Button,
  Form,
  Input,
  Divider,
  Message,
  Checkbox
} from 'semantic-ui-react'

// import { countrySelect } from './merchantVerify/CountrySelect'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
// import newOtp from 'otp-generators'
import ContactAddress from './storeInfo/ContactAddress'
import MobilePhone from './storeInfo/MobilePhone'
import IdentityDetail from './storeInfo/IdentityDetail'
import CreditCard from './storeInfo/CreditCard'
import LoadingPhone from '../../RedirectPages/LoadingPhone'

class MerchantStorePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      storeName: '',
      verified: false,
      userInfo: '',
      userID: '',
      saved: false,
      merchantInfo: '',
      somethingWrong: false,
      saveLoading: false
    }
    this.handleStoreName = this.handleStoreName.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
  }

  componentDidMount () {
    const self = this
    const log = localStorage.getItem('merchantLogin')
    self.setState({ log: log })
    if (log === 'true') {
      self.getUserInfo()
    }

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getUserInfo () {
    const self = this
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    self.setState({
      identifier: identifier,
      token: token
    })

    axios(`${BACKEND_URL}/users/me`, {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res)
        const userInfo = [res.data]
        if (userInfo === null) {
          self.setState({
            userInfo: []
          })
        } else if (userInfo.length > 0) {
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id
          })
        }

        const merchantInfo = userInfo[0].merchantInfo
        console.log(merchantInfo)
        if (merchantInfo !== null) {
          self.setState({ merchantInfo: merchantInfo })
        }

        if (
          merchantInfo.storeName !== null &&
          merchantInfo.storeName !== undefined
        ) {
          self.setState({
            storeName: merchantInfo.storeName
          })
        }

        console.log(userInfo)
      })

      .catch(function (err) {
        self.setState({
          somethingWrong: 'Something Went Wrong'
        })
        self.props.history.push('/loginfirst')
      })
  }

  handleStoreName (event) {
    this.setState({
      storeName: event.target.value
    })
  }

  handleSaveClick () {
    const self = this
    const s = self.state
    const userID = this.state.userID
    const token = localStorage.getItem('token')
    const storeName = s.storeName

    self.setState({ saveLoading: true })

    const merchantInfo = Object.assign(s.merchantInfo, { storeName: storeName })
    console.log(merchantInfo)

    axios(`${BACKEND_URL}/users/` + userID, {
      method: 'put',
      data: { merchantInfo },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (response) {
        console.log(response)
        self.setState({
          saved: true,
          somethingWrong: false,
          saveLoading: false
        })
        // console.log(s.merchantInfo)
      })

      .catch(function (err) {
        self.setState({
          somethingWrong: 'something is wrong' + err,
          saveLoading: false
        })
      })
  }
  toPrevios () {
    window.history.go(-1)
  }

  render () {
    const self = this
    const s = this.state

    return (
      <>
        {this.state.loading ? (
          <LoadingPhone />
        ) : (
          <Segment>
            <Grid container columns='equal' style={{ marginBottom: 10,  marginTop: 10 }}>
              <Grid.Row>
                <Grid.Column width={1} >
                  <Icon
                    name='chevron left'
                    onClick={self.toPrevios}
                    
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header>Store Detail</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={16}>
                  <Divider />
                  <Form>
                    <Form.Field width={6}>
                      <label>Store Name</label>
                      <Input
                        value={s.storeName}
                        onChange={this.handleStoreName}
                        placeholder='Store Name'
                      />
                    </Form.Field>
                    <div>
                      <Grid>
                        <Grid.Row columns={2}>
                          <Grid.Column style={{ paddingRight: 20 }}>
                            <Form>
                              <Form.Input
                                label='Business Email'
                                required
                                placeholder='Business Email'
                                // width={5}
                                // value={this.state.email}
                                // onChange={this.onEmail}
                              />
                              <Form.Field
                                control={Checkbox}
                                label='this email will be used to receive payments from customers'
                              />
                            </Form>
                          </Grid.Column>

                          <Grid.Column
                            style={{ paddingTop: 25, paddingLeft: 20 }}
                          >
                            <Button color='orange'>
                              Create/ connect a business paypal account
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <Divider vertical style={{ height: 30, marginTop: 25 }}>
                        or
                      </Divider>
                    </div>

                    {/* <Form.Field>
                                        <label>Store ID</label>
                                        <Input 
                                            action={{
                                                color: 'teal',
                                                labelPosition: 'left',
                                                icon: 'copy',
                                                content: 'Copy'
                                            }}
                                            actionPosition='right'
                                            fluid 
                                            placeholder='Store ID' 
                                            value={this.state.storeId}
                                            readOnly
                                        />
                                    </Form.Field> */}
                  </Form>
                  <br />
                  <Grid.Row>
                    {s.somethingWrong !== false && (
                      <Message warning>
                        <Message.Header>Somthing Went Wrong</Message.Header>
                        <p>{this.state.somethingWrong}</p>
                      </Message>
                    )}
                    {s.saved === true && s.saveLoading === false && (
                      <Message positive>
                        <Message.Header>Saved</Message.Header>
                        <p>You have saved your store name</p>
                      </Message>
                    )}
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={3}>
                <Grid.Column width={6}>
                  <Button
                    loading={this.state.saveLoading}
                    onClick={this.handleSaveClick}
                    fluid
                  >
                    SAVE
                  </Button>
                </Grid.Column>
              </Grid.Row>

              {/* <Grid.Row columns={1}>
                            <Grid.Column width={5}>
                                <Header >Store Logo</Header>
                                <Divider/>
                                <Image src='https://res.cloudinary.com/sheyou/image/upload/v1649165084/_b7d786a54e.png?21902375.5' />
                            </Grid.Column>
                        </Grid.Row> */}

              {/* <Grid.Row columns={1}>
                <Grid.Column width={16}>
                  <Header>Address</Header>
                  <Divider />
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Select
                        onChange={this.state.handleCountry}
                        fluid
                        options={countries}
                        placeholder='Country'
                        label='Country'
                      />

                      <Form.Input
                        onChange={this.state.handlestate}
                        fluid
                        placeholder='state'
                        label='state'
                      />
                      <Form.Input
                        onChange={this.state.handleExactAddress}
                        fluid
                        placeholder='City'
                        label='City'
                      />
                    </Form.Group>
                    <Form.Input
                      fluid
                      label='Exact Address'
                      placeholder='Exact Address'
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row> */}

              <ContactAddress />

              <MobilePhone />

              <CreditCard />

              <IdentityDetail />
            </Grid>
          </Segment>
        )}
      </>
    )
  }
}

export default withRouter(MerchantStorePhone)
